import { Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { writeFile } from 'xlsx';
// import {
//   statisticsForTeamAssignment,
//   statisticsForAssignmentStrings,
// } from '../../../../lib/strings/statistics';
import { statisticsTeamAssignment } from './apiToTabel';
import {ByUltraUserSigup} from '../../../../services/signinService'
import {
  getStatisticsForAdminTeamAssignment,
  deleteUserByUltraAdmin,
  getStatisticsForLogin,  
} from '../../../../services/statisticsService';
import { updateUserPermissions } from '../../../../services/statisticsService';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { showErrorMessage } from '../../../../services/candyEventService';


export default function StatisticsForAssignmentForAdmin(props) {
  const { language } = props;
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const [phoneValid, setPhoneValid] = useState(true);
  useEffect(() => {
    fetchStatisticsTeamAssignment();
  }, []);

  const fetchStatisticsTeamAssignment = async () => {
    try {
      const response = await getStatisticsForAdminTeamAssignment();
      setUserData(response['List']);
    } catch (error) {
      console.error('Failed to fetch statistics for admin:', error);
    }
  };

  const statisticsQuery = useQuery(
    ['StatisticsForAssignment', { ...currentFilters, page: currentPage }],
    fetchStatisticsTeamAssignment,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
  };

  const showSuccessMessage = (title) => {
    notification.success({
      message: `${title}`,
      duration: 10,
    });
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };

  const handleUpdatePermissions = async (username, userId, permissions) => {
    try {
      await updateUserPermissions(username, userId, permissions);
      if (userId){  
      showSuccessMessage('User Data Updated successfully');
      }else{
        showErrorMessage('Details Are not Updated,Please check the Data')
      }
    } catch (error) {   
      showErrorMessage('Something Went Wrong');
      console.error('Failed to update user permissions:', error);
    }
  };

  const handleDeleteUser = async (userId) => {   
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {     
        await deleteUserByUltraAdmin(userId);
        showSuccessMessage('User Deleted successfully');     
        setTimeout(() => {         
          window.location.reload();
        }, 1000);
      } catch (error) {       
        console.error('Failed to delete user:', error);
      }
    }
  };

  const XLSX = require('xlsx');
  const handleLoginStats = async (userId) => {
    try {
      const response = await getStatisticsForLogin(userId);
      if (typeof response['list'] === 'object') {
        const sheetData = response['list'].map((item) => {
          const loginDate = new Date(item.created).toLocaleDateString();
          const loginTime = new Date(item.created).toLocaleTimeString();
          return [
            item.user_id,
            response['user_details'].username,
            response['user_details'].phoneNumber,
            loginDate,
            loginTime,
          ];
        });

        // Create the workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(sheetData);

        // Add column headers
        const headers = ['User ID', 'User Name', 'Phone Number', 'Login Dates', 'Login Time'];
        XLSX.utils.sheet_add_aoa(sheet, [headers], { origin: 'A1' });

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, sheet, 'User Login Stats');

        // Generate a Blob from the workbook
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Create a download link and trigger the download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${response['user_details'].username}_${response['user_details'].phoneNumber}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
        showSuccessMessage('User Statistics Fetched successfully');
      } else {
        console.error('Invalid response format. Expected an object.');       
      }
    } catch (error) {   
      console.error('Failed to fetch user login stats:', error);
    }
  };

  const handlePermissionChange = (index, permission) => {
    const updatedUserData = [...userData];
    const user = updatedUserData[index];
    const permissions = user.userPermissions.split(',').map(Number);

    if (permission === 1) {
      const isSuperadminAllChecked = !user.isSuperadminAllChecked;

      if (isSuperadminAllChecked) {
        user.userPermissions = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16';
      } else {
        user.userPermissions = '';
      }

      user.isSuperadminAllChecked = isSuperadminAllChecked;
    } else {
      const updatedPermissions = permissions.includes(permission)
        ? permissions.filter((p) => p !== permission)
        : [...permissions, permission];

      if (!user.isSuperadminAllChecked) {
        user.userPermissions = updatedPermissions.join(',');
      }

      if (updatedPermissions.length === 16) {
        user.isSuperadminAllChecked = true;
      } else {
        user.isSuperadminAllChecked = false;
      }
    }

    setUserData(updatedUserData);
    updateUserPermissions(user.username, user._id, user.userPermissions);
  };


  const handleAddUser = async (newUser) => {   
    try {
      const newUserPermissions = newUser.userPermissions;   
      const userDataForSignup = {
        username: newUser.username,
        phoneNumber: newUser.phoneNumber,
        firstName: newUser.firstName,
        userPermissions: newUserPermissions,
        new_role: 1000,
      };    
      await ByUltraUserSigup(userDataForSignup);  
      // If the user is successfully added, remove the "isNewRow" flag
      newUser.isNewRow = false;
      setUserData([...userData]);
      showSuccessMessage('User Added successfully');
    } catch (error) {
      // Handle API call error
      showErrorMessage('Failed to add the user');
      console.error('Failed to add the user:', error);
    }
  };
  
  const handlePhoneNumberChange = (event, index) => {
    const updatedPhoneNumber = event.target.value;
    const updatedUserData = [...userData]; // Clone the userData array to avoid mutating state directly
    updatedUserData[index].phoneNumber = updatedPhoneNumber; 
    setUserData(updatedUserData); // Update the state with the new userData array
  };
  const handleNameChange = (event, index) => {
    const updatedFullName = event.target.value;
    const updatedUserData = [...userData];  
    updatedUserData[index].firstName = updatedFullName;
    setUserData(updatedUserData);
  };
    
  const columns = [
    {
      title: 'Date',
      render: (_, record) => {
        const date = new Date(record.created);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        });

        return (
          <div key={record.id}>
            <span className="dateLbl">{formattedDate}</span>
            <div style={{ marginTop: '5px', marginRight: '5px' }}>
              <button
                className="ant-btn default-btn xs ml-5 mr-5"
                onClick={() => handleLoginStats(parseInt(record.uid))}
              >
                Login Stats
              </button>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Teamprofile',
      render: (_, record, index) => (
        <div key={record.id}>
          <div className="teamLoop">
            <span className="lblText">Name:</span>
            <input
              className="inpBlock"
              type="text"
              name="name"
              placeholder="Name"
              value={record.firstName || ''}
              onChange={(event) => handleNameChange(event, index)}
              readOnly={!record.isNewRow}
            />
            
          </div>
          <div className="teamLoop">
            <span className="lblText">Username:</span>
            <input
              type="text"
              className="inpBlock"
              name="username"
              placeholder="Username"
              value={record.username || ''}
              onChange={(event) => {
                const updatedUserData = [...userData];
                updatedUserData[index].username = event.target.value;
                setUserData(updatedUserData);
              }}              
            />
          </div>
          <div className="teamLoop">
            <span className="lblText">Phone no:</span>
            <input
              type="text"
              className="inpBlock"
              name="phoneNumber"
              placeholder="Phone No."
              value={record.phoneNumber || ''}
              onChange={(event) => {
                // const isValid = validatePhoneNumber(event, index);
                // console.log("$$$$$",isValid)
                // if (isValid) {
                    handlePhoneNumberChange(event, index);
                // }
            }}
            
              // onBlur={(event) => validatePhoneNumber(event, index)} // Call validatePhoneNumber on blur
              readOnly={!record.isNewRow}
           />             
          </div>
          {/* Render checkboxes for AccessSetting */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Checkbox inputs */}
          </div>
          <br />
        </div>
      ),
    },
    
    {
      title: 'AccessSetting',
      render: (_, record, index) => {
        const permissions = record.userPermissions.split(',').map(Number);
        const hasPermission = (permission) => permissions.includes(permission);

        return (
          <div key={record.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <div className="accessLoop">
                  <input
                    className="mr-3"
                    type="checkbox"
                    name="upper"
                    checked={hasPermission(1)}
                    onChange={() => handlePermissionChange(index, 1)} // Example: Toggle permission 1
                  />
                  Admin {'>'} All
                </div>
  
                  <div className='accessLoop'>
                    <input
                      className='mr-3'
                      type="checkbox"
                      name="mid"
                      checked={hasPermission(6)}
                      onChange={() => handlePermissionChange(index,6)}
                    />
                    Admin {`>`}kit
                  </div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="lower" checked={hasPermission(10)}
                    onChange={() => handlePermissionChange(index,10)} />Admin{'>'}Dome Addr.</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(2)} 
                    onChange={() => handlePermissionChange(index,2)}/>Admin{'>'}Charactors</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(7)} 
                    onChange={() => handlePermissionChange(index,7)}/>Admin{'>'}Request</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(11)} 
                    onChange={() => handlePermissionChange(index,11)}/>Admin{'>'}Dome Build</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(3)} 
                    onChange={() => handlePermissionChange(index,3)}/>Admin{'>'}Stats</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(8)} 
                    onChange={() => handlePermissionChange(index,8)}/>Admin{'>'}Notifs</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(13)} 
                    onChange={() => handlePermissionChange(index,13)}/>Admin{'>'}Dome Admin</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(4)} 
                    onChange={() => handlePermissionChange(index,4)}/>Admin{'>'}Candy C.</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(9)} 
                    onChange={() => handlePermissionChange(index,9)}/>Admin{'>'}Users</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(5)} 
                    onChange={() => handlePermissionChange(index,5)}/>Admin{'>'}Idol</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(12)}
                    onChange={() => handlePermissionChange(index,12)} />Admin{'>'}Account</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(16)} 
                    onChange={() => handlePermissionChange(index,16)}/>Admin{'>'}Help Admin</div>
                  </div>
                  
                  {record.isNewRow ? null : (
              <div>
                <div style={{ marginTop: '5px' }}>
                  <button
                    type="button"
                    className="ant-btn yellow-gradient xs ml-5 mr-5"
                    onClick={() =>
                      handleUpdatePermissions(record.username, record._id, record.userPermissions)
                    }
                  >
                    Update
                  </button>
                </div>
                <div style={{ marginTop: '5px' }}>
                  <button
                    type="button"
                    className="ant-btn default-btn xs ml-5 mr-5"
                    onClick={() => handleDeleteUser(record._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
            </div>
            <br />
          </div>
        );
      },
    },
    {
      title: () => (
        <button
          className="ant-btn ant-btn-primary"
          onClick={addNewRow}          
        >
          +
        </button>
      ),
      render: (_, record) => {
        if (record.isNewRow) {
          return (
            <div>
              <button
                type="button"
                className="ant-btn yellow-gradient xs ml-5 mr-5"
                onClick={() => handleAddUser(record)}
                disabled={!phoneValid} // Disable the button if phone is not valid
              >
                Add
              </button>
            </div>
          );
        }
        return null;
      },
    },
    
  ];
  const addNewRow = () => {
    const newRow = {
      id: Date.now(), // Unique identifier for the new row
      firstName: '',
      lastName: '',
      username: '',
      phoneNumber: '',
      userPermissions: '',
      isSuperadminAllChecked: false,
      isNewRow: true, // Add a flag to indicate this is a newly added row
    };
    setUserData([newRow, ...userData]);
  };
  
  // Function to delete a row
  const deleteRow = (index) => {
    const updatedData = [...userData];
    updatedData.splice(index, 1);
    setUserData(updatedData);
  };

  columns.forEach((column) => {
    if (typeof column.title === 'function') {
      const TitleComponent = column.title;
      column.title = (
        <div style={{ textAlign: 'center' }}>
          <TitleComponent />
        </div>
      );
    }
  });

  return (
      <div className='tableAdmin' style={{maxHeight: '396px' }}>
        <Table
        style={{ backgroundColor: '#f1f1f1' }} /* Replace this with the shade of gray you want */
        className='table-scroll-bar'
        columns={columns}
        dataSource={userData}
        pagination={false}
        scroll={{ y: 300 }}
      />
      </div>
  );
}


