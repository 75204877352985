import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, notification } from 'antd';
import {
  maximumAllowedMediaSize,
  typeJPEG,
  typePNG,
  typeGIF,
  typeWEBP,
  typeMP4,
  typeQuickTime,
  typeAVI,
  typeWMV,
} from '../lib/config';
import UploadIcon from '../assets/ad_placeholder.svg';
import ReactPlayer from 'react-player';
import * as treasureCreation from '../lib/strings/treasureCreation';
import { baseURL } from '../lib/config';
import PropTypes from 'prop-types';

function UploadFile(props) {
  const {
    label,
    isVideo,
    initialFileUrl,
    valueUrl,
    onChange,
    size,
    formClassName,
    formOther,
    language,
    rowType,
    rowJustify,
    disableFileSelect,
  } = props;

  const [currentFile, setCurrentFile] = useState();
  const [currentFileUrl, setCurrentFileUrl] = useState<string | ArrayBuffer>('');
  const [fileType, setFileType] = useState<string>();

  // useEffect(() => {
  //     if (currentFileUrl) {
  //         onChange({file: currentFile, fileUrl: currentFileUrl, fileType: fileType})
  //     }
  // }, [currentFileUrl])

  useEffect(() => {
    if (valueUrl !== currentFileUrl) {
      setCurrentFileUrl(valueUrl);
    }
  }, [valueUrl]);

  const inputRef: any = useRef();

  const newSelectDisplay = (fileUrl, fileType: 'video' | 'image', size: 'small') => {
    let fileUrlToShow = fileUrl;
    if (!fileUrl && fileUrl === '' && initialFileUrl) {
      fileUrlToShow = `${baseURL}${initialFileUrl}`;
    }
    switch (fileType) {
      case 'image':
        if (fileUrlToShow) {
          return <img src={fileUrlToShow} className="upload-sticker-image adjust" />;
        } else if (size === 'small') {
          return (
            <img
              src={UploadIcon}
              className="upload-sticker-image"
              style={{ width: '21px', height: '28px' }}
            />
          );
        }
        return <img src={UploadIcon} className="upload-sticker-image" />;
      case 'video':
        if (fileUrlToShow) {
          return <ReactPlayer url={`${fileUrlToShow}`} controls height="auto" />;
        }
        return <img src={UploadIcon} className="upload-sticker-image" />;
      default:
        console.warn('wrong case');
    }
  };

  const supportedFileTypes = isVideo
    ? [typeMP4, typeQuickTime, typeAVI, typeWMV]
    : [typeJPEG, typePNG, typeGIF, typeWEBP];
  const handleFile = (event: any) => {
    event.preventDefault();

    const file = event.target.files[0];
    const type = file.type;
    if (supportedFileTypes.indexOf(type) > -1 && file.size < maximumAllowedMediaSize) {
      const reader = new FileReader();

      setCurrentFile(file);
      setFileType(type);

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setCurrentFileUrl(reader.result);
        onChange({ file: file, fileUrl: reader.result, fileType: type });
      };
    } else {
      notification.error({
        message: treasureCreation.TreasureCreationImageUploadMessage[language],
        description: treasureCreation.TreasureCreationImageUploadDescription[language],
      });
    }
  };

  const handleRefClick = (event) => {
    event.persist();
    inputRef.current.value = '';
    inputRef.current.click();
  };

  return (
    <Form.Item {...formOther} className={formClassName} label={label}>
      <Row type={rowType} justify={rowJustify}>
        <Col style={{ width: '100%' }}>
          <input
            type="file"
            onChange={handleFile}
            ref={inputRef}
            style={{ visibility: 'hidden' }}
            accept={supportedFileTypes.join(', ')}
          />
          <div className="upload" onClick={!disableFileSelect ? handleRefClick : () => {}}>
            {newSelectDisplay(currentFileUrl, isVideo ? 'video' : 'image', size)}
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
}

UploadFile.propTypes = {
  isVideo: PropTypes.bool,
  label: PropTypes.string,
  valueUrl: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', '']),
  formClassName: PropTypes.string,
  formOther: PropTypes.object,
  language: PropTypes.string,
  rowType: PropTypes.string,
  rowJustify: PropTypes.string,
  id: PropTypes.string,
  disableFileSelect: PropTypes.bool,
};

UploadFile.defaultProps = {
  isVideo: false,
  label: '',
  initialFileUrl: '',
  valueUrl: '',
  onChange: () => {},
  size: '',
  formClassName: 'roboto-regular image-upload-req',
  language: 'EN',
  rowType: 'flex',
  rowJustify: 'center',
  disableFileSelect: false,
};

export default UploadFile;
