import React from 'react';
import LocationMapControl from './LocationMapControl';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import * as treasureActions from '../../actions/treasureActions';

interface LocationsMapControlListProps {
  locations: any[];
  selectLocation?: (e: any) => void;
  mapInstance: any;
  selectedLocation: number;
  updateMapAddress: () => void;
}

const actionCreators = {
  selectLocation: treasureActions.selectedLocation,
  updateMapAddress: treasureActions.updateMapAddressOnIdle,
};

const mapStateToProps = (state: { locations: any; address: any; selectedLocation: any }) => {
  const { locations, address, selectedLocation } = state;
  const { mapInstance } = address;
  return { locations, mapInstance, selectedLocation };
};

const LocationsMapControlList = (props: LocationsMapControlListProps) => {
  const changeSelectedLocationAndPan = (e: any) => {
    props.selectLocation(e.target.value);

    setTimeout(() => {
      const location = props.locations[e.target.value];
      props.mapInstance.panTo({ lat: location.latitude, lng: location.longitude });
    }, 1000);

    setTimeout(() => {
      props.updateMapAddress();
    }, 5000);
  };

  const locationsList: JSX.Element[] = [];

  for (let i = 0; i < props.locations.length; i++) {
    locationsList.push(<LocationMapControl key={i} index={i} />);
  }

  return (
    <Radio.Group className="location-list" onChange={changeSelectedLocationAndPan}>
      {locationsList}
    </Radio.Group>
  );
};

export default connect(mapStateToProps, actionCreators)(LocationsMapControlList);
