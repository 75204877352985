import React from 'react';
import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import { importDomeEditing } from '../../../../services/domeService';
import {b} from '../../../dashboard/menu/i18next'

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

const errorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class DomeNotice extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      thisTime: true,
      color: this.props.color ? this.props.color : '',
      addr_name: '',
    };
  }

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  inputHandler = (e: any) => {
    this.setState({
      addr_name: e.target.value,
    });
    if (e.target.value) {
      this.setState({ thisTime: false });
    } else {
      this.setState({ thisTime: true });
    }
  };

  importImageUrl = async (e: any) => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    const addr_name = profile.edit_current_dome.addr_name.trim();
    const import_addr_name = this.state.addr_name.trim();
    if (addr_name == import_addr_name) {
      errorMessage(
        b?.t('Import dome address name should not be same as current dome address name.')
      );
      return false;
    }
    const type = 1;
    let importImageUrlData = await importDomeEditing(
      id,
      this.state.addr_name,
      type,
      this.state.color
    );
  };

  render() {
    const context = this.context;
    return (
      <div id="tab-2" className="tab-pane ">
        <div className="mb-10">
          <i className="blueBox mr-5"></i>
          <span className="text-primary mr-5">Noticeboard</span>
          <i>
            <img src={infoIcon} alt="Info" height="14" />
          </i>
        </div>

        <div className="grid-row border-bottom pb-10">
          <div className="col col-2"></div>
          <div className="col col-10">
            <div className="grid-row sm">
              <div className="col col-2">
                <h4 className="text-primary">Import</h4>
              </div>
              <div className="col col-10">
                <label>Dome Name to import</label>
                <div className="fx-jcsb-aic mb-5">
                  <input
                    placeholder="Enter Dome Name"
                    onChange={this.inputHandler}
                    type="text"
                    value={this.state.addr_name}
                    className="form-control xs ml-5"
                  />
                  <button
                    onClick={this.importImageUrl}
                    className="ant-btn primary-ghost-btn xs ml-5"
                  >
                    Verify
                  </button>
                </div>
                <div className="fx-aic">
                  <span className="size12 mr-5">Not at this time</span>
                  <input type="checkbox" checked={this.state.thisTime} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-15 pb-15">
          <p className="text-center text-primary">Go to Dome Admin to edit</p>
        </div>
      </div>
    );
  }
}

export default DomeNotice;
