// import React from 'react';

// import { notification, Row, Spin } from 'antd';
// import AppContext from '../../../../AppContext';
// import closeIcon from '../../../../../src/assets/closeXsmall.svg';
// import { baseURL } from '../../../../lib/config';

// import burgerKing from '../../../../../src/assets/burgerKing.png';
// import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
// import defaultBackground from '../../../../../src/assets/dome-preview.png';
// import logoPic from '../../../../../src/assets/logoPic.png';
// import {
//   FileUpload,
//   getDomeTypes,
//   UpdateSetting,
// } from '../../../../services/domeService';

// import ReactCrop from 'react-image-crop';
// // import mergeImages from 'merge-images';
// import 'react-image-crop/dist/ReactCrop.css';
// import { blobToFile, getCroppedImg } from '../../../../helpers/ImageCropping';

// const showSuccessMessage = (title) => {
//   notification.success({
//     message: `${title}`,
//     duration: 10,
//   });
// };

// const showErrorMessage = (title) => {
//   notification.error({
//     message: `${title}`,
//     duration: 10,
//   });
// };

// class DomeOuterLogo extends React.Component<any, any> {
//   private imageElement: React.RefObject<HTMLImageElement>;
//   static contextType = AppContext;
//   constructor(props) {
//     super(props);
//     this.state = {
//       error: false,
//       success: false,
//       image: '',
//       fileName: '',
//       preview: '',
//       file: '',
//       src: '',
//       type: '',
//       crop: {
//         unit: 'px', // default, can be 'px' or '%'
//         x: 10,
//         y: 50,
//         width: 100,
//         height: 100,
//       },
//     };
//     this.imageElement = React.createRef();
//   }

//   onChange = (crop) => {
//     this.setState({ crop });
//   };

//   onImageLoaded = (image) => {
//     this.imageElement = image;
//   };

//   onComplete = async (crop) => {
//     const croppedFile = await getCroppedImg(
//       this.imageElement,
//       crop,
//       this.state.fileName,
//       this.state.type
//     );
//     const file = blobToFile(croppedFile, this.state.fileName);
//     const result = await FileUpload(file);
//     let resultfile = result.file;
//     /**.................................... */
//     // const profile = await this.getContext();
//     // const current_edit_dome = profile['edit_current_dome'] || {};

//     // let url1 = `https://app.loamoa.cladev.com/uploads/${resultfile.id}`;
//     // let url2 = `https://app.loamoa.cladev.com/uploads/${current_edit_dome?.icon_3d?.id}`;

//     // const getBase64FromUrl = async (url) => {
//     //   const data = await fetch(url);
//     //   const blob = await data.blob();
//     //   return new Promise((resolve) => {
//     //     const reader = new FileReader();
//     //     reader.readAsDataURL(blob);
//     //     reader.onloadend = () => {
//     //       const base64data = reader.result;
//     //       resolve(base64data);
//     //     };
//     //   });
//     // };

//     // let firstUrl = await getBase64FromUrl(url2);
//     // let secondUrl = await getBase64FromUrl(url1);

//     // // console.log('firstUrl', firstUrl);
//     // // console.log('url2', url2);
//     // mergeImages([
//     //   { src: firstUrl },
//     //   { src: secondUrl, innerWidth: 30, innerHeight: 30 },
//     // ]).then((b64) => {
//     //   console.log('hjkkk', b64);
//     //   // document.querySelector('#finalImg').src = b64;
//     // });
//     /**.................................... */

//     preview: baseURL + '/uploads/' + resultfile.id;
//     let preview = URL.createObjectURL(croppedFile);
//     this.setState({
//       preview: preview,
//       file: resultfile,
//     });
//   };

//   componentDidMount = async () => {
//     const profile = await this.getContext();
//     const current_edit_dome = profile['edit_current_dome'] || {};

//     this.setState({
//       image: current_edit_dome.outerlogo,
//       fileName: current_edit_dome.outerlogo.name,
//       preview: baseURL + '/uploads/' + current_edit_dome.outerlogo.id,
//     });
//   };

//   getContext = async () => {
//     const context = this.context;
//     let profile = context.profile || {};
//     return profile;
//   };

//   updateContext = async (data: any) => {
//     const context = this.context;
//     context.setProfile(data);
//   };

//   // oNDropFileHandler = async(files) =>{
//   // 	let file = files[0];
//   // 	let image = file;
//   // 	let fileName = file.name;
//   // 	let preview = URL.createObjectURL(file);
//   // 	const result = await FileUpload(file);
//   // 	let resultfile = result.file;

//   // 	this.setState({
//   // 		image: image,
//   // 		fileName:fileName,
//   // 		preview: preview,
//   // 		file: resultfile
//   // 	});

//   // }

//   fileHandler = async (e) => {
//     e.persist();
//     if (e.target.files.length > 0) {
//       let profile = await this.getContext();
//       const current_edit_dome = profile['edit_current_dome'] || {};
//       let image = e.target.files[0];
//       let fileName = e.target.files[0].name;
//       let preview = URL.createObjectURL(e.target.files[0]);
//       const result = await FileUpload(e.target.files[0]);
//       let file = result.file;

//       // if (file) {
//       //   let url1 = `https://app.loamoa.cladev.com/uploads/${file.id}`;
//       //   let url2 = `https://app.loamoa.cladev.com/uploads/${current_edit_dome?.icon_3d?.id}`;

//       //   const getBase64FromUrl = async (url) => {
//       //     const data = await fetch(url);
//       //     const blob = await data.blob();
//       //     return new Promise((resolve) => {
//       //       const reader = new FileReader();
//       //       reader.readAsDataURL(blob);
//       //       reader.onloadend = () => {
//       //         const base64data = reader.result;
//       //         resolve(base64data);
//       //       };
//       //     });
//       //   };

//       //   let firstUrl = await getBase64FromUrl(url2);
//       //   let secondUrl = await getBase64FromUrl(url1);

//       //   // console.log('firstUrl', firstUrl);
//       //   // console.log('url2', url2);
//       //   mergeImages([
//       //     { src: firstUrl },
//       //     { src: secondUrl, innerWidth: 30, innerHeight: 30 },
//       //   ]).then((b64) => {
//       //     console.log('hjkkk', b64);
//       //     // document.querySelector('#finalImg').src = b64;
//       //   });
//       // }

//       this.setState({
//         image: image,
//         fileName: fileName,
//         preview: preview,
//         file: file,
//         src: preview,
//         type: image.type,
//       });
//     }
//   };

//   publish = async () => {
//     if (!this.state.file) {
//       showErrorMessage('File not uploaded.');
//       return false;
//     }
//     let profile = await this.getContext();
//     const current_edit_dome = profile['edit_current_dome'] || {};

//     let id = current_edit_dome.ID;

//     let obj = {
//       outer_logo: this.state.file,
//     };
//     const res = await UpdateSetting(id, obj);
//     const domeSetting = await getDomeTypes(id);

//     profile.domeTypeSetting = domeSetting.dome_type_setting;

//     current_edit_dome.outerlogo = this.state.file;
//     profile.current_edit_dome = current_edit_dome;
//     await this.update(profile);

//     showSuccessMessage('Dome updated successfully.');
//     this.props.closeOuterLogo();
//   };

//   update = async (profile: any) => {
//     let domeSettingList = profile.domeSettingList;
//     let current_edit_dome = profile.current_edit_dome;
//     domeSettingList[current_edit_dome.current_index] = current_edit_dome;
//     profile.domeSettingList = domeSettingList;
//     await this.updateContext(profile);
//   };

//   renderBackgroundUrl = (url) => {
//     if (url) {
//       return 'https://app.loamoa.cladev.com/uploads/' + url;
//     }
//     return `${defaultBackground}`;
//   };
//   renderBottomPosition = (url) => {
//     if (url) {
//       return '38px';
//     }
//     return '36px';
//   };
//   renderheight = (url) => {
//     if (url) {
//       return '40px';
//     }
//     return '30px';
//   };
//   renderborder = (url) => {
//     if (url) {
//       return '1px solid #0000004a';
//     }
//     return 'none';
//   };
//   renderWidthAndHeight = (url) => {
//     if (url) {
//       return '27px';
//     }
//     return '19px';
//   };

//   render() {
//     const context = this.context;
//     const profile = context.profile;
//     const current_edit_dome = profile['edit_current_dome'] || {};

//     let dome_name = current_edit_dome.dome_name || '';
//     let addr_name = current_edit_dome.addr_name || '';
//     return (
//       <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
//         <div className="third-card relative">
//           <div className="fx-jcsb-aic mb-20">
//             <h4 className="text-primary mb-0 font-bold">&#62; Outer Logo</h4>
//             <i
//               onClick={(e) => this.props.closeOuterLogo(e)}
//               className="iconx cursor"
//             >
//               <img src={closeIcon} alt="Info" />
//             </i>
//           </div>

//           <div className="domeAddContainer scrollSection">
//             <div className="grid-row profileImgUpload">
//               <div className="col col-8">
//                 <h4 className="profileImgUpload__label">Logo file upload</h4>
//                 <div className="grid-row sm">
//                   <div className="col col-8">
//                     <input
//                       type="text"
//                       value={this.state.fileName}
//                       className="form-control xs"
//                       readOnly
//                     />
//                   </div>
//                   <div className="col col-4">
//                     <label className="ant-btn primary-ghost-btn xs btn-block">
//                       <input
//                         onChange={this.fileHandler}
//                         type="file"
//                         className="d-none"
//                       />
//                       Upload
//                     </label>
//                   </div>
//                 </div>
//               </div>

//               <div className="col col-4 text-center">
//                 <figure className="photoPrev">
//                   <ReactCrop
//                     src={this.state.src}
//                     onComplete={this.onComplete}
//                     onImageLoaded={this.onImageLoaded}
//                     crop={this.state.crop}
//                     onChange={(newCrop) => this.onChange(newCrop)}
//                   />
//                 </figure>
//                 <h5>Drag and fit</h5>
//               </div>
//             </div>

//             <div className="text-center mb-20">
//               <h3 className="text-primary weight-700">Preview</h3>
//             </div>

//             <div className="text-center">
//               <div
//                 style={{
//                   backgroundImage: `url(${this.renderBackgroundUrl(
//                     current_edit_dome?.icon_3d?.id
//                   )})`,
//                   // backgroundImage: `url(${
//                   //   'https://app.loamoa.cladev.com/uploads/' +
//                   //   current_edit_dome.icon_3d.id
//                   // })`,
//                   backgroundSize: 'contain',
//                   backgroundRepeat: 'no-repeat',
//                 }}
//                 className="dome-preview"
//               >
//                 <span className="outerLogo"></span>
//                 {/* <span className="outerLogo">Logo</span> */}
//                 <div
//                   style={{
//                     height: this.renderheight(current_edit_dome?.icon_3d?.id),
//                     width: '40px',
//                     borderRadius: '50%',
//                     // boxShadow: '0px 2px 2px 2px',
//                     position: 'absolute',
//                     display: 'flex',
//                     bottom: this.renderBottomPosition(
//                       current_edit_dome?.icon_3d?.id
//                     ),
//                     border: this.renderborder(current_edit_dome?.icon_3d?.id),
//                     left: '39%',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     // background: '#fff',
//                   }}
//                 >
//                   <img
//                     style={{
//                       width: this.renderWidthAndHeight(
//                         current_edit_dome?.icon_3d?.id
//                       ),
//                       height: this.renderWidthAndHeight(
//                         current_edit_dome?.icon_3d?.id
//                       ),
//                     }}
//                     className="dome-preview-pic"
//                     src={this.state.preview ? this.state.preview : logoPic}
//                     alt="Logo"
//                     height="19"
//                   />
//                 </div>
//               </div>
//               {/* <img id="finalImg" height={100} width={100} /> */}
//             </div>

//             <div className="grid-row fx-jcc">
//               <div className="col col-6 fx-jcsb">
//                 <button
//                   onClick={(e) => this.props.closeOuterLogo(e)}
//                   className="ant-btn primary-ghost-btn xs"
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   onClick={this.publish}
//                   className="ant-btn yellow-gradient xs"
//                 >
//                   Edit
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Row>
//     );
//   }
// }

// export default DomeOuterLogo;


import React from 'react';
import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import logoPic from '../../../../../src/assets/logoPic.png';
import { connect } from 'react-redux';
class DomeOuterLogo extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };
  render() {
    const { translation } = this.props
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62;{ translation('Outer Logo') }</h4>
            <i onClick={this.props.closeOuterLogo} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>
          <br></br>
          


          <div className="domeAddContainer scrollSection fx-jcc-aic">
            <div className="text-center">
              <figure className="mb-3">
                <img src={logoPic} alt="logo" />
              </figure>
              <h2 className="text-muted mb-0">{ translation('Coming soon') }</h2>
            </div>

          </div>
        </div>
      </Row>
    );
  }
}
const mapStateToProps = (state:any) => (
  {
  translation: state.userData.translation,
}
);

export  default connect(mapStateToProps, null)( DomeOuterLogo);
