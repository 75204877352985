import React, { useEffect ,useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import {Select } from 'antd';
import ReactDOM from 'react-dom';
import AppContext from '../../../AppContext';

import i18n from "i18next";
import { useTranslation, initReactI18next,} from "react-i18next";
import { useDispatch } from 'react-redux';
import tEn from 'locales/en/transaltion.json';
import tKo from 'locales/ko/transaltion.json';
import { store } from '../../../helpers/store';

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: {
        translation:tEn 
      },
      ko: {
        translation: tKo
      },
    },
    lng: "en", 
    fallbackLng: "en",

    interpolation: {
      escapeValue: false 
    }
  });

  export var b
  
function i18next( ){ 

  const location=useLocation()
  const history=useHistory()
  // console.log("location",location)
  var c = location.search
  var d= c.slice(1)
   const { t } = useTranslation();
   b={t}
   if(c!==''){
   localStorage.setItem('lang',d)
   }

  const [language,setLanguage]=useState(localStorage.getItem('lang'))

  
//    var [l,st]=useSearchParams()
// const g= l.get('lang')
// console.log(g,"g")
  useEffect(() => {
    let currentLang = localStorage.getItem('lang');
    i18n.changeLanguage(currentLang);
    store.dispatch({ type: "USER_TRANSLATION" ,translation:t})
  }, [])

  const changeLang = (e)=>{

    let a =e.target.value
    setLanguage(a)
    localStorage.setItem('lang',a);
      i18n.changeLanguage(a);
      history.push('/?'+a)
       window.location.reload();
      
      //  localStorage.removeItem("lang")
    
  } 
  return (
    <>    
  <select className='langSelectLogin' id="mySelect" value={language} onChange={changeLang}>
    <option value="en">English</option>
    <option value="ko">한국어</option>
  </select>
    </>
  );
} export default i18next
