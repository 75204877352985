import React, { Component } from 'react';
import { Row, Col } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import downloadIcon from '../../../../../src/assets/downloadIcon.png';
import { CreateSupport } from './supportService';
import Moment from 'react-moment';
import moment from 'moment';
import { throttle } from 'throttle-debounce';
import { connect } from 'react-redux';

class CreateMasterSupport extends Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      subject: '',
      description: '',
      date_time: '',
    };
  }

  submitHandler = throttle(
    7000,
    async (e: any) => {
      let data = {
        date_time: moment(this.state.date_time).format('YYYY-MM-DD hh:mm:ss'),
        subject: this.state.subject,
        message: this.state.description,
      };
      await CreateSupport(data);
    },
    {
      noTrailing: true,
    }
  );

  componentDidMount = async () => {
    const date = new Date();
    this.setState({
      date_time: date,
    });
  };

  inputHandler = async (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeHandler = async (e: any) => {
    let profile = await this.getContext();
    profile.createMasterSupport = false;
    await this.updateContext(profile);
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  render() {
    const { translation } = this.props
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62; {translation('Open support case')}
              </h4>
              <div className="fx-aic">
                <i onClick={this.closeHandler} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="">
              <div className="my-setting-form">
                <div className="grid-row sm form-group">
                  <label className="col col-4 inp-label regular">{translation('Date')}</label>
                  <div className="col col-8">
                    {/* <p className="mt-10 mb-0">Apr 26 2021</p> */}
                    <p className="mt-10 mb-0">
                      <Moment format="MMMM DD YYYY ">
                        {this.state.date_time}
                      </Moment>
                    </p>
                  </div>
                </div>
                <div className="grid-row sm form-group">
                  <label className="col col-4 inp-label regular">{translation('Subject')}</label>
                  <div className="col col-8">
                    <input
                      name="subject"
                      onChange={this.inputHandler}
                      type="text"
                      value={this.state.value}
                      className="inp regular"
                    />
                  </div>
                </div>
                <div className="grid-row sm form-group">
                  <label className="col col-4 inp-label regular">
                  {translation('Description')}
                  </label>
                  <div className="col col-8">
                    <textarea
                      name="description"
                      onChange={this.inputHandler}
                      rows={10}
                      className="inp regular"
                      placeholder={translation('Description')}
                    >
                      {this.state.value}
                    </textarea>
                  </div>
                </div>
                <div className="grid-row sm form-group">
                  <label className="col col-4 inp-label regular">&nbsp;</label>
                  <div className="col col-8">
                    <input
                      onClick={this.submitHandler}
                      type="submit"
                      value={translation("Submit")}
                      className="ant-btn primary-ghost-btn xs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)(CreateMasterSupport);
