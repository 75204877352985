import React, {Component} from "react";
import { Row } from "antd";

import AppContext from "../../../../AppContext";

import editIcon from '../../../../../src/assets/icon_edit.svg';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import iconSend from '../../../../../src/assets/iconSend.svg';


class SupportDetails extends Component<any,any>{
    static contextType = AppContext;
    constructor(props: any){
        super(props);
    }

    closeEditSupport = async() =>{
        let profile = await this.getContext();
        profile.editSupport = false;
        await this.updateContext(profile);
    }

    getContext = async () => {
        const context = this.context;
        let profile = context.profile || {};
        return profile;
    }

    updateContext = async (data: any) => {
        const context = this.context;
        context.setProfile(data);
    }
    render(){
        return(
            <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
                <div className="second-card relative">
                    <div className="fx-jcsb-aic mb-20">
                        <h4 className="text-primary mb-0 font-bold">&#62; Subject</h4>
                        <div className="fx-aic">
                            <i onClick={this.closeEditSupport} className="iconx cursor">
                                <img src={closeIcon} alt="Info" />
                            </i>
                        </div>
                    </div>
                    
                    <div className="my-setting-form scrollSection" style={{maxHeight:'500px'}}>
                        <div className="grid-row sm form-group">
                            <label className="col col-3 inp-label regular text-right">Subject</label>
                            <div className="col col-9">
                                <input type="text" value="Ssdfdljsssxxxxxxxxxxxxxxxx….." className="inp regular" />
                            </div>
                        </div>
                        <div className="grid-row sm form-group">
                            <label className="col col-3 inp-label regular text-right">Date</label>
                            <div className="col col-9">
                                <p className="mt-10 mb-0">May 10 2021 04:49:28 GMT+0900</p>
                            </div>
                        </div>
                        <div className="mb-5">
                            <span className="text-primary">Correspondence</span>
                        </div>
                        <div className="grid-row sm form-group">
                            <label className="col col-3 inp-label smallLabel size11">
                            Maria <br />
                            LoaMoa Team
                            Jun 07 2021
                            08:28: 38 GMT
                            +0900.
                            </label>
                            <div className="col col-9">
                                <textarea rows={3} className="inp regular">Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..</textarea>
                            </div>
                        </div>
                        <div className="grid-row sm form-group">
                            <label className="col col-3 inp-label smallLabel size11">
                            JohnB <br />
                            Jun05 2021<br />
                            08:28: 38 GMT<br />
                            +0900.
                            </label>
                            <div className="col col-9">
                                <textarea rows={3} className="inp regular" placeholder="Message">Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..</textarea>
                            </div>
                        </div>
                        <div className="grid-row sm form-group">
                            <label className="col col-3 inp-label smallLabel size11">
                            Maria <br />
                            LoaMoa Team <br />
                            May 11 2021 <br />
                            08:28: 38 GMT <br />
                            +0900.
                            </label>
                            <div className="col col-9">
                                <textarea rows={3} className="inp regular" placeholder="Message">Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..</textarea>
                            </div>
                        </div>
                        <div className="grid-row sm form-group">
                            <label className="col col-3 inp-label smallLabel size11">
                            JohnB <br />
                            May 10 2021 <br />
                            04:49:28 GMT <br />
                            +0900.
                            </label>
                            <div className="col col-9">
                                <textarea rows={3} className="inp regular" placeholder="Message">Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..</textarea>
                            </div>
                        </div>

                        <div className="to-email-section">
                            <label>To email</label>
                            <div>
                                <div className="fx">
                                    <div className="col col-2 text-right">
                                        <button className="emlAddBtn">+</button>
                                    </div>
                                    <div className="col col-8">
                                        <input type="text" value="" className="form-control" />
                                    </div>
                                    <div className="col col-2">
                                        <button className="emailSendBtn"><img src={iconSend} alt="send" /></button>
                                    </div>
                                </div>
                                <div className="fx">
                                    <div className="col col-4 fx-aic-inline">
                                        <span>Name of staff</span>
                                    </div>
                                    <div className="col col-6">
                                        <input type="text" value="Maria" className="form-control h-30" />
                                    </div>
                                    <div className="col col-2">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        )
    }
}
export default SupportDetails;