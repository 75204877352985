import React, { useState, useEffect } from 'react';
import { pending, requestSuccessful } from '../lib/const';
import { notification } from 'antd';
import { serverError } from '../lib/strings/error';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import moment from 'moment';
import countryData from '../helpers/country.json';
import { statusConstants } from '../containers/dashboard/menu/statistics/apiToTabel';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

const countryToCountryCode = (countryName) => {
  // country codes from applicationCache, saved in redux

  const country = countryData.find((country) => countryName === country.name);
  return country.alpha2Code;
};

const unixTimestamp = (momentDate) => parseInt(moment(momentDate).format('x'));

export const getStatisticsForTreasures = async (query: any) => {
  const findKitId = (kitType) => {
    const kitList = store.getState().kitList;
    const kit = kitList.find((kit) => kitType === kit.name);
    return kit?.ID;
  };

  const statusNameToApi = (statusName) => {
    switch (statusName) {
      case 'Pending':
        return statusConstants.pending;
      case 'Active':
        return statusConstants.active;
      case 'Inactive':
        return statusConstants.inactive;
    }
    // 'Pending', 'Active', 'Inactive'
  };

  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statistics +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          status: query?.status ? `${statusNameToApi(query.status)}` : '',
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          treasureName: query?.treasureName ? query.treasureName : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          kittypeid: query?.kitType ? `${findKitId(query.kitType)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForCoins = async (query: any) => {

  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statisticsCoins +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForPoints = async (query: any) => {
  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statisticsPoints +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForDomeStats = async (query: any) => {
  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statisticsDomeStats +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          limit: query?.limit ? query.limit : 500,
          filter:query?.filter ? query.filter : ''
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForPartnersAdmin = async (query: any) => {
  const paymentStatusQuery = query?.paymentStatus === 'Not paid' ? 'pending' : query?.paymentStatus;
  try {
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsPartners}-admin?` +
        new URLSearchParams({
          page: `${query.page}`,
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          paymentStatus: query?.paymentStatus ? `${paymentStatusQuery}` : '',
          status: query?.status ? `${query.status}` : '',
          partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const markAsPaid = async (ID) => {
  const fetchResponse = await fetch(
    `${config.baseURL}${config.statisticsPartners}/markAsPaid/${ID}`,
    {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
         Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful && response.data === 'Success!') {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.data}, ${response.code}`;
  }
};

export const getStatisticsForPartners = async (query: any) => {
  const paymentStatusQuery = query?.paymentStatus === 'Not paid' ? 'pending' : 'paid';
  const treasureStatusQuery = query?.status === 'Inactive' ? 'finished' : query?.status;
  try {
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsPartners}?` +
        new URLSearchParams({
          page: `${query.page}`,
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          paymentStatus: query?.paymentStatus ? paymentStatusQuery : '',
          treasureStatus: query?.status ? `${treasureStatusQuery.toLowerCase()}` : '',
          partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
           Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForSTeamAssignment = async () => {
  try {  
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsSupAssignment}`,
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },        
      }
    );   
    const response = await fetchResponse.json();  
    if (response.code === requestSuccessful) {
      const userList = response['data']['List'];
      const filteredUserList = userList.filter((user) => user.phoneNumber !== "+12139355403");
      const filteredResponseData = {
        ...response.data,
        List: filteredUserList
      };     
      return filteredResponseData;
    }
    
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForAdminTeamAssignment = async () => {
  try {  
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsAdminAssignment}`,
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },        
      }
    );   
    const response = await fetchResponse.json();  
    if (response.code === requestSuccessful) {
      const userList = response['data']['List'];
      const filteredUserList = userList.filter((user) => user.phoneNumber !== " ");
      const filteredResponseData = {
        ...response.data,
        List: filteredUserList
      };      
      return filteredResponseData;
    }
    
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};



export const updateUserPermissions = async (username,userId, permissions) => {    
  try {   
    const url = `${config.baseURL}${config.userStatePermissionsUpdate}`   
    const payload = JSON.stringify({
      userPermissions: permissions,
      spikaID: userId,
      username:username
    });

    const headers = {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: payload,
    });
    if (!response.ok) {
      throw new Error('Failed to update user permissions');
    }
    const data = await response.json();  
  } catch (error) {
    console.log("ERROR IN PERMISSION",error);
  }
};


export const deleteUserByUltraAdmin = async (userId) => { 
  try {
    const fetchResponse = await fetch(
      `https://app.loamoa.cladev.com/api/v2/user/update/userPermissions/${userId}`,
        {
      method: 'PUT',
      headers: {        
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}


export const getStatisticsForLogin = async (userId) => {
  try {
    const fetchResponse = await fetch(
      `https://app.loamoa.cladev.com/api/v2/user/list/statics/detail/${userId}`,
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {     
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try again later");
    return error;
  }
};

export const CartcheCkoutFromCoupon = async () => {  
  try {
    const url = `${config.baseURL}${config.cartCheckout}`   
    const payload = JSON.stringify({   
      iscoupon: '1',   
    });

    const headers = {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Cookie: 'connect.sid=s%3ACeUXbWs3kgikOCzapafhvM7mTuQ5wxIo.vQAl%2FaKZvktA85SrZWaeqNtlRdKuI3D9k0ALj0Ew4Lc; lang=en',
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: payload,
    });

    if (!response.ok) {
      throw new Error('Failed to update user permissions');
    }
    const data = await response.json();    
  } catch (error) {
    console.log(error);
  }
};