import React, {Component} from 'react';
import { Row, Col } from 'antd';
import AppContext from '../../../../AppContext';
import { connect } from 'react-redux';
// import { store } from '../../../../helpers/store';
// import { roleAdmin } from '../../../../lib/const';

class  MasterSupport extends Component <any, any>{
    static contextType = AppContext;
    constructor(props: any){
        super(props);
        this.state = {
            support_active_class: '',
            // open_support_active_class: '',
            support_case_active_class: '',
            role: ''
        }
        this.handleSupport = this.handleSupport.bind(this);
    }

    handleSupport  = async(e: any) =>{
        let profile = await this.getContext();
        profile.createMasterSupport = false;
        profile.openMasterSupport = true;
        await this.updateContext(profile);
        this.setState({
            support_active_class: 'active',
            // support_case_active_class: '',
        });
    }

    handleCreateSupport = async(e: any) =>{
        let profile = await this.getContext();
        profile.createMasterSupport = true;
        profile.openMasterSupport = false;
        await this.updateContext(profile);
        this.setState({
            support_active_class: 'active',
            // support_case_active_class: '',
        });
    }

    handleOpenSupport = async(e: any) =>{
        let profile = await this.getContext();
        profile.openMasterSupport = true;
        profile.createMasterSupport = false;
        await this.updateContext(profile);
        this.setState({
            support_active_class: 'active',
            // open_support_active_class: '',
            // support_case_active_class: 'active',
        });
    }
    

    getContext = async () => {
        const context = this.context;
        let profile = context.profile || {};
        return profile;
    }

    updateContext = async (data: any) => {
        const context = this.context;
        context.setProfile(data);
    }

    render(){
        const { translation } = this.props
        const context = this.context;
        let createmasterSupport = context.profile.createMasterSupport|| false;
        let supportCase = context.profile.openMasterSupport || false;
        let open_support_active_class = createmasterSupport ? 'active': '' ;
        let support_case_active_class = supportCase ? 'active' : '';
        return(
            <>
                <Row className="fx-jcc mb-10">
                    <button onClick={this.handleSupport}  className={'ant-btn primary-ghost-btn xs '+this.state.support_active_class }> { translation("Support")}</button>
                </Row>
                {this.state.support_active_class && 
                <Row className="fx-jcc mb-10">
                    <Col className="mr-5">  <button  onClick={this.handleCreateSupport} className={'ant-btn primary-ghost-btn xs '+ open_support_active_class}> { translation("Open support case")}</button></Col>
                    <Col className="ml-5"> <button onClick={this.handleOpenSupport} className={'ant-btn primary-ghost-btn xs '+ support_case_active_class}> { translation("Support cases")}</button></Col>
                </Row>
                }

            </>
        )
    }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});

export default connect(mapStateToProps, null)(MasterSupport);