import React, { Component } from 'react';
import { Row, Spin, notification, Modal } from 'antd';
import {
  approveNameReview,
  getMessagesList,
  getNameReview,
  getNameReviewDetail,
  sendEmailMessage,
} from '../../../../services/domeService';

import AppContext from '../../../../AppContext';
import Nyc from './nyc';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import iconDownload from '../../../../../src/assets/iconDownload.svg';
import iconSend from '../../../../../src/assets/iconSend.svg';
import locationIcon from '../../../../../src/assets/location-on-map.svg';
import moment from 'moment';
import { saveAs } from 'file-saver';
import ImagePreviewModal from '../domeEdit/components/ImagePreviewModal';
import ImagePreviewCustom from '../domeEdit/components/ImagePreviewCustom';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

class NameReview extends Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      domeDetail: {},
      type: '',
      msg: '',
      staff_name: '',
      name_request_id: 0,
      dome_id: 0,
      edit: false,
      loader: false,
      dLoader: false,
      mailLoader: false,
      statusLoader: false,
      toggleReview: false,
      toggleMail: false,
      approved: false,
      pending: false,
      done: false,
      needMore: false,
      reviewDetail: [],
      nameReqs: [],
      msgList: [],
      statusResp: {},
      domeStatus: 0,
      errors: {},
      imagePreview: false,
      imagePreviewSource: '',
    };
  }

  componentDidMount = async () => {
    this.setState({
      loader: true,
    });
    const nameReqs = await getNameReview();
    this.setState({
      loader: false,
    });
    this.setState({
      nameReqs: nameReqs,
    });
  };

  close = async () => {
    let profile = await this.getContext();
    profile['name_review'] = false;
    await this.updateContext(profile);
  };

  onCloseImagePreview = () => {
    this.setState({
      imagePreview: false,
      imagePreviewSource: '',
    });
  };
  onOpenImagePreview = (url) => {
    this.setState({
      imagePreview: true,
      imagePreviewSource: url,
    });
  };

  getDetail = async (e, res) => {
    e.preventDefault();
    this.setState({
      dLoader: true,
    });
    const resp = await getNameReviewDetail(res.ID);
    const msgResp = await getMessagesList(res.ID);
    this.setState({
      msgList: msgResp.data,
      dome_id: res.ID,
      domeDetail: res,
      dLoader: false,
      toggleReview: true,
      reviewDetail: resp.data,
      domeStatus: resp.dome_status,
      toggleMail: resp.dome_status === 1 ? false : true,
    });
  };

  saveNotification = async () => {
    const { name_request_id, type, staff_name } = this.state;
    if (type === '') {
      showErrorMessage('Please select status');
      return false;
    }
    if (!staff_name) {
      showErrorMessage('Please enter Staff Name');
      return false;
    }

    this.setState({
      statusLoader: true,
    });
    //STAFF NAME DOES NOT GOES HERE - BACKEND REQUIRED
    const res = await approveNameReview(name_request_id, type);
    showSuccessMessage(res.msg);
    const nameReqs = await getNameReview();
    const msgResp = await getMessagesList(this.state.dome_id);
    console.log(res, 'abc');
    // const resp = await getNameReviewDetail(res.data[0].id);
    const resp = await getNameReviewDetail(res.data[0].dome_id);

    this.setState({
      reviewDetail: resp.data,
      domeStatus: resp.dome_status,
      msgList: msgResp.data,
      statusLoader: false,
      staff_name: '',
      msg: '',
      nameReqs: nameReqs,
      toggleMail: res.dome_status === 0 ? true : false,
    });
  };

  sendEmailToUser = async () => {
    const { dome_id, msg, staff_name } = this.state;
    if (msg === '' || msg === null || msg === undefined) {
      showErrorMessage('Text should not be empty.');
      return false;
    }
    if (staff_name === '' || staff_name === null || staff_name === undefined) {
      showErrorMessage('Stuff Name should not be empty.');
      return false;
    }
    let data = {
      body: msg,
      staff_name: staff_name,
      dome_id: dome_id,
    };
    this.setState({
      mailLoader: true,
    });
    const res = await sendEmailMessage(data);
    const msgResp = await getMessagesList(dome_id);
    showSuccessMessage(res.msg);
    this.setState({
      msgList: msgResp.data,
      mailLoader: false,
      staff_name: '',
      msg: '',
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: '',
      },
    });
  };

  handleDone = (name_request_id, typeId) => {
    this.setState({
      name_request_id: name_request_id,
      type: typeId,
      done: !this.state.done,
      approved: !this.state.approved,
    });
  };

  handleNeedMore = (name_request_id, typeId) => {
    this.setState({
      name_request_id: name_request_id,
      type: typeId,
      needMore: !this.state.needMore,
      pending: !this.state.pending,
    });
  };

  closeNameReview = () => {
    this.setState({
      toggleReview: false,
    });
  };

  closeDetails = async () => {
    this.setState({
      edit: false,
    });
  };

  edit = async () => {
    this.setState({
      edit: true,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  handleDownloadClick = (url, filename) => {
    saveAs(url, filename);
  };

  render() {
    const {
      loader,
      dLoader,
      nameReqs,
      msgList,
      reviewDetail,
      toggleReview,
      statusLoader,
      toggleMail,
      domeDetail,
    } = this.state;
    let markUp = !loader ? (
      nameReqs.length > 0 ? (
        nameReqs.map((resp, index) => (
          <div className="let-long-loop domeAddLoop " key={index}>
            <div className="let-long-loop__left">
              <div className="let-long-loop__left__row mb-10">
                <div className="let-long-loop__left__row__info">
                  <h3 className="font-bold size14 mb-0">
                    {resp.addr_name} <span className="text-muted">@</span>
                    {resp.dome_status === 1 ? resp.dome_name : ''}
                  </h3>
                  <div className="let-long-loop__left__row__info__row">
                    <i className="let-long-loop__left__row__icon">
                      <img src={locationIcon} alt="Location" />
                    </i>
                    <div className="let-long-loop__left__row__info__row__info size10">
                      <div className="mr-10">
                        <p className="mb-0">Latitude</p>
                        <p className="mb-0">{resp.location.x}</p>
                      </div>
                      <div className="mb-0">
                        <p className="mb-0">Longitude</p>
                        <p className="mb-0">{resp.location.y}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="let-long-loop__right">
              <div className="fx-jcsb-aic">
                <div className="size12">
                  <p className="mb-3 text-error">
                    Submitted on{' '}
                    {moment(resp.name_submitted).format('DD/MM/YYYY')}{' '}
                    <img
                      src={editIcon}
                      onClick={(e) => this.getDetail(e, resp)}
                      className="cursor"
                      alt="icon"
                      height="14"
                    />
                    {resp.dome_status === 0 ? (
                      <span className="redNoti"></span>
                    ) : (
                      ''
                    )}
                  </p>
                  <p>
                    Dome Name status:{' '}
                    <span
                      className={
                        resp.dome_status === 1 ? 'text-success' : 'text-danger'
                      }
                    >
                      {resp.dome_status === 0 ? 'Pending' : 'Approved'}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <tr>
          <td colSpan={3} align="center">
            No Data Found
          </td>
        </tr>
      )
    ) : (
      <Spin size="large" style={{ marginTop: '2em', marginLeft: '14em' }} />
    );

    let markUpDetail = !dLoader ? (
      reviewDetail.length > 0 ? (
        reviewDetail.map((detail, i) => (
          <tr>
            <td style={{ verticalAlign: 'middle' }}>
              <p className="text-muted mb-0">
                {moment(detail.submit_date).format('hh:mm A')}
              </p>
              <p className="text-muted mb-0">
                {moment(detail.submit_date).format('MMM DD YYYY')}
              </p>
            </td>
            <td>
              <p className="fileText fx-jcsb-aic">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.onOpenImagePreview(
                      `https://app.loamoa.cladev.com/uploads/${detail.file}`
                    )
                  }
                  className="filePathName"
                >
                  File:{detail.file}
                </span>
                <span>
                  <a
                    // href={`https://app.loamoa.cladev.com/uploads/${detail.file}&download=true`}
                    href="#!"
                    // target="_blank"
                    onClick={() =>
                      this.handleDownloadClick(
                        `https://app.loamoa.cladev.com/uploads/${detail.file}`,
                        detail.file
                      )
                    }
                  >
                    <img src={iconDownload} alt="icon" height="11px" />
                  </a>
                </span>
              </p>
              <p className="mb-0">
                {detail.file_status === null && (
                  <span className="fx-aic mr-5">
                    <input
                      className="mr-3"
                      type="checkbox"
                      onChange={() => this.handleDone(detail.id, 1)}
                      checked={this.state.done}
                    />{' '}
                    Done
                  </span>
                )}
                {detail.file_status === null && (
                  <span className="fx-aic">
                    <input
                      className="mr-3"
                      type="checkbox"
                      onChange={() => this.handleNeedMore(detail.id, 0)}
                      checked={this.state.needMore}
                    />{' '}
                    Need more
                  </span>
                )}
                {detail.file_status === 1 && (
                  <span className="fx-aic mr-5">
                    <input className="mr-3" type="checkbox" checked={true} />{' '}
                    Done
                  </span>
                )}
                {detail.file_status === 0 && (
                  <span className="fx-aic">
                    <input className="mr-3" type="checkbox" checked={true} />{' '}
                    Need more
                  </span>
                )}
              </p>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <p className="mb-0">
                {detail.file_status === null && (
                  <span className="fx-aic">
                    <input
                      className="mr-3"
                      type="checkbox"
                      checked={this.state.pending}
                      name="pending"
                    />{' '}
                    Pending
                  </span>
                )}
                {detail.file_status === 0 && (
                  <span className="fx-aic">
                    <input className="mr-3" type="checkbox" checked={true} />{' '}
                    Pending
                  </span>
                )}
                {detail.file_status === null && (
                  <span className="fx-aic">
                    <input
                      className="mr-3"
                      type="checkbox"
                      name="approved"
                      checked={this.state.approved}
                    />
                    Approved
                  </span>
                )}
                {detail.file_status === 1 && (
                  <span className="fx-aic">
                    <input className="mr-3" type="checkbox" checked={true} />
                    Approved
                  </span>
                )}
                {detail.staff_name && (
                  <span
                    style={{
                      float: 'right',
                      color: 'silver',
                      fontSize: '14px',
                    }}
                  >
                    {detail.staff_name}
                  </span>
                )}

                {detail.file_status === null && (
                  <span className="fx-jcfe-aic mt-5">
                    <button
                      className="buttons ant-btn yellow-gradient xs auto"
                      disabled={statusLoader ? true : false}
                      onClick={this.saveNotification}
                    >
                      {statusLoader ? 'Loading...' : 'Enter'}
                    </button>
                  </span>
                )}
              </p>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={3} align="center">
            No Data Found
          </td>
        </tr>
      )
    ) : (
      <Spin size="large" style={{ marginTop: '2em', marginLeft: '14em' }} />
    );

    let markUpMsg = !dLoader ? (
      msgList.length > 0 ? (
        msgList.map((msg, j) => (
          <tr key={j}>
            <td style={{ verticalAlign: 'middle' }}>
              <span className="text-muted">
                {moment(msg.created_at).format('hh:mm A MMM DD YYYY')}
              </span>
            </td>
            <td style={{ verticalAlign: 'middle' }}>{msg.staff_name}</td>
            <td style={{ verticalAlign: 'middle' }}>{msg.message}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={3} align="center">
            No Data Found
          </td>
        </tr>
      )
    ) : (
      <Spin size="large" style={{ marginTop: '2em', marginLeft: '14em' }} />
    );
    let domeName =
      domeDetail.dome_status === 1
        ? domeDetail.dome_name
        : domeDetail.addr_name;
    let dome_name = domeDetail.dome_name;
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">&#62; Name Review</h4>
              <div className="fx-aic">
                <i onClick={this.close} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>
            <div className="domeAddContainer scrollSection">{markUp}</div>
          </div>
        </Row>
        <Row
          className={
            toggleReview
              ? 'menu-wrapper treasure-menu-wrapper third-dialog'
              : 'menu-wrapper treasure-menu-wrapper third-dialog d-none'
          }
        >
          <div className="third-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">&#62; {domeName}</h4>
              <div className="fx-aic">
                <i onClick={this.closeNameReview} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>
            <div className="fx-aic mb-10">
              <div className="col col-6">Dome Name to be approved</div>
              <div className="col col-6">
                <input
                  type="text"
                  value={dome_name}
                  disabled
                  className="form-control botLine bdr-primary"
                />
              </div>
            </div>
            <div className="fx mb-20">
              <div className="col col-2">&nbsp;</div>
              <div className="col col-4 fx-aic-inline">
                <span>Name of staff</span>
              </div>
              <div className="col col-6">
                <input
                  type="text"
                  placeholder="Staff Name"
                  name="staff_name"
                  value={this.state.staff_name}
                  onChange={this.handleChange}
                  className="form-control h-30"
                />
              </div>
            </div>
            <div
              className="domeAddContainer scrollSection"
              style={{ height: 'calc(100vh - 173px)' }}
            >
              <table className="table primary size11 mb-5">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>File review</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {markUpDetail}
              </table>
              <table className="table primary size11 mb-5">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name of Staff</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                {markUpMsg}
              </table>
              <div
                className={
                  toggleMail
                    ? 'to-email-section pl-0 pr-0'
                    : 'to-email-section d-none'
                }
              >
                <label>To email</label>
                <div>
                  <div className="fx mb-5">
                    <div className="col col-2 text-right">
                      <button className="emlAddBtn">+</button>
                    </div>
                    <div className="col col-8">
                      <textarea
                        rows={5}
                        placeholder="Message to send"
                        name="msg"
                        value={this.state.msg}
                        onChange={this.handleChange}
                        className="form-control"
                      ></textarea>
                    </div>
                    <div className="col col-2 pl-0 pr-0">
                      <button
                        className="emailSendBtn cursor"
                        disabled={this.state.mailLoader ? true : false}
                        onClick={this.sendEmailToUser}
                      >
                        <img src={iconSend} alt="send" />
                      </button>
                    </div>
                  </div>
                  {/* <div className="fx">
                    <div className="col col-4 fx-aic-inline">
                      <span>Name of staff</span>
                    </div>
                    <div className="col col-6">
                      <input
                        type="text"
                        placeholder="Staff Name"
                        name="staff_name"
                        value={this.state.staff_name}
                        onChange={this.handleChange}
                        className="form-control h-30"
                      />
                    </div>
                    <div className="col col-2">&nbsp;</div>
                  </div> */}
                  {this.state.mailLoader ? (
                    <div className="fx-jcc">
                      <Spin size="large" style={{ marginTop: '3em' }} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
        {this.state.edit && <Nyc close={this.closeDetails} />}
        {this.state.imagePreview && (
          <Modal
            visible={this.state.imagePreview}
            footer={null}
            centered
            onCancel={this.onCloseImagePreview}
            className="resize-modal-to-children"
            bodyStyle={{ width: 'fit-content', padding: '0px' }}
            closeIcon={<img src={closeIcon} />}
          >
            <ImagePreviewCustom
              imagePreviewSource={this.state.imagePreviewSource}
              closeModal={this.onCloseImagePreview}
            />
          </Modal>
        )}
      </>
    );
  }
}

export default NameReview;
