import React, { useContext, useEffect,  } from 'react';
import { Row, Button, Typography, Col, InputNumber, notification } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import equalIcon from '../../../../assets/equal.svg';
import { menuConstants } from '../../../../constants/menuConstants';
 import {getUserData} from '../../../../services/signinService';
import { connect } from 'react-redux';
import { rechargeConstants } from '../../../../constants/rechargeConstants';
import StripePayment from '../../../../components/StripePayment';
import { persistor } from '../../../../helpers/store';
import lcashIcon from '../../../../assets/lcash.svg';
import closeIcon from '../../../../assets/closeXsmall.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation} from "react-i18next";
import {
  recharge,
  lCash,
  currentBalance,
} from '../../../../lib/strings/balance';
import { languages } from '../../../../lib/interfaces/language';

import { userConstants } from '../../../../constants/signinConstants';
import { LOCAL_STORAGE_USERDATA } from '../../../../lib/config';
import { userData } from '../../../../reducers/userReducer';
import AppContext from '../../../../AppContext';
import {
  plumtousd,
  bankInformation,
  bankInformationget,
  
  
} from '../../../../services/domeService';
import { abaNumber } from '../../../../lib/strings/statistics';
import { pending } from '../../../../lib/const';

interface Props {
  handleClose: () => void;
  updateLCash: (amount: number) => void;
  (e: React.ChangeEvent<HTMLInputElement>): void;
  amount: number;
  currentLCash: number;
  usd: number;
  isPaymentInitiated: boolean;
  language?: languages;
  userData: any;
  username: any;
   books: any;
  user?: any;
  plum_rec?:number

  storeUserData: (user: object) => void;
  
}

const mapState = (state: {
  authentication: any;
  userData: { lcash: any ,plum_rec:any,books:any};
  recharge: { usd: any; amount: any };
  isPaymentInitiated: boolean;
}) => {
  return {
    currentLCash: state.userData.books,
    usd: state.recharge.usd,
    amount: state.recharge.amount,
    isPaymentInitiated: state.isPaymentInitiated,
    language: state.authentication.language,
    userData: state.userData,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    updateLCash: (amount: number) =>
      dispatch({ type: rechargeConstants.STORE_AMOUNT, amount }),
    storeUserData: (user: any) =>
      dispatch({ type: userConstants.STORE_USER_DATA, user }),
  };
};

const Cashing = (props: Props) => {
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [checked, setChecked] = useState(false);
  const [plumpAmt, setPlumpmAmt] = useState(null);
  const [plums, setPlums] = useState(0);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  

  const [apidata, setApidata] = useState({
    key1: '',
    key2: '',
    key3: '',
    key4: '',
  });
  const [apidataToCompare, setApidataToCompare] = useState({
    key1: '',
    key2: '',
    key3: '',
    key4: '',
  });
  
  //  const [books, setBooks] = useState('');

  const [updatedata, setUpdatedata] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [pinding,setPinding]=useState(false)
  const [result,setResult]=useState(0)
   const [user,setUser]=useState('')
   const[usds,setUsds]=useState('')

  const profile = useContext(AppContext);
  useEffect(() => {
    persistor.persist();
    persistor.flush();
  }, []);

  const updateLCash = (e: any) => {
    props.updateLCash(e);
  };

  const showErrorMessage = (title) => {
    notification.error({
      message: `${title}`,
      duration: 10,
    });
  };

  const showSuccessMessage = (title) => {
    notification.success({
      message: `${title}`,
      duration: 10,
    });
  };



  const onStripePayment = async () => {
    props.storeUserData({ refetch: true });
    localStorage.setItem(
      LOCAL_STORAGE_USERDATA,
      JSON.stringify({ ...userData, refetch: true })
    );
    if (plums >= 500) {
      let res = await plumtousd(plums, props?.userData.username);
      // setRecplum(res.plum)

      showSuccessMessage(t('Request Sent'));
      setPinding(true)
    } else {
      showErrorMessage(t('Minimum plums to cash requires 500 Plums'));
    }
  };
 


 


  const closeRecharge = () => {
    profile.profile['cashing'] = null;
    profile.setContextData(profile.profile);
  };

  

  const submitHandlar = async () => {
    if (checked) {
      setShow(true);
      setShowText(false);
      let res = await bankInformation(apidata);
      
      rate();
    } else {
      setShowText(true);
    }
  };

  const rate = async () => {
    let data = await bankInformationget();
    
      // setBooks(data.data[0].plum_rec)
     setResult(data.plum_result.length)
  if(data.plum_result.length){  setUser(data.plum_result[0].cashed_plum )
    setUsds(data.plum_result[0].usd)
  
  }
    
    if (data.data[0].account_number) {
   
      setUpdatedata(true);
    }
    setApidata({
      key1: data.data[0].account_number,
      key2: data.data[0].bank_name,
      key3: data.data[0].beneficiary_name,
      key4: data.data[0].swift_code,
    });
    setApidataToCompare({
      key1: data.data[0].account_number,
      key2: data.data[0].bank_name,
      key3: data.data[0].beneficiary_name,
      key4: data.data[0].swift_code,
    });
  };

  useEffect(() => {
    rate();
       if(result){
        setPinding(true)
       } 
      //  fetchUserData ()       
     
    //  fetchUserData()
    
  }, [result]);

  const closeHandlar = () => {
    setShow(false);
  };

  // const inputHandlar =async (e:any,) => {
  //   setBankData({
  //      ...bankdata,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const check = (e) => {
    setChecked(!checked);
  };

  const updateplumps = (e) => {
    let num = Number.parseInt(e.target.value);
    setPlums(num);
    
    setPlumpmAmt(num * 0.12);
    
  };

  const thousandSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const compareValues = (val1, val2) =>
    JSON.stringify(val1) === JSON.stringify(val2);

  const onFieldsChange = (e, keyName) => {
    let tempData = {
      ...apidata,
      [keyName]: e.target.value,
    };

    setApidata(tempData);
    if (compareValues(tempData, apidataToCompare)) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }
  };

  return (
    <>
      <Row className="kit-list-wrapper treasure-menu-wrapper second-dialog cardBiger">
        <div className="scrollable">
          <Row
            className="contest-top-row"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <div>
              <Button onClick={props.handleClose} className="details-arrow">
                <img src={arrow} alt="close" />
              </Button>
              <Typography.Text
                style={{ textTransform: 'none' }}
                className="contest-title bold"
              >
               {t("Cashing")} 
                {/* {recharge[props.language]} */}
              </Typography.Text>
            </div>
            <div>
              <i onClick={closeRecharge} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </Row>
          <Row className="recharge-container">
            <Row type="flex" justify="space-between">
              <Typography.Text
                style={{ textTransform: 'none' }}
                className="contest-heading"
              >
                {t('currentBalance')}
              </Typography.Text>
              <Typography.Text>
                <div className="fx-aic ml-5">
                  <span className="blue-dot mr-5"></span>
                  {thousandSeparator(props.currentLCash?props.currentLCash:0)}  
                  {/* {recplum} */}
                  {/* {thousandSeparator(props.userData.plum_rec)} */}
                </div>
                {/* {props.currentLCash ? props.currentLCash : 0}{" "} */}
                {/* {thousandSeparator(props.userData.plum_rec)} */}
              {/* {console.log( props.userData.plum_rec)} */}

                {/* {books} */}
                {/* <img src={lcashIcon} style={{ marginLeft: ".2em" }} /> */}
              </Typography.Text>
            </Row>
            <Row className="recharge-form">
              <Row type="flex" justify="center">
                <Col>
                  <Row>
                    <Typography.Text>
                      {/* {lCash[props.language]} */}
                      {t("Plums")}  
                    </Typography.Text>
                  </Row>
                  <Row>
                    {result?user: 
                    <input
                      className="input-treasure"
                      min={0}
                      name="lcash"
                      onChange={(e) => {
                        updateplumps(e);
                      }}
                    />
 } 
                  </Row>
                </Col>
                <Col style={{ margin: '1em' }}>
                  <img src={equalIcon} style={{ width: '55px' }} />
                </Col>
                <Col>
                  <Row>
                    <Typography.Text>USD</Typography.Text>
                  </Row>
                  <Row>
                    {/* <Typography.Text style={{ fontSize: "1.5em" }}>
                      ${plumpAmt}
                    </Typography.Text> */}
                    {result? "$ "+usds:
                    <strong>
                      ${' '}
                      {typeof plumpAmt == 'number' && !isNaN(plumpAmt)
                        ? plumpAmt.toFixed(2)
                        : ''}
                      {/* {plumpAmt} */}
                    </strong>
}
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="center" className="button-payment">
                 {result?
                <Button
                className="buttons pending-btn biger"  
              >
                {t("Pending")}
              </Button> 

                 :  
                 <Button
                 style={
                  plums < 500
                    ? {
                        background: '#fff',
                        color: 'silver',
                        cursor: 'not-allowed',
                      }
                    : {}
                }
                className={pinding?"buttons pending-btn biger ":"buttons yellow-gradient "}
                onClick={plums < 500 ? () => {} : onStripePayment}
              >
                {pinding?t("Pending"):t("Submit")}
              </Button>

                } 
                {/* <Button
                  style={
                    plums < 500
                      ? {
                          background: '#fff',
                          color: 'silver',
                          cursor: 'not-allowed',
                        }
                      : {}
                  }
                  className={pinding?"buttons pending-btn biger ":"buttons yellow-gradient "}
                  onClick={plums < 500 ? () => {} : onStripePayment}
                >
                  {pinding?"Pending":"Submit"}
                </Button> */}
              </Row>
              <Row type="flex" justify="center">
                {/* 1.00 Lcash = 1.00 US$ */}
                <span style={{ color: 'red' }}>
                {t('Minimum plums to cash requires 500 Plums')} 
                </span>
              </Row>
            </Row>

            <Row className="recharge-form">
              <Row type="flex" justify="center">
                <Col>
                  <Row>
                    {/* <Typography.Text>{lCash[props.language]}</Typography.Text> */}
                    <Typography.Text>
                      <strong>
                        {t("Bank account information to receive the cash")}
                      </strong>
                    </Typography.Text>
                  </Row>
                  <form onSubmit={submitHandlar} style={{ width: '100%' }}>
                    <Row className="mb-5">
                      <span>-{t("Beneficiary name")} : </span>
                      <input
                        className="input-treasure"
                        min={0}
                        name="beneficiary_name"
                        value={apidata.key3}
                        //  onChange={(e) =>{ inputHandlar(e)
                        //  }}
                        // onChange={(e) =>
                        //   setApidata({ ...apidata, key3: e.target.value })
                        // }
                        onChange={(e) => onFieldsChange(e, 'key3')}
                      />
                    </Row>
                    <Row className="mb-5">
                      <span>-{t("Bank name")} : </span>
                      <input
                        className="input-treasure"
                        min={0}
                        name="bank_name"
                        value={apidata.key2}
                        // onChange={(e) =>{ inputHandlar(e)
                        // }}

                        // onChange={(e) =>
                        //   setApidata({ ...apidata, key2: e.target.value })
                        // }
                        onChange={(e) => onFieldsChange(e, 'key2')}
                      />
                    </Row>
                    <Row className="mb-5">
                      <span>-{t("Account number")}: </span>
                      <input
                        className="input-treasure"
                        min={0}
                        value={apidata.key1}
                        name="account_number"
                        // onChange={(e) =>{ inputHandlar(e)
                        // }}
                        // onChange={(e) =>
                        //   setApidata({ ...apidata, key1: e.target.value })
                        // }
                        onChange={(e) => onFieldsChange(e, 'key1')}
                      />
                    </Row>
                    <Row className="mb-5">
                      <span>-{t("Swift code or ABA number(If any)")} : </span>
                      <input
                        className="input-treasure"
                        min={0}
                        value={apidata.key4}
                        name="aba_number"
                        // onChange={(e) =>
                        //   setApidata({ ...apidata, key4: e.target.value })
                        // }
                        onChange={(e) => onFieldsChange(e, 'key4')}
                        // onChange={(e) =>{ inputHandlar(e)
                        // }}
                      />
                    </Row>

                    <Row className="pt-10">
                      <input
                        type="checkbox"
                        className="input-treasure"
                        min={0}
                        name="lcash"
                        onChange={check}
                      />
                      <span>
                        {' '}
                        &nbsp;{t("I agree to the Loa Moa")}&nbsp;
                        <label>
                          <Link
                            to="/terms-of-use"
                            style={{ display: 'inline-block' }}
                          >
                            <b>
                              <u>&nbsp;{t('terms-of-use')}&nbsp;</u>
                            </b>
                          </Link>
                        </label>
                        &nbsp;{t('and')}&nbsp;
                        <label>
                          <Link to="/privacy-policy">
                            <b>
                              <u>&nbsp; {t('privacy-policy')}&nbsp;</u>
                            </b>
                          </Link>
                        </label>
                      </span>
                    </Row>
                  </form>
                </Col>
              </Row>
              {showText && (
                  <strong style={{ color: 'red',marginLeft:"25%" ,fontSize:"90%"}}>
                    {t('I agree to the Loa Moa  terms-of-use  and  privacy-policy')}
                  </strong>
                )}
              <Row type="flex" justify="center" className="button-payment">
               
                {updatedata ? (
                  
                  <Button
                    style={
                      !isDataChanged
                        ? {
                            background: '#fff',
                            color: 'silver',
                            cursor: 'not-allowed',
                          }
                        : {}
                    }
                    className="buttons yellow-gradient"
                 
                    onClick={isDataChanged ? submitHandlar : () => {}}
                  >
                    {t('Update')}
                  </Button>
                ) : (
                  <Button
                    className="buttons yellow-gradient"
                    
                    onClick={submitHandlar}
                  >
                   {t('Submit')} 
                  </Button>
                )}
              </Row>
            </Row>
          </Row>
        </div>
      </Row>
     
    </>
  );
};

export default connect(mapState, mapDispatch)(Cashing);
