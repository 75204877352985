declare var SERVICE_URL: string;

export const isDev = process.env['NODE_ENV'] === 'development';
export const baseURL = SERVICE_URL;
export const signupAPI = '/api/v2/user/signup';
export const verifyAPI = '/api/v2/user/signup/verify';
export const finishAPI = '/api/v2/user/signup/finish';
export const treasureAPI = '/api/armax/treasure';
export const locationAPI = '/api/armax/location';
export const messageLocationAPI = '/api/armax/messageLocation';
export const treasureUnitAPI = '/api/armax/treasureUnit';
export const hashtagAPI = '/api/armax/hashtag';
export const userDetailsAPI = '/api/v2/user/detail';
// export const userPlumAPI = '/domeAddress/admin/cointoplum';
export const getUserTreasure = '/api/armax/user/treasures';
export const getTreasureListByStatusAPI = '/api/armax/treasure/all';
export const stickerCharactersAPI = '/api/armax/stickerCharacters';
export const contestAPI = '/api/armax/contest';
export const characterAPI = '/shop/stickers';
export const characterStoryAPI = '/api/armax/story';
export const contestVideoAPI = '/api/armax/contest/guidelineVideo';
export const kitAPI = '/kitType';
export const userTransactionHistory = '/api/armax/user/transactionHistory';
export const paymentGetSessionAPI = '/api/armax/payment/session';
export const paymentVerifyAPI = '/api/armax/payment/verify';
export const exchangeAPI = '/api/armax/user/exchange';
export const exchangeRateAPI = '/api/armax/exchangeRate';
export const getPushNotificationsAPI = '/api/armax/notifications/all';
export const notificationAPI = '/api/armax/notifications';
export const userListAPI = '/api/armax/user/all';
export const localization = '/api/armax/localization/byModel';
export const localizationUploadFiles = '/api/armax/file';
export const adKitReuseList = '/api/armax/user/adkit';
export const statistics = '/api/armax/statistics';
export const statisticsCoins = '/api/armax/statistics/coins';
export const statisticsSupAssignment = '/api/v2/user/list/statics/1100/'; //for superAdmin
export const statisticsAdminAssignment = '/api/v2/user/list/statics/1000/'; //for Admin
export const userStatePermissionsUpdate = '/api/v2/user/update/userPermissions'; //for permission update
export const deleteUserByUltraAdmin = '/api/v2/user/update/userPermissions/'//for deleting the Admin and super admin
export const userLoginStats = '/api/v2/user/list/statics/detail/'//for Login stats
export const statisticsPoints = '/api/armax/statistics/points';
export const statisticsDomeStats = '/api/armax//domeAddress/admin/dome_stats/';
export const statisticsPartners = '/api/armax/statistics/partners';
export const candyEvent = '/api/armax/candyEvent';
export const candyEventSendNotifications = '/api/armax/notifications/candyEvent';
export const Recurring='/api/armax/treasure/recurring/'

export const cashExchange = '/api/armax/exchangeRate/exchangeRequest';
export const partnerRequest = '/api/armax/partnerRequest';

export const createDome = '/api/armax/domeAddress';
export const editingImport = createDome + '/editing/import';

export const plum_received ='/api/armax/domeAddress/admin/plum_received/';
// /domeAddress/editing/import

export const myDome = '/api/armax/domeAddress/my_dome';
export const addressName = '/api/armax/domeAddress/address_name';
export const checkDomeName = '/api/armax/domeAddress/setting_name/available';
export const updateType = createDome + '/update_type';
export const searchDomeByName = createDome + '/search_dome_by_name';
export const searchDomeByNameAndAddress = createDome + '/dome_name_import/search'
export const importDomeByName = createDome + '/dome_name_import'
export const importedDomeStatus = createDome + '/dome_name_import/status'
export const importedDomeNameStatus = createDome + '/importverify/status'
export const editDomeNameImportConfirm = createDome + '/dome_name_import/edit'
export const deleteDomeNameImport = createDome + '/dome_name_import/remove'
export const importDome = createDome + '/import';
export const domeSettingDelete = createDome + '/delete_type';
export const importVerify = createDome + '/importverify';
export const uploadHallImages = createDome + '/hall';
export const fileUpload = '/api/v2/file/upload';
export const updateSetting = '/api/armax/domeAddress/update_setting';
export const fetchInBoundry = '/api/armax/domeAddress/fetch_in_boundry';
export const domeList = '/api/armax/domeAddress/domes';
export const admnNotification = '/api/armax/domeAddress/admin/notifications';
export const getAdminNotification = '/api/armax/domeAddress/admin';
export const nameRequests = '/api/armax/domeAddress/admin/names_request';
export const deleteDomeName = '/api/armax/domeaddress/';
export const sendEmail = '/api/armax/domeAddress/admin/names_request/sendEmail/';
export const getMessages = '/api/armax/domeAddress/admin/names_request/messages/';
export const get3DIconsImg = '/api/armax/album/icon3d/load/';
export const domeType = '/api/armax/domeAddress/dome_type/';
// export const getAdminNotice =  '/api/armax/domeAddress/admin/{dome_id}/notice'
export const RegisterDome = '/api/armax/domeaddress/register';
export const addtocart = '/api/armax/domeAddress/add_to_cart';

export const fetchCart = '/api/armax/domeAddress/fetch_cart';
export const cartCheckout = '/api/armax/payment/checkout_cart';
export const deleteFromCart = '/api/armax/domeAddress/delete_cart'

export const domepayment = '/api/armax/payment/domepayment';

export const createSupportTicket = '/api/armax/support';
export const getSupportTicket = '/api/armax/support';
export const userDetail = '/api/v2/user/detail';
export const userUpdate = '/api/v2/user/update';

export const addCard = '/api/armax/payment/methods/';
// export const  cardList = '/api/armax//payment/methods/';


export const hashSalt = '8zgqvU6LaziThJI1uz3PevYd';
export const useSha256 = false;

export const gm = 'AIzaSyAC7FC5wCXyfRRFiA5b3zQ1hF85iz_8EM4';

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const formDataHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const get = 'GET';
export const post = 'POST';
export const put = 'PUT';
export const del = 'DELETE';

export const LOCAL_STORAGE_USER = 'user';
export const LOCAL_STORAGE_USERDATA = 'userData';
export const LOCAL_STORAGE_USER_LOCATION = 'userLocation';

export const LOCAL_STORAGE_LANGUAGE = 'language';
export const LOCAL_STORAGE_COUNTRY = 'country';

export const stripeDev = 'pk_test_eodY9hMOuCwaQb5Y1jT5FZe800NQYaOjk3';

export const stripeLive = 'pk_live_qefVewQtkhqw4JmuBXbFtVy900GR3aXdJd';

export const stripe = {
  apikey: isDev ? stripeDev : stripeLive,
};

export const maximumAllowedMediaSize = 300000000;

export const typeJPEG = 'image/jpeg';
export const typePNG = 'image/png';
export const typeGIF = 'image/gif';
export const typeWEBP = 'image/webp';
export const typeMP4 = 'video/mp4';
export const typeQuickTime = 'video/quicktime';
export const typeAVI = 'video/x-msvideo';
export const typeWMV = 'video/x-ms-wmv';
export const typePDF = 'application/pdf';
export const typeMP3 = 'audio/mpeg';
