import { store } from '../helpers/store';
import { baseURL, getPushNotificationsAPI, notificationAPI, userListAPI } from '../lib/config';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

export const getScheduledPushNotifications = async () => {
  try {
    const fetchResponse = await fetch(baseURL + getPushNotificationsAPI, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.notifications;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const getSentPushNotifications = async () => {
  try {
    const fetchResponse = await fetch(baseURL + getPushNotificationsAPI, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const createNotification = async (notification: any, time: number, localization: any) => {
  try {
    const fetchResponse = await fetch(baseURL + notificationAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: notification.title,
        description: notification.description,
        timestamp: time,
        userIds: JSON.stringify(notification.userIDs),
        url: notification.url,
        type: notification.type,
        contestID: notification.contestID,
        localization,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const getAllUsersList = async (page: number, searchQuery?: string) => {
  const params: any = new URLSearchParams({
    page: `${page}`,
  });
  if (searchQuery) {
    params.append('searchBy', searchQuery);
  }
  try {
    const fetchResponse = await fetch(baseURL + userListAPI + '?' + params, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.users;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const getIdolUsersList = async (
  page: number,
  contestID: number,
  userType: string,
  searchQuery?: string,
  contestantID?: number
) => {
  let usersToFetch: any = new URLSearchParams({
    page: `${page}`,
    contestID: `${contestID}`,
  });

  if (userType === 'voters') {
    usersToFetch.append('onlyVoters', true);
  } else {
    usersToFetch.append('onlyContestants', true);
  }

  if (searchQuery) {
    usersToFetch.append('searchBy', searchQuery);
  }

  if (contestantID) {
    usersToFetch.append('contestantID', contestantID);
  }
  try {
    const fetchResponse = await fetch(baseURL + userListAPI + '?' + usersToFetch, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.users;
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const deleteNotification = async (id: number) => {
  try {
    const fetchResponse = await fetch(baseURL + notificationAPI + '/' + id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const updateNotification = async (notification: any, time: number, localization: any) => {
  const oldNotification = store.getState().notificationDetails;
  let { url } = notification;
  if (url.indexOf('https://') === -1) {
    url = `${'https://'}${url}`;
  }
  let updateBody: any = {};
  updateBody.localization = localization;
  if (notification.type !== oldNotification.type) updateBody.type = notification.type;
  if (notification.title !== oldNotification.title) updateBody.title = notification.title;
  if (url !== oldNotification.url) updateBody.url = url;
  if (notification.description !== oldNotification.description)
    updateBody.description = notification.description;
  if (notification.contestID !== oldNotification.contestID)
    updateBody.contestID = notification.contestID;
  if (notification.timestamp !== oldNotification.timestamp)
    updateBody.timestamp = notification.timestamp;
  if (notification.userIDs.length > 0) updateBody.userIds = JSON.stringify(notification.userIDs);

  try {
    const fetchResponse = await fetch(baseURL + notificationAPI + '/' + notification.ID, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...updateBody,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
};
