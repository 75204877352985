import React from 'react';
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import iconInfo from '../../../../src/assets/icon_info_blue.svg';
import cardCVV from '../../../../src/assets/card-cvv.svg';
import allCards from '../../../../src/assets/all-cards.png';
import { store } from '../../../helpers/store';
import { connect } from 'react-redux';
import {b} from '../../dashboard/menu/i18next'
import {
  baseURL,
  domepayment,
  cartCheckout,
  createDome,
} from '../../../lib/config';
import { cartPayment } from '../../../services/domePaymentService';
import { notification } from 'antd';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

import AppContext from '../../../AppContext';

export const logEvent = (name) => (event) => {
  // console.log(`[${name}]`, event);
};

export const Result = ({ children }) => (
  <div className="result">{children}</div>
);

export const ErrorResult = ({ children }) => (
  <div className="error">{children}</div>
);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#252525',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#999',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

class CheckoutForm extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      postal: '',
      errorMessage: null,
      paymentMethod: null,
      email: null,
      card_holder_name: '',
      save_card: false,
      loader: false,
      };
  }

  handleInputEmail = (e: any) => {
    this.setState({
      email: e.target.value,
    });
  };

  inputHandler = (e: any) => {
    this.setState({
      card_holder_name: e.target.value,
    });
  };

  handleSaveCard = (e) => {
    this.setState({ save_card: !this.state.save_card });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    const { name, postal } = this.state;
    const userData = store.getState().userData;
    if (!this.state.email) {
      this.setState({
        email: userData.email,
      }); 
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const payload = await stripe.createToken(cardElement);
    if (payload.error) {
       showErrorMessage(b?.t("Your card number is incomplete"));
      this.setState({
        loader: false,
      });
      return false;
    } else {
      this.setState({
        token: payload.token.id,
        errorMessage: null,
      });
    }
    let profile = this.context.profile;
    if (profile.iscart !== null) {
      this.setState({
        loader: true,
        });
      await this.cardCheckout(payload.token.card);
      this.setState({
        loader: false,
       });
    } else {
      this.setState({
        loader: true,
       });
      await this.payments(payload.token.card);
      this.setState({
        loader: false,
  });
    }
  };

  async cardCheckout(card) {
    const context = this.context;
    const profile = context.profile;
    let data = {
      profile: profile,
      email: this.state.email,
      card_token: this.state.token,
      expires: card.exp_month + '/' + card.exp_year,
    };
    await cartPayment(card, data);

    let items = profile.items.filter(function (item) {
      return item.check === false;
    });

    profile.iscart = null;
    profile.items = null;
    profile.item_count = items.length;
    profile.is_payment = false;
    profile.year = null;

    profile['fetch_in_boundry'] = true;
    // remove all current dome
    let markers = profile['markers'] || [];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    profile['markers'] = [];
    context.setProfile(profile);
    profile['is_dome_registered_successfully'] = true;
    showSuccessMessage(b?.t('Dome created successfully'));
    // setTimeout(this.redirectToDashBoard, 200);  
  }

  async payments(card) {
    const context = this.context;
    let profile = context.profile;
    let resisterRes = {};

    if (profile.name === 'Renew') {
      resisterRes = {
        code: 1,
        data: { inserted_dome_id: profile.inserted_dome_id },
      };
    } else {
      /**REMOVE THIS FROM HEREE  */
      const requestOptionForRegister = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addr_name: profile.dome_name,
          longitude: profile.dome_lat,
          latitude: profile.dome_lng,
        }),
      };

      resisterRes = await fetch(
        baseURL + createDome,
        requestOptionForRegister
      ).then((r) => r.json());
      /**REMOVE THIS TO HEREE  */
    }

    /** NEED TO REMOVE THIS IF  */
    // console.log("1234",resisterRes)
    if (resisterRes.code !== 1) {
     
      profile.dome_error = true;
      profile.register = false;
      profile.is_payment = false;
      // profile.dome_name = profile1.dome_name;
      context.setProfile(profile);

      return false;
    } else {
      const requestOption = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          save_card: this.state.save_card,
          card_token: this.state.token,
          /** NEED TO CHANGE THIS TO PROFILE.inserted_dome_id */
          dome_id: resisterRes.data.inserted_dome_id,
          card: card.last4,
          card_type: card.brand,
          amount: profile.total,
          year: profile.year,
          expires: card.exp_month + '/' + card.exp_year,
        }),
      };
      const res = await fetch(baseURL + domepayment, requestOption).then((r) =>
        r.json()
      );
      profile.iscart = null;
      profile.items = null;
      profile.is_payment = false;
      profile.year = null;
      profile.register = false;

      profile['fetch_in_boundry'] = true;
      // remove all current dome
      let markers = profile['markers'] || [];
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      profile['markers'] = [];
      profile['is_dome_registered_successfully'] = true;
      context.setProfile(profile);
      showSuccessMessage(b?.t('Dome created successfully'));
      // setTimeout(this.redirectToDashBoard, 200);
      }
  }

  redirectToDashBoard() {
    window.location.reload();
  }
  componentDidMount() {
    let profile = this.context.profile;
    if(profile.show){profile.year=1
      profile.total=18.0}

  }

  render() {
    const { stripe } = this.props;
    // console.log(stripe,"stripe")
    
    const { postal, name, token, errorMessage } = this.state;
    const userData = store.getState().userData;

    let email = this.state.email ? this.state.email : userData.email;
    let is_cart = false;
    const context = this.context;
    let cart = context.profile.items;
    let total = this.context.profile.total;



    
    if (cart !== null) {
      is_cart = true;
      total = cart['total']; 
    }
    let isLoader = this.state.loader ? (
      <div className="spinner-grow" role="status">
        <span className="sr-only">{b?.t('Loading...')}</span>
      </div>
    ) : (
      ''
    );
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label>{b?.t('Email')}</label>
          <input
            type="email"
            value={email}
            className="form-control"
            onChange={this.handleInputEmail}
          />
        </div>
        <div className="form-group">
          <label>{b?.t("Card information")}</label>
          <div className="card-information">
            <div className="card-information__top">
              <CardNumberElement
                id="cardNumber"
                onChange={logEvent('change')}
                onReady={logEvent('number')}
                options={ELEMENT_OPTIONS}
              />
              <div className="card-information__top__icons">
                <img src={allCards} alt="Info" />
              </div>
            </div>
            <div className="card-information__bot">
              <div className="card-information__bot__left">
                <CardExpiryElement
                  id="expiry"
                  onChange={logEvent('change')}
                  onReady={logEvent('ready')}
                  options={ELEMENT_OPTIONS}
                />
              </div>
              <div className="card-information__bot__right">
                <CardCvcElement
                  id="cvc"
                  onChange={logEvent('change')}
                  onReady={logEvent('ready')}
                  options={ELEMENT_OPTIONS}
                />
                <img src={cardCVV} alt="Info" />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label> {b?.t('Name on card')}</label>
          <input
            onChange={this.inputHandler}
            type="text"
            value={this.state.card_holder_name}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>{b?.t('Country or region')}</label>
          <select className="form-control">
            <option value="Republic of Korea">{b?.t('Republic of Korea')}</option>
          </select>
        </div>

        <div className="saveInfoBlock">
          <label className="fx-aic">
            <input
              type="checkbox"
              name="save_card"
              className="mr-5"
              onChange={this.handleSaveCard}
            />
            <span>{b?.t('Save information to pay faster next time')}</span>
          </label>
          <i>
            <img src={iconInfo} alt="Info" />
          </i>
        </div>
        <button
          type="submit"
           disabled={!stripe}
          className="ant-btn buttons btn-block purple-gradient rd-5"
        >
          {this.state.loader ? isLoader : b?.t('Pay $')  + total.toFixed(2)}
        </button>
      </form>
    );
  }
}

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);



export default  InjectedCheckoutForm
 
