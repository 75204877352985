import React, { useState, useEffect } from 'react';
import { Table ,Paginate} from '../../../../components';
import {admin_cointoplum_history  } from '../../../../services/domeService';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import ReactPaginate from 'react-paginate';
import ChevronLeft from '../../../../assets/chevronLeft.svg';
import ChevronRight from '../../../../assets/chevronRight.svg';
import { Row, Spin, Col } from 'antd';
import moment from 'moment';
function getWindowDimensions() {
 
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
  );
  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const PlumCreated = (props:any) => {
  const [data, setData] = useState([])
  const [isActive, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [length1, setLength1] = useState(0);
  const [total_plum_created ,setTotal_plum_created] =useState(0)
  const [pagination,setPagination]=useState(0)
  const [pagcurrent, setPagCurrent]=useState(1)
   
  // useEffect(()=>{
  //   (async()=>{
  //     let data = await admin_cointoplum_history(1,props.datafuntion.Ce3
  //       ,props.datafuntion.Ce1,props.datafuntion.Ce2); 
  //     setData(data.data);
  //   })();
  // }, [props.datafuntion.Ce3,props.datafuntion.Ce1,props.datafuntion.Ce2]);
  
 

  useEffect(() => {
    (async () => {
      setLoading(true)
      let data = await admin_cointoplum_history(1,props.datafuntion.Ce3
        ,props.datafuntion.Ce1,props.datafuntion.Ce2); 
      // let data = await admin_cointoplum_history (1,null,null,null)
      setLength1(data.data.length)
      setData(data.data);
      setPagination(data.pagination.total_pages)
      setTotal_plum_created(data.total_plum_created )
      setLoading(false)
      propFun(data.data.length, data.total_plum_created,data.total_number );
      
    })();
  },
  [props.datafuntion.Ce3,props.datafuntion.Ce1,props.datafuntion.Ce2])
  //  []);

  const propFun=(le:any,data:any,total:any)=>{
    props.props(le,data,total)
  }

  const onPageChange=async (e:any)=>{
    let page_no=e.selected
    let data = await admin_cointoplum_history(page_no+1,props.datafuntion.Ce3,props.datafuntion.Ce1,props.datafuntion.Ce2)
    setData(data.data);
  
    setLength1(data.data.length)
    setTotal_plum_created(data.total_plum_received)
    setPagCurrent(data.pagination.current)
    
    setLoading(false);
  
  
  }

  const columns = [
    {
      title: 'Number',
      key: 'Number',
      render: (text, indx, record) => <span>{indx + (pagcurrent-1)*10+1}</span>,
    },
    {
      title: 'Users',
      key: 'users',
      render: (text, indx, item) => <span>
         {[item.username]}  
        </span>,
    },
    
    {
      title: 'Plum exchanged from coins',
      key: 'Plum exchanged from coins',
      render: (text, indx, item) => <span>
       { console.log("xxxbbb",item)}
        <span>
          {[item.plum]}
          {'   '}
      
        </span>
      </span>,
    },
    {
      title: 'Date',
      key: 'Date',
      render: (text, index, item) => {
        return <span>
          {moment(item.date).format('MM/DD/YY')}
          </span>;
      },
    },

  ];
  

  const { width, height } = useWindowDimensions();
  return (
    <>
    <div className='plum-created'>
      <Table
          width={width - 70}
          height={height - 220}
          // language={language}
           loading={loading}
            dataSource={data}
          columns={columns}
         //  {...otherProps} 
          />
           <Row style={{ marginTop: '30px' }} type="flex" justify="center">
                    <Col>
                    <ReactPaginate
      containerClassName="statistic-paging-container"
      pageClassName="statistic-paging-page"
      pageLinkClassName="statistic-paging-page-link"
      activeLinkClassName="statistic-paging-page-link-active"
      pageCount={pagination}
      previousLabel={<img src={ChevronLeft} />}
      nextLabel={<img src={ChevronRight} />}
      marginPagesDisplayed={1}
       onPageChange={onPageChange}
     
    />
                    </Col>
                </Row>

    </div>
  
    </>
  )
};



