import React, { Component } from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { connect } from 'react-redux';
import { baseURL } from '../../../../lib/config';
import * as appConstants from '../../../../lib/strings/appConstants';
import { languages } from '../../../../lib/interfaces/language';
import TreasureLocation from './TreasureLocation';
import * as treasureService from '../../../../services/treasureService';
import { detailsConstants } from '../../../../constants/treasureDetailsConstants';
import * as treasureInfo from '../../../../lib/strings/treasureInfo';
import {b} from '../../../dashboard/menu/i18next'
import {
  arrow,
  imgPlaceholder,
  pointImg,
  stickerImg,
  candyImg,
  iconLDiscount,
} from '../../../../assets';

import {
  approved,
  declined,
  inReview,
  roleAdmin,
  roleMaster,
  candy,
  sticker,
  draft,
  point,
  LDiscount,
} from '../../../../lib/const';
import * as menuActions from '../../../../actions/menuActions';
import { menuConstants } from '../../../../constants/menuConstants';
import { persistor } from '../../../../helpers/store';
import { treasureClients } from '../../../../lib/SubMenuConstants';
import ReactPlayer from 'react-player';
import { Action } from 'redux';
import { DeleteConfirmationModal } from '../../../../components';
import * as treasureActions from '../../../../actions/treasureActions';
import moment from 'moment';

interface Props {
  treasureDetails: any;
  language?: languages;
  role?: number;
  authentication: any;
  editDraft?: () => void;
  closeDetails?: () => void;
  openClients?: (menu: string) => void;
  deleteTreasure?: (ID: number) => void;
  expireTreasure?: (ID: number) => void;
  reviewed?: (ID: number) => void;
  storeClients?: (clients: any) => void;
  dispatch: any;
  closeSubMenu1: () => Action;
  treasureBatchEdit: (payload: any) => void;
  editTreasureListParametar: (ID: number, parametarName: string, value: any) => void;
  editTreasureDetailParametar: (parametarName: string, value: any) => void;
}

const mapState = (state: {
  treasureDetails: any;
  authentication: { language: languages };
  userData: { role: number };
}) => {
  const { treasureDetails, authentication, userData } = state;
  const { language } = authentication;
  const { role } = userData;
  return { treasureDetails, language, role, authentication };
};

const mapDispatch = (
  dispatch: (arg0: { type: string; menu?: string; ID?: number; clients?: any }) => any
) => {
  return {
    closeDetails: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    openClients: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_2, menu }),
    deleteTreasure: (ID: number) => dispatch({ type: detailsConstants.DELETE_TREASURE, ID }),
    reviewed: (ID: number) => dispatch({ type: detailsConstants.REVIEWED, ID }),
    storeClients: (clients: any) => dispatch({ type: detailsConstants.STORE_CLIENTS, clients }),
    editDraft: () => dispatch({ type: menuConstants.EDIT_DRAFT }),
    closeSubMenu1: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    treasureBatchEdit: (payload: any) => dispatch(treasureActions.treasureBatchEdit(payload)),
    editTreasureListParametar: (ID, parametarName, value) =>
      dispatch(treasureActions.editTreasureListParametar(ID, parametarName, value)),
    editTreasureDetailParametar: (parametarName, value) =>
      dispatch(treasureActions.editTreasureDetailParametar(parametarName, value)),
    dispatch,
  };
};
let duration1=''
let recurring1=''
class TreasureInfo extends Component<Props, any> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      showLocations: false,
      deleteConfirmationModalIsOpen: false,
      DurationTimeData:0
    };
  }

  handleShowLocations = () => {
    this.setState({
      showLocations: !this.state.showLocations,
    });
  };

  // componentDidMount() {
  //   this.durationTime()
  // }

  recurring=()=>{
   let recurringon =  this.props.treasureDetails?.recurring
   if(recurringon==="0"){
    recurring1 ="Off"
   }
   if(recurringon==="1"){
    recurring1='On'
   }

  }

  durationTime=()=>{
    let time = parseInt((this.props.treasureDetails?.rec_duration/60).toFixed())
    // let a=({...this.state,DurationTimeData:time + ' hours'})
    // this.setState(a)
    duration1=time.toFixed() +' hours'
    if(time>24){
        const days=time/24
        //let b=({...this.state,DurationTimeData:days.toFixed() +' days'})
        duration1=days.toFixed() +' days'
       // this.setState(b)
        if(days>7){
          let weeks=days/7
          // let c=({...this.state,DurationTimeData:weeks.toFixed() +' weeks'})
          // this.setState(c)
          duration1=weeks.toFixed() +' weeks'
          if(weeks>4){
            let month=weeks/4
            // let d=({...this.state,DurationTimeData:month.toFixed() +' months'})
            // this.setState(d)
            duration1=month.toFixed() +' months'
          }
        }
    }
   }
  handleButtons = (e: any) => {
    this.props.closeSubMenu1();
    treasureService.treasureUpdate(this.props.treasureDetails.ID, 'status', e.target.value);
    this.props.reviewed(this.props.treasureDetails.ID);
  };

  deleteTreasure = () => {
    treasureService.deleteTreasure(this.props.treasureDetails.ID);
    this.props.deleteTreasure(this.props.treasureDetails.ID);
    this.props.closeDetails();
  };

  expireTreasure = () => {
    const { treasureDetails, editTreasureListParametar, editTreasureDetailParametar } = this.props;
    const currentTime = parseInt(moment().format('x'));
    console.log(currentTime,"timevvvv")
    treasureService.treasureUpdate(treasureDetails.ID, 'endDate', currentTime);
    editTreasureListParametar(treasureDetails.ID, 'endDate', currentTime);
    editTreasureDetailParametar('endDate', currentTime);
    this.props.closeDetails();
    // old way that was not working
    // treasureService.expireTreasure(this.props.treasureDetails.ID)
    // this.props.expireTreasure(this.props.treasureDetails.ID)
  };

  showClients = async () => {
    if (this.props.treasureDetails.status > inReview) {
      const res = await treasureService.getUsersByTreasureID(this.props.treasureDetails.ID);
      if (res) {
        this.props.storeClients(res.userData);
        this.props.openClients(treasureClients);
      }
    }
  };

  pickImage = () => {
    const { treasureDetails } = this.props;
    switch (treasureDetails.treasureType) {
      case sticker:
        return (
          <img
            src={
              treasureDetails.unitImage
                ? `${baseURL}${treasureDetails.unitImage.pic.path}`
                : stickerImg
            }
          />
        );
      case point:
        if (treasureDetails.mediaPath?.pic?.path) {
          return <img src={`${baseURL}${treasureDetails.mediaPath.pic.path}`} />;
        } else if (treasureDetails.mediaPath?.video?.path) {
          return <ReactPlayer url={`${baseURL}${treasureDetails.mediaPath.video.path}`} controls />;
        } else {
          return <img src={pointImg} />;
        }
      case candy:
        return <img src={candyImg} />;
      case LDiscount:
        return <img src={iconLDiscount} />;

      default:
        break;
    }
  };

  editDraft = () => {
    const { dispatch, closeDetails, treasureBatchEdit, treasureDetails } = this.props;
    persistor.pause();

    treasureBatchEdit({ ...treasureDetails, isEditing: true });
    dispatch(menuActions.editTreasure());
    closeDetails();
  };

  toggleDeleteConfirmationModalHandler = () => {
    this.setState((currentState) => {
      return { deleteConfirmationModalIsOpen: !currentState.deleteConfirmationModalIsOpen };
    });
  };

  render() {
    this.durationTime()
    this.recurring()
    const { treasureDetails, language, authentication } = this.props;
    const { deleteConfirmationModalIsOpen } = this.state;
    let exposureDuration = (treasureDetails.endDate - treasureDetails.startDate) / 1000 / 60 / 60;
    if (exposureDuration < 0) {
      exposureDuration = 0;
    }
    let duration;
    let durationUnit;
    if (exposureDuration <= 24) {
      duration = exposureDuration.toFixed();
      durationUnit =
        duration === '1'
        ?b?.t('hour')
        :b?.t('hours') ;       
    } else if (exposureDuration > 24 && exposureDuration <= 24 * 7) {
      const hoursToDays = exposureDuration / 24;
      duration = hoursToDays.toFixed();
      durationUnit =
        duration === '1'
        ?b?.t('day')
        :b?.t('days') ;
    } else if (exposureDuration > 24 * 7) {
      const hoursToWeeks = exposureDuration / (24 * 7);
      duration = hoursToWeeks.toFixed();
      durationUnit =
        duration === '1'
        ?b?.t('week')
        :b?.t('weeks') ;
    }

    const status = treasureDetails.status;
    let statusText;
    switch (status) {
      case draft:
        statusText =b?.t("Draft");
        break;
      case inReview:
        statusText =b?.t('Pending');
        break;
      case approved:
        statusText = b?.t('Approved');
        break;
      case declined:
        statusText = b?.t('Declined');
        break;
      default:
        break;
    }

    return (
      <Row className="details-wrapper">
        <Row type="flex" justify="center" className="treasure-info">
          <Col style={{ width: '100%' }}>
            <Row className="details-top-row" type="flex" justify="center">
              <Button onClick={this.props.closeDetails} className="details-arrow">
                <img src={arrow} alt="close" />
              </Button>
              <Typography.Text className="fredoka">
                {treasureDetails.status > inReview ? treasureDetails.treasureName : 'Review'}
              </Typography.Text>
            </Row>

            {treasureDetails.status < approved ? (
              <Row type="flex" justify="center">
                <Typography.Text className="fredoka">
                  {treasureDetails.treasureName}
                </Typography.Text>
              </Row>
            ) : null}

            {this.props.role >= roleAdmin ? (
              <Row type="flex" justify="center" align="middle" className="created-by">
                <img
                  src={
                    treasureDetails.ownerInfo.ownerData.avatar
                      ? `${baseURL}/uploads/${treasureDetails.ownerInfo.ownerData.avatar.picture.nameOnServer}`
                      : imgPlaceholder
                  }
                />
                <Typography.Text className="bold">
                  {treasureDetails.ownerInfo.ownerData.username}
                </Typography.Text>
              </Row>
            ) : null}

            <Row type="flex" justify="center" className="selected-treasure-img">
              {this.pickImage()}
            </Row>

            {/* Treasure type */}
            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">{b?.t("Type")}</Typography.Text>
              <Typography.Text>
                {(treasureDetails.treasureType === sticker &&
                  b?.t('Sticker')) ||
                  (treasureDetails.treasureType === point &&
                    b?.t('Point')) ||
                  (treasureDetails.treasureType === candy &&
                    treasureInfo.treasureTypeCandy[language]) ||
                  (treasureDetails.treasureType === LDiscount &&
                    treasureInfo.treasureTypeLDiscount[language])}
              </Typography.Text>
            </Row>

            {treasureDetails.treasureType === sticker ? (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">
                  {b?.t('Character')}
                </Typography.Text>
                <Typography.Text>{this.props.treasureDetails.characterName}</Typography.Text>
              </Row>
            ) : null}

            {/* LDiscount value: */}
            {treasureDetails.treasureType === LDiscount && (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">
                  {treasureInfo.treasureLdiscountValue[language]}
                </Typography.Text>
                <Typography.Text>{this.props.treasureDetails.discount_value}</Typography.Text>
              </Row>
            )}

            {treasureDetails.treasureType !== candy ? (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">
                  {b?.t('duration')}
                </Typography.Text>
                <Typography.Text>
                  {duration} {durationUnit}
                </Typography.Text>
              </Row>
            ) : null}

            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">{b?.t('Number of units')}</Typography.Text>
              <Typography.Text>{treasureDetails.totalNumberOfUnits}</Typography.Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">
                {b?.t('Number of locations')}
              </Typography.Text>
              <Typography.Text>{treasureDetails.locations.length}</Typography.Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">{b?.t('Status')}</Typography.Text>
              <Typography.Text>{statusText}</Typography.Text>
            </Row>
            {treasureDetails.status > inReview ? (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">{b?.t('Clients')}</Typography.Text>
                <Typography.Text>{treasureDetails.clients}</Typography.Text>
              </Row>
            ) : null}
           <Row type="flex" justify="space-between">
              <Typography.Text className="bold">Duration Time</Typography.Text>
              <Typography.Text>{duration1}</Typography.Text>
            </Row>

            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">Recurring</Typography.Text>
              <Typography.Text>{recurring1}</Typography.Text>
            </Row>

          </Col>

          {!this.state.showLocations ? (
            <Row type="flex" justify="center" className="details-margin">
              <Button onClick={this.handleShowLocations} className="transparent-btn-black">
                {b?.t('Show Locations')}
              </Button>
            </Row>
          ) : (
            <Row type="flex" justify="center" className="details-margin">
              <Button onClick={this.handleShowLocations} className="transparent-btn-black">
                {b?.t('Hide Locations')}
              </Button>
            </Row>
          )}

          {this.state.showLocations ? (
            <Row type="flex" justify="center">
              {treasureDetails.locations.map((location: any, index: number) => {
                return (
                  <TreasureLocation
                    key={index}
                    ID={location.ID}
                    location_number={index}
                    radius={location.radius}
                    lat={location.latitude}
                    lng={location.longitude}
                    messageLocations={treasureDetails.messageLocations}
                    isOpen={this.state.showLocations}
                  />
                );
              })}
            </Row>
          ) : null}

          {treasureDetails.status === inReview && this.props.role >= roleAdmin ? (
            <Row type="flex" justify="space-between" className="details-margin">
              <Button
                className="transparent-btn-black"
                onClick={(e) => this.handleButtons(e)}
                value={declined}
              >
                {b?.t('Decline')}
              </Button>
              <Button
                className="buttons yellow-gradient"
                onClick={(e) => this.handleButtons(e)}
                value={approved}
              >
                {b?.t("Approve")}
              </Button>
            </Row>
          ) : null}

          <Row type="flex" justify="space-between" align="middle" className="details-margin">
            {treasureDetails.ownerInfo.ownerData._id === authentication.user.user._id ? (
              <Button
                style={{ width: '72px', height: '16px' }}
                className="buttons transparent-btn-black"
                onClick={this.expireTreasure}
              >
                <span style={{ textDecoration: 'underline' }}>
                  {b?.t('Expire')}
                </span>
              </Button>
            ) : null}
            {treasureDetails.ownerInfo.ownerData._id === authentication.user.user._id ? (
              <Button
                style={{ width: '72px', height: '16px' }}
                className="buttons transparent-btn-red"
                onClick={this.toggleDeleteConfirmationModalHandler}
              >
                <span style={{ textDecoration: 'underline' }}>
                {b?.t('Delete')} 
                </span>
              </Button>
            ) : null}
            {treasureDetails.status === approved && this.props.role >= roleMaster ? (
              <Button
                style={{ width: '105px', height: '34px' }}
                className="buttons purple-gradient-right"
                onClick={this.showClients}
              >
               {b?.t('Clients')}  
              </Button>
            ) : null}
            {treasureDetails.status === draft ? (
              <Button
                style={{ width: '105px', height: '34px' }}
                className="buttons purple-gradient-right"
                onClick={this.editDraft}
              >
                {b?.t('Edit')}
              </Button>
            ) : null}
          </Row>
        </Row>
        <DeleteConfirmationModal
          visible={deleteConfirmationModalIsOpen}
          onCancel={this.toggleDeleteConfirmationModalHandler}
          name={treasureDetails.treasureName}
          onDelete={this.deleteTreasure}
          language={language}
        />
      </Row>
    );
  }
}

export default connect(mapState, mapDispatch)(TreasureInfo);
