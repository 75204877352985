import React from 'react';
import AppContext from '../../../AppContext';
import { Row, Spin } from 'antd';
import { listMyDomes, userDetails } from '../../../services/domeService';
import { store } from '../../../helpers/store';

import closeIcon from '../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../src/assets/location-on-map.svg';
import { Profiler } from 'react';
import Flag from 'react-flagkit';
import Profile from './Profile';
import { connect } from 'react-redux';

class AccountMenu extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      account_setting: { settings: false, setting_active_class: '' },
      lcash_setting: { lcash: false, lcash_active_class: '' },
      plum_setting: { plum: false, plum_active_class: '' },
      received_setting: { received: false, received_active_class: '' },
      cashing_setting: { cashing: false, cashing_active_class: '' },
      history_setting: { history: false, history_active_class: '' },
    };
    this.accountSetting = this.accountSetting.bind(this);
  }

  mySettings = async () => {
    let profile = await this.getContext();
    profile.loader = true;
    profile.received = false;
    profile.cashing = false;
    profile.history = false;
    const id = store.getState().authentication.user.user._id;
    const user = await userDetails(id);
    profile.user = user.user;
    await this.updateContext(profile);

    profile = await this.getContext();
    profile.loader = false;
    profile.domeList = [];
    profile.domeSettingList = [];
    profile.accountSetting = true;
    profile.paymentMethos = false;
    await this.updateContext(profile);

    let account = this.state.account;
    account = { ...this.state.account, setting_active_class: 'active' };
    let account_setting = this.state.account_setting;
    let settings = {
      ...account_setting.settings,
      my_setting: true,
      my_setting_active_class: 'active',
      payment_setting: false,
      payment_setting_active_class: '',
    };

    account_setting = { ...account_setting, settings: settings };
    this.setState({
      account_setting: account_setting,
    });
  };

  paymentSettings = async () => {
    let profile = await this.getContext();
    profile.loader = true;
    // const domeList = await listMyDomes();

    profile = await this.getContext();
    profile.loader = false;
    profile.domeList = [];
    profile.myDomeList = [];
    profile.domeSettingList = [];
    profile.accountSetting = false;
    profile.paymentMethos = true;
    await this.updateContext(profile);

    let account_setting = this.state.account_setting;
    let settings = {
      ...account_setting.settings,
      my_setting: false,
      my_setting_active_class: '',
      payment_setting: true,
      payment_setting_active_class: 'active',
    };
    account_setting = { ...account_setting, settings: settings };
    this.setState({
      account_setting: account_setting,
    });
  };

  accountSetting = async () => {
    let context = await this.getContext();
    context.received = false;
    context.cashing = false;
    context.history = false;
    let account_setting = this.state.account_setting;
    let settings = {
      ...account_setting.settings,
      my_setting: false,
      renewals_billing: false,
      payment_methods: false,
    };
    account_setting = {
      ...account_setting,
      settings: settings,
      setting_active_class: 'active',
    };
    this.setState({
      account_setting: account_setting,
      lcash_setting: {},
      plum_setting: {}
    });
    let profile = await this.getContext();

    profile['recharge'] = false;
    await this.updateContext(profile);
  };

  lcashSetting = async () => {
    let lcash_setting = this.state.lcash_setting;
    let lcash = { ...lcash_setting.lcash, recharge: false, trnsaction: false };
    lcash_setting = {
      ...lcash_setting,
      lcash: lcash,
      lcash_active_class: 'active',
    };
    this.setState({
      account_setting: {},
      plum_setting:{},
      lcash_setting: lcash_setting,
    });
    let context = await this.getContext();
    context.accountSetting = false;
    context['paymentMethos'] = false;
    context.received = false;
    context.cashing = false;
    context.history = false;
    await this.updateContext(context);
  };

  recharge = async () => {
    let context = await this.getContext();
    context.recharge = true;
    let lcash_setting = this.state.lcash_setting;
    let lcash = {
      ...lcash_setting.lcash,
      recharge: true,
      trnsaction: false,
      recharge_active_class: 'active',
      trnsaction_active_class: '',
    };
    lcash_setting = { ...lcash_setting, lcash: lcash };
    this.setState({
      lcash_setting: lcash_setting,
    });
    await this.updateContext(context);
  };

  transaction = async () => {
    let lcash_setting = this.state.lcash_setting;
    let lcash = {
      ...lcash_setting.lcash,
      recharge: false,
      trnsaction: true,
      
      recharge_active_class: '',
      trnsaction_active_class: '',
    };
    lcash_setting = { ...lcash_setting, lcash: lcash };
    this.setState({
      lcash_setting: lcash_setting,
    });
  };

  myReceived= async () => {
    let context = await this.getContext();
    context.received = true;
    context.cashing = false;
    context.history = false;
    let plum_setting = this.state.plum_setting;
    let plum = {
      ...plum_setting.plum,
      received_active_class: 'active', 
      cashing_active_class: '',
       history_active_class: '',
    };
    plum_setting = { ...plum_setting, plum: plum };
    this.setState({
      plum_setting: plum_setting,
    });
    await this.updateContext(context);
  };
  

  myCashing= async ()=>{
    let context = await this.getContext();
    context.received = false;
    context.cashing = true;
    context.history = false;
    let plum_setting = this.state.plum_setting;
    let plum = {
      ...plum_setting.plum,
      received_active_class: '', 
      cashing_active_class: 'active',
       history_active_class: '',
    };
    plum_setting = { ...plum_setting, plum: plum };
    this.setState({
      plum_setting: plum_setting,
    });
    
    await this.updateContext(context);

    

  }
  myHistory = async ()=>{
    let context = await this.getContext();

    context.received = false;
    context.cashing = false;
    context.history = true;
    let plum_setting = this.state.plum_setting;
    let plum = {
      ...plum_setting.plum,
      received_active_class: '', 
      cashing_active_class: '',
       history_active_class: 'active',
    };
    plum_setting = { ...plum_setting, plum: plum };
    this.setState({
      plum_setting: plum_setting,
    });
    
    await this.updateContext(context);
  }

  myplum = async () => {
    let context = await this.getContext();
    context.recharge = false;
    context.trnsaction= false;
    context.paymentMethos = false;
    context.accountSetting = false;
    let plum_setting = this.state.plum_setting;
   
    let plum = { ...plum_setting.plum};
    plum_setting = {
      ...plum_setting,
      plum: plum,
      plum_active_class: 'active',
    };
    plum_setting = { ...plum_setting, plum: plum };
    this.setState({
      account_setting: {},
      lcash_setting:{},
      plum_setting: plum_setting,
    });
    await this.updateContext(context);
  };

  componentDidMount = async () => {
    let profile = await this.getContext();
    profile.domeList = [];
    profile.domeSettingList = [];
    profile.accountSetting = false;
    profile.current_dome = false;
    profile.edit_current_dome = false;
    await this.updateContext(profile);
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  render() {
    const { translation } = this.props
    const context = this.context;
    let toalDomeSettingList = 0;
    const domeSettingList = context.profile.domeSettingList || [];
    toalDomeSettingList = domeSettingList.length;
    let loader = context.profile.loader || false;
    let paymentMethos = context.profile.paymentMethos || false;
    let paymentMethodActiveClass = paymentMethos ? 'active' : '';
    let accountSettingActive = context.profile.accountSetting || false;
    let rechargeActive = context.profile.recharge || false;
    return (
      <>
        <Row className="fx-jcc flex-btn mb-15">
          <button
            onClick={this.accountSetting}
            className={
              'ant-btn primary-ghost-btn xs  ' +
              this.state.account_setting.setting_active_class
            }
          >
          { translation("Settings")}
            {/* Settings */}
          </button>
          <button disabled={true} className={'ant-btn primary-ghost-btn xs '}>
          { translation("Order History")}
            {/* Order History */}
          </button>
          <button
            onClick={this.lcashSetting}
            className={
              'ant-btn primary-ghost-btn xs ' +
              this.state.lcash_setting.lcash_active_class
            }
          >
          { translation("Lcash")}
            {/* Lcash */}
          </button>
          <button 
          onClick={this.myplum}
          className={'ant-btn primary-ghost-btn xs '+
          this.state.plum_setting.plum_active_class
          }>
            { translation("Plums")}
            {/* Plums */}
          </button>
        </Row>

        {this.state.account_setting.settings && (
          <Row className="fx-jcc flex-btn mb-15">
            <button
              onClick={this.mySettings}
              className={`ant-btn primary-ghost-btn xs ${accountSettingActive ? 'active' : ''
                } `}
            // className={
            //   'ant-btn primary-ghost-btn xs  ' +
            //   this.state.account_setting.settings.my_setting_active_class
            // }
            >
              { translation("My settings")}
              {/* My settings */}
            </button>
            <button disabled={true} className={'ant-btn primary-ghost-btn xs '}>
            { translation("Renewals & Billing")}
              {/* Renewals & Billing */}
            </button>
            <button
              onClick={this.paymentSettings}
              className={
                'ant-btn primary-ghost-btn xs ' + paymentMethodActiveClass
              }
            >
              { translation("Payment methods")}
              {/* Payment methods */}
            </button>
          </Row>
        )}

        {this.state.lcash_setting.lcash && (
          <Row className="fx-jcc flex-btn mb-15">
            <button
              onClick={this.recharge}
              className={`ant-btn primary-ghost-btn xs ${rechargeActive ? 'active' : ''
                }`}
            >
               { translation("Recharge")}
              {/* Recharge */}
            </button>
            <button
              onClick={this.transaction}
              disabled
              className={
                'ant-btn primary-ghost-btn xs ' +
                this.state.lcash_setting.lcash.trnsaction_active_class
              }
            >
              { translation("Transaction")}
              {/* Transaction */}
            </button>
          </Row>
        )}
        
        {this.state.plum_setting.plum && (
          <Row className="fx-jcc flex-btn mb-15">
            <button
              onClick={this.myReceived}
              className={'ant-btn primary-ghost-btn xs '+
              this.state.plum_setting.plum.received_active_class
                }
            >
              { translation("Received")}
              {/* Received */}
            </button>
            <button
                onClick={this.myCashing}
              className={'ant-btn primary-ghost-btn xs '+
              this.state.plum_setting.plum.cashing_active_class
            }
            >
               { translation("Cashing")}
               {/* Cashing */}
            </button>

            <button
              onClick={this.myHistory}
              className={'ant-btn primary-ghost-btn xs '+
              this.state.plum_setting.plum.history_active_class
            }
            >
              { translation("History")}
             {/* History */}
            </button>
          </Row>
        )}
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default  connect(mapStateToProps, null) (AccountMenu);
