
import { store } from '../helpers/store';


export const getMarkerLocation  = (lat:any, lng: any,dome_addr: any) =>{
    const mapInstance = store.getState().address.mapInstance;
    let latlngset = new google.maps.LatLng(lat, lng);
    mapInstance.setCenter(latlngset);
    const bounds = mapInstance.getBounds();
    let lat1 = bounds.getSouthWest().lat();
    let lng1 = bounds.getSouthWest().lng(); 

    let lat3 = (lat1 + lat)/2;
    let lng3 = (lng1 + lng)/2;

     let newCenter = new google.maps.LatLng(lat3, lng3);
    // mapInstance.setCenter(newCenter);
    

    var markerIcon = {
    url: 'https://app.loamoa.cladev.com/uploads/location.gif',
    // scaledSize: new google.maps.Size(20, 20),
    labelOrigin: new google.maps.Point(10,-5),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(11,22),
    };

    const marker = new google.maps.Marker({
      position: latlngset,
      draggable: false,
      optimized: false,
      icon: markerIcon,
      label: {
        text: dome_addr,
        color: '#0275d8',
        className: 'marker-label',
      },
      map: mapInstance,

    });

    // let circle = new google.maps.Circle({
    //   map: mapInstance,
    //   clickable: false,
    //   radius: 20,
    //   fillColor: '#fff',
    //   fillOpacity: .6,
    //   strokeColor: '#313131',
    //   strokeOpacity: .4,
    //   strokeWeight: .8,
    //   center: latlngset
    // });
    // // attach circle to marker
    // circle.bindTo('center', marker, 'position');

    return {marker: marker,  center:newCenter};
}
