import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Form, InputNumber, Radio, DatePicker,Switch  } from 'antd';
import calendar from '../../assets/icon_date.svg';
import moment, { Moment, weekdays } from 'moment';
import { connect } from 'react-redux';
import {Recuring} from '../../../src/services/treasureService'
import * as candyEventService from '../../../src/services/candyEventService';

import * as treasureCreation from '../../lib/strings/treasureCreation';
import { useTranslation } from 'react-i18next';


import PropTypes from 'prop-types';

export interface duration {
  durationSelection: string;
}
function StartEndDatePicker(props) {
  const {
    language,
    initialStartDateValue,
    initialEndDateValue,
    onChangeStartDate,
    onChangeEndDate,
    formStartDateId,
    formEndDateId,
    startDateFormMessage,
    endDateFormMessage,
    treasure,
    ID,
   candyEvent,
  } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const currentDate = moment().subtract(1, 'minute');
  const {t}=useTranslation()

  const [startDate, setStartDate] = useState(initialStartDateValue);
  const [endDate, setEndDate] = useState(initialEndDateValue);
  const [duration, setDuration] = useState(Number);
  const [durationSelection, setDurationSelection] = useState<'hours' | 'days' | 'weeks'>('hours');
  const [endstart1,setendstart]=useState(null)
// console.log(startDate,"start"),
// console.log(endDate,"end")
// console.log(endstart1)
  useEffect(() => {
    if (initialStartDateValue && initialEndDateValue) {
      setFieldsValue({
        duration: calculateDuration(initialStartDateValue, initialEndDateValue),
      });
    }
  }, []);
  const handleStartDate = (date) => {
    const newStartDateMs = parseInt( moment(date).set('second', 0).set('millisecond', 0).format('x')
    );

    if (endDate) {
      setFieldsValue({
        duration: calculateDuration(newStartDateMs, endDate),
      });
    }
    setStartDate(newStartDateMs);
  };

  const handleEndDate = (date) => {
  const newEndDateMs = parseInt(moment(date).set('second', 0).set('millisecond', 0).format('x'));
  if (startDate) {
      setFieldsValue({
        duration: calculateDuration(startDate, newEndDateMs),
      });
    }

    setEndDate(newEndDateMs);
    setDuration(0);
  };
 
  const calculateDuration = (start, end) => {
    let differenceTime = (end - start) / 1000 / 60 / 60;
    //  console.log(Math.round(differenceTime))
    let durationDisplay = 0;

    if (differenceTime >= 24) {
      durationDisplay = differenceTime / 24;
      setDurationSelection('days');
      return durationDisplay.toFixed(0);
    }

    setDurationSelection('hours');
    return differenceTime.toFixed(0);
  };

  const handleDurationSelection = (event) => {
    setDurationSelection(event.target.value);
  };

  const handleDuration = (value: number) => {
    setDuration(value); 
  };

  useEffect(()=>{
    if (startDate && duration && durationSelection) {
    const newEndDate = moment(startDate).add(duration, durationSelection);
    setEndDate(parseInt(newEndDate.format('x')));
    }

  },[startDate, duration, durationSelection])

  useEffect(()=>{
    let differenceTime = (endDate - startDate) / 1000 / 60 / 60;
    differenceTime=differenceTime*60
    localStorage.setItem("endstart1",differenceTime.toString());
    setendstart(differenceTime)
  },[startDate, duration, durationSelection,endDate])
  

  useEffect(() => {
    if (startDate !== 0) {
      onChangeStartDate(startDate); 
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== 0) {
      onChangeEndDate(endDate);  
    }
  }, [endDate]);

  

  const OnOff=async(checked: boolean)=>{
    // let id=treasure?.ID
    let ck=''

    if(checked){
      ck='1'
    }
    else {
      ck='0'
    }
    localStorage.setItem('ck',ck.toString() )
  //  const a = await Recuring(id,ck,endstart1)  
  }

  return (
    <div className="startEndDatePicker">
      <Row type='flex' align='middle' className='mb-15'>
        <Col span={20}>
          <Form.Item className='mb-10'>
            <Row type="flex" justify="start" align="middle" className="full-width">
              <Col span={2} className="dateTextContainer">
                <Typography.Text>{t('Start')}</Typography.Text>
              </Col>
              <Col className='inpBlock setw' span={18}>
                {getFieldDecorator(formStartDateId, {
                  rules: [
                    {
                      required: formStartDateId && true,
                      message: startDateFormMessage || t('Please enter start date'),
                    },
                  ],
                  initialValue: initialStartDateValue ? moment(initialStartDateValue) : null,
                })(
                  <DatePicker
                    className="dateSelectorContainer"
                    format={'DD/MM/YY hh:mm A'}
                    showTime={true}
                    suffixIcon={<img src={calendar} />}
                    onChange={handleStartDate}
                    disabled={false}
                    disabledDate={(current) => {
                      return (
                        currentDate.isAfter(current) || (endDate && moment(endDate).isBefore(current))
                      );
                    }}
                  />
                )}
              </Col>         
            </Row>
          </Form.Item>

          <Form.Item className='mb-0'>
            <Row type="flex" justify="start" align="middle" className="full-width">
              <Col span={2} className="dateTextContainer">
                <Typography.Text>{t('End')}</Typography.Text>
              </Col>
              <Col span={18} className='inpBlock setw'>
                {getFieldDecorator(formEndDateId, {
                  rules: [
                    {
                      required: formEndDateId && true,
                      message: endDateFormMessage || t('Please enter end date'),
                    },
                  ],
                  initialValue: initialEndDateValue ? moment(initialEndDateValue) : null,
                })(
                  <DatePicker
                    className="dateSelectorContainer"
                    format={'DD/MM/YY hh:mm A'}
                    showTime={true}
                    suffixIcon={<img src={calendar} />}
                    onChange={handleEndDate}
                    disabled={false}
                    disabledDate={(current) => {
                      const newCurrent = moment(current);
                      newCurrent.set('hours', 23).set('minutes', 59);
                      return moment(startDate).isAfter(newCurrent);
                    
                    }}
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={4} className='text-center'>
          <span className='size12'>Recurring</span>
          <Switch checkedChildren="On" unCheckedChildren="Off" defaultChecked onChange={OnOff} />
        </Col>
      </Row>

      <Form.Item className="roboto-regular mb-0">
        <Row type="flex" justify="space-between" className="row-duration">
          <Col className="durationTextContainer">
            <Typography.Text>
              {t('Duration')}
            </Typography.Text>
          </Col>

          <Col className="durationTextContainer">
            {getFieldDecorator('duration', {
              rules: [
                {
                  required: false,
                  message: t('Please input sticker duration'),
                },
              ],
            })(
              <InputNumber
                className="character-input-number"
                placeholder="..."
                name="duration"
                onChange={handleDuration}
                min={1}
              />
            )}
          </Col>
          {/* onChange={e => this.handleDuration(e, 'radio')}   value={this.props.durationUnit} */}
          <Radio.Group
            style={{ display: 'flex' }}
            onChange={handleDurationSelection}
            // {console.log(durationSelection)}
            value={durationSelection}
              >
            <Col className="durationTextContainer">
              <Radio.Button className="character-radio-button" value="weeks">
                {t('weeks')}
              </Radio.Button>
            </Col>
            <Col className="durationTextContainer">
              <Radio.Button className="character-radio-button" value="days">
                {t('days')}
              </Radio.Button>
            </Col>
            <Col className="durationTextContainer">
              <Radio.Button className="character-radio-button" value="hours">
                {t("h")}
              </Radio.Button>
            </Col>
          </Radio.Group>
        </Row>
      </Form.Item>
      <div className="bottomSpacer"></div>
    </div>
  );
}

StartEndDatePicker.propTypes = {
  language: PropTypes.string,
  initialStartDateValue: PropTypes.number,
  initialEndDateValue: PropTypes.number,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  formStartDateId: PropTypes.string,
  formEndDateId: PropTypes.string,
  startDateFormMessage: PropTypes.string,
  endDateFormMessage: PropTypes.string,
  form: PropTypes.object.isRequired,
};

StartEndDatePicker.defaultProps = {
  language: 'EN',
  onChangeStartDate: () => {},
  onChangeEndDate: () => {},
  formStartDateId: 'startDate',
  formEndDateId: 'endDate',
};

const mapStateToProps = (state) => (
  {
  candyEvent :state.candyEvent.ID,
  treasure:state.treasure
}
);
export default connect(mapStateToProps)(StartEndDatePicker);
