// import { ImportDome, SearchDome, UpdateDome, UpdateDomeType, deleteDome, getDomeTypes, getMessagesList, isExistsDomeName, toggleDomeType } from '../../../services/domeService';
import {
  ImportDome,
  SearchDome,
  UpdateDome,
  UpdateDomeType,
  deleteDome,
  getDomeTypes,
  getImportDomeDelete,
  getImportDomeStatus,
  getMessagesList,
  getSearchDomeByNameAndAddress,
  isExistsDomeName,
  listMyDomes,
  postEditDomeNameImportConfirm,
  toggleDomeType,
  getDeleteDomeSettings,
  publishUnpublishDome,
  publishDomeApi,
  getImportDomeNameStatus,
  getDuplicateDomeNameDelete,
  getDomeAddressNextSuffix,
} from "../../../services/domeService";
import { Row, Spin, notification } from "antd";
import { throttle } from "throttle-debounce";
import AfterImport from "./domeSetting/afterImport";
import AppContext from "../../../AppContext";
import DomeDuplicateVerfication from "./domeSetting/DomeDuplicateVerfication";
import DomeNameSetting from "./domeSetting/nameSetting";
import React from "react";
import ReactTooltip from "react-tooltip";
import VerificationImport from "./domeSetting/verificationImport";
import arrowBlue from "../../../../src/assets/arrowBlue.png";
import arrowBrown from "../../../../src/assets/arrowBrown.png";
import arrowBrownLight from "../../../../src/assets/arrowBrownLight.png";
import arrowCream from "../../../../src/assets/arrowCream.png";
import arrowGray from "../../../../src/assets/arrowGray.png";
import arrowGreen from "../../../../src/assets/arrowGreen.png";
import arrowOrange from "../../../../src/assets/arrowOrange.png";
import arrowPink from "../../../../src/assets/arrowPink.png";
import arrowPrimary from "../../../../src/assets/arrowPrimary.png";
import arrowPurple from "../../../../src/assets/arrowPurple.png";
import arrowRed from "../../../../src/assets/arrowRed.png";
import arrowSky from "../../../../src/assets/arrowSky.png";
import changeIcon1 from "../../../../src/assets/changeIcon1.png";
import closeIcon from "../../../../src/assets/closeXsmall.svg";
import deleteIcon from "../../../../src/assets/icon_delete_o.svg";
import editIcon from "../../../../src/assets/icon_edit.svg";
import { getMarkerLocation } from "../../../helpers/GetLocation";
import infoIcon from "../../../../src/assets/icon_info.svg";
import locationIcon from "../../../../src/assets/location-on-map.svg";
import moment from "moment";
import { store } from "../../../helpers/store";
import DomeEditing from "./domeEditing";
import { baseURL, myDome } from "../../../lib/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as menuActions from "../../../actions/menuActions";
import { useTranslation } from "react-i18next";
import { b } from "../../dashboard/menu/i18next";
import temp from "../../../../src/assets/templateA-1.jpg";
import temp1 from "../../../../src/assets/223237.jpg";
import FsLightbox from "fslightbox-react";

import imageCircle from "../../../../src/assets/imageCircle.png";
import noticeboardCircle from "../../../../src/assets/noticeboardCircle.png";
import applicationCircle from "../../../../src/assets/applicationCircle.png";
import performanceScheduleCircle from "../../../../src/assets/performanceScheduleCircle.png";
import ticketBoxCircle from "../../../../src/assets/ticketBoxCircle.png";

// import AfterImport from './domeSetting/afterImport';
// import DomeNameSetting from './domeSetting/nameSetting';
// import ReactTooltip from 'react-tooltip';
// import VerificationImport from './domeSetting/verificationImport';
// import arrowBrown from '../../../../src/assets/arrowBrown.png';
// import arrowBrownLight from '../../../../src/assets/arrowBrownLight.png';
// import arrowCream from '../../../../src/assets/arrowCream.png';
// import arrowGray from '../../../../src/assets/arrowGray.png';
// import arrowPink from '../../../../src/assets/arrowPink.png';
// let arr = []
function SelectedType(props) {
  const { t } = useTranslation();

  if (props.editType == 1) {
    return (
      <>
        <div className="typeSection__infographic">
          <div className="templateCircle1 temp-a2">
            <span className="points topCenter">{t("Hall")} (20)</span>
            {/* <span className="points lobby">{t("Lobby")}</span> */}
          </div>
        </div>
        <div className="typeSection__right">
          <ul className="buletPoints rightSide">
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            {/* <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li> */}
            <li>
              <img height={18} src={noticeboardCircle} alt="Image" /> {t("Noticeboard")}
            </li>
            {/* <li>
              <img src={arrowGreen} alt="Image" /> {t("Hall")}
            </li>
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li> */}
            <li>
              <img height={18} src={applicationCircle} alt="Image" /> 
              {t("Application")}
              <br />
              {t("(Menu)")}
            </li>
            {/* <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img height={18} src={imageCircle} alt="Image" /> {t("Image+")}
            </li> */}
          </ul>
        </div>
      </>
    );
  } else if (props.editType == 5) {
    return (
      <>
        <div className="typeSection__infographic">
          <div className="templateCircle1 temp-a1">
            <span className="points topCenter">{t("Hall")} (16)</span>
            {/* <span className="points lobby">{t("Lobby")}</span> */}
          </div>
        </div>
        <div className="typeSection__right">
          <ul className="buletPoints rightSide">
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            {/* <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li> */}
            <li>
              <img src={noticeboardCircle} alt="Image" /> {t("Noticeboard")}
            </li>
            {/* <li>
              <img src={arrowGreen} alt="Image" /> {t("Hall")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>*/}
            <li> 
              <img src={applicationCircle} alt="Image" /> 
              {t("Application")}
              <br />
              {t("(Menu)")}
            </li>
            {/* <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li> */}
          </ul>
        </div>
      </>
    );
  } else if (props.editType == 2) {
    return (
      <>
        <div className="typeSection__infographic">
          <div className="templateCircle2 temp-b1">
            <span className="points topCenter">{t("Arena")}</span>
            {/* <span className="points lobby">{t("Lobby")}</span> */}
          </div>
        </div>
        <div className="typeSection__right">
          <ul className="buletPoints rightSide">
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={noticeboardCircle} alt="Image" /> {t("Noticeboard")}
            </li>
            <li>
              <img src={performanceScheduleCircle} alt="Image" /> {t("Performance Schedule")}
            </li>
            <li>
              <img src={applicationCircle} alt="Image" /> 
              {t("Application")}
              <br />
              {t("(Menu)")}
            </li>
            <li>
              <img src={ticketBoxCircle} alt="Image" /> 
              {t("Ticket Box")}
              <br />
              {t("(Menu)")}
            </li>

            {/* <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li> 
            
            <li>
              <img src={arrowGreen} alt="Image" /> {t("Arena")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
             */}
          </ul>
        </div>
      </>
    );
  } else if (props.editType == 4) {
    return (
      <>
        <div className="typeSection__infographic">
          <div className="templateCircle2">
            <span className="points topCenter">{t("Arena")}</span>
            {/* <span className="points lobby">{t("Lobby")}</span> */}
          </div>
        </div>
        <div className="typeSection__right">
          <ul className="buletPoints rightSide">
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={applicationCircle} alt="Image" /> 
              {t("Application")}
              <br />
              {t("(Menu)")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={noticeboardCircle} alt="Image" /> {t("Noticeboard")}
            </li>
            <li>
              <img src={arrowGreen} alt="Image" /> {t("Arena")}
            </li>
            <li>
              <img src={performanceScheduleCircle} alt="Image" /> {t("Performance Schedule")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={ticketBoxCircle} alt="Image" /> {t("Ticket Box")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
          </ul>
        </div>
      </>
    );
  } else if (props.editType == 3) {
    return (
      <>
        <div className="typeSection__infographic">
          <div className="templateCircle3">
            <span className="points topLeft">{t("Hall")}</span>
            <span className="points lobby">{t("Lobby")}</span>
            <span className="points topRight">{t("Arena")}</span>
          </div>
        </div>
        <div className="typeSection__right">
          <ul className="buletPoints rightSide">
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={applicationCircle} alt="Image" /> 
              {t("Application")}
              <br />
              {t("(Menu)")}
            </li>
            <li>
              <img src={noticeboardCircle} alt="Image" /> {t("Noticeboard")}
            </li>
            <li>
              <img src={arrowOrange} alt="Image" /> {t("Hall")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={arrowBlue} alt="Image" /> {t("Arena")}
            </li>
            <li>
              <img src={performanceScheduleCircle} alt="Image" /> {t("Performance Schedule")}
            </li>
            <li>
              <img src={ticketBoxCircle} alt="Image" /> {t("Ticket Box")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
            <li>
              <img src={imageCircle} alt="Image" /> {t("Image+")}
            </li>
          </ul>
        </div>
      </>
    );
  } else {
    return <> </>;
  }
}
function renderButtonBasedOnImportStatus(impName, isVerified, impVerifyToggle) {

  // const { t } = useTranslation();
  if (impName && isVerified) {
    return (
      <button
        // onClick={(e) => this.openModal(e, current_dome.dome_name)}
        className="ant-btn xs auto ml-30 verified"
      >
        {b?.t("Verified")}
      </button>
    );
  }
  if (impName && !isVerified) {
    return (
      <button
        // onClick={(e) => this.openModal(e, current_dome.dome_name)}
        className="ant-btn xs auto ml-30 pending"
      >
        {b?.t("Pending")}
      </button>
    );
  }
  return (
    <button
      className="ant-btn primary-ghost-btn xs ml-5"
      onClick={impVerifyToggle}
    >
      {b?.t("Verify")}
    </button>
  );
}
const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};
const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

class DomeSetting extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      templatea: 'active',
      templateb: '',
      Zoom: false,
      Zoom1: false,
      Zoom2: false,
      dome_id: 0,
      toShow: false,
      dome_name: "",
      dome_at: "@",
      image: null,
      success: "",
      error: "",
      typeEditSelected: false,
      edit: false,
      TemplateA: false,
      TemplateB: false,
      type: 0,
      // dome_type:{},
      type1: false,
      type2: false,
      type3: false,
      options: [],
      importName: "",
      verify: false,
      verification_to_import: false,
      after_verification: false,
      import_focus: false,
      noThisTime: true,
      loader: false,
      domeLoader: false,
      sureBtn: false,
      nameToImport: false,
      typeLoader: false,
      isImport: true,
      settingId: "0",
      toggelImport: false,
      toggelDomeSettings: true,
      verifyModal: false,
      deleteModal: false,
      duplicate_verify_email_modal: false,
      import_name_or_address: "",
      selectedValuesForDomeImport: {},
      isImportedDomeStatusVerified: false,
      loadAgain: false,
      confirmDuplicateImportModal: false,
      deleteDuplicateImportModal: false,
      deleteDomeSettingsModal: false,
      showEditSettingsModal: false,
      isDomeNameVerified: false,
      loadDomeAgainForUpdate: false,
      importDomeNameToImportMailInfo: false,
      cannotDeleteModal: false,
      deleteDomeNameToImportModal: false,
      disableEnterButton: true,
      domeNameToImportAfterVerifiedModal: false,
    };
  }
  checkStatusOfImportedDome = async () => {
    if (
      this.context &&
      this.context.profile &&
      this.context.profile.current_dome
    ) {
      const { ID, import_name } = this.context.profile.current_dome;
      if (import_name) {
        const res = await getImportDomeStatus(ID);
        return res.verified;
      }
    }
    return false;
  };
  // checkStatusOfImportedDomeName = async () => {
  //   if (
  //     this.context &&
  //     this.context.profile &&
  //     this.context.profile.current_dome
  //   ) {
  //     const { ID, dome_name, parent_id } = this.context.profile.current_dome;
  //     if (dome_name && parent_id) {
  //       const res = await getImportDomeNameStatus(ID, parent_id);
  //       return res.verified;
  //     }
  //   }
  //   return false;
  // };
  async getMyDome() {
    const requestOption = {
      method: "GET",
      headers: {
        "access-token": store.getState().authentication.user.newToken,
        Accept: "application/json",
      },
    };
    const res = await fetch(baseURL + myDome, requestOption).then((r) =>
      r.json()
    );
    return res.data;
  }
  callDomeApiForUpdatedData = async () => {
    const context = this.context;
    let profile = context.profile || {};
    let myDome = await this.getMyDome();
    let curr_updated_dome = myDome.find(
      (x) => x.ID === profile.current_dome.ID
    );
    // curr_updated_dome.dome_name = `${curr_updated_dome.dome_name}${curr_updated_dome.dome_name_suffix}`;
    profile.current_dome = curr_updated_dome;
    profile.domeSettingList = myDome;
    await this.updateContext(profile);
    this.setState({
      loadDomeAgainForUpdate: false,
      isImport:
        curr_updated_dome.dome_name && curr_updated_dome.file ? false : true,
      // isImport: curr_updated_dome.dome_name !== null ? false : true,
      noThisTime: curr_updated_dome.dome_name !== null ? false : true,
    });
  };
  calculateNameToImport = () => {
    if (
      this.context.profile.current_dome.dome_name &&
      this.context.profile.current_dome.file
    ) {
      return false;
    }
    if (
      this.context.profile.current_dome.dome_name &&
      !this.context.profile.current_dome.file
    ) {
      return true;
    }
    return false;
  };
  componentDidMount = async () => {
    const isVerified = await this.checkStatusOfImportedDome();
    // const isDomeNameVerified = await this.checkStatusOfImportedDomeName();
    this.setState({
      addr_name: this.context.profile.current_dome.addr_name || "",
      noThisTime:
        this.context.profile.current_dome.dome_name !== null ? false : true,
      isImport:
        this.context.profile.current_dome.dome_name &&
          this.context.profile.current_dome.file
          ? false
          : true,
      nameToImport: this.calculateNameToImport(),
      type: this.context.profile.current_dome.dome_type
        ? parseInt(this.context.profile.current_dome.dome_type)
        : 0,
      toShow: this.context.profile.current_dome.dome_type ? true : false,
      toggelImport: this.context.profile.current_dome.import_name
        ? true
        : false,
      toggelDomeSettings: this.context.profile.current_dome.import_name
        ? false
        : true,
      import_name_or_address:
        this.context.profile.current_dome.import_name || "",
      isImportedDomeStatusVerified: isVerified,
      // isDomeNameVerified: isDomeNameVerified,
      ImportName: this.context.profile.current_dome.dome_name,
      domeNameToImportAfterVerifiedModal:
        this.context.profile.current_dome.dome_status == 1 &&
          !this.context.profile.current_dome.dome_name_suffix &&
          !this.context.profile.current_dome.file
          ? true
          : false,
    });
  };
  componentDidUpdate = async () => {
    const context = this.context;
    let profile = context.profile || {};
    let id = profile.current_dome.ID;
    let selected_dome_id_for_imd_or_dsb =
      profile.selected_dome_id_for_imd_or_dsb;
    let addr_name = profile.current_dome.addr_name || "";
    if (this.state.loadDomeAgainForUpdate) {
      await this.callDomeApiForUpdatedData();
    }
    if (id === selected_dome_id_for_imd_or_dsb && profile.isSettingsClosed) {
      this.setState({
        toggelImport: profile.toggelImport_cntx,
        toggelDomeSettings: profile.toggelImport_cntx
          ? false
          : profile.toggelDomeSettings_cntx,
      });
      profile.isSettingsClosed = false;
      this.updateContext(profile);
    }
    if (this.state.addr_name !== addr_name) {
      const isVerified = await this.checkStatusOfImportedDome();
      this.setState({
        addr_name: addr_name,
        dome_name: false,
        isImportedDomeStatusVerified: isVerified,
      });
    } else if (this.state.loadAgain) {
      const isVerified = await this.checkStatusOfImportedDome();
      this.setState({
        isImportedDomeStatusVerified: isVerified,
        loadAgain: false,
      });
    }
  };
  callLoadAgain = () => {
    this.setState({
      loadAgain: true,
    });
  };
  currentDomeLoadAgainHandler = () => {
    this.setState({
      loadDomeAgainForUpdate: true,
    });
  };
  checkDomeName = async (dome_name: any) => {

    if (!dome_name) {
      showErrorMessage(b?.t("Please Enter Dome Name"));
      return false;
    }
    if (this.state.disableEnterButton) {
      showErrorMessage(b?.t("Please Enter Valid Dome Name"));
      return false;
    }
    /**##################################################################### */
    const context = this.context;
    let profile = context.profile || {};
    let id = profile.current_dome.ID;
    this.setState({
      loader: !this.state.image ? false : true,
    });
    const resp = await UpdateDome(
      id,
      this.state.dome_name || profile?.current_dome?.dome_name
      // this.state.image
    );
    console.log("yes here..........", resp);
    showSuccessMessage(b?.t("Dome updated Successfully"));
    this.setState({
      loader: false,
      success: "",
      sureBtn: false,
      noThisTime: !this.state.noThisTime,
    });
    profile.current_dome.dome_name =
      this.state.dome_name || profile?.current_dome?.dome_name;
    profile.current_dome.file = this.state.image ? this.state.image.name : "";
    profile.domeSettingList[profile.current_dome.current_index] =
      profile.current_dome;
    await this.updateDomeList(id);
    this.updateContext(profile);
    this.setState({
      dome_name: profile.current_dome.dome_name,
    });
    /**##################################################################### */
    this.setState({
      isImport: false,
    });
  };
  handleUpload = async (e: any) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleDomeTypeToggle = async (domeId, typeId, status) => {
    const resp = await toggleDomeType(typeId, status);
    showSuccessMessage(b?.t("Data Updated"));
    const domeSetting = await getDomeTypes(domeId);
    let profile = await this.getContext();
    profile.domeTypeSetting = domeSetting.dome_type_setting;
    // console.log(profile.domeTypeSetting, 'profile.domeTypeSetting')
    await this.updateContext(profile);
  };
  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };
  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };
  closeModal = async () => {
    this.setState({
      sureBtn: false,
    });
  };
  openModal = async (e, status) => {
    if (status) {
      this.setState({
        sureBtn: true,
      });
    }
  };
  handleSubmit = async () => {
    if (!this.state.image) {
      showErrorMessage(b?.t("Please upload file"));
      return false;
    }
    const context = this.context;
    let profile = context.profile || {};
    let id = profile.current_dome.ID;
    this.setState({
      loader: !this.state.image ? false : true,
    });
    const resp = await UpdateDome(
      id,
      this.state.dome_name || profile?.current_dome?.dome_name
      // this.state.image
    );
    showSuccessMessage(b?.t("Dome updated Successfully"));
    this.setState({
      loader: false,
      success: "",
      sureBtn: false,
      noThisTime: !this.state.noThisTime,
    });
    profile.current_dome.dome_name =
      this.state.dome_name || profile?.current_dome?.dome_name;
    profile.current_dome.file = this.state.image ? this.state.image.name : "";
    profile.domeSettingList[profile.current_dome.current_index] =
      profile.current_dome;
    await this.updateDomeList(id);
    this.updateContext(profile);
    this.setState({
      dome_name: profile.current_dome.dome_name,
    });
  };
  yesToDelete = async () => {
    const context = this.context;
    let profile = context.profile || {};
    let id = profile.current_dome.ID;
    const resp = await deleteDome(id);
    showSuccessMessage(b?.t("Dome Name removed Successfully"));
    this.setState({
      sureBtn: false,
      noThisTime: true,
      isImport: true,
      image: null,
    });
    profile.current_dome.dome_name = null;
    profile.current_dome.file = null;
    profile.current_dome.dome_status = 0;
    profile.domeSettingList[profile.current_dome.current_index] =
      profile.current_dome;
    this.updateContext(profile);
    this.setState({
      dome_name: null,
      image: null,
    });
  };
  handleNoThisTime = () => {
    this.setState({
      noThisTime: !this.state.noThisTime,
    });
  };
  domeNameHandler = async (e: any) => {
    let success = this.state.success;
    let error = this.state.error;
    let disableEnterButton = true;
    if (e.target.value === "") {
      showErrorMessage(b?.t("Please Enter name"));
      success = "";
      error = "";
      this.setState({
        domeLoader: false,
        dome_name: "",
        success: false,
        error: false,
      });
      return false;
    } else {
      this.setState({
        domeLoader: true,
        dome_name: e.target.value,
      });
      const data = await isExistsDomeName(e.target.value);
      if (!data.exists) {
        success = "Yes, you can register";
        error = "";
        disableEnterButton = false;
      } else {
        error = "Not available";
        success = "";
        disableEnterButton = true;
      }
      this.setState({
        success: success,
        error: error,
        domeLoader: false,
        disableEnterButton: disableEnterButton,
      });
    }
  };
  editType = (e: any) => {
    this.setState({
      edit: true,
      TemplateA: true,
      TemplateB: false,
      templatea: "active",
      templateb: '',
      typeEditSelected: false,
    });

  };
  domeEditingHandler = async (dome_id, domeData) => {
    let profile = await this.getContext();
    // const domeSetting = await getDomeTypes(dome_id);
    let domeSettingList = profile.domeSettingList;
    // profile.edit_current_dome = domeData;
    // profile.current_dome = domeData.ID;
    // profile.domeTypeSetting = domeSetting.dome_type_setting;
    // await this.updateContext(profile);
    let current_dome = domeSettingList[domeData.ID];
    // profile.edit_current_dome = current_dome;
    profile.edit_current_dome = domeData;
    // profile.edit_current_dome.current_index = domeData.ID;
    await this.updateContext(profile);
    this.setState({
      showEditSettingsModal: true,
    });
  };
  domeDetails = async (id: number, index: number, type) => {
    let profile = await this.getContext();
    const domeSetting = await getDomeTypes(id);
    let domeSettingList = profile.domeSettingList;
    profile.edit_current_dome = null;
    profile.current_dome = null;
    profile.admin_dome = null;
    profile.domeTypeSetting = domeSetting.dome_type_setting;
    await this.updateContext(profile);
    let current_dome = {};
    if (!index) {
      current_dome = domeSettingList.find((x) => x.ID === id);
    } else {
      current_dome = domeSettingList[index];
    }
    profile.current_dome = null;
    profile.edit_current_dome = current_dome;
    profile.edit_current_dome.current_index = index;
    profile.isDomeEditingActive = "active";
    profile.isDomeSubEditingActive = true;
    profile.domeEditingTypeColor = type.color;
    profile.domeEditingTypeName = type.name;
    await this.updateContext(profile);
  };
  domeDetailsGoToAdmin = async (id: number, index: number, type) => {
    let profile = await this.getContext();
    const domeSetting = await getDomeTypes(id);
    let domeSettingList = profile.domeSettingList;
    profile.edit_current_dome = null;
    profile.current_dome = null;
    profile.admin_dome = null;
    profile.domeTypeSetting = domeSetting.dome_type_setting;
    await this.updateContext(profile);
    let current_dome = domeSettingList[index];
    profile.current_dome = null;
    profile.edit_current_dome = null;
    profile.admin_dome = current_dome;
    profile.admin_dome.current_index = index;
    profile.isDomeAdminNavigation = "active";
    profile.isDomeSubAdminActive = true;
    // profile.domeEditingTypeColor = type.color;
    profile.domeAdminTypeName = type.name;
    await this.updateContext(profile);
  };
  closeEditType = (e: any) => {
    this.setState({
      edit: false,
      TemplateA: false,
      TemplateB: false,
    });
  };
  typeSubmitHandler = async (typeId) => {
    if (typeId === 0) {
      showErrorMessage(b?.t("Please Select Template"));
      return false;
    }
    this.setState({
      toShow: true,
      type: typeId,
      typeEditSelected: true,
      edit: false,
    });
  };
  typeChecked = (id: any) => {
    this.setState({
      toShow: false,
      type: id,
      typeEditSelected: true,
    });
  };

  typeCheckedA = () => {
    this.setState({
      TemplateA: true,
      templatea: 'active',
      TemplateB: false,
      templateb: '',
    });
  };

  typeCheckedB = () => {
    this.setState({
      TemplateB: true,
      TemplateA: false,
      templatea: '',
      templateb: 'active',
    });
    this.setState({

    });
  };

  deleteType = async () => {
    this.setState({
      edit: false,
      typeEditSelected: false,
      type: 0,
      deleteModal: !this.state.deleteModal,
    });
    const context = this.context;
    let profile = context.profile;
    let current_dome = profile.current_dome;
    let id = current_dome.ID;
    profile.current_dome.dome_type = 0;
    profile.current_dome.setting_status = 0;
    profile.domeSettingList[profile.current_dome.current_index] =
      profile.current_dome;
    // await UpdateDomeType(id, "0");
    context.setProfile(profile);
  };
  saveType = async () => {
    const context = this.context;
    let profile = context.profile;
    let current_dome = profile.current_dome;
    let id = current_dome.ID;
    current_dome.dome_type = this.state.type;
    console.log(current_dome, "bbbb")
    // current_dome.setting_status = current_dome.dome_status;
    profile.current_dome = current_dome;
    profile.domeSettingList[profile.current_dome.current_index] =
      profile.current_dome;
    this.setState({
      typeLoader: true,
    });
    let res = await UpdateDomeType(id, this.state.type);
    console.log(res, "res12345")
    if (res.dome.ID > 0) {
      //  showSuccessMessage(res.description);
      /**
       UPDATE THIS EVERYWHERE WHEN THINGS ARE EDITED
       */
      const domeSetting = await getDomeTypes(id);
      // this.setState({dome_type:domeSetting.dome_type})

      //  console.log(domeSetting, "123idArif")

      profile.domeTypeSetting = domeSetting.dome_type_setting;
      //  console.log(profile.domeTypeSetting,"hello")
      // console.log("object profile.domeTypeSetting", profile.domeTypeSetting)
    } else {
      showErrorMessage(b?.t("Something went wrong"));
    }
    this.setState({
      typeLoader: false,
    });

    context.setProfile(profile);
    this.setState({
      dome_name: current_dome.dome_name,
      dome_at: "@",
      image: current_dome.file,
      success: "",
      error: "",
      typeEditSelected: false,
      edit: false,
      type: false,
      type1: false,
      type2: false,
    });
  };
  closeDomeSetting = () => {
    const context = this.context;
    let profile = context.profile;
    profile.current_dome = false;
    profile.isSettingsClosed = true;
    context.setProfile(profile);
  };
  ImportNameHandler = async (e: any) => {
    e.persist();
    let importName = e.target.value;
    this.setState({
      importName: importName,
    });
    if (importName.length >= 2) {
      let option = await SearchDome(this.state.importName);
      this.setState({
        option: option,
        import_focus: true,
        nameToImport: true,
      });
    } else {
      this.setState({
        option: [],
        import_focus: false,
        nameToImport: false,
      });
    }
    if (e.target.value) {
      this.setState({
        nameToImport: true,
        noThisTime: false,
      });
    } else {
      this.setState({
        nameToImport: false,
        noThisTime: true,
      });
    }
  };
  changeOption = async (e) => {
    this.setState({
      importName: e.target.value,
      option: [],
      import_focus: false,
    });
  };
  importStatus = async (obj: any) => {
    this.setState(obj);
  };
  verifyImport = throttle(
    15000,
    async (e: any) => {
      const profile = await this.getContext();
      let id = profile["current_dome"].ID;
      const data = await ImportDome(this.state.importName, id);
      if (data) {
        if (data.description) {
          return showErrorMessage(b?.t("Invalid Dome Name"));
          // return showErrorMessage(data.description);
        } else if (data.email) {
          await this.updateDomeList(id);
          this.setState({
            importDomeNameToImportMailInfo: true,
            emailForVerification: data.email,
          });
        }
      }
      // if (data.owner) {
      //   await this.importStatus({ verification_to_import: true });
      // } else {
      //   await this.importStatus({ verify: true });
      // }
    },
    {
      noTrailing: true,
    }
  );
  encodeEmail = (email) => {
    let finalEmail = [];
    let splited = email.split("");
    let atTheRateInd = email.indexOf("@");
    let dotInd = email.indexOf(".");
    let indexArray = [
      atTheRateInd,
      atTheRateInd - 1,
      atTheRateInd - 2,
      atTheRateInd - 3,
      dotInd,
    ];
    for (let index = 0; index < splited.length; index++) {
      if (indexArray.includes(index)) {
        finalEmail.push(splited[index]);
      } else {
        finalEmail.push("X");
      }
    }
    return finalEmail.join("");
  };
  onEditImportDomeNameClick = async () => {
    const context = this.context;
    let profile = context.profile || {};
    let current_dome = profile.current_dome;
    if (!current_dome.dome_name_suffix) {
      let formValues = {
        domename: current_dome.dome_name,
      };
      const res = await getDomeAddressNextSuffix(formValues);
      if (res && res.length) {
        profile.current_dome["next_suffix_after_import"] = res[0];
        await this.updateContext(profile);
      }
    }
    this.importStatus({ verification_to_import: true });
  };
  closeImportDomeNameMailInfoModal = () => {
    this.setState({
      importDomeNameToImportMailInfo: false,
    });
  };
  getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
    const contextData = this.context;
    let profile = contextData.profile;
    const mapInstance = store.getState().address.mapInstance;
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }
    let res = getMarkerLocation(lat, lng, dome_addr);
    mapInstance.setCenter(res.center);
    this.setState({
      marker: res.marker,
    });
  };
  goToAdmin = async (e, index) => {
    let contextData = await this.getContext();
    contextData.current_dome = contextData.domeSettingList[index];
    contextData.current_dome.current_index = index;
    contextData.domeSettingList = contextData.domeSettingList;
    contextData.domeSettingList = [];
    contextData["current_menu_name"] = "admin_domes";
    contextData["go_to_setting"] = 1;
    await this.updateContext(contextData);
    // this.props.menuChange('dome-admin');
  };
  enableDomeImport = async () => {
    const context = this.context;
    let profile = context.profile || {};
    let id = profile.current_dome.ID;
    this.setState({
      toggelImport: !this.state.toggelImport,
      toggelDomeSettings: false,
    });
    profile.toggelImport_cntx = !this.state.toggelImport;
    profile.toggelDomeSettings_cntx = false;
    profile.selected_dome_id_for_imd_or_dsb = id;
    await this.updateContext(profile);
  };
  enableDomeSettings = async () => {
    const context = this.context;
    let profile = context.profile || {};
    let id = profile.current_dome.ID;
    this.setState({
      toggelDomeSettings: !this.state.toggelDomeSettings,
      toggelImport: false,
    });
    profile.toggelImport_cntx = false;
    profile.toggelDomeSettings_cntx = !this.state.toggelDomeSettings;
    profile.selected_dome_id_for_imd_or_dsb = id;
    await this.updateContext(profile);
  };
  onImportNameOrAddressChange = ({ target }) => {
    const { value } = target;
    this.setState({
      import_name_or_address: value,
    });
  };
  impVerifyToggle = async () => {
    const profile = await this.getContext();
    let id = profile["current_dome"].ID;
    const data = await getSearchDomeByNameAndAddress(
      this.state.import_name_or_address
    );
    if (
      data &&
      data.anotherDome &&
      Object.keys(data.anotherDome).length !== 0
    ) {
      const { email, anotherDome } = data;
      const { addr_name, dome_status, dome_name, dome_name_suffix, file } =
        anotherDome;
      this.setState({
        selectedValuesForDomeImport: {
          addr_name,
          ID: id,
          dome_name,
          email,
          dome_status,
          dome_name_suffix,
          file,
        },
        duplicate_verify_email_modal: true,
      });
    } else if (
      data &&
      data.description &&
      data.description === "Invalid Dome not found."
    ) {
      this.setState({ verifyModal: !this.state.verifyModal });
    }
  };
  closeDomeDuplicateVerificationModal = () => {
    this.setState({
      duplicate_verify_email_modal: false,
    });
  };
  deleteModalToggle = () => {
    this.setState({ deleteModal: !this.state.deleteModal });
  };
  updateDomeList = async (currDomeId) => {
    let profile = await this.getContext();
    profile.loader = true;
    await this.updateContext(profile);
    const domeList = await listMyDomes();
    const curr_dome = domeList.find((x) => x.ID === currDomeId);
    const domeSetting = await getDomeTypes(currDomeId);
    profile = await this.getContext();
    profile.loader = false;
    profile.myDomeList = [];
    profile.myDomeList = domeList;
    profile.domeSettingList = domeList;
    profile.domeTypeSetting = domeSetting.dome_type_setting;
    profile.current_dome = curr_dome;
    await this.updateContext(profile);
  };
  onEditDuplicateImportClick = () => {
    this.setState({
      confirmDuplicateImportModal: true,
    });
  };
  onDeleteDuplicateImportClick = () => {
    const addr_status = this.context.profile.current_dome.addr_status;
    if (addr_status) {
      this.setState({
        cannotDeleteModal: !this.state.cannotDeleteModal,
      });
    } else {
      this.setState({
        deleteDuplicateImportModal: true,
      });
    }
  };
  closeDeleteDuplicateImportClick = () => {
    this.setState({
      deleteDuplicateImportModal: false,
    });
  };
  closeConfirmDuplicateImportModal = () => {
    this.setState({
      confirmDuplicateImportModal: false,
    });
  };
  onConfirmDeleteDuplicateImportClick = async () => {
    const dome_id = this.context.profile.current_dome.ID;
    await getImportDomeDelete(dome_id);
    await this.updateDomeList(dome_id);
    this.setState({
      import_name_or_address: "",
      // toggelImport: false,
      type: this.context.profile.current_dome.dome_type
        ? parseInt(this.context.profile.current_dome.dome_type)
        : 0,
    });
    await this.callLoadAgain();
    this.closeDeleteDuplicateImportClick();
  };
  onConfirmDuplicateImportClick = async () => {
    const dome_id = this.context.profile.current_dome.ID;
    const dome_name = this.context.profile.current_dome.import_name;
    await postEditDomeNameImportConfirm(dome_name, dome_id);
    await this.updateDomeList(dome_id);
    this.closeConfirmDuplicateImportModal();
  };
  deleteDomeSettingsModalToggle = () => {
    const addr_status = this.context.profile.current_dome.addr_status;
    if (addr_status) {
      this.setState({
        cannotDeleteModal: !this.state.cannotDeleteModal,
      });
    } else {
      this.setState({
        deleteDomeSettingsModal: !this.state.deleteDomeSettingsModal,
      });
    }
  };
  deleteConfirmDomeSettings = async () => {
    let profile = await this.getContext();
    const dome_id = this.context.profile.current_dome.ID;
    await getDeleteDomeSettings(dome_id);
    await this.updateDomeList(dome_id);
    this.setState({
      type: "0",
      // toggelDomeSettings: false,
      typeEditSelected: false,
    });
    profile.domeTypeSetting = [];
    await this.updateContext(profile);
    //domeTypeSetting
    this.deleteDomeSettingsModalToggle();
  };
  deleteConfirmDomeNameToDuplicate = async () => {
    // let profile = await this.getContext();
    const dome_id = this.context.profile.current_dome.ID;
    await getDuplicateDomeNameDelete(dome_id);
    await this.updateDomeList(dome_id);
    this.setState({
      nameToImport: false,
      importName: "",
    });
    this.deleteDomeNameToImportModalToggle();
  };
  onUploadClick = async () => {
    const dome_id = this.context.profile.current_dome.ID;
    await publishDomeApi(dome_id);
    await this.updateDomeList(dome_id);
  };
  renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return "";
  };
  closeCannotDeleteModal = () => {
    this.setState({
      cannotDeleteModal: false,
    });
  };
  deleteDomeNameToImportModalToggle = () => {
    this.setState({
      deleteDomeNameToImportModal: !this.state.deleteDomeNameToImportModal,
    });
  };
  closeDomeNameToImportAfterVerifiedModal = () => {
    this.setState({
      domeNameToImportAfterVerifiedModal: false,
    });
  };
  renderDisableForSettings = (template_type, index) => {
     let a =  localStorage.getItem("dome_type")
    //  console.log(a,"dometype")
  
    // console.log(template_type,"template")
    // console.log(index,"index")
    if (template_type === "A"  && index>= 9 &&  a=="1") {
      return true;

    }
     if (template_type === "A" && index>= 10 && a=="5") {
     return true;
    }

    // if (template_type === "C" && index >= 10) {
    //   return true;
    // }
    if (template_type === "B" && index >= 11) {
      return true;
    }
    return false;
  };
  render() {
    const { translation } = this.props;
    const {
      domeLoader,
      sureBtn,
      nameToImport,
      noThisTime,
      typeLoader,
      toShow,
      loader,
      toggelImport,
      toggelDomeSettings,
      verifyModal,
      deleteModal,
    } = this.state;
    const context = this.context;
    let enableUploadForSettings = false;
    let profile = context.profile || {};
    let edit_current_dome = profile["edit_current_dome"] || false;
    let current_dome = profile.current_dome;
    let domeTypeSetting =
      profile && profile.domeTypeSetting && profile.domeTypeSetting.length > 0
        ? profile.domeTypeSetting
        : [];
    let dome_name = current_dome.dome_name || "";
    let import_dome_name = current_dome.dome_name || "";
    let file_name =
      current_dome.dome_status == 2 ? "" : current_dome.file || "";
    if (this.state.dome_name !== "") {
      dome_name = this.state.dome_name || "";
    }
    if (this.state.importName !== "") {
      import_dome_name = this.state.importName || "";
    }
    let openUploadSection = false;
    let pending = false;
    let enterText = "Enter";
    let activeClass = "";
    let disable = 0;
    let submitText = "Submit";
    let submitClass = "";
    let btn = "yellow-gradient";
    let template_type = "";
    if (
      current_dome.dome_status == 0 &&
      current_dome.dome_name != "" &&
      current_dome.dome_name !== null
    ) {
      pending = true;
      btn = "pending";
      enterText = "Pending";
      activeClass = "active";
      disable = 1;
      submitText = "Pending";
      submitClass = "active";
    } else if (current_dome.dome_status == 1) {
      enterText = "Approved";
      activeClass = "active";
      disable = 1;
      submitText = "Verified";
      submitClass = "";
      btn = "verified";
    } else if (current_dome.dome_status == 2) {
      pending = true;
      btn = " pending blinkBtn";
      enterText = "Resubmit";
      activeClass = "active";
      disable = 1;
      submitText = "Resubmit";
      submitClass = "active";
    }
    let type = current_dome.dome_type;
    if (this.state.type) {
      type = this.state.type;
    }
    let setting_status = current_dome.setting_status;
    let typeEditSelected = false;
    if (type != "0" || current_dome.dome_status === 1) {
      typeEditSelected = true;
    } else {
      openUploadSection = true;
    }
    let isLoader = this.state.loader ? (
      <div className="spinner-grow" role="status">
        <span className="sr-only"> {translation("Loading...")}</span>
      </div>
    ) : (
      ""
    );

    if (domeTypeSetting && domeTypeSetting.length !== 0) {
      
      let lengthOfHideStatus = 0;
      let lengthOfCompStatus = 0;
      let b = true;
      for (let i = 0; i < domeTypeSetting.length; i++) {
        let alreadyIncremented = false;
        if (domeTypeSetting[i]["name"] === "Hall"){ 
          template_type = "A";
          // arr.push(domeTypeSetting)
          // console.log("arr", arr)
        }
        // if (domeTypeSetting[i]["name"] === "Hall") {
        //   console.log('222222',domeTypeSetting)
        //   template_type = "C";
        //   // arr.push(domeTypeSetting)
        //   // console.log("arr", arr)
        // }
        if (domeTypeSetting[i]["name"] === "Arena") {
           
          template_type = "B";
          b = false;

        }
        if (domeTypeSetting[i].is_hide === "1") {
          lengthOfHideStatus++;
          alreadyIncremented = true;
        }
        if (domeTypeSetting[i].status === "1" && !alreadyIncremented) {
          lengthOfCompStatus++;
        }
        // if (
        //   domeTypeSetting[i].status === '1' &&
        //   domeTypeSetting[i]['name'] === 'Arena'
        // ) {
        //   console.log('vvvvvv', domeTypeSetting);
        //   lengthOfCompStatus = lengthOfCompStatus - 1;
        //   b = false;
        // }
      }
      // let n=arr[0].map((i,ind)=>{
      //   if(ind===0){
      //   i.name="이미지+"
      //   }

      //   return i
      // })

      //  console.log('n',n)
      // if (!b) {
      //   lengthOfCompStatus = lengthOfCompStatus - 1;
      // }
      if (
        lengthOfHideStatus === domeTypeSetting.length ||
        lengthOfCompStatus === domeTypeSetting.length ||
        lengthOfCompStatus + lengthOfHideStatus >= domeTypeSetting.length
      ) {
        enableUploadForSettings = true;
      }
    }
    let currentLang = localStorage.getItem('lang');

    const Zoomtemplate = () => {
      this.setState({
        Zoom: !this.state.Zoom
      })
    }

    const Zoomtemplate1 = () => {
      this.setState({
        Zoom1: !this.state.Zoom1
      })
    }
    const Zoomtemplate2 = () => {
      this.setState({
        Zoom2: !this.state.Zoom2
      })
    }
    // console.log('enableUploadForSettings', enableUploadForSettings);
    // console.log("dometypeSetting",domeTypeSetting)
    // let lengthOfHideStatus = 0;
    // let lengthOfCompStatus = -1;

    // for (let i = 0; i < domeTypeSetting.length; i++) {
    //   if (domeTypeSetting[i]['name'] === 'Arena') {
    //     template_type = 'B';
    //   }
    //   if (domeTypeSetting[i].is_hide === '1') {
    //     lengthOfHideStatus++;
    //   }
    //   if (domeTypeSetting[i].status === '1') {
    //     lengthOfCompStatus++;
    //   }
    //   console.log("---------",lengthOfHideStatus)
    // }
    // if (
    //   lengthOfHideStatus === domeTypeSetting.length ||
    //   lengthOfCompStatus === domeTypeSetting.length ||
    //   lengthOfCompStatus  + lengthOfHideStatus>= domeTypeSetting.length
    // ) {
    //   enableUploadForSettings = true;
    // }
    // console.log("99999",domeTypeSetting)
    // console.log("")

    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog ">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62; {translation("Dome Settings")}
              </h4>
              <i onClick={this.closeDomeSetting} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
            <div className="domeAddContainer scrollSection">
              <div className="let-long-loop domeAddLoop">
                <div className="let-long-loop__left">
                  <div className="let-long-loop__left__row mb-10">
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        this.getLocation(
                          current_dome.location.y,
                          current_dome.location.x,
                          current_dome.addr_name,
                          e
                        )
                      }
                      className="let-long-loop__left__row__icon"
                    >
                      <img src={locationIcon} alt="Location" />
                    </i>
                    <div className="let-long-loop__left__row__info">
                      <h4 className="font-bold mb-0">
                        <span className="text-muted">@</span>
                        {this.renderDomeName(
                          current_dome.dome_status,
                          current_dome.dome_name,
                          current_dome.dome_name_suffix,
                          current_dome.file
                        )}
                        {/* {current_dome.dome_status === 1
                          ? `${current_dome.dome_name}${
                              current_dome.dome_name_suffix
                                ? current_dome.dome_name_suffix
                                : ''
                            }`
                          : ''} */}
                      </h4>
                      <p className="text-dark size13 mb-0">
                        {current_dome.addr_name}
                      </p>
                      <div className="let-long-loop__left__row__info__row">
                        <div className="let-long-loop__left__row__info__row__info size10">
                          <div className="mr-10">
                            <p className="mb-0">{translation("Latitude")}</p>
                            <p className="mb-0">
                              {current_dome.location.x.toFixed(13)}
                            </p>
                          </div>
                          <div className="mb-0">
                            <p className="mb-0">{translation("Longitude")}</p>
                            <p className="mb-0">
                              {current_dome.location.y.toFixed(13)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dome-setting-loop">
                <div className="fx-jcfe size12">
                  <label className="fx-aic">
                    <span className="mr-5">
                      {translation("Not at this time")}{" "}
                    </span>{" "}
                    <input
                      type="checkbox"
                      name="noThisTime"
                      checked={this.state.noThisTime}
                      onChange={this.handleNoThisTime}
                    />
                  </label>
                </div>
                <div className="grid-row">
                  <div className="col col-6">
                    <h4 className="text-primary font-bold">
                      <i className="blueBox"></i>
                      {translation(" Dome Name Settings")}
                      <div className="infoTip">
                        <span className="infoTip__icon">&nbsp;</span>
                        <div className="infoTip__tooltip">
                          <p className="size12 mb-0 font-regular">
                            {translation("Default")}
                          </p>
                        </div>
                      </div>
                    </h4>
                    <p className="size11">
                      {current_dome.addr_name}
                      {translation("Use your business name instead of")}{" "}
                      {/* {current_dome.addr_name}  */}
                    </p>
                  </div>
                  <div className={nameToImport ? "d-none" : "col col-6"}>
                    {domeLoader ? (
                      <div className="lds-facebook-md absoluteLdr">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="fx-aic">
                      @
                      <input
                        type="text"
                        onChange={this.domeNameHandler}
                        value={dome_name}
                        className="inp font-bold"
                      />
                      {current_dome.dome_name !== null ? (
                        <div className="fx">
                          <img src={changeIcon1} alt="icon" />
                          <input
                            type="submit"
                            onClick={(e) =>
                              this.openModal(e, current_dome.dome_name)
                            }
                            value={translation("Change")}
                            className={"ant-btn xs auto"}
                          />
                        </div>
                      ) : (
                        <input
                          type="submit"
                          onClick={() => this.checkDomeName(dome_name)}
                          value={translation("Enter")}
                          className={"ant-btn primary-ghost-btn xs auto"}
                        />
                      )}
                    </div>
                    {domeLoader ? (
                      ""
                    ) : (
                      <div className="fx-aic">
                        {this.state.success && (
                          <p className="success">{this.state.success}</p>
                        )}
                        {this.state.error && (
                          <p className="error">{this.state.error}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* Upload files section */}
                {/* <div className={this.state.isImport ? 'd-none' : 'mb-10'}>
                  <div className="fx-jcfe-aic mb-10">
                    <span className="size12 mr-5">Upload files</span>
                    <input
                      type="text"
                      readOnly={true}
                      value={
                        this.state.image ? this.state.image.name : file_name
                      }
                      className="form-control primary size12 xs w-100 mr-5"
                    />
                    <label className="ant-btn primary-ghost-btn xs auto mr-5 fileBtn">
                      <input onChange={this.handleUpload} type="file" />
                      Upload
                    </label>
                    <div className="infoTip">
                      <span className="infoTip__icon">&nbsp;</span>
                      <div className="infoTip__tooltip">
                        <p className="size12 mb-0">
                          Please, submit related documents to prove your
                          ownership to this Name. Such documents may include
                          certifications of trademark or business registration
                          or a certified copy of your corporate registration,
                          etc. Our team will review your submissions and notify
                          the results of your evaluation to e-mail.
                        </p>
                      </div>
                    </div>
                    {current_dome.dome_name !== null ? (
                      <button
                        // onClick={(e) => this.openModal(e, current_dome.dome_name)}
                        onClick={this.handleSubmit}
                        className={
                          'ant-btn xs auto ml-30 ' + btn + ' ' + submitClass
                        }
                      >
                        {submitText}
                      </button>
                    ) : (
                      <button
                        onClick={this.handleSubmit}
                        className={
                          'ant-btn xs auto ml-30 ' + btn + ' ' + submitClass
                        }
                      >
                        {loader ? 'Loading...' : submitText}
                      </button>
                    )}
                  </div>
                  <p className="size12 text-center">
                    e.g. Trade Mark holders’ certificate or business license
                    etc.
                  </p>
                </div> */}
                {/* import section */}
                <div className={!this.state.isImport ? "d-none" : "grid-row"}>
                  <div className="col col-2"></div>
                  <div className="col col-10">
                    <div className="grid-row sm">
                      <div className="col col-2">
                        <h5 className="text-primary font-bold">
                          {translation("Import")}
                        </h5>
                      </div>
                      <div className="col col-10">
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "70%" }}>
                            {translation("Dome Name to import")}
                          </div>
                          {/* <div style={{ width: '30%' }}> */}
                          {submitText === "Verified"
                            && (
                              <div className="fx-jcfe">
                                <button
                                  style={{ margin: "auto" }}
                                  onClick={this.onEditImportDomeNameClick}
                                  className="iconx"
                                >
                                  <img src={editIcon} alt="edit" height="14" />
                                </button>
                                <button
                                  onClick={
                                    this.deleteDomeNameToImportModalToggle
                                  }
                                  className="iconx"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete"
                                    height="14"
                                  />
                                </button>
                              </div>
                            )}
                          {submitText === "Pending"
                            && (
                              <div className="fx-jcfe">
                                <button
                                  onClick={
                                    this.deleteDomeNameToImportModalToggle
                                  }
                                  className="iconx"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete"
                                    height="14"
                                  />
                                </button>
                              </div>
                            )}
                          {/* </div> */}
                        </div>
                        {/* <label>Dome Name to import</label> */}
                        <div className="fx-jcsb-aic mb-5">
                          <div className="input-prefix-container">
                            <input
                              placeholder={translation("Dome Name")}
                              onChange={this.ImportNameHandler}
                              type="text"
                              value={import_dome_name}
                              className="form-control size12 xs w-200"
                              readOnly={
                                submitText && submitText !== "Submit"
                                  ? true
                                  : false
                              }
                            />
                            <input
                              onClick={
                                submitText === "Submit"
                                  ? this.verifyImport
                                  : () => { }
                              }
                              type="submit"
                              // value="Verify"
                              value={
                                submitText === "Submit"
                                  ? b?.t("Verify")
                                  : submitText
                              }
                              // className="ant-btn primary-ghost-btn xs auto"
                              className={`ant-btn xs auto ${submitText} ${btn === "yellow-gradient"
                                ? "primary-ghost-btn"
                                : btn
                                }
                            `}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="size12 mt-5">
                      {translation("If you want")}
                      {/* If you want to use a Dome Name you already have, import
                      it. */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dome-setting-loop ">
                <div className="grid-row">
                  <div className="col col-6">
                    <div className="fx-aic">
                      <i className="blueBox mr-5"></i>
                      <span className="text-primary font-bold">
                        {translation("Dome Settings")}
                      </span>
                      {/* <div className="infoTip">
                                                <span className="infoTip__icon">&nbsp;</span>
                                                <div className="infoTip__tooltip">
                                                    <p className="size12 mb-0">
                                                        Begin with selecting a template from the Dome type.
                                                        To publish the Dome, click the ‘Upload’ button on the
                                                        Dome Setting Board which makes the setting status
                                                        into ‘Uploaded’.
                                                        The ‘Upload’ button will be activated after all
                                                        ‘Uncompleted’ sign changes to either ‘Completed’ or
                                                        ‘Hidden’ on the Dome Setting Board.
                                                    </p>
                                                </div>
                                            </div> */}
                    </div>
                    {/* <p className="size14 text-primary">Dome settings &#62; Type</p> */}
                  </div>
                  <div className="col col-6">
                    <div className="fx-jcfe mt-5 mb-5">
                      <span className={`text-primary size12`}>
                        {translation("Setting status")}:{" "}
                        <span
                          className={
                            setting_status === 1
                              ? translation("approved")
                              : translation("notSubmitted")
                          }
                        >
                          <em>
                            {setting_status === 1
                              ? translation("Uploaded")
                              : translation("Not uploaded")}
                          </em>
                        </span>
                      </span>
                    </div>
                    {/* {toggelImport && (
                      <div className="fx-jcfe">
                        <button onClick={this.editType} className="iconx">
                          <img src={editIcon} alt="edit" height="14" />
                        </button>
                        {typeEditSelected && (
                          <button onClick={this.deleteType} className="iconx">
                            <img src={deleteIcon} alt="delete" height="14" />
                          </button>
                        )}
                      </div>
                    )} */}
                  </div>
                </div>
                <div>
                  <div className="fx-aic">
                    <label>
                      <input
                        type="checkbox"
                        className="mr-3"
                        onClick={this.enableDomeImport}
                        checked={toggelImport}
                      />
                      <span className="text-primary size12">
                        {translation("Import a Dome to duplicate")}
                      </span>
                    </label>
                    <div className="infoTip">
                      <span
                        className="infoTip__icon"
                        data-for="duplicateExi"
                        data-tip="8"
                      >
                        &nbsp;
                      </span>
                      <ReactTooltip
                        id="duplicateExi"
                        className="toolTip"
                        getContent={(dataTip) => (
                          <div>
                            <p>{translation("Duplicate")}</p>
                          </div>
                        )}
                        effect="solid"
                        delayHide={500}
                        delayShow={500}
                        delayUpdate={500}
                        place={"right"}
                        border={true}
                        type={"light"}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      toggelImport ? "pl-30 mb-10" : "pl-30 mb-10 d-none"
                    }
                  >
                    <label className="size12">
                      {translation("Dome Name or Dome Address to import")}
                    </label>
                    <div className="fx">
                      <input
                        type="text"
                        placeholder={translation("Dome Name")}
                        className="form-control xs"
                        value={this.state.import_name_or_address}
                        onChange={this.onImportNameOrAddressChange}
                      />
                      {renderButtonBasedOnImportStatus(
                        this.context.profile.current_dome.import_name,
                        this.state.isImportedDomeStatusVerified,
                        this.impVerifyToggle
                      )}
                      {/* <button
                        className="ant-btn primary-ghost-btn xs ml-5"
                        onClick={this.impVerifyToggle}
                      >
                        Verify
                      </button> */}
                      <div
                        className={`fx-aic ${this.context.profile.current_dome.import_name &&
                          !this.state.isImportedDomeStatusVerified
                          ? ""
                          : "d-none"
                          }`}
                      >
                        <button
                          onClick={this.onDeleteDuplicateImportClick}
                          className="iconx"
                        >
                          <img src={deleteIcon} alt="delete" height="14" />
                        </button>
                      </div>
                      <div
                        className={`fx-aic ${this.state.import_name_or_address &&
                          this.state.isImportedDomeStatusVerified
                          ? ""
                          : "d-none"
                          }`}
                      >
                        <button
                          onClick={this.onEditDuplicateImportClick}
                          className="iconx"
                        >
                          <img src={editIcon} alt="edit" height="14" />
                        </button>
                        <button
                          onClick={this.onDeleteDuplicateImportClick}
                          className="iconx"
                        >
                          <img src={deleteIcon} alt="delete" height="14" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="fx-aic">
                    <label>
                      <input
                        type="checkbox"
                        className="mr-3"
                        onClick={this.enableDomeSettings}
                        checked={toggelDomeSettings}
                      />
                      <span className="text-primary size12">
                        {translation("Set a Dome from the beginning")}
                      </span>
                    </label>
                    <div className="infoTip">
                      <span
                        className="infoTip__icon"
                        data-for="begin-small"
                        data-tip="8"
                      >
                        &nbsp;
                      </span>
                      <ReactTooltip
                        id="begin-small"
                        className="toolTipTwo"
                        getContent={(dataTip) => (
                          <div>
                            <p>
                              {" "}
                              {translation("Begin")}
                              {/* Begin with selecting a template from the Dome
                              type. */}
                            </p>
                          </div>
                        )}
                        effect="solid"
                        delayHide={500}
                        delayShow={500}
                        delayUpdate={500}
                        place={"right"}
                        border={true}
                        type={"light"}
                      />
                    </div>
                  </div>
                  <div
                    className={toggelDomeSettings ? "fx-aic" : "fx-aic d-none"}
                  >
                    <div className="fx-jcfe-aic col-12">
                      <span className="text-primary">
                        {translation("Type")}
                      </span>
                      <button onClick={this.editType} className="iconx">
                        <img src={editIcon} alt="edit" height="14" />
                      </button>
                      {typeEditSelected &&
                        domeTypeSetting &&
                        domeTypeSetting.length ? (
                        <button
                          onClick={this.deleteDomeSettingsModalToggle}
                          className="iconx"
                        >
                          <img src={deleteIcon} alt="Delete" height="14" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                {typeEditSelected && (
                  <div className="typeSection">
                    <div className="typeSection__left"></div>
                    <SelectedType toShow={toShow} editType={type} />
                  </div>
                )}

                {this.state.typeEditSelected && (
                  <div className="text-center pb-10">
                    <p className="mb-5 size12">
                      {" "}
                      {translation("Dome Settings")}
                    </p>
                    <button
                      onClick={this.saveType}
                      disabled={typeLoader ? true : false}
                      className="ant-btn yellow-gradient xs font-bold"
                    >
                      {typeLoader
                        ? translation("Loading...")
                        : translation("Submit")}
                    </button>
                  </div>
                )}
              </div>
              {typeEditSelected && (
                <>
                  <div>
                    {domeTypeSetting.length > 0 && (
                      <>
                        <div className="fx-jcfe mt-5 mb-5">
                          <span className={`text-primary size12`}>
                            {translation("Setting status")}:{" "}
                            <span
                              className={
                                setting_status === 1
                                  ? translation("approved")
                                  : translation("notSubmitted")
                              }
                            >
                              <em>
                                {setting_status === 1
                                  ? translation("Uploaded")
                                  : translation("Not uploaded")}
                              </em>
                            </span>
                          </span>
                        </div>
                        <div className="grid-row xs aic mt-5 mb-5">
                          <div className="col col-3">
                            <div className="infoTip">
                              <span
                                className="infoTip__icon"
                                data-for="begin"
                                data-tip="8"
                              >
                                &nbsp;
                              </span>
                              <ReactTooltip
                                id="begin"
                                className="toolTipTwo"
                                getContent={(dataTip) => (
                                  <div>
                                    <p>
                                      {translation("To publish")}
                                      {/* To publish the Dome, click the ‘Upload’
                                      button below which makes the setting
                                      status into ‘Uploaded’. The ‘Upload’
                                      button will be activated after all
                                      ‘Uncompleted’ sign changes to either
                                      ‘Completed’ or ‘Hidden’ below */}
                                    </p>
                                  </div>
                                )}
                                effect="solid"
                                delayHide={500}
                                delayShow={500}
                                delayUpdate={500}
                                place={"right"}
                                border={true}
                                type={"light"}
                              />
                            </div>
                          </div>
                          <div className="col col-3"></div>
                          <div className="col col-3">
                            <span className="size12">
                              {translation("Date")}
                            </span>
                          </div>
                          <div className="col col-1">
                            <span className="size12">
                              {translation("Editing")}
                            </span>
                          </div>
                          <div className="col col-2"></div>
                        </div>
                      </>
                    )}                   
                    {

                      domeTypeSetting.length > 0 &&

                      domeTypeSetting.map((type, t) => (

                        <div
                          className= 'grid-row xs aic mt-5 mb-5 size13 loopRow' 
                          key={t}
                        >
                          <div className="col col-3">
                            <span style={{ color: type.color }}>
                              {currentLang == "ko" ? type.name_ko : type.name}
                            </span>
                          </div>
                          <div className="col col-3">
                            <button
                              style={{
                                cursor: this.renderDisableForSettings(
                                  template_type,
                                  t
                                )
                                  ? "not-allowed"
                                  : "default",
                              }}
                              // style={
                              //   type.status === '0'
                              //     ? { background: '#A9A9A9' }
                              //     : {}

                              // }
                              className={`ant-btn xss ${type.is_hide === "1"

                                ? "hidden-btn "
                                : type.status === "0"
                                  ? "uncompleted-btn dls "
                                  : "completed-btn "
                                }`}
                            >
                              {" "}
                              <em>
                                {" "}
                                {type.is_hide === "1"
                                  ? translation("Hidden")
                                  : type.status === "0"
                                    ? translation("Uncompleted")
                                    : translation("Completed")}
                              </em>
                            </button>
                          </div>
                          <div className="col col-3">
                            <span className="text-muted">
                              {" "}
                              {moment(type.date).format("MMM DD, YYYY")}
                            </span>
                          </div>
                          {type.name !== "Noticeboard" &&
                            type.name !==
                            "Performance Schedule" && (
                              <div className="col col-1">
                                <button
                                  className="iconx"
                                  disabled={this.renderDisableForSettings(
                                    template_type,
                                    t
                                  )}
                                  style={{
                                    cursor: this.renderDisableForSettings(
                                      template_type,
                                      t
                                    )
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                  onClick={() =>
                                    this.domeDetails(
                                      current_dome.ID,
                                      current_dome.current_index,
                                      type
                                      // current_dome.current_index
                                    )
                                  }
                                  >
                                 <img src={editIcon} alt="edit" height="14" />
                                </button>
                              </div>
                            )}
                          {type.name === "Noticeboard" ||
                            type.name === 'Performance Schedule' ? (
                            <div className="col col-3 text-center">
                              <span className="ml-3">
                                {translation("Go to")}  {" "}
                                <u
                                  className="text-primary cursor"
                                  onClick={(e) =>
                                    this.domeDetailsGoToAdmin(
                                      current_dome.ID,
                                      current_dome.current_index,
                                      type
                                    )
                                  }
                                // onClick={(e) =>
                                //   this.goToAdmin(
                                //     e,
                                //     current_dome.current_index
                                //   )
                                // }Go to
                                >
                                  {translation('Admin')}
                                </u>
                              </span>
                            </div>
                          ) : type.name === "Basic Setting" ? (
                            ""
                          ) : (
                            <div className="col col-2 text-center">
                              <input
                                type="checkbox"
                                disabled={this.renderDisableForSettings(
                                  template_type,
                                  t
                                )}
                                style={{
                                  cursor: this.renderDisableForSettings(
                                    template_type,
                                    t
                                  )
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                onChange={() =>
                                  this.handleDomeTypeToggle(
                                    type.dome_id,
                                    type.id,
                                    type.is_hide === "0" ? "1" : "0"
                                  )
                                }
                                checked={type.is_hide === "1" ? true : false}
                              />
                              <span className="ml-3">
                                {translation("Hide")}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  {domeTypeSetting.length > 0 && (
                    <div className="text-center mt-10">
                      <button
                        style={
                          enableUploadForSettings
                            ? {}
                            : { color: "#80808075", cursor: "not-allowed" }
                        }
                        className={`ant-btn ${enableUploadForSettings
                          ? "yellow-gradient"
                          : "white-btn"
                          }  xs`}
                        onClick={
                          enableUploadForSettings
                            ? this.onUploadClick
                            : () => { }
                        }
                      // disabled={!enableUploadForSettings}
                      >
                        {translation("Upload")}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Row>
        {/* Dome Name verification to import card*/}
        {this.state.verify && (
          <VerificationImport vrificationstatus={this.importStatus} />
        )}
        {/* Dome Name after import card */}
        {this.state.verification_to_import && (
          <AfterImport
            importname={import_dome_name}
            // importname={this.state.importName}
            vrificationstatus={this.importStatus}
            currentDomeLoadAgainHandler={this.currentDomeLoadAgainHandler}
          />
        )}
        {/* Dome Name settings card */}
        {this.state.after_verification && (
          <DomeNameSetting vrificationstatus={this.importStatus} />
        )}
        {/**DOME NAME VERIFICATION AFTER DUPLICATE */}
        {this.state.duplicate_verify_email_modal && (
          <DomeDuplicateVerfication
            selectedData={this.state.selectedValuesForDomeImport}
            onClose={this.closeDomeDuplicateVerificationModal}
            updateDomeList={this.updateDomeList}
            callLoadAgain={this.callLoadAgain}
          />
        )}
        {/* {this.state.showEditSettingsModal && <DomeEditing isUsedForThirdD />} */}
        {/* {edit_current_dome && <DomeEditing isUsedForThirdD />} */}
        {/* Dome type card */}

        {this.state.edit && (
          <Row className="menu-wrapper treasure-menu-wrapper second-dialog ">
            <div className="second-card">
              <div className="fx-jcsb-aic mb-20">
                <h4 className="text-primary mb-0 font-bold">
                  {" "}
                  &#62; {translation("Dome type")}
                </h4>
                <i onClick={this.closeEditType} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
              <div className="domeAddContainer scrollSection">
                <div className="fx-jcc">
                  <button
                    className={'ant-btn primary-ghost-btn xs ml-15 mr-15 ' + this.state.templatea}
                    onClick={() => this.typeCheckedA()}
                  >
                    {translation("Template A")}
                  </button>
                  <button
                    className={'ant-btn primary-ghost-btn xs ml-15 mr-15 ' + this.state.templateb}
                    onClick={() => this.typeCheckedB()}
                  >
                    {translation("Template B")}
                  </button>
                </div>

                {this.state.TemplateA && (
                  <Row className="menu-wrapper treasure-menu-wrapper second-dialog ">
                    <div className="second-card">
                      <div className="typeSection">
                        <div className="typeSection__left">
                          <div className="mb-5">
                            <p className="size12 mb-0">{translation("Template A-1")}</p>
                            <p className="size12 mb-0">{translation("(Alpha version)")}</p>
                            <p className="size12 mb-0">-Contemporary</p>
                          </div>
                          <div className="fx-aic mb-20">
                            <input
                              type="checkbox"
                              onChange={() => this.typeChecked(5)}
                              checked={this.state.type === 5}
                              id="5"
                              name="type"
                            />
                            <span className="text-primary size12 ml-3">
                              {translation("Yes")}
                            </span>
                          </div>
                          <ul className="buletPoints">
                            <li>{translation("NFT Gallery")}</li>
                            <li>{translation("Art Gallery")}</li>
                            <li>{translation("Museum")}</li>
                            <li>{translation("Show Room")}</li>
                            <li>{translation("Community Hall")}</li>
                            <li>{translation("Fanclub")}</li>
                            <li>{translation("Private Exhibition")}</li>
                            <br />
                            <li> <img src={temp} onClick={Zoomtemplate} style={{ width: "100px", border: "#ddd solid 1px", padding: "3px", cursor: "pointer" }} /></li>
                            <FsLightbox
                              toggler={this.state.Zoom}
                              sources={[
                                temp
                              ]}
                            />
                          </ul>
                        </div>
                        {/* <SelectedType toShow={toShow} editType="1" /> */}
                        <div className="typeSection__infographic">
                          <div className="templateCircle1 temp-a1">
                            <span className="points topCenter">
                              {translation("Hall")} (16)
                            </span>
                            {/* <span className="points lobby">
                              {translation("Lobby")}
                            </span> */}
                          </div>
                        </div>
                        <div className="typeSection__right">
                          <ul className="buletPoints rightSide">
                            <li>
                              <img height={18} src={imageCircle} alt="Image" />{" "} {translation("Image+")}
                            </li>
                            {/* <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" />{" "}
                              {translation("Image+")}
                            </li> */}
                            <li>
                              <img height={18} src={noticeboardCircle} alt="Image" />{" "}
                              {translation("Noticeboard")}
                            </li>
                            {/* <li>
                              <img height={18} src={arrowGreen} alt="Image" />{" "}
                              {translation("Hall")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" />{" "}
                              {translation("Image+")}
                            </li>*/}
                            <li> 
                              <img height={18} src={applicationCircle} alt="Image" />{" "}
                              {translation("Application")}
                              <br />
                              {translation("(Menu)")}
                            </li>
                            {/* <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="orDivider"></div>

                      <div className="typeSection">
                        <div className="typeSection__left">
                          <div className="mb-5">
                            <p className="size12 mb-0">{translation("Template A-2")}</p>
                            <p className="size12 mb-0">{translation("(Alpha version)")}</p>
                            <p className="size12 mb-0">-Classic</p>
                          </div>
                          <div className="fx-aic mb-20">
                            <input
                              type="checkbox"
                              onChange={() => this.typeChecked(1)}
                              checked={this.state.type === 1}
                              id="1"
                              name="type"
                            />
                            <span className="text-primary size12 ml-3">
                              {translation("Yes")}
                            </span>
                          </div>
                          <ul className="buletPoints">
                            <li>{translation("NFT Gallery")}</li>
                            <li>{translation("Art Gallery")}</li>
                            <li>{translation("Museum")}</li>
                            <li>{translation("Show Room")}</li>
                            <li>{translation("Community Hall")}</li>
                            <li>{translation("Fanclub")}</li>
                            <li>{translation("Private Exhibition")}</li>
                            <br />
                            <li> <img src={temp1} onClick={Zoomtemplate1} style={{ width: "100px", border: "#ddd solid 1px", padding: "3px", cursor: "pointer" }} /></li>
                            <FsLightbox
                              toggler={this.state.Zoom1}
                              sources={[
                                temp1
                              ]}
                            />
                          </ul>
                        </div>
                        {/* <SelectedType toShow={toShow} editType="1" /> */}
                        <div className="typeSection__infographic">
                          <div className="templateCircle1 temp-a2">
                            <span className="points topCenter">
                              {translation("Hall")} (20)
                            </span>
                            {/* <span className="points lobby">
                              {translation("Lobby")}
                            </span> */}
                          </div>
                        </div>
                        <div className="typeSection__right">
                          <ul className="buletPoints rightSide">
                            <li>
                              <img height={18} src={imageCircle} alt="Image" />{" "}
                              {translation("Image+")}
                            </li>
                            {/* <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" />{" "}
                              {translation("Image+")}
                            </li> */}
                            <li>
                              <img height={18} src={noticeboardCircle} alt="Image" />{" "}
                              {translation("Noticeboard")}
                            </li>
                            {/* <li>
                              <img src={arrowGreen} alt="Image" />{" "}
                              {translation("Hall")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" />{" "}
                              {translation("Image+")}
                            </li>*/}
                            <li> 
                              <img height={18} src={applicationCircle} alt="Image" />{" "}
                              {translation("Application")}
                              <br />
                              {translation("(Menu)")}
                            </li>
                            {/* <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li>
                            <li>
                              <img height={18} src={imageCircle} alt="Image" /> {translation("Image+")}
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </Row>
                )}

                {this.state.TemplateB && (
                  <Row className="menu-wrapper treasure-menu-wrapper second-dialog ">
                    <div className="second-card">
                      {/* <div className="fx-jcsb-aic mb-20">
                        <h4 className="text-primary mb-0 font-bold">
                          {" "}
                          &#62; {translation("Dome type")}
                        </h4>
                        <i
                          onClick={this.closeEditType}
                          className="iconx cursor"
                        >
                          <img src={closeIcon} alt="Info" />
                        </i>
                      </div> */}
                      <div className="domeAddContainer scrollSection">
                        <div className="typeSection">
                          <div className="typeSection__left">
                            <div className="mb-5">
                              <p className="size12 mb-0">{translation("Template B-1")}</p>
                              <p className="size12 mb-0">{translation("(Alpha version)")}</p>
                              <p className="size12 mb-0">-Classic</p>
                            </div>
                            <div className="fx-aic mb-20">
                              <input
                                type="checkbox"
                                onChange={() => this.typeChecked(2)}
                                id="2"
                                checked={this.state.type === 2}
                              />
                              <span className="text-primary size12 ml-3">
                                {translation("Yes")}
                              </span>
                            </div>
                            <ul className="buletPoints">
                              <li>{translation("Performance Hall")}</li>
                              <li>{translation("Concert Hall")}</li>
                              <li>{translation("Event Hall")}</li>
                              <li>{translation("Campaign")}</li>
                              <li>MCN</li>
                              <br />
                              <li> <img src={temp} onClick={Zoomtemplate2} style={{ width: "100px", border: "#ddd solid 1px", padding: "3px", cursor: "pointer" }} /></li>
                              <FsLightbox
                                toggler={this.state.Zoom2}
                                sources={[
                                  temp
                                ]}
                              />
                            </ul>
                          </div>
                          {/* <SelectedType toShow={toShow} editType="2" /> */}

                          <div className="typeSection__infographic">
                            <div className="templateCircle2 temp-b1">
                              <span className="points topCenter">
                                {translation("Arena")}
                              </span>
                              {/* <span className="points lobby">
                                {translation("Lobby")}
                              </span> */}
                            </div>
                          </div>
                          <div className="typeSection__right">
                            <ul className="buletPoints rightSide">
                              <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li>
                              <li>
                                <img height={18} src={noticeboardCircle} alt="Image" /> {translation("Noticeboard")}
                              </li>
                              {/* <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li>*/}
                              {/* <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li> 
                              <li>
                                <img src={arrowGreen} alt="Image" /> {translation("Arena")}
                              </li>*/}
                              <li>
                                <img height={18} src={performanceScheduleCircle} alt="Image" /> {translation("Performance Schedule")}
                              </li>
                              {/* <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li> */}
                              <li> 
                                <img height={18} src={applicationCircle} alt="Image" /> {translation("Application")}
                                <br />
                                {translation("(Menu)")}
                              </li>
                              <li>
                                <img height={18} src={ticketBoxCircle} alt="Image" />{" "}
                                {translation("Ticket Box")}
                                <br />
                                {translation("(Menu)")}
                              </li>
                              {/* <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li>
                              <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li>
                              <li>
                                <img height={18} src={imageCircle} alt="Image" />{" "}
                                {translation("Image+")}
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                )}
              </div>
            </div>



            {/* <div className="orDivider"></div>
                <div className="typeSection">
                  <div className="typeSection__left">
                    <div className="fx-aic">
                      <span className="size12">Template C</span>
                    </div>
                    <div className="fx-aic mb-20">
                      <input
                        type="checkbox"
                        onChange={() => this.typeChecked(3)}
                        id="3"
                        checked={this.state.type === 3}
                      />
                      <span className="text-primary size12 ml-3">Yes</span>
                    </div>
                    <ul className="buletPoints">
                      <li>Multifunction & Multiuse</li>
                    </ul>
                  </div>
                  
                  <div className="typeSection__infographic">
                    <div className="templateCircle3">
                      <span className="points topLeft">Hall</span>
                      <span className="points lobby">Lobby</span>
                      <span className="points topRight">Arena</span>
                    </div>
                  </div>
                  <div className="typeSection__right">
                    <ul className="buletPoints rightSide">
                      <li>
                        <img src={arrowPurple} alt="Image" /> Image+
                      </li>
                      <li>
                        <img src={arrowRed} alt="Image" /> Application
                      </li>
                      <li>
                        <img src={arrowPrimary} alt="Image" /> Noticeboard
                      </li>
                      <li>
                        <img src={arrowOrange} alt="Image" /> Hall
                      </li>
                      <li>
                        <img src={arrowCream} alt="Image" /> Image+
                      </li>
                      <li>
                        <img src={arrowGreen} alt="Image" /> Image+
                      </li>
                      <li>
                        <img src={arrowBlue} alt="Image" /> Arena
                      </li>
                      <li>
                        <img src={arrowPrimary} alt="Image" /> Performance
                        Schedule
                      </li>
                      <li>
                        <img src={arrowSky} alt="Image" /> Ticket Box
                      </li>
                      <li>
                        <img src={arrowPink} alt="Image" /> Image+
                      </li>
                      <li>
                        <img src={arrowBrown} alt="Image" /> Image+
                      </li>
                      <li>
                        <img src={arrowBrownLight} alt="Image" /> Image+
                      </li>
                    </ul>
                  </div>
                </div> */}
            {/* <div className="text-center">
             <button onClick={() => this.typeSubmitHandler(this.state.type)} type="submit" className="ant-btn yellow-gradient xs">Enter</button>
             </div> */}

          </Row>
        )}

        <div className={!sureBtn ? "overlay" : "overlay opened"}></div>
        <div
          className={!sureBtn ? "custom-modal xs" : "custom-modal xs opened"}
        >
          <div className="custom-modal__close" onClick={this.closeModal}></div>
          <div className="custom-modal__body">
            <p>{translation("Are you sure to change the Dome Name?")}</p>
            <div className="fx-jcsb">
              <button
                onClick={this.closeModal}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                {translation("Cancel")}
              </button>
              <button
                onClick={this.yesToDelete}
                className="ant-btn yellow-gradient xs rd-5"
              >
                {this.state.loader ? isLoader : translation("Yes")}
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            !this.state.confirmDuplicateImportModal
              ? "overlay"
              : "overlay opened"
          }
        ></div>
        <div
          className={
            !this.state.confirmDuplicateImportModal
              ? "custom-modal xs"
              : "custom-modal xs opened"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeConfirmDuplicateImportModal}
          ></div>
          <div className="custom-modal__body">
            <p> {translation("Are you sure to Import now?")} </p>
            <div className="fx-jcsb">
              <button
                onClick={this.closeConfirmDuplicateImportModal}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                {translation("Cancel")}
              </button>
              <button
                onClick={this.onConfirmDuplicateImportClick}
                className="ant-btn yellow-gradient xs rd-5"
              >
                {translation("Yes")}
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            !this.state.deleteDuplicateImportModal
              ? "overlay"
              : "overlay opened"
          }
        ></div>
        <div
          className={
            !this.state.deleteDuplicateImportModal
              ? "custom-modal xs"
              : "custom-modal xs opened"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeDeleteDuplicateImportClick}
          ></div>
          <div className="custom-modal__body">
            <p> {translation("Are you sure to delete?")} </p>
            <div className="fx-jcsb">
              <button
                onClick={this.closeDeleteDuplicateImportClick}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                {translation("Cancel")}
              </button>
              <button
                onClick={this.onConfirmDeleteDuplicateImportClick}
                className="ant-btn yellow-gradient xs rd-5"
              >
                {translation("Yes")}
              </button>
            </div>
          </div>
        </div>
        <div className={verifyModal ? "overlay opened" : "overlay"}></div>
        <div
          className={verifyModal ? "custom-modal xs opened" : "custom-modal xs"}
        >
          <div
            className="custom-modal__close"
            onClick={this.impVerifyToggle}
          ></div>
          <div className="custom-modal__body pb-15">
            <p className="text-primary">
              {translation("Not registered")}. <br />
              {translation("Please, check and try again")}.{" "}
            </p>
          </div>
        </div>
        <div className={deleteModal ? "overlay opened" : "overlay"}></div>
        <div
          className={deleteModal ? "custom-modal xs opened" : "custom-modal xs"}
        >
          <div
            className="custom-modal__close"
            onClick={this.deleteModalToggle}
          ></div>
          <div className="custom-modal__body pb-15">
            <p className="text-primary">
              {translation("Are you sure to delete")}?
            </p>
            <div className="fx-jcsb">
              <button
                onClick={this.deleteModalToggle}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                {translation("Cancel")}
              </button>
              <button
                className="ant-btn yellow-gradient xs rd-5"
                onClick={this.deleteType}
              >
                {translation("Yes")}
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.deleteDomeSettingsModal ? "overlay opened" : "overlay"
          }
        ></div>
        <div
          className={
            this.state.deleteDomeSettingsModal
              ? "custom-modal xs opened"
              : "custom-modal xs"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.deleteDomeSettingsModalToggle}
          ></div>
          <div className="custom-modal__body pb-15">
            <p className="text-primary">
              {translation("Are you sure to delete")}
            </p>
            <div className="fx-jcsb">
              <button
                onClick={this.deleteDomeSettingsModalToggle}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                {translation("Cancel")}
              </button>
              <button
                className="ant-btn yellow-gradient xs rd-5"
                onClick={this.deleteConfirmDomeSettings}
              >
                {translation("Yes")}
              </button>
            </div>
          </div>
        </div>
        {/***######################################################## */}
        <div
          className={
            this.state.importDomeNameToImportMailInfo
              ? "overlay opened"
              : "overlay"
          }
        ></div>
        <div
          className={
            !this.state.importDomeNameToImportMailInfo
              ? "custom-modal xs"
              : "custom-modal xs opened"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeImportDomeNameMailInfoModal}
          ></div>
          <div className="custom-modal__body">
            <p>
              {translation("The verification code for @")}
              {this.state.importName} {translation("has been sent")}
              {translation("to")} @{this.state.importName}’s
              {translation("assigned email")}{" "}
              {this.state.emailForVerification &&
                this.encodeEmail(this.state.emailForVerification)}
            </p>
          </div>
        </div>
        {/***######################################################## */}
        <div
          className={
            this.state.cannotDeleteModal ? "overlay opened" : "overlay"
          }
        ></div>
        <div
          className={
            !this.state.cannotDeleteModal
              ? "custom-modal xs"
              : "custom-modal xs opened"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeCannotDeleteModal}
          ></div>
          <div className="custom-modal__body">
            <p>
              {translation(
                "It can be deleted after unpublishing this Dome in Dome settings"
              )}
            </p>
          </div>
        </div>
        <div
          className={
            this.state.deleteDomeNameToImportModal
              ? "overlay opened"
              : "overlay"
          }
        ></div>
        <div
          className={
            this.state.deleteDomeNameToImportModal
              ? "custom-modal xs opened"
              : "custom-modal xs"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.deleteDomeNameToImportModalToggle}
          ></div>
          <div className="custom-modal__body pb-15">
            <p className="text-primary">
              {translation("Are you sure to delete")}
            </p>
            <div className="fx-jcsb">
              <button
                onClick={this.deleteDomeNameToImportModalToggle}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                {translation("Cancel")}
              </button>
              <button
                className="ant-btn yellow-gradient xs rd-5"
                onClick={this.deleteConfirmDomeNameToDuplicate}
              >
                {translation("Yes")}
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.domeNameToImportAfterVerifiedModal
              ? "overlay opened"
              : "overlay"
          }
        ></div>
        <div
          className={
            this.state.domeNameToImportAfterVerifiedModal
              ? "custom-modal xs opened"
              : "custom-modal xs"
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeDomeNameToImportAfterVerifiedModal}
          ></div>
          <div className="custom-modal__body pb-15">
            <p className="text-primary">
              {translation(
                "Dome Name to Import is successfully verified .Please click on"
              )}{" "}
              <img src={editIcon} alt="edit" height="14" />{" "}
              {translation("for the final step")}
              {translation(
                "to complete the Dome Name to Import procedure"
              )} .{" "}
            </p>
          </div>
        </div>

      </>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      menuChange: (menu) => dispatch(menuActions.changeMenu(menu)),
    },
    dispatch
  );
const mapStateToProps = (state: any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, mapDispatchToProps)(DomeSetting);
