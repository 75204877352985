import { notificationsConstants } from '../constants/notificationsConstants';
import { contestConstants } from '../constants/contestConstants';

const initialState: Array<any> = [];

export const notificationsList = (state = initialState, action: any) => {
  switch (action.type) {
    case notificationsConstants.STORE_NOTIFICATIONS:
      return [...action.notifications];
    case notificationsConstants.DELETE_NOTIFICATION:
      const newState = state.filter(function (obj) {
        return obj.ID !== action.ID;
      });
      return newState;
    default:
      return state;
  }
};

export const initialStateNotification = {
  isEditing: false,
  whenToSend: 1,
  audienceType: 1,
  userIDs: new Array(),
  title: 'Title',
  areAllUsersSelected: true,
  contestantIDs: new Array(),
  type: 'contest-voters-all',
};

export const notification = (state = initialStateNotification, action: any) => {
  switch (action.type) {
    case notificationsConstants.NOTIFICATION_TYPE:
      const type = action.category === 'all' ? 'general-all' : state.type;
      if (action.category === 'all') {
        return {
          ...state,
          category: action.category,
          type: type,
          contestID: null,
          userIDs: [],
          contestUserType: '',
        };
      } else {
        return {
          ...state,
          category: action.category,
          type: type,
        };
      }

    case notificationsConstants.NOTIFICATION_INPUT:
      return {
        ...state,
        [action.param]: action.value,
      };
    case notificationsConstants.NOTIFICATION_DATE:
      return {
        ...state,
        timestamp: action.timestamp,
      };
    case notificationsConstants.WHEN_TO_SEND:
      return {
        ...state,
        whenToSend: action.value,
      };
    case notificationsConstants.STORE_USERS:
      return {
        ...state,
        /* userIDs: action.users.map((user: any) => user.mysqlID) */
      };
    case notificationsConstants.SELECTED_USERS:
      return {
        ...state,
        userIDs: action.user,
      };
    case notificationsConstants.STORE_CONTEST_ID:
      return {
        ...state,
        contestID: action.ID,
      };
    case notificationsConstants.ALL_SELECTED:
      return {
        ...state,
        areAllUsersSelected: action.areAllUsersSelected,
      };
    case notificationsConstants.CONTEST_USER_TYPE:
      return {
        ...state,
        contestUserType: action.contestUserType,
      };
    case notificationsConstants.SELECTED_CONTESTANT:
      return {
        ...state,
        contestantIDs: action.user,
      };
    case notificationsConstants.EDIT_NOTIFICATION:
      return {
        ...initialStateNotification,
        isEditing: true,
        ...action.notification,
      };
    case notificationsConstants.CLEAR_USERS:
      return {
        ...state,
        userIDs: [],
      };
    case notificationsConstants.TYPE_BACKEND:
      return {
        ...state,
        type: action.notificationType,
      };
    case notificationsConstants.NOTIFICATIONS_INITIAL:
      return initialStateNotification;
    default:
      return state;
  }
};

export const userList = (state: Array<any> = [], action: any) => {
  switch (action.type) {
    case notificationsConstants.STORE_USERS:
      return [...action.users];
    default:
      return state;
  }
};

export const notificationDetails = (state = {}, action: any) => {
  switch (action.type) {
    case notificationsConstants.NOTIFICATION_DETAILS:
      return {
        ...state,
        ...action.notification,
      };
    case notificationsConstants.PURGE_NOTIFICATION_DETAILS:
      return {};
    default:
      return state;
  }
};

export const notificationsLogicInitialState = { shouldUpdate: false };

export const notificationsLogic = (state = notificationsLogicInitialState, action: any) => {
  switch (action.type) {
    case notificationsConstants.UPDATE_NOTIFICATIONS:
      return { ...state, shouldUpdate: action.payload };
    default:
      return state;
  }
};

export const notificationLocalization = (state: any = {}, action) => {
  switch (action.type) {
    case notificationsConstants.SET_NOTIFICATIONS_LOCALIZATION:
      return action.payload;
    case notificationsConstants.UPDATE_NOTIFICATION_LOCALIZATION:
      const currentLanguage = action.language.toLowerCase();
      return { ...state, [currentLanguage]: { ...state[currentLanguage], ...action.payload } };
    case notificationsConstants.PURGE_DATA:
      return {};
    default:
      return state;
  }
};
