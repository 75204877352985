import React from 'react';

import AppContext from '../../../../AppContext';

import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import { connect } from 'react-redux';

import ReactTooltip from 'react-tooltip';
import {
  deleteDomeApplication,
  getDomeTypes,
} from '../../../../services/domeService';

class DomeApplication extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      thisTime: true,
      color: this.props.color ? this.props.color : '',
      addr_name: '',
      cannotDeleteModal: false,
    };
  }

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  onDeleteApplication = async () => {
    let profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    const addr_status = current_edit_dome.addr_status;
    if (addr_status) {
      this.setState({
        cannotDeleteModal: !this.state.cannotDeleteModal,
      });
    } else {
      await deleteDomeApplication(id);
      profile['application_data'] = null;
      const domeSetting = await getDomeTypes(id);

      profile.domeTypeSetting = domeSetting.dome_type_setting;
      await this.updateContext(profile);
    }
    this.props.onApplicationModalClose();
  };

  closeCannotDeleteModal = () => {
    this.setState({
      cannotDeleteModal: false,
    });
  };

  // inputHandler = (e: any) => {
  //   this.setState({
  //     addr_name: e.target.value,
  //   });
  //   if (e.target.value) {
  //     this.setState({ thisTime: false });
  //   } else {
  //     this.setState({ thisTime: true });
  //   }
  // };

  // importImageUrl = async (e: any) => {
  //   const profile = await this.getContext();
  //   const id = profile.edit_current_dome.ID;
  //   const addr_name = profile.edit_current_dome.addr_name.trim();
  //   const import_addr_name = this.state.addr_name.trim();
  //   if (addr_name == import_addr_name) {
  //     errorMessage(
  //       'Import dome address name should not be same as current dome address name.'
  //     );
  //     return false;
  //   }
  //   const type = 1;
  //   let importImageUrlData = await importDomeEditing(
  //     id,
  //     this.state.addr_name,
  //     type,
  //     this.state.color
  //   );
  // };

  render() {
    const {translation}=this.props
    const context = this.context;
    const profile = context.profile;
    const application_data = profile['application_data'] || {};
    const title = application_data?.title;
    const profile_image = application_data?.profile_image?.name;
    const default_image = application_data?.default_image?.name;

    return (
      <>
        <div id="tab-5" className="tab-pane">
          <div className="mb-0">
            {/* <i className="blueBox mr-5"></i> */}
            <span className="text-primary mr-5">{translation('Application')}</span>
            <div className="infoTip">
              <span className="infoTip__icon" data-for="soclose" data-tip="8">
                &nbsp;
              </span>
              <ReactTooltip
                id="soclose"
                getContent={(dataTip) => (
                  <div>
                    <p className="mb-0">
                      {translation('Connect your own app with Google play store and Apple app store.')}
                     
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place={'right'}
                border={true}
                type={'light'}
              />
            </div>
          </div>

          {/* <div className='fx-jcsb-aic mt-5 mb-5'>
                    <h4 className='mb-0 size12 text-primary'>Title, Profile Image, Default image, API</h4>
                    <div className='fx-aic'>
                        <button className='iconx xs'><img src={editIcon} alt="Edit" height={14} /></button>
                        <button className='iconx xs'><img src={deleteIcon} alt="Delete" height={14} /></button>
                    </div>
                </div>

                <div className="grid-row border-bottom pb-10 mb-10">
                    <div className="col col-2"></div>
                    <div className="col col-10">
                        <div className="grid-row sm">
                            <div className="col col-2">
                                <h4 className="text-primary">Import</h4>
                            </div>
                            <div className="col col-10">
                                <label>Dome Name to import</label>
                                <div className="fx-jcsb-aic mb-5">
                                    <input placeholder="Enter Dome Name" onChange={this.inputHandler} type="text" value={this.state.addr_name} className="form-control xs ml-5" />
                                    <button onClick={this.importImageUrl} className="ant-btn primary-ghost-btn xs ml-5">Verify</button>
                                </div>
                                <div className="fx-aic">
                                    <span className="size12 mr-5">Not at this time</span>
                                    <input type="checkbox" checked={this.state.thisTime} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

          {/* <ol className="editingList mb-30">
                    <li className="editingList__item">
                        <div className="editingList__item__row">
                            <div className="editingList__item__row__left fullSide">
                                <div className="grid-row xs">
                                    <div className="col col-4 fx-aic">
                                        <label className="fileLabel">Name to be shown</label>
                                    </div>
                                    <div className="col col-4">
                                        <input type="text" value="dummy.doc" className="form-control xs rd-0" readOnly />
                                    </div>
                                    <div className="col col-2">
                                        <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn">
                                            <input type="file" />
                                            Upload
                                        </label>
                                    </div>
                                    <div className="col col-2">
                                        <button className="ant-btn yellow-gradient btn-block xs">Preview</button>
                                    </div>
                                </div>
                                <p className="mt-5 size12 mb-0">Note: less than 8 letters</p>
                            </div>
                            <div className="editingList__item__row__action">
                                <button className="iconx xs"><img src={editIcon} alt="Edit" height="14" /></button>
                                <button className="iconx xs"><img src={deleteIcon} alt="Delete" height="14" /></button>
                            </div>
                        </div>
                    </li>
                    <li className="editingList__item">
                        <div className="editingList__item__row">
                            <div className="editingList__item__row__left fullSide">
                                <div className="grid-row xs">
                                    <div className="col col-3 fx-aic">
                                        <label className="fileLabel">API</label>
                                    </div>
                                    <div className="col col-5">
                                        <input type="text" value="dummy.doc" className="form-control xs rd-0" readOnly />
                                    </div>
                                    <div className="col col-2">
                                        <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn">
                                            <input type="file" />
                                            Upload
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="editingList__item__row__action">
                                <button className="iconx xs"><img src={editIcon} alt="Edit" height="14" /></button>
                                <button className="iconx xs"><img src={deleteIcon} alt="Delete" height="14" /></button>
                            </div>
                        </div>
                    </li>
                </ol> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '2rem',
              marginBottom: '0.5rem',
            }}
          >
            <button
              onClick={this.props.onApplicationEditClick}
              className="iconx xs"
            >
              <img src={editIcon} alt="Edit" height="14" />
            </button>
            <button onClick={this.onDeleteApplication} className="iconx xs">
              <img src={deleteIcon} alt="Delete" height="14" />
            </button>
          </div>
          <ul className="editingList noStyle">
            <li className="editingList__item">
              <div className="editingList__item__row">
                <div className="editingList__item__row__left w-full">
                  <div className="grid-row xs">
                    <div className="col col-6">
                      <label className="fileLabel">{translation('Title')}</label>
                    </div>
                    <div className="col col-5">
                      <div className="fileBtn">
                        {/* <input
                        type="text"
                        // className="form-control inp"
                        // value={title}
                        readOnly
                      /> */}
                        <span className="fileBtn__title">{title}</span>
                      </div>
                    </div>
                  </div>

                  <div className="grid-row xs">
                    <div className="col col-6">
                      <label className="fileLabel">{translation('Profile Image file')}</label>
                    </div>
                    <div className="col col-5">
                      <label className="fileBtn">
                        {/* <input type="file" readOnly /> */}
                        <span className="fileBtn__title">{profile_image}</span>
                      </label>
                    </div>
                  </div>
                  <div className="grid-row xs">
                    <div className="col col-6">
                      <label className="fileLabel">{translation('Default Image file')}</label>
                    </div>
                    <div className="col col-5">
                      <label className="fileBtn">
                        {/* <input type="file" readOnly /> */}
                        <span className="fileBtn__title">{default_image}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          className={
            this.state.cannotDeleteModal ? 'overlay opened' : 'overlay'
          }
        ></div>
        <div
          className={
            !this.state.cannotDeleteModal
              ? 'custom-modal xs'
              : 'custom-modal xs opened'
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeCannotDeleteModal}
          ></div>
          <div className="custom-modal__body">
            <p>
             {translation(' It can be deleted after unpublishing this Dome or just hidden in Dome settings')}
            </p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomeApplication);
