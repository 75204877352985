import React, { useState } from 'react';
import { Row, Col, Button, Typography, Radio } from 'antd';
import deleteIcon from '../../../../assets/icon_delete.svg';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { connect } from 'react-redux';
import edit from '../../../../assets/icon_edit.svg';
import { Dispatch, Action } from 'redux';
import { menuConstants } from '../../../../constants/menuConstants';
import clockIcon from '../../../../assets/clock_icon.svg';
import calendar from '../../../../assets/icon_date.svg';
import loamoaIcon from '../../../../assets/logo_push.svg';
import { searchUsers, notificationForm } from '../../../../lib/SubMenuConstants';
import moment from 'moment';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import { deleteNotification } from '../../../../services/notificationsService';
import {
  ios,
  android,
  now,
  url,
  audience,
  browse,
  schedule,
  selected,
  idol,
  typeVoters,
  typeContestants,
} from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';

import { DeleteConfirmationModal } from '../../../../components';
import * as appConstants from '../../../../lib/strings/appConstants';

interface Props {
  handleClose: () => Action;
  openUserList: (menu: string) => Action;
  title: string;
  timestamp: number;
  url: string;
  editNotification: (notification: any) => Action;
  openSubMenu1: (menu: string) => Action;
  deleteNotification: (ID: number) => Action;
  type: string;
  language: languages;
  description: string;
  ID: number;
  contestID: number;
  contestName: string;
  authentication: any;
}

const mapState = (state: any) => {
  return {
    title: state.notificationDetails.title,
    timestamp: state.notificationDetails.timestamp,
    url: state.notificationDetails.url,
    ID: state.notificationDetails.ID,
    type: state.notificationDetails.type,
    language: state.authentication.language,
    description: state.notificationDetails.description,
    contestID: state.notificationDetails.contestID,
    contestName: state.notificationDetails.contestName,
    authentication: state.authentication,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    openUserList: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_2, menu }),
    editNotification: (notification: any) =>
      dispatch({ type: notificationsConstants.EDIT_NOTIFICATION, notification }),
    openSubMenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    deleteNotification: (ID: number) =>
      dispatch({ type: notificationsConstants.DELETE_NOTIFICATION, ID }),
  };
};

const NotificationDetails = (props: Props) => {
  const { authentication } = props;
  const { language } = authentication;

  const [preview, setPreview] = useState('android');

  const delNotification = async () => {
    await deleteNotification(props.ID);
    props.deleteNotification(props.ID);
    props.handleClose();
  };

  const editNotification = () => {
    const editObj: any = props;
    let { url, ...rest } = editObj;
    url = url.replace('https://', '');
    rest.url = url;
    if (editObj.type.indexOf('contest') !== -1) {
      rest.category = 'idol';
    } else {
      rest.category = 'all';
    }

    if (editObj.contestID) {
      if (editObj.type.indexOf('voters')) {
        rest.contestUserType = 'voters';
      } else {
        rest.contestUserType = 'contestants';
      }
    }
    props.editNotification(rest);
    props.openSubMenu1(notificationForm);
  };

  const handleRadio = (e: any) => {
    setPreview(e.target.value);
  };

  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState(false);
  const toggleDeleteConfirmationModalHandler = () => {
    setDeleteConfirmationModalIsOpen((currentState: boolean) => !currentState);
  };

  return (
    <Row className="kit-details-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="space-between" align="middle">
          <Col>
            <Button onClick={props.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>

            <Typography.Text className="contest-title bold">{props.title}</Typography.Text>
          </Col>
          <Col>
            {Date.now() < props.timestamp ? (
              <Button className="button-image" onClick={editNotification}>
                <img src={edit} alt="close" />
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row style={{ padding: '1em' }}>
          {props.type.indexOf('general') === -1 ? (
            <Row className="bold">
              {`${idol[props.language]} > ${props.contestName} > ${
                props.type.indexOf('voters') === -1
                  ? typeContestants[props.language]
                  : typeVoters[props.language]
              }`}
            </Row>
          ) : null}
          <Row type="flex" justify="space-between" className="notif-radio">
            <Row type="flex" justify="start">
              <Radio.Group onChange={handleRadio} value={preview}>
                <Radio.Button value="android">{android[props.language]}</Radio.Button>
                <Radio.Button value="ios">{ios[props.language]}</Radio.Button>
              </Radio.Group>
            </Row>

            <Row type="flex" justify="start" className="full-width">
              {preview === 'android' ? (
                <Row className="notification-preview android">
                  <Row>
                    <img src={loamoaIcon} />
                    <Typography.Text className="notif-head-android">LoaMoa</Typography.Text>
                    <Typography.Text>
                      {moment(props.timestamp, 'x').format('h:mm A')}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text className="notification-title">{props.title}</Typography.Text>
                  </Row>
                  <Row>LoaMoa</Row>
                </Row>
              ) : (
                <Row className="notification-preview ios">
                  <Row type="flex" justify="space-between">
                    <Col>
                      <img src={loamoaIcon} />
                      <Typography.Text className="notif-head-ios">LOAMOA</Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>{now[props.language]}</Typography.Text>
                    </Col>
                  </Row>
                  <Row className="notification-title">{props.title}</Row>
                  <Row>LoaMoa</Row>
                </Row>
              )}
            </Row>
          </Row>
          <Row type="flex" justify="start" className="margin-form">
            <Row style={{ marginBottom: '1em' }}>
              <Typography.Text className="contest-title bold">
                {url[props.language]}
              </Typography.Text>
            </Row>
            <Row style={{ width: '100%' }}>
              <Typography.Text>{props.url}</Typography.Text>
            </Row>
          </Row>
          {/* <Row className='margin-form'>
                            <Row type='flex' justify='space-between' style={{ marginBottom: '1em' }}>
                                <Typography.Text className='contest-title bold'>
                                    {audience[this.props.language]}
                                    </Typography.Text>


                                <div className='all-prices' onClick={() => this.props.openUserList(searchUsers)}>
                                    {browse[this.props.language]}
                                </div>
                            </Row>
                            <Row>
                                0 {selected
                                [this.props.language]}
                                </Row>
                        </Row> */}
          <Row type="flex" justify="start" className="margin-form radio-btn">
            <Row className="full-width">
              <Typography.Text className="contest-title bold">
                {schedule[props.language]}
              </Typography.Text>
            </Row>

            <Row type="flex" justify="space-between" className="full-width">
              <Col>
                <Typography.Text>
                  <img src={calendar} />
                  {moment(props.timestamp, 'x').format('DD/MM/YY')}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>
                  <img src={clockIcon} />
                  {moment(props.timestamp, 'x').format('h:mm A')}
                </Typography.Text>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row type="flex" justify="center" className="full-width">
          <Button
            style={{ width: '72px', height: '16px', paddingBottom: '30px' }}
            className="buttons transparent-btn-red"
            onClick={toggleDeleteConfirmationModalHandler}
          >
            <span style={{ textDecoration: 'underline' }}>
              {appConstants.buttonDelete[language]}
            </span>
          </Button>
        </Row>
      </div>
      <DeleteConfirmationModal
        visible={deleteConfirmationModalIsOpen}
        onCancel={toggleDeleteConfirmationModalHandler}
        name={props.title}
        onDelete={delNotification}
        language={language}
      />
    </Row>
  );
};

export default connect(mapState, mapDispatch)(NotificationDetails);
