import React from 'react';
import { Radio } from 'antd';
import { connect } from 'react-redux';

interface LocationMapControlProps {
  index: number;
  selectedLocation: number;
}

const mapState = (state: { selectedLocation: number }) => {
  const { selectedLocation } = state;
  return { selectedLocation };
};

const LocationMapControl = (props: LocationMapControlProps) => {
  return (
    <div className="location-controls">
      <Radio.Button
        className="location-button fredoka"
        value={props.index}
        checked={props.selectedLocation === props.index}
      >
        #{props.index + 1}
      </Radio.Button>
    </div>
  );
};

export default connect(mapState)(LocationMapControl);
