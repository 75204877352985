import * as quickData from './quickData';
import moment from 'moment';

export const fillCandyChallenge = async (
  setFieldsValue,
  setGlobalInfo,
  setFirstPrize,
  setSecondPrize,
  setThirdPrize
) => {
  const globalUrl = 'https://dev.loamoa.com/uploads/fnphoUWu5GRVpEaorwZaeEncIZ7OAIGs.png';

  const prize1Url = 'https://dev.loamoa.com/uploads/sWbxceQpSsiiU0A90bQHQOckvndoywpI.jpeg';

  const prize2Url = 'https://dev.loamoa.com/uploads/E7TjSXFT6UOjJCXN7UO6ZufS6TRjKQ6Q.jpeg';

  const prize3Url = 'https://dev.loamoa.com/uploads/rfO5aJEHkywiWHxER55VdDrZtupRYWpx.jpeg';

  const [globalImage, prize1Image, prize2Image, prize3Image] = await Promise.all([
    quickData.constructPictureFile(globalUrl),
    quickData.constructPictureFile(prize1Url),
    quickData.constructPictureFile(prize2Url),
    quickData.constructPictureFile(prize3Url),
  ]);

  const currentMonth = moment().get('M');

  setGlobalInfo({
    title: 'quickFill title',
    eventPictureFile: globalImage.file,
    eventPictureUrl: globalImage.base64,
    month: currentMonth,
    startDate: parseInt(moment().startOf('month').format('x')),
    endDate: parseInt(moment().endOf('month').format('x')),
  });

  setFirstPrize({
    name: 'quickFill Prize 1',
    goods: 'physical',
    pictureFile: prize1Image.file,
    pictureUrl: prize1Image.base64,
  });

  setSecondPrize({
    name: 'quickFill Prize 2',
    goods: 'physical',
    pictureFile: prize2Image.file,
    pictureUrl: prize2Image.base64,
  });

  setThirdPrize({
    name: 'quickFill Prize 3',
    goods: 'digital',
    pictureFile: prize3Image.file,
    pictureUrl: prize3Image.base64,
  });

  setFieldsValue({
    title: 'quickFill title',
    eventPictureUrl: 'filled',
    month: currentMonth,
    ['firstPrize-goods']: 'physical',
    ['firstPrize-name']: 'quickFill Prize 1',
    ['firstPrize-picture']: 'filled',

    ['secondPrize-goods']: 'physical',
    ['secondPrize-name']: 'quickFill Prize 2',
    ['secondPrize-picture']: 'filled',

    ['thirdPrize-goods']: 'digital',
    ['thirdPrize-name']: 'quickFill Prize 3',
    ['thirdPrize-picture']: 'filled',
  });
};
