import { LanguageMap } from '../interfaces/language';

// Stats
export const statisticsForTreasuresText: LanguageMap<string> = {
  EN: 'Statistics for treasures',
  KO: 'Statistics for treasures',
};

export const statisticsForPointsText: LanguageMap<string> = {
  EN: 'Statistics for points',
  KO: 'Statistics for points',
};

export const statisticsForCoinsText: LanguageMap<string> = {
  EN: 'Statistics for coins',
  KO: 'Statistics for coins',
};

export const statisticsForAddressCoupon: LanguageMap<string> = {
  EN: 'Statistics For Dome Address Coupon',
  KO: 'Statistics For Dome Address Coupon',
};
export const statisticsForTeamAssignment: LanguageMap<string> = {
  EN: 'Statistics For Team Assignment (Exclusive)',
  KO: 'Statistics For Team Assignment (Exclusive)',
};
// Filter
export const filter: LanguageMap<string> = {
  EN: 'Filter',
  KO: 'Filter',
};

// FilterForm
export const username: LanguageMap<string> = {
  EN: 'Username:',
  KO: 'Username:',
};

export const usernamePlaceholder: LanguageMap<string> = {
  EN: 'Name..',
  KO: 'Name..',
};

export const treasureName: LanguageMap<string> = {
  EN: 'Treasure name:',
  KO: 'Treasure name:',
};

export const treasureNamePlaceholder: LanguageMap<string> = {
  EN: 'Loa..',
  KO: 'Loa..',
};

export const from: LanguageMap<string> = {
  EN: 'From:',
  KO: 'From:',
};

export const to: LanguageMap<string> = {
  EN: 'To:',
  KO: 'To:',
};

export const country: LanguageMap<string> = {
  EN: 'Country:',
  KO: 'Country:',
};

export const adKitType: LanguageMap<string> = {
  EN: 'AD Kit type:',
  KO: 'AD Kit type:',
};

export const status: LanguageMap<string> = {
  EN: 'Status:',
  KO: 'Status:',
};

export const apply: LanguageMap<string> = {
  EN: 'Apply',
  KO: 'Apply:',
};

export const clearAll: LanguageMap<string> = {
  EN: 'Clear all',
  KO: 'Clear all',
};

export const partnerUsername: LanguageMap<string> = {
  EN: 'Partner Username:',
  KO: 'Partner Username:',
};

export const paymentStatus: LanguageMap<string> = {
  EN: 'Payment status:',
  KO: 'Payment status:',
};

export const treasureStatus: LanguageMap<string> = {
  EN: 'Treasure status:',
  KO: 'Treasure status:',
};

export const noResultWareFound: LanguageMap<string> = {
  EN: 'No results were found.',
  KO: 'No results were found.',
};

export const statisticsForTreasureStrings = {
  EN: {
    date: 'Date',
    treasureName: 'Treasure Name',
    username: 'User Name',
    country: 'Country',
    kitType: 'KIT Type',
    createdUnits: 'Created Units',
    collectedUnits: 'Collected Units',
    availableUnits: 'Available Units',
    duration: 'Remaining duration',
    pricePerUnit: 'Lcash / unit',
    ldiscountValue: 'LDiscount',
    unusedLcash: 'Unused Lcash',
    chargedLcash: 'Charged Lcash',
    status: 'Status',
  },
  KO: {
    date: 'Date',
    treasureName: 'Treasure Name',
    username: 'User Name',
    country: 'Country',
    kitType: 'KIT Type',
    createdUnits: 'Created Units',
    collectedUnits: 'Collected Units',
    availableUnits: 'Available Units',
    duration: 'Remaining duration',
    pricePerUnit: 'Lcash / unit',
    ldiscountValue: 'LDiscount',
    unusedLcash: 'Unused Lcash',
    chargedLcash: 'Charged Lcash',
    status: 'Status',
  },
};

export const statisticsForPointsStrings = {
  EN: {
    date: 'Date',
    username: 'Username',
    country: 'Country',
    collectedPoints: 'Collected points',
    pointsConvertedAsCoins: 'Points converted as coins',
    usedPoints: 'Used points',
    userPointBalance: 'User point balance',
  },
  KO: {
    date: 'Date',
    username: 'Username',
    country: 'Country',
    collectedPoints: 'Collected points',
    pointsConvertedAsCoins: 'Points converted as coins',
    usedPoints: 'Used points',
    userPointBalance: 'User point balance',
  },
};

export const statisticsForCoinsStrings = {
  EN: {
    date: 'Date',
    username: 'Username',
    country: 'Country',
    purchasedCoins: 'Purchased coins',
    coinsConvertedFromPoints: 'Coins converted from points',
    usedCoins: 'Used coins',
    userCoinBalance: 'User coin balance',
  },
  KO: {
    date: 'Date',
    username: 'Username',
    country: 'Country',
    purchasedCoins: 'Purchased coins',
    coinsConvertedFromPoints: 'Coins converted from points',
    usedCoins: 'Used coins',
    userCoinBalance: 'User coin balance',
  },
};

export const statisticsForAddressStrings = {
  EN: {
    date: 'Coupon issue date',
    username: 'Username',
    country: 'Country',
    NoofCoupons: 'No of Coupons',
    DomeAddress1: 'Dome Address1',
    DomeAddress2: 'Dome Address2',
    DomeAddress3: 'Dome Address3',
    Unusednoofcoupons:"Unused no of coupons",
    TotalnoofDomeAddresses:"Total no of Dome Addresses"
  },
  KO: {
    date: 'Coupon issue date',
    username: 'Username',
    country: 'Country',
    NoofCoupons: 'No of Coupons',
    DomeAddress1: 'Dome Address1',
    DomeAddress2: 'Dome Address2',
    DomeAddress3: 'Dome Address3',
    Unusednoofcoupons:"Unused no of coupons",
    TotalnoofDomeAddresses:"Total no of Dome Addresses"
  },
};

export const statisticsForAssignmentStrings = {
  EN: {
    Date: 'Date',
    Teamprofile:'Team profile',
    AccessSetting:'Access Setting',
    
  },
  KO: {
    Date: 'Date',
    Teamprofile:'Team profile',
    AccessSetting:'Access Setting',
  },
};

export const totalLcashBalance: LanguageMap<string> = {
  EN: 'Total Lcash balance:',
  KO: 'Total Lcash balance:',
};

export const Totalnoofcoupons: LanguageMap<string> = {
  EN: 'Total no of coupons:',
  KO: 'Total no of coupons:',
};

export const TotalnoofTeam: LanguageMap<string> = {
  EN: 'Total no of Team:',
  KO: 'Total no of Team:',
};
export const totalCollectedPoints: LanguageMap<string> = {
  EN: 'Total collected points:',
  KO: 'Total collected points:',
};

export const totalPointsConverted: LanguageMap<string> = {
  EN: 'Total points converted:',
  KO: 'Total points converted:',
};

export const totalPointsBalance: LanguageMap<string> = {
  EN: 'Total points balance:',
  KO: 'Total points balance:',
};

export const totalPurchaseCoins: LanguageMap<string> = {
  EN: 'Total purchased coins:',
  KO: 'Total purchased coins:',
};

export const totalCoinsConvertedFrom: LanguageMap<string> = {
  EN: 'Total coins converted from',
  KO: 'Total coins converted from',
};
export const totalUsedCoins: LanguageMap<string> = {
  EN: 'Total used coins:',
  KO: 'Total used coins:',
};

export const totalCoinsBalance: LanguageMap<string> = {
  EN: 'Total coins balance:',
  KO: 'Total coins balance:',
};

export const select: LanguageMap<string> = {
  EN: 'Select',
  KO: 'Select',
};

export const statisticsForPartners: LanguageMap<string> = {
  EN: 'Statistics for partners',
  KO: 'Statistics for partners',
};

export const statisticsForPartnersTitle: LanguageMap<string> = {
  EN: 'STATISTICS FOR PARTNERS',
  KO: 'STATISTICS FOR PARTNERS',
};

export const statisticsForPartnersStringsAdmin = {
  EN: {
    date: 'Expiry date',
    treasureName: 'Treasure Name',
    creatorUsername: `Creator's username`,
    partnerUsername: `Partner's username`,
    partnerShare: `Partner's share ($)`,
    partnerBankAccount: `Partners bank account`,
    paymentStatus: `Payment status`,
  },
  KO: {
    date: 'Expiry date',
    treasureName: 'Treasure Name',
    creatorsUsername: `Creator's username`,
    partnersUsername: `Partner's username`,
    partnersShare: `Partner's share ($)`,
    partnersBankAccount: `Partners bank account`,
    paymentStatus: `Payment status`,
  },
};

export const statisticsForPartnersStringsPartner = {
  EN: {
    date: 'Creation date',
    treasureName: 'Treasure Name',
    creatorUsername: `Creator's username`,
    createdUnits: `Created Units`,
    collectedUnits: `Collected Units`,
    treasureStatus: `Treasure Status`,
    chargedCash: `Charged Lcash`,
    partnerShare: `Your share ($)`,
    paymentStatus: `Payment status`,
  },
  KO: {
    date: 'Creation date',
    treasureName: 'Treasure Name',
    creatorUsername: `Creator's username`,
    createdUnits: `Created Units`,
    collectedUnits: `Collected Units`,
    treasureStatus: `Treasure Status`,
    chargedCash: `Charged Lcash`,
    partnerShare: `Your share ($)`,
    paymentStatus: `Payment status`,
  },
};

export const pay: LanguageMap<string> = {
  EN: 'Pay',
  KO: 'Pay',
};

export const paid: LanguageMap<string> = {
  EN: 'Paid',
  KO: 'Paid',
};

export const waiting: LanguageMap<string> = {
  EN: 'Waiting',
  KO: 'Waiting',
};

export const received: LanguageMap<string> = {
  EN: 'Received',
  KO: 'Received',
};

export const filterStatusList = {
  EN: ['Pending', 'Active', 'Inactive'],
  KO: ['Pending', 'Active', 'Inactive'],
};

export const paymentStatusList = {
  EN: ['Paid', 'Not paid'],
  KO: ['Paid', 'Not paid'],
};

export const treasureStatusList = {
  EN: ['Active', 'Finished'],
  KO: ['Active', 'Finished'],
};

export const thisAmountIsCurrent: LanguageMap<string> = {
  EN: '*This amount is current. You will know exact amount of your share when treasure ends.',
  KO: '*This amount is current. You will know exact amount of your share when treasure ends.',
};

export const usernamePopup: LanguageMap<string> = {
  EN: 'Username:',
  KO: 'Username:',
};

export const beneficiaryName: LanguageMap<string> = {
  EN: 'Beneficiary name:',
  KO: 'Beneficiary name:',
};

export const bankName: LanguageMap<string> = {
  EN: 'Bank name:',
  KO: 'Bank name:',
};

export const accountNumber: LanguageMap<string> = {
  EN: 'Account number:',
  KO: 'Account number:',
};

export const abaNumber: LanguageMap<string> = {
  EN: 'ABA number:',
  KO: 'ABA number:',
};
export const partnersShare: LanguageMap<string> = {
  EN: 'Partners share:',
  KO: 'Partners share:',
};

export const email: LanguageMap<string> = {
  EN: 'Email:',
  KO: 'Email:',
};

export const markAsPaid: LanguageMap<string> = {
  EN: 'Mark as paid',
  KO: 'Mark as paid',
};
