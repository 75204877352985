import React from 'react';

import { notification, Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import {
  ImportDome,
  postDomeNameVerifyAfterImport,
  UpdateDome,
} from '../../../../services/domeService';
import {b} from '../../../dashboard/menu/i18next'


import closeIcon from '../../../../../src/assets/closeXsmall.svg';
// import locationIcon from '../../../../src/assets/location-on-map.svg';
// import infoIcon from '../../../../src/assets/icon_info.svg';
// import editIcon from '../../../../src/assets/icon_edit.svg';
// import deleteIcon from '../../../../src/assets/icon_delete_o.svg';
// import { isExistsDomeName, UpdateDome,UpdateDomeType, SearchDome } from '../../../services/domeService';

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

class AfterImport extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      dome_name: '',
    };
  }

  componentDidMount = async () => {
    const profile = await this.getContext();
    const current_dome = profile.current_dome;
    if (current_dome.dome_name_suffix) {
      this.setState({
        dome_name: current_dome.dome_name_suffix,
      });
    } else {
      this.setState({
        dome_name: current_dome.next_suffix_after_import,
      });
    }
  };

  domeNameSetting = async (e: any) => {
    const profile = await this.getContext();
    const current_dome = profile.current_dome;
    // let dome_name = this.props.importname + this.state.dome_name;
    try {
      const res = await postDomeNameVerifyAfterImport(
        current_dome.ID,
        this.state.dome_name
      );
      // current_dome.dome_name = `${this.props.importname}${this.state.dome_name}`;
      profile.current_dome = current_dome;

      profile.domeSettingList[profile.current_dome.current_index] =
        current_dome;
      await this.updateContext(profile);

      // this.props.vrificationstatus({
      //   verification_to_import: false,
      //   // after_verification: true,
      //   // verify: false,
      // });
      await showSuccessMessage(b?.t('Dome Name update successfully'));
      this.props.vrificationstatus({
        verification_to_import: false,
      });
      this.props.currentDomeLoadAgainHandler();
    } catch (err) {}
  };

  onChangeHandler = async (e: any) => {
    this.setState({
      dome_name: e.target.value,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  close = async (e: any) => {
    this.props.vrificationstatus({
      verification_to_import: false,
    });
  };

  render() {
    const context = this.context;
    const profile = context.profile;
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              {' '}
              &#62;{b?.t('Dome Name Confirmation after import')}
            </h4>
            <i onClick={this.close} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          {/* <div className="grid-row sm fx-aic mb-10">
            <div className="col col-3 size12 text-right">Dome Address</div>
            <div className="col col-8">
              <input
                type="text"
                value={profile.current_dome.addr_name}
                className="form-control xs"
              />
            </div>
          </div> */}
          <div className="grid-row sm fx-aic mb-10">
            {/* <div className="col col-3 size12 ">Dome Name</div> */}
            <div className="col col-3 size12 text-right">{b?.t('Dome Name')}</div>
            <div className="col col-8">
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                //  className="grid-row sm"
              >
                <div
                // className="col col-4"
                >
                  @{this.props.importname}
                  {/* <input
                    type="text"
                    value={this.props.importname}
                    className="form-control xs"
                  /> */}
                </div>
                <div
                //  className="col col-8"
                >
                  <input
                    onChange={this.onChangeHandler}
                    type="text"
                    value={this.state.dome_name}
                    className="form-control xs"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: 'right',
              color: 'silver',
              marginBottom: '10px',
            }}
          >
           {b?.t('It can be changed to what you like')}
          </div>

          {/* <div className="size12">
            <p>
              Are you sure if you’re going to take @
              {this.props.importname + this.state.dome_name} as a Dome Name for{' '}
              {profile.current_dome.addr_name}?
            </p>
          </div> */}
          <div className="fx-jcc">
            {/* <button
              onClick={this.close}
              className="ant-btn primary-ghost-btn xs"
            >
              Cancel
            </button> */}
            <button
              onClick={this.domeNameSetting}
              className="ant-btn yellow-gradient xs ml-10"
            >
             {b?.t("Confirm")} 
            </button>
          </div>
        </div>
      </Row>
    );
  }
}

export default AfterImport;
