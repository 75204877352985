import React, { Component } from 'react';

import closeIcon from '../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../src/assets/location-on-map.svg';
import iconDelete from '../../../../src/assets/icon_delete_o.svg';
import visaCard from '../../../../src/assets/visaCard.png';
import editIcon from '../../../../src/assets/icon_edit.svg';
import iconInfo from '../../../../src/assets/icon_info_blue.svg';
import cardCVV from '../../../../src/assets/card-cvv.svg';
import allCards from '../../../../src/assets/all-cards.png';
import { connect } from 'react-redux';
import { Modal} from 'antd';

import {b} from '../../dashboard/menu/i18next'

import AppContext from '../../../AppContext';

import { CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  baseURL,
  domepayment,
  cartCheckout,
  createDome,
  stripe,
} from '../../../lib/config';

import InjectedCheckoutForm from './stripeForm';
import CartItems from './cartItems';

import { getMarkerLocation } from '../../../helpers/GetLocation';
import { getCardList } from './domeAccount/account';

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

// import { connect } from 'react-redux';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
}

import { notification, Row } from 'antd';
import { store } from '../../../helpers/store';
import { cartPayment } from '../../../services/domePaymentService';
import RegisterDomPayNowButton from './RegisterDomPayNowButton';
import Profile from './Profile';

interface DomRegisterState {
  is_payment: boolean;
  profile: any;
  price: number;
  discount_price: number;
  total: number;
  year: number;
  registerCheck: boolean;
  email: any;
  loader: boolean;
  marker: string;
  isActive:boolean;
  coupons:any
}


interface DomRegisterProps {
  paymentHandler?: () => void;
}
const stripePromise = loadStripe(stripe.apikey);
// const { confirm } = Modal;

class RegisterDom extends Component<DomRegisterProps, DomRegisterState> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      isActive: false,
      is_payment: false,
      profile: {},
      price: 18.0,
      discount_price: 18.0,
      total: 18.0,
      year: 1,
      registerCheck: true,
      email: null,
      loader: false,
      marker: '',
      coupons:0,
    };
    this.getLocation = this.getLocation.bind(this);
  }

  paymentHandler = async (e: any) => {
    const context = this.context;
    let profile = context.profile;
    const cardList = await getCardList();
    profile['cardList'] = cardList;
    profile.total = this.state.total;
    profile.year = this.state.year;
    profile.is_payment = true;
    profile.iscart = null;
    profile.items = null;
    context.setProfile(profile);
    this.setState({
      is_payment: true,
      isActive: false,

    });
  };


  paymentHandlerCoupon = async (price: any) => {
    let resisterRes = {};
    let profile = this.context.profile;
    const data = profile.total
    if (!data) {
      this.setState({ total: 0 })
      const requestOptionForRegister = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addr_name: profile.dome_name,
          longitude: profile.dome_lat,
          latitude: profile.dome_lng,
          iscoupon:1
          

        }),


      }
      resisterRes = await fetch(
        baseURL + createDome,
        requestOptionForRegister
      ).then((r) => r.json());
    }
    else {
      let a = data - price
      this.setState({ total: a })
      this.paymentHandler(0)
    }
    this.setState({
      isActive: false,

    });
  }

  closeRegisterPopUp = (e: any) => {
    const context = this.context;
    let profile = this.context.profile;
    profile.register = false;
    context.setProfile(profile);
    this.setState({
      total: 18.0,
    });
  };

  closePaymentPopUP = (e: any) => {
    const context = this.context;
    let profile = context.profile;
    profile.is_payment = false;
    context.setProfile(profile);
    this.setState({
      is_payment: false,
    });
  };

  onChangeYear = (e: any) => {
    this.context.profile.show = false;
    let value = e.target.value;
    value = value.split('_');
    let discount = value[0];
    let year = value[1];
    let discount_price = this.state.price - (this.state.price * discount) / 100;
    console.log(discount_price, "discountprice")
    const context = this.context;
    let profile = this.context.profile;
    profile.total = discount_price * year;
    console.log(profile.total, "profile.total")
    this.setState({
      discount_price: discount_price,
      total: discount_price * year,
      year: year,
    });
    profile.year = year;
    context.setProfile(profile);
  };

  // getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
  //    getMarkerLocation(lat, lng, dome_addr);
  // };

  getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
    const contextData = this.context;
    let profile = contextData.profile;
    const mapInstance = store.getState().address.mapInstance;
    if (this.state.marker) {
      this.state.profile.setMap(null);
    }
    let res = getMarkerLocation(lat, lng, dome_addr);
    mapInstance.setCenter(res.center);
    this.setState({
      profile: res.marker,
    });
  };

  componentDidMount = async () => {
    // const cardList = await getCardList();
    // let profile = await this.getContext();
    // profile['cardList'] = cardList;=18.0
    //  Profile.total= 18.0,
    let profile = this.context.profile;
    profile.year = 1;
    this.setState({
      ...this.state,
      year: 1,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    console.log('profile', profile);
    return profile;
  };

  /**####################################### PAY WITH SAVE CARD ############################################# */

  handleSubmit = async (cardItem, elementObj, stripeObj) => {
    const userData = store.getState().userData;
    if (!this.state.email) {
      this.setState({
        email: userData.email,
      });
    }

    let profile = this.context.profile;
    if (profile.iscart !== null) {
      this.setState({
        loader: true,
      });
      await this.cardCheckout(cardItem);
      this.setState({
        loader: false,
      });
    } else {
      this.setState({
        loader: true,
      });
      await this.payments(cardItem, elementObj, stripeObj);
      this.setState({
        loader: false,
      });
    }
  };

  async cardCheckout(cardItem) {
    const context = this.context;
    const profile = context.profile;
    let data = {
      profile: profile,
      email: this.state.email,
      card_token: cardItem.card_token,
      expires: cardItem.expires,
    };
    let card = {
      last4: cardItem.card,
      brand: cardItem.card_type,
    };
    await cartPayment(card, data);

    let items = profile.items.filter(function (item) {
      return item.check === false;
    });

    profile.iscart = null;
    profile.items = null;
    profile.item_count = items.length;
    profile.is_payment = false;
    profile.year = null;

    profile['fetch_in_boundry'] = true;
    // remove all current dome
    let markers = profile['markers'] || [];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    profile['markers'] = [];
    context.setProfile(profile);
    profile['is_dome_registered_successfully'] = true;
    showSuccessMessage(b?.t('Dome created successfully'));
    // setTimeout(this.redirectToDashBoard, 200);
  }

  async payments(card, elementObj, stripeObj) {
    const context = this.context;
    let profile = context.profile;
    // if (!stripeObj || !elementObj) {
    //   console.log('NOT POSSIBLE EMPTY'); // Stripe.js has not yet loaded.
    //   // Make  sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }

    // const cardEle = elementObj.getElement(CardElement);
    // console.log('cardEle', cardEle);
    // const result = await stripeObj.createToken(cardEle);
    // console.log('result', result);
    // const payload = await stripeObj.createSource({
    //   type: 'ideal',
    //   token: card.card_token,
    //   amount: profile.total,
    //   currency: 'eur',
    //   redirect: {
    //     return_url: 'https://test.test.test/myresponse?this=atest',
    //   },
    // });
    // console.log('payload', payload);
    // console.log('payload', payload.element);

    // return;

    // if (payload.error) {
    //   showErrorMessage(payload.error.message);
    //   return;
    // }
    let resisterRes = {};

    /**REMOVE THIS FROM HEREE  */
    if (profile.name === 'Renew') {
      resisterRes = {
        code: 1,
        data: { inserted_dome_id: profile.inserted_dome_id },
      };
    } else {
      const requestOptionForRegister = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addr_name: profile.dome_name,
          longitude: profile.dome_lat,
          latitude: profile.dome_lng,
          iscoupon:0
        }),
      };

      resisterRes = await fetch(
        baseURL + createDome,
        requestOptionForRegister
      ).then((r) => r.json());
    }


    /**REMOVE THIS TO HEREE  */

    /** NEED TO REMOVE THIS IF  */
    if (resisterRes.code !== 1) {
      profile.dome_error = true;
      profile.register = false;
      profile.is_payment = false;
      // profile.dome_name = profile1.dome_name;
      context.setProfile(profile);

      return false;
    } else {
      // elementObj,stripeObj
      // console.log('in payyyyyyyyy', elementObj, stripeObj);

      // if (!stripeObj || !elementObj) {
      //   console.log('NOT POSSIBLE EMPTY'); // Stripe.js has not yet loaded.
      //   // Make  sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }

      // const payload = await stripeObj.createSource({
      //   type: 'ideal',
      //   token: card.card_token,
      //   amount: profile.total,
      //   currency: 'eur',
      //   redirect: {
      //     return_url: 'https://test.test.test/myresponse?this=atest',
      //   },
      // });
      // console.log('payload', payload);

      // if(payload.error){
      //   showErrorMessage(payload.error.message);
      //   return;
      // }

      // const temp_card_token =

      const requestOption = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          save_card: false,
          card_token: card.card_token,
          /** NEED TO CHANGE THIS TO PROFILE.inserted_dome_id */
          dome_id: resisterRes.data.inserted_dome_id,
          card: card.card,
          card_type: card.card_type,
          amount: profile.total,
          year: profile.year,
          expires: card.expires,
        }),
      };
      const res = await fetch(baseURL + domepayment, requestOption).then((r) =>
        r.json()
      );
      profile.iscart = null;
      profile.items = null;
      profile.is_payment = false;
      profile.year = null;
      profile.register = false;

      profile['fetch_in_boundry'] = true;
      let markers = profile['markers'] || [];
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      profile['markers'] = [];
      profile['is_dome_registered_successfully'] = true;
      context.setProfile(profile);
      showSuccessMessage(b?.t('Dome created successfully'));
    }
  }

  /**####################################### PAY WITH SAVE CARD ############################################# */

  payWithSavedCard = async (card, elementObj, stripeObj) => {
    console.log('done', card);
    // console.log('in payWithSavedCard', elementObj, stripeObj);
    await this.handleSubmit(card, elementObj, stripeObj);
    this.setState({
      ...this.state,
      year: 1,
    });
  };



  toggleClass = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const { translation } = this.props
    const isActive = this.state.isActive;

    var register = false;
    var pageName = 'register';
    var is_payment = false;
    var iscart = false;
    const profile = this.context.profile;
    if (profile) {
      register = profile.register;
      pageName = translation("Renew");


      if (profile.iscart !== null) {
        iscart = profile.iscart;
      }
      is_payment = profile.is_payment;
      // if (profile.is_payment !== null) {
      //     is_payment = profile.is_payment;
      // }
    }
    let cardList = profile.cardList || [];
    return (
      <>
        {iscart ? <CartItems /> : ''}
        {register ? (
          <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
            <div className="second-card register-card">
              <div className="fx-jcsb-aic mb-20">
                <h4 className="text-primary mb-0 font-bold">
                  {' '}
                  &#62; {pageName}
                </h4>
                <i onClick={this.closeRegisterPopUp} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
              <div className="let-long-loop">
                <div className="let-long-loop__left">
                  <div className="let-long-loop__left__row">
                    <i className="let-long-loop__left__row__info__row__check mr-5">
                      <input
                        type="checkbox"
                        checked={this.state.registerCheck ? true : false}
                      />
                    </i>
                    <div className="let-long-loop__left__row__info">
                      <h3 className="font-bold size14 mb-0">
                        {this.context.profile.dome_name}
                      </h3>
                      <div className="let-long-loop__left__row__info__row">
                        <i
                          style={{ cursor: 'pointer' }}
                          onClick={(e) =>
                            this.getLocation(
                              this.context.profile.dome_lng,
                              this.context.profile.dome_lat,
                              this.context.profile.dome_name,
                              e
                            )
                          }
                          className="let-long-loop__left__row__icon"
                        >
                          <img src={locationIcon} alt="Location" />
                        </i>
                        <div className="let-long-loop__left__row__info__row__info size10">
                          <div className="mr-10">
                            <p className="mb-0">{translation('Latitude')}</p>
                            <p className="mb-0">
                              {this.context.profile.dome_lat.toFixed(11)}
                            </p>
                          </div>
                          <div className="mb-0">
                            <p className="mb-0">{translation("Longitude")}</p>
                            <p className="mb-0">
                              {this.context.profile.dome_lng.toFixed(11)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="let-long-loop__right">
                  <div className="fx-aic size10 mb-10">
                    {/* <span translate="no">${this.state.price.toFixed(2)}</span>  */}
                    <span className="p-5" translate="no">
                      ${profile.show ? 18 : this.state.total.toFixed(2)}
                    </span>
                    <i className="p-5">x</i>
                    <select
                      onChange={this.onChangeYear}
                      translate="no"
                      className="size10 p-5"
                    >
                      <option value="0_1">1 {translation("Year")}</option>
                      <option value="5_2">2 {translation("Year")} (5% off)</option>
                      <option value="10_3">3 {translation("Year")} (10% off)</option>
                      <option value="15_5">5 {translation("Year")} (15% off)</option>
                    </select>
                    <i className="p-5">=</i>
                    <span translate="no" className="p-5">
                      {/* ${this.state.total.toFixed(2)} */}$
                      {profile.show ? 18 : this.state.total.toFixed(2)}
                    </span>
                    {/* <i><img src={iconDelete} alt="Delete" height="16px" /></i> */}
                  </div>
                  <div className="totalAmt">
                    <span className="totalAmt__title">{translation('Total amount')}:</span>
                    <span translate="no" className="totalAmt__value">
                      {/* ${this.state.total.toFixed(2)} */}$
                      {profile.show ? 18 : this.state.total.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="fx-jcfe">
                <button
                  onClick={this.toggleClass}
                  // onClick={()=>this.showConfirm(this.paymentHandler)}
                  className="ant-btn buttons yellow-gradient xs rd-5"
                >
                  {translation("Pay")}
                </button>
              </div>
            </div>
          </Row>
        ) : (
          ''
        )}
        {/* Pay Dialog */}
        {is_payment ? (
          <Row className="menu-wrapper treasure-menu-wrapper third-dialog ">
            <div className="third-card pay-card">
              <div className="fx-jcsb-aic mb-20">
                <h4 className="text-primary mb-0 font-bold"> &#62; {translation('Pay')}</h4>
                <i onClick={this.closePaymentPopUP} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
              <div className="domeAddContainer scrollSection">
                {cardList.length > 0 ? (
                  <>
                    <h3 className="font-bold">{translation('Pay with Payment method')}</h3>
                    {cardList.map((card, index) => (
                      <div className="payment-card payCard">
                        <div className="payment-card__left">
                          <figure className="mb-3">
                            <img src={visaCard} alt="visa" />
                          </figure>
                          <h4>
                            {card.card_type} * * * * {card.card}
                          </h4>
                          <p className="size12">{card.address}</p>
                          <p className="size12">{card.city}</p>
                        </div>
                        <div className="payment-card__left">
                          <h5 className="size12">{translation('Expires')} {card.expires}</h5>
                          {/* <RegisterDomPayNowButton
                              ={this.payWithSavedCard}
                            card={card}
                          /> */}
                          <div className="fx-jcfe">
                            <button
                              className="ant-btn buttons purple-gradient rd-5 btn-block"
                              onClick={() => this.payWithSavedCard(card)}
                            >
                              {translation('Pay Now')}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  ''
                )}
                <h3 className="font-bold">{translation('Pay with card')}</h3>
                <Elements stripe={stripePromise}>
                  <InjectedCheckoutForm />
                </Elements>
              </div>
            </div>
          </Row>
        ) : (
          ''
        )}


        <div className={!isActive ? "overlay" : "overlay opened"}></div>
        <div className={!isActive ? "custom-modal xs" : "custom-modal xs opened"}>
          <div className="custom-modal__close" onClick={this.toggleClass}></div>
          <div className="custom-modal__body">
            <p><span className='couponBadge'>COUPON</span> Free coupons available:{this.state.coupons}</p>
            <p>{translation("Would you like to pay with free coupon?")}</p>
            <div className="fx-jcsb">
              <button
                onClick={this.paymentHandler}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                No thanks
                {/* <div className={!isActive ? "custom-modal xs" : "custom-modal xs opened"}></div> */}
              </button>
              <button
                // onClick={this.yesToDelete}
                className="ant-btn yellow-gradient xs rd-5"
                onClick={() => this.paymentHandlerCoupon(this.state.price)}
              // onClick={() => handleCouponCartCheckout()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  translation: state.userData.translation,
  coupons: state.userData.coupons,
});
export default connect(mapStateToProps, null)(RegisterDom);
