import React, { useState, useEffect } from 'react';
import { Row, Spin } from 'antd';
import { connect } from 'react-redux';
import AppContext from '../../../../AppContext';
import { useTranslation} from "react-i18next";
import {b} from '../../../dashboard/menu/i18next'
import {
  FileUpload,
  PublishImageWithUrl,
  loadDome,
  importDomeEditing,
  getDomeTypes,
} from '../../../../services/domeService';
import { baseURL } from '../../../../lib/config';
import { notification } from 'antd';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import closeWhite from '../../../../../src/assets/closeWhite.svg';
import ReactTooltip from 'react-tooltip';
import { showErrorMessage } from '../../../../services/candyEventService';

import Profile from '../Profile';

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const errorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

export function ImageUrlSection(props) {
  const { t } = useTranslation();
  function validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      // const context = this.context;

      const profile = await this.getContext();
      let id = profile.edit_current_dome.ID;
      setCount(id);
    })();
  }, []);

  return (
    <div
      style={{ marginBottom: '60px' }}
      key={props.key}
      className="imgUrlLoop fx"
    >
      <div className="imgUrlLoop__left">
        <ol className="editingList mb-30">
          <li className="editingList__item">
            <div className="editingList__item__row">
              <div className="editingList__item__row__left fullSide">
                <div className="grid-row xs">
                  <div className="col col-3 fx-aic">
                    <label className="fileLabel">{t('Image')}</label>
                  </div>
                  <div className="col col-5">
                    <input
                      type="text"
                      value={props.item.fileName}
                      className="form-control xs rd-0"
                    />
                  </div>
                  <div className="col col-3">
                    <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn">
                      <input
                        onChange={props.handleFile}
                        name="file"
                        type="file"
                      />
                      {t('Upload')}
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="editingList__item__row__action"> */}
              {/* <button className="iconx xs"><img src={editIcon} alt="Edit" height="14" /></button>
                            <button className="iconx xs"><img src={deleteIcon} alt="Delete" height="14" /></button> */}
              {/* <button onClick={props.removeUrlHandler} className="close closeBtn down"><img src={closeWhite} alt="Delete" height="14" /></button> */}
              {/* </div> */}
            </div>
          </li>
          <p className="error">{t('(Optional)')}</p>
          <li className="editingList__item">
            <div className="editingList__item__row">
              <div className="editingList__item__row__left fullSide">
                <div className="grid-row xs">
                  <div className="col col-3 fx-aic">
                    <label className="fileLabel">{t('Url')}</label>
                  </div>
                  <div className="col col-5">
                    <input
                      pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                      onChange={props.urlHandler}
                      type="text"
                      value={props.item.url}
                      className="form-control xs rd-0"
                    />
                  </div>

                  <div className="col col-3">
                    <button
                      onClick={props.showUrlIcon}
                      className="ant-btn primary-ghost-btn xs btn-block"
                    >
                     {t('Enter')} 
                    </button>
                  </div>
                  {/* <div className="col col-3">
                                    <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn">
                                        <input type="file" />
                                        Upload
                                    </label>
                                </div>
                                <div className="col col-3">
                                    <button className="ant-btn yellow-gradient xs">Preview</button>
                                </div> */}
                </div>
              </div>
              {/* <div className="editingList__item__row__action"> */}
              {/* <button className="iconx xs"><img src={editIcon} alt="Edit" height="14" /></button>
                            <button className="iconx xs"><img src={deleteIcon} alt="Delete" height="14" /></button> */}
              {/* </div> */}
            </div>
          </li>
        </ol>
      </div>
      <div
        style={{ position: 'relative' }}
        className="imgUrlLoop__right text-center"
      >
        {props.item.show_icon && validURL(props.item.url) && (
          <div
            style={{
              width: '0px',
              position: 'absolute',
              height: '0px',
              top: '-20px',
              borderLeft: ' 11px solid transparent',
              borderRight: '11px solid transparent',
              borderTop: '23px solid rgb(236, 9, 9)',
              left: '40px',
            }}
          ></div>
        )}
        <div className="imgPreview">
          {props.item.preview ? (
            <img
              style={{ maxWidth: '96px' }}
              onClick={() => props.onImagePreviewClick(props.indx)}
              src={props.item.preview}
              alt="image"
            />
          ) : (
            ''
          )}
        </div>
        <button
          onClick={async () => {
            props.removeUrlHandler();
            await PublishImageWithUrl(props.id, 1, [
              { color: props.color, file: '', url: '' },
            ]);
          }}
          className="close closeBtn down"
        >
          <img src={closeWhite} alt="Delete" height="14" />
        </button>
      </div>
    </div>
  );
}

class DomeImageUrl extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      addMoreCount: 1,
      inputList: [
        {
          url: '',
          preview: '',
          image: '',
          fileName: '',
          file: '',
          show_icon: false,
        },
      ],
      addr_name: '',
      thisTime: true,
      color: this.props.color ? this.props.color : '',
      url_value: '',
    };
    this.urlHandler = this.urlHandler.bind(this);
    this.removeUrlHandler = this.removeUrlHandler.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }
 
  getImageUrlContent = async () => {
    let previewUrl = baseURL + '/uploads/';
    const profile = await this.getContext();
    let id = profile.edit_current_dome.ID;
    let result = await loadDome(id);
    let inputList = [];
    let isColorMatched = false;

    if (result.filesUrl.length > 0) {
      for (let i = 0; i < result.filesUrl.length; i++) {
        if (result.filesUrl[i].color === this.props.color) {
          isColorMatched = true;
          var _inputList = {
            url: result.filesUrl[i].url,
            preview: previewUrl + result.filesUrl[i].file.id,
            fileName: result.filesUrl[i].file.name,
            file: result.filesUrl[i].file,
            show_icon: result.filesUrl[i].url ? true : false,
          };
          inputList.push(_inputList);
        }
      }
    } else {
      inputList = [
        {
          url: '',
          preview: '',
          image: '',
          fileName: '',
          file: '',
          show_icon: false,
        },
      ];
    }
    if (!isColorMatched) {
      inputList = [
        {
          url: '',
          preview: '',
          image: '',
          fileName: '',
          file: '',
          show_icon: false,
        },
      ];
    }

    this.setState({ inputList: inputList });
  };

  componentDidMount = async () => {
    await this.getImageUrlContent();
  };
  componentDidUpdate = async (
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) => {
    if (prevProps?.color !== this.props.color) {
      await this.getImageUrlContent();
    }
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  addMore = async (e: any) => {
    let temp_list = [...this.state.inputList];
    temp_list = [
      ...temp_list,
      {
        url: '',
        preview: '',
        image: '',
        fileName: '',
        file: '',
        show_icon: false,
      },
    ];
    this.setState({
      inputList: temp_list,
    });
  };

  urlHandler = async (e, index) => {
    let list = [...this.state.inputList];
    list[index]['url'] = e.target.value;
    this.setState({
      inputList: list,
    });
  };

  showUrlIcon = async (index) => {
    let list = [...this.state.inputList];
    list[index]['show_icon'] = true;
    this.setState({
      inputList: list,
    });
  };

  removeUrlHandler = async (e, index) => {
    let list = [...this.state.inputList];
    list[index] = {
      ...list[index],
      preview: '',
      image: '',
      fileName: '',
      file: '',
      url: '',
      show_icon: false,
    };
    // list.splice(index, 1);
    this.setState({
      inputList: list,
    });
  };

  handleFile = async (e: any, index) => {
    if (e.target.files.length > 0) {
      let list = [...this.state.inputList];
      list[index]['image'] = e.target.files[0];
      list[index]['fileName'] = e.target.files[0].name;
      list[index]['preview'] = URL.createObjectURL(e.target.files[0]);
      const file = await FileUpload(e.target.files[0]);
      list[index]['file'] = file.file;

      this.setState({
        inputList: list,
      });
    }
  };
  publish = async () => {
    const profile = await this.getContext();
    let id = profile.edit_current_dome.ID;
    const inputList = this.state.inputList;
    let data = [];
    let errors = [];
    for (let i = 0; i < inputList.length; i++) {
      var file_exits = inputList[i].file.id || false;
      if (!file_exits) {
        showErrorMessage(b?.t('Error'), b?.t('Image should not be an empty.'));
        return false;
      }
      var _data = {
        color: this.props.color,
        url: inputList[i].url,
        file: inputList[i].file,
      };
      data.push(_data);
    }
    if (data.length === 0) {
      return false;
    }
    // Call API
    const response = await PublishImageWithUrl(id, 1, data);
    const domeSetting = await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;
    this.updateContext(profile);
    showSuccessMessage(b?.t('Success'),b.t("Image uploaded successfully."));
  };

  importImageUrl = async (e: any) => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    const addr_name = profile.edit_current_dome.addr_name.trim();
    const import_addr_name = this.state.addr_name.trim();
    if (addr_name == import_addr_name) {
      errorMessage(
        b?.t('Import dome address name should not be same as current dome address name.')
      );
      return false;
    }
    const type = 1;
    let importImageUrlData = await importDomeEditing(
      id,
      this.state.addr_name,
      type,
      this.state.color
    );

    await this.getImageUrlContent();
  };

  inputHandler = (e: any) => {
    this.setState({
      addr_name: e.target.value,
    });
    if (e.target.value) {
      this.setState({ thisTime: false });
    } else {
      this.setState({ thisTime: true });
    }
  };

  render() {
    const {translation}=this.props
    const context = this.context;
    console.log('context......................', this.context);
    const inputList = this.state.inputList;
    return (
      <div id="tab-1" className="tab-pane">
        <div className="mb-10">
          <i className="blueBox mr-5"></i>
          <span className="text-primary mr-5">{translation('Image+')}</span>
          {/* <i><img src={infoIcon} alt="Info" height="14" /></i> */}
          <div className="infoTip">
            <span className="infoTip__icon" data-for="soclose" data-tip="8">
              &nbsp;
            </span>
            <ReactTooltip
              id="soclose"
              getContent={(dataTip) => (
                <div>
                  <p className="mb-0">
                    {translation('Upload an image to show the image only. Or upload an image with optional URL to link to the preferred link.')}
                  </p>
                </div>
              )}
              effect="solid"
              delayHide={500}
              delayShow={500}
              delayUpdate={500}
              place={'right'}
              border={true}
              type={'light'}
            />
          </div>
        </div>
        {/* <div className="grid-row border-bottom pb-10">
                    <div className="col col-2"></div>
                    <div className="col col-10">
                        <div className="grid-row sm">
                            <div className="col col-2">
                                <h4 className="text-primary">Import</h4>
                            </div>
                            <div className="col col-10">
                                <label>Dome Name to import</label>
                                <div className="fx-jcsb-aic mb-5">
                                    <input placeholder="Enter Dome Name" onChange={this.inputHandler} type="text" value={this.state.addr_name} className="form-control xs ml-5" />
                                    <button onClick={this.importImageUrl} className="ant-btn primary-ghost-btn xs ml-5">Verify</button>
                                </div>
                                <div className="fx-aic">
                                    <span className="size12 mr-5">Not at this time</span>
                                    <input type="checkbox" checked={this.state.thisTime} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="addMoreImg">
          {inputList.map((item, index) => (          
            <ImageUrlSection
              onImagePreviewClick={this.props.onImagePreviewClick}            
              key={index}
              handleFile={(e) => this.handleFile(e, index)}
              item={item}
              urlHandler={(e) => this.urlHandler(e, index)}
              removeUrlHandler={(e) => this.removeUrlHandler(e, index)}
              indx={index}
              showUrlIcon={() => this.showUrlIcon(index)}
              color={this.props.color}
              id={context.profile.edit_current_dome.ID}
            />         
          ))}
        </div>
        {/* <div className="fx-jcfe mt-10 mb-10">
          <button onClick={this.addMore} className="ant-btn addMore">
            +
          </button>
        </div> */}
        <div className="grid-row fx-jcc">
          <div className="col col-6 fx-jcsb">
            <button
              onClick={(e) =>
                this.props.closeContent(e, { activeImageUrl: false })
              }
              className="ant-btn primary-ghost-btn xs"
            >
             {translation('Cancel')} 
            </button>
            <button
              onClick={this.publish}
              className="ant-btn yellow-gradient xs"
            >
              {translation('Edit')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)( DomeImageUrl);
