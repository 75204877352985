import { Row, notification } from 'antd';

import React from 'react';
import Dropzone from 'react-dropzone';
import AppContext from '../../../AppContext';
import closeIcon from '../../../assets/closeXsmall.svg';
import logoPic from '../../../assets/logoPic.png';
import { connect } from 'react-redux';
import {b} from '../../dashboard/menu/i18next'
import {
  FileUpload,
  getDomeApplication,
  getDomeTypes,
  postDomeApplication,
} from '../../../services/domeService';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

class ApplicationEdit extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      //   error: false,
      //   success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      defaultImage: '',
      defaultFileName: '',
      defaultPreview: '',
      defaultFile: '',
      title: '',
      android_url: '',
      ios_url: '',
    };
  }

  componentDidMount = async () => {
    let profile = await this.getContext();
    const application_data = profile['application_data'];
    if (application_data && Object.keys(application_data).length !== 0) {
      const { android_url, default_image, profile_image, ios_url, title } =
        application_data;
      this.setState({
        image: profile_image,
        file: profile_image,
        fileName: profile_image.name,
        preview: `https://app.loamoa.cladev.com/uploads/${profile_image.id}`,
        defaultImage: default_image,
        defaultFile: default_image,
        defaultFileName: default_image.name,
        defaultPreview: `https://app.loamoa.cladev.com/uploads/${default_image.id}`,
        android_url,
        ios_url,
        title,
      });
    }
  };

  oNDropFileHandler = async (files) => {
    let file = files[0];
    let image = file;
    let fileName = file.name;
    let preview = URL.createObjectURL(file);
    const result = await FileUpload(file);
    let resultfile = result.file;
    this.setState({
      image: image,
      fileName: fileName,
      preview: preview,
      file: resultfile,
    });
  };

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result = await FileUpload(e.target.files[0]);
      let file = result.file;

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
      });
    }
  };

  DefaultfileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let defaultImage = e.target.files[0];
      let defaultFileName = e.target.files[0].name;
      let defaultPreview = URL.createObjectURL(e.target.files[0]);
      const result = await FileUpload(e.target.files[0]);
      let defaultFile = result.file;

      this.setState({
        defaultImage: defaultImage,
        defaultFileName: defaultFileName,
        defaultPreview: defaultPreview,
        defaultFile: defaultFile,
      });
    }
  };

  onChangeHandler = async (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  publish = async (e) => {
    const { title, file, defaultFile, android_url, ios_url } = this.state;

    if (!title) {
      showErrorMessage(b?.t('Please enter Title'));
      return;
    }

    if (!file) {
      showErrorMessage(b?.t('Please Upload Background Image'));
      return;
    }
    // if (!defaultFile) {
    //   showErrorMessage('Please Upload default Image');
    //   return;
    // }

    if (!ios_url) {
      if (!this.validURL(android_url) || !android_url) {
        showErrorMessage(b?.t('Please Enter valid Andriod App Url'));
        return;
      }
    }

    if (!android_url) {
      if (!this.validURL(ios_url) || !ios_url) {
        showErrorMessage(b?.t('Please Enter valid Ios App Url'));
        return;
      }
    }

    let formValues = {
      title: title,
      profile_image: file,
      default_image: defaultFile,
      android_url: android_url,
      ios_url: ios_url,
    };
    let profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    console.log(current_edit_dome);
    let id = current_edit_dome.ID;

    await postDomeApplication(formValues, id);
    const res = await getDomeApplication(id);
    if (res) {
      profile['application_data'] = res;
    } else {
      profile['application_data'] = null;
    }
    const domeSetting = await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;
    this.updateContext(profile);
  };

  onEnterClick = (type) => {
    const { android_url, ios_url } = this.state;
    console.log(type, android_url);
    console.log(type, ios_url);
    if (type === 'ios') {
      if (!ios_url || !this.validURL(ios_url)) {
        showErrorMessage(b?.t('Please Enter valid Ios App Url'));
      } else {
        showSuccessMessage(b?.t('Valid Url'));
      }
    }
    if (type === 'andriod') {
      if (!android_url || !this.validURL(android_url)) {
        showErrorMessage(b?.t('Please Enter valid Andriod App Url'));
      } else {
        showSuccessMessage(b?.t('Valid Url'));
      }
    }
  };

  render() {
    const {translation}=this.props
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62;{translation('Application')} </h4>
            <i
              onClick={this.props.onApplicationModalClose}
              className="iconx cursor"
            >
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div style={{ display: 'flex' }} className="col col-12">
              <div style={{ marginRight: '1rem' }} className="mb-10">
                <div className="mb-10">
                  <h4 className="profileImgUpload__label">
                    {translation('Title')}{translation('(Shown only 8 letters)')}
                    <em className="required">{translation('Required')}</em>
                  </h4>
                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    className="form-control xs ttc"
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className="grid-row sm">
                  <h4
                    style={{ marginLeft: '0.5rem' }}
                    className="profileImgUpload__label"
                  >
                   {translation('Background Image File')} 
                    <em className="required">{translation('Required')}</em>
                  </h4>
                  <div className="col col-8">
                    <input
                      value={this.state.fileName}
                      type="text"
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input
                        onChange={this.fileHandler}
                        type="file"
                        className="d-none"
                      />
                     {translation('Upload')} 
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-center profileImgUpload twoDIconPrev">
                <figure className="photoPrev">
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      this.oNDropFileHandler(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <img
                            height="120"
                            width="120"
                            src={
                              this.state.preview
                              // ? this.state.preview
                              // : `https://app.loamoa.cladev.com/uploads/` +
                              //   this.state.preview
                            }
                          />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </figure>
                <h5> {translation("Drag and fit")}</h5>
              </div>
            </div>

            <div className="grid-row"></div>
            <div className="text-center mb-20">
              <h3 className="text-primary weight-600">{translation('Preview')}</h3>
            </div>
            <div style={{ border: 'none' }} className="twoDPlaceholder">
              <figure className="twoDPlaceholder__fig">
                <img
                  src={
                    this.state.preview
                    //   ? this.state.preview
                    //   : `https://app.loamoa.cladev.com/uploads/` +
                    //     background_image_2d
                  }
                  alt="Logo"
                />
              </figure>
              {/* <figcaption className="twoDPlaceholder__info">
                <h3 className="weight-700">{this.state.fileName}</h3>
                <h4 className="weight-700 text-muted">{this.state.fileName}</h4>
              </figcaption> */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  marginTop: '2rem',
                  padding: '0.5rem',
                  marginBottom: '3rem',
                }}
              >
                <h3
                  style={{
                    marginBottom: '1rem',
                  }}
                  className="text-primary"
                >
                 {translation('Default Image')} 
                </h3>
                <div style={{ position: 'relative' }}>
                  <img
                    height={150}
                    width={150}
                    style={
                      this.state.defaultPreview
                        ? {
                            border: '1px solid grey',
                            borderRadius: '23px',
                          }
                        : {
                            border: '1px solid grey',
                            borderRadius: '23px',
                            objectFit: 'none',
                          }
                    }
                    src={
                      this.state.defaultPreview
                        ? this.state.defaultPreview
                        : logoPic
                      //   ? this.state.preview
                      //   : `https://app.loamoa.cladev.com/uploads/` +
                      //     background_image_2d
                    }
                  />
                  {!this.state.defaultPreview && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100px',
                        left: '33px',
                      }}
                    >
                     {translation('Coming soon')} 
                    </div>
                  )}
                </div>
              </div>
              <div
              // style={{
              //   display: 'flex',
              //   justifyContent: 'center',
              //   margin: 'auto',
              // }}
              >
                <label className="ant-btn primary-ghost-btn xs">
                  <input
                    onChange={this.DefaultfileHandler}
                    type="file"
                    className="d-none"
                  />
                 {translation('Upload')} 
                </label>
              </div>
            </div>
            <h3
              style={{
                marginBottom: '1rem',
              }}
              className="text-primary"
            >
              {translation('App Option')} 
              <em className="required">{translation('(Required - either one or both)')}</em>
            </h3>
            <div style={{ display: 'flex' }}>
              <div className="col col-2">{translation('Andriod')}</div>
              <div className="col col-6">
                <input
                  value={this.state.android_url}
                  name="android_url"
                  onChange={this.onChangeHandler}
                  type="text"
                  className="form-control xs"
                />
              </div>
              <div
                onClick={() => this.onEnterClick('andriod')}
                className="col col-4"
              >
                <label className="ant-btn primary-ghost-btn xs btn-block">
                  {translation('Enter')}
                </label>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <div className="col col-2">Ios</div>
              <div className="col col-6">
                <input
                  value={this.state.ios_url}
                  name="ios_url"
                  onChange={this.onChangeHandler}
                  type="text"
                  className="form-control xs"
                />
              </div>
              <div
                onClick={() => this.onEnterClick('ios')}
                className="col col-4"
              >
                <label className="ant-btn primary-ghost-btn xs btn-block">
                {translation('Enter')}
                </label>
              </div>
            </div>
            <div
              style={{
                marginTop: '1rem',
              }}
              className="grid-row fx-jcc"
            >
              <div className="col col-6 fx-jcsb">
                <button
                  onClick={(e) => this.props.onApplicationModalClose}
                  className="ant-btn primary-ghost-btn xs"
                >
                  {translation('Cancel')}
                </button>
                <button
                  onClick={this.publish}
                  //   disabled={this.state.loader ? true : false}
                  className="ant-btn yellow-gradient xs"
                >
                  {/* {this.state.loader ? "Loading..." : "Edit"} */}
                  {translation('Edit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (ApplicationEdit);
