export const locationConstants = {
  CREATE_LOCATION: 'CREATE_LOCATION',
  CREATE_MSG_LOCATION: 'CREATE_MSG_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  DELETE_MSG_RADIUS: 'DELETE_MSG_RADIUS',
  ADDRESS: 'ADDRESS',
  UPDATE_MAP_ADDRESS: 'UPDATE_MAP_ADDRESS',
  UPDATE_LOCATION_ADDRESS: 'UPDATE_LOCATION_ADDRESS',
  NO_OF_UNITS: 'NO_OF_UNITS',
  UPDATE_MSG_RADIUS: 'UPDATE_MSG_RADIUS',
  LOCATION_RADIUS: 'LOCATION_RADIUS',
  MAP_INSTANCE: 'MAP_INSTANCE',
  MAP_ZOOM: 'MAP_ZOOM',
  SELECTED: 'SELECTED',
  UPDATE_LOCATION_ON_IDLE: 'UPDATE_LOCATION_ON_IDLE',
  UPDATE_SELECTED: 'UPDATE_SELECTED',
  UPDATE_MSG_LOCATION: 'UPDATE_MSG_LOCATION',
  CALCULATED_UNITS: 'CALCULATED_UNITS',
  STORE_UNITS: 'STORE_UNITS',
  STORE_RESPONSE: 'STORE_RESPONSE',
  NOT_SENT: 'NOT_SENT',
  ALREADY_SENT: 'ALREADY_SENT',
  DELETE_UNITS: 'DELETE_UNITS',
  DELETE_ALL_MSG_RADIUS: 'DELETE_ALL_MSG_RADIUS',
  UPDATE_ALL_MSG_RADIUS: 'UPDATE_ALL_MSG_RADIUS',
  RESET_REDUCERS: 'RESET_REDUCERS',
  CHANGE_AFTER_DELETE: 'CHANGE_AFTER_DELETE',
  LOCATION_RADIUS_INPUT: 'LOCATION_RADIUS_INPUT',
  LOCATION_FROM_DRAFT: 'LOCATION_FROM_DRAFT',
  MSG_LOCATION_FROM_DRAFT: 'MSG_LOCATION_FROM_DRAFT',
  UNITS_FROM_DRAFTS: 'UNITS_FROM_DRAFTS',
  CLEAR_ALL_MSG_RADIUS: 'CLEAR_ALL_MSG_RADIUS',
};
