import React, {Component} from 'react';
import { Row, Col } from 'antd';
import AppContext from '../../../../AppContext';

class  HelpMenu extends Component <any, any>{
    static contextType = AppContext;
    constructor(props: any){
        super(props);
        this.state = {
            support_active_class: ''
        }
        this.handleSupport = this.handleSupport.bind(this);
    }

    handleSupport  = async(e: any) =>{
        let profile = await this.getContext();
        profile.support = true;
        await this.updateContext(profile);
        this.setState({
            support_active_class: 'active'
        });
    }   

    getContext = async () => {
        const context = this.context;
        let profile = context.profile || {};
        return profile;
    }

    updateContext = async (data: any) => {
        const context = this.context;
        context.setProfile(data);
    }

    render(){
        return(
            <>
            <Row className="fx-jcc">
                    <button onClick={this.handleSupport}  className={'ant-btn primary-ghost-btn xs '+this.state.support_active_class }>Support</button>
            </Row>
            
            </>
        )
    }
}


export default HelpMenu;

