import React from 'react';

import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { baseURL } from '../../../../lib/config';

import burgerKing from '../../../../../src/assets/burgerKing.png';
import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import logoPic from '../../../../../src/assets/logoPic.png';
import { connect } from 'react-redux';
import {
  FileUpload,
  getDomeTypes,
  UpdateSetting,
} from '../../../../services/domeService';
import { Translation } from 'react-i18next';
import {b} from '../../../dashboard/menu/i18next'

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};
class DomeOuterWall extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      outerwall: '',
    };
  }

  componentDidMount = async () => {
    const profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    this.setState({
      outerwall: current_edit_dome.outerwall,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result = await FileUpload(e.target.files[0]);
      let file = result.file;

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
      });
    }
  };
  publish = async () => {
    if (!this.state.outerwall) {
      showErrorMessage(b?.t('Please select outerwall'));
      return false;
    }
    let profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {
      outer_wall: this.state.outerwall,
    };
    const res = await UpdateSetting(id, obj);
    const domeSetting = await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;

    current_edit_dome.outerwall = this.state.outerwall;
    profile.current_edit_dome = current_edit_dome;
    await this.update(profile);

    showSuccessMessage(b?.t('Dome updated successfully.'));

    this.props.closeOterWall();
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
    await this.updateContext(profile);
  };

  onChangeOuterWall = async (e) => {
    this.setState({
      outerwall: e.currentTarget.value,
    });
  };
  render() {

    const {translation}=this.props
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62;{translation('Inner Type')}</h4>
            <i onClick={this.props.closeOterWall} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection fx-jcc-aic">
            <div className="text-center">
              <figure className="mb-3">
                <img src={logoPic} alt="logo" />
              </figure>
              <h2 className="text-muted mb-0">{translation('Coming soon')}</h2>
            </div>

            {/* <div className="grid-row profileImgUpload">
							<div className="col col-8">
								<h4 className="profileImgUpload__label">File upload</h4>
								<div className="grid-row sm mb-10">
									<div className="col col-8">
										<input type="text" value="" className="form-control xs" readOnly />
									</div>
									<div className="col col-4">
										<label className="ant-btn primary-ghost-btn xs btn-block">
											<input type="file" className="d-none" />
											Upload
										</label>
									</div>
								</div>

							</div>
							<div className="col col-4 text-center">
								<figure className="photoPrev"></figure>
							</div>
						</div> */}

            {/* <div className="text-center mb-20">
							<label htmlFor="copper"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/copper_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="copper" name="outerwall" value="copper"  checked={this.state.outerwall === 'copper'}  />

							<label htmlFor="blue"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/blue_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="blue" name="outerwall" value="blue" checked={this.state.outerwall === 'blue'} />

							<label htmlFor="gold"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/gold_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="gold" name="outerwall" value="gold" checked={this.state.outerwall === 'gold'} />

							<label htmlFor="graphite"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/graphite_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="graphite" name="outerwall" value="graphite"  checked={this.state.outerwall === 'graphite'} />

							<label htmlFor="green"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/green_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="green" name="outerwall" value="green" checked={this.state.outerwall === 'green'} />

							<label htmlFor="grey"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/grey_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="grey" name="outerwall" value="grey" checked={this.state.outerwall === 'grey'} />

							<label htmlFor="pink"> <img width="80" height="80" src="https://app.loamoa.cladev.com/uploads/pink_outerwall.png" /></label>
							<input onChange={this.onChangeOuterWall} type="radio" id="pink" name="outerwall" value="pink" checked={this.state.outerwall === 'pink'} />
						</div>

						<div className="grid-row fx-jcc">
							<div className="col col-6 fx-jcsb">
                                <button onClick={e=>this.props.closeProfile(e)} className="ant-btn primary-ghost-btn xs">Cancel</button>
                                <button onClick={this.publish} className="ant-btn yellow-gradient xs">Publish</button>
							</div>
						</div>
						*/}
          </div>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomeOuterWall);
