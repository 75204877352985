import React, { Component } from 'react';
import { Row, Col, Button, Typography, Select } from 'antd';
import imgPlaceholder from '../../../assets/profile_picture.svg';
import Logout from '../../../assets/home_logout_icon.svg';
import Dashboard from '../../../assets/icon_dashboard.svg';
import * as userActions from '../../../actions/signinActions';
import { connect } from 'react-redux';
import * as userService from '../../../services/signinService';
import { baseURL } from '../../../lib/config';
import I18next from './i18next';
import {
  roleMaster,
  rolePartner,
  roleAdmin,
  roleSuperadmin,
} from '../../../lib/const';
import lcashIcon from '../../../assets/lcash.svg';
import {
  admin,
  superadmin,
  ultrasuperadmin,
  master,
  masterPartner,
} from '../../../lib/strings/appConstants';
import { languages } from '../../../lib/interfaces/language';
import emojiFlags from 'emoji-flags';
import Flag, { Props } from 'react-flagkit';
import { menuConstants } from '../../../constants/menuConstants';
import { store } from '../../../helpers/store';
import { userData } from '../../../reducers/userReducer';
import { b } from './i18next'


const { Text } = Typography;

const actionCreators = (dispatch: any) => {
  return {
    onClickLogout: () => dispatch(userActions.signout())
  };
};
const mapStateToProps = (state: {
  authentication: { user: any; language: string, };
  userData: any;
  menuUIChange: any;
}) => {
  const { authentication, userData, menuUIChange } = state;
  const { user, language, } = authentication;
  const { role, lcash, username, books ,coupons, phoneNumber} = userData;
  const { country } = userData;
  return {
    user,
    userData,
    menuUIChange,
    role,
    lcash,
    language,
    country,
    username,
    books,
    coupons,
    phoneNumber,
  };
};

interface ProfileInfoProps {
  onClickLogout: () => void;
  user?: any;
  userData: any;
  menuUIChange: any;
  role: number;
  lcash?: number;
  language: languages;
  country: any;
  username: any;
  books: any;
  coupons:any;
  phoneNumber: string;
}


interface ProfileInfoState {
  img: any;
  username: string;
  phoneNumber: string;
  lcash: number;
  books: any,
  coupons:any
}

export class ProfileInfo extends Component<ProfileInfoProps, ProfileInfoState> {

  constructor(props: Readonly<ProfileInfoProps>) {
    super(props);
    this.state = {
      img: imgPlaceholder,
      username: '',
      phoneNumber: '',
      lcash: 0,
      books: 0,
      coupons:0,
    };


  }

  fetchUserData = async () => {
    const res = await userService.getUserData(this.props.user.user._id);
    store.dispatch({ type: 'USER_BOOKS', books: res.plum_rec });
    store.dispatch({ type: "USER_ROLE", Role: res.role })
    store.dispatch({type: "COUPONS", coupons: res.coupons })
    this.setState({ books: res.plum_rec });   
    this.setState({coupons:res.coupons});
    return res;
  };

  componentDidMount = async () => {
    if (this.props.userData.avatar && this.props.userData.avatar.picture) {
      this.setState({
        img: `${baseURL}/uploads/${this.props.userData.avatar.picture.nameOnServer}`,
      });
    }

    if (this.props.userData.lcash) {
      this.setState({
        lcash: this.props.userData.lcash,
      });
    }
    this.fetchUserData()


  };




  thousandSeparator = (x: Props) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }

  thousandSeparators = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }
  componentDidUpdate = (prevProps: any) => {
    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData.avatar && this.props.userData.avatar.picture) {
        this.setState({
          img: `${baseURL}/uploads/${this.props.userData.avatar.picture.nameOnServer}`,
        });
      }
    }
    if (prevProps.lcash !== this.props.lcash) {
      if (this.props.lcash) {
        this.setState({
          lcash: this.props.lcash,
        });
      }
    }

  };


  switchRole = () => {
    const { country, role, language, phoneNumber } = this.props;
    const countryFlag = this.props.country ? (
      <Flag
        country={this.props.country.toUpperCase()}
        style={{ marginBottom: 2 }}
      />
    ) : null;
    switch (this.props.role) {
      case roleMaster:
        return (
          <>
          
            <div className='couponBlock'>
              <h5>
                <span>({this.state.coupons})<span className="couponBadge">COUPON</span> </span>
                <div className="infoTip">
                  <span className="infoTip__icon" style={{ height: '22px' }}>&nbsp;</span>
                  <div className="infoTip__tooltip md-size">
                    <p className="size12 mb-0 font-regular">Through the Candy Challenge, you can receive a coupon that allows you to register a Dome Address for free. Select the Dome Address of your desired location and click the 'Pay' button to use the free coupon.</p>
                  </div>
                </div>
              </h5>
              <p className='mb-0' style={{lineHeight:'normal'}}>Dome Address</p>
              <span>{this.state.coupons > 0 && (
        <span>Expired: {this.getLastDayOfMonth()}</span>
      )}</span>
            </div>
            <Row className="center large-text-no-padding ">
              <Text className="roboto-regular">
                {b?.t('master')}
                {/* {master[this.props.language]}{' '} */}
              </Text>
              {countryFlag}
            </Row>

            <Row className="col-24 fx-jcsb large-text-no-padding ">
              <Text className="roboto-regular center" style={{ width: '25%', fontSize: '14px' }}>
                <img style={{ marginRight: '.2em' }} src={lcashIcon} />
                {this.thousandSeparators(this.state.lcash)}
                {/* {this.state.lcash} */}
              </Text>
              <div className="roboto-regular fx" style={{ width: '75%', fontSize: '14px' }}>
                <div className='text-center'>{b?.t('Received1')}</div>
                <div className="infoTip">
                  <span className="infoTip__icon" style={{ height: '22px' }}>&nbsp;</span>
                  <div className="infoTip__tooltip sm-size">
                    <p className="size12 mb-0 font-regular">
                      {b?.t('The received plums')}

                    </p>
                  </div>
                </div>
                <div className='fx-aic ml-5'>
                  <span className='blue-dot mr-5'></span>

                  {this.state.books ? this.thousandSeparator(this.state.books ? this.state.books : '') : 0}

                </div>
              </div>


            </Row>
          </>
        );

      case rolePartner:
        return (
          <>
          
            <Row className="center large-text-no-padding">
            
              <Text className="roboto-regular">
                {masterPartner[this.props.language]}{' '}
              </Text>
              {countryFlag}
            </Row>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">
                <img style={{ marginRight: '.2em' }} src={lcashIcon} />
                {this.state.lcash}
              </Text>
            </Row>
          </>
        );

      case roleAdmin:
        return (
          <Row className="center large-text-no-padding">
            <Text className="roboto-regular">
              {admin[this.props.language]}{' '}
            </Text>
            {countryFlag}
          </Row>
        );

      case roleSuperadmin:
        return (
          <>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">
                {phoneNumber === '+12139355403' ? ultrasuperadmin[this.props.language] : superadmin[this.props.language]}{' '}
              </Text>
              {countryFlag}
            </Row>

            <div className="roboto-regular fx-jcc" style={{ width: '100%', fontSize: '14px' }}>
              <div className='text-center'>Received Plum Balance</div>
              <div className="infoTip">
                <span className="infoTip__icon" style={{ height: '22px' }}>&nbsp;</span>
                <div className="infoTip__tooltip sm-size">
                  <p className="size12 mb-0 font-regular">
                          The received plums from the users’donation can be cashed on the Account> Plums> Cashing
                  </p>
                </div>
              </div>
              <div className='fx-aic ml-5'>
                <span className='blue-dot mr-5'></span>

                {this.state.books ? this.thousandSeparator(this.state.books ? this.state.books : '') : 0}
              </div>
            </div>

          </>
        );
      default:
        break;
    }
  };
  getLastDayOfMonth() {
    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    return `${lastDayOfMonth}.${currentMonth},${currentDate.getFullYear()}`;
  }

  render() {
    return (
      <div className="profile-info-safari-fix">
        <Row type="flex" justify="center" className="profile-info-wrapper">
          <div className='langSelect'>
            <I18next />
          </div>
          <Col className='ant-col-24'>
            <Row type="flex" justify="center" align="middle">
              <img
                alt="profile photo"
                src={this.state.img}
                className="profile-photo"
              />
            </Row>
            <Row>
              <Col className="center large-text-no-padding">
                <Text className="fredoka">{this.props?.username}</Text>
              </Col>
            </Row>
            {this.switchRole()}{' '}
          </Col>
          <Button className="button-logout" onClick={this.props.onClickLogout}>
            {b?.t('Logout')}
            <img src={Logout} className="logout-icon" />
          </Button>
          <Button
            onClick={() => window.location.reload()}
            className="button-dashboard"
          >
            {b?.t('Dashboard')}

            <img src={Dashboard} className="dashboard-icon" />
          </Button>


        </Row>
      </div>
    );
  }
}
export default connect(mapStateToProps, actionCreators)(ProfileInfo);
