import { baseURL, addCard, getSupportTicket } from '../../../../lib/config';
import { store } from '../../../../helpers/store';
import { responseCodes } from '../../../../lib/const';
import { notification } from 'antd';
import {b} from '../../../../containers/dashboard/menu/i18next';
  
  
  const showErrorMessage = (title, description?) => {
    notification.error({
      message: `${title}`,
      description: `${description || ''}`,
      duration: 10,
    });
  };

  const showSuccessMessage = (title, description?) => {
    notification.success({
      message: `${title}`,
      description: `${description || ''}`,
      duration: 10,
    });
  };
  
  
  

  export const getCardList = async () => {
    try {
      const fetchResponse = await fetch(baseURL+ addCard , {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
        },
      });
      const response = await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        return response.data;
      } else {
        showErrorMessage(response.error, response.code);
        return Promise.reject('failed to get sections');
      }
    } catch (error) {
      showErrorMessage("Try after sometimes");
      return error;
    }
  };
  
  export const CreateCard = async (data) => {
    try {
      const fetchResponse = await fetch(baseURL + addCard, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data),
      });
      const response = await fetchResponse.json();
      
      if (response.code === responseCodes.successful) {
        showSuccessMessage(b?.t('Success'), b?.t('Card created successfully'));
        return response.data;
      } else {
         showErrorMessage(response.error, response.code);
        return Promise.reject('failed to create card');
      }
    } catch (error) {
      showErrorMessage("Try after sometimes");
      return error;
    }
  };
  
  
  