import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { Row, Col, Button, Typography, Select } from 'antd';
import { Table, Filter, Paginate } from '../../../../components';
import * as menuActions from '../../../../actions/menuActions';
import * as statisticsStrings from '../../../../lib/strings/statistics';
import PropTypes from 'prop-types';
// import { lang } from 'moment';
import * as statisticsService from '../../../../services/statisticsService';
import { getStatisticsForSTeamAssignment } from '../../../../services/statisticsService';
import { statisticsForCoins } from './apiToTabel';

import { useQuery } from 'react-query';
import {
    statisticsForTeamAssignment,
    statisticsForCoinsStrings,
} from '../../../../lib/strings/statistics';
import { data } from '../domeAdmin/domeAdmin';
import ForAdmin from './StatisticsForAddressForAdmin'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function Statistics2(props) {
    const {
        //     language,
        closeSubmenuFullScreen,
        //     label,
        //     loading,
        //     statisticsType,
        //     dataSource,
        //     clickedUsername,
        kitList,
        //     onFiltersChange,
        //     onPageChange,
        //     totalItems,
        //     pageSize,
        //     currentPage,
        //     total,
        role,
        //     isPartner,
        ...otherProps
    } = props;

    const { width, height } = useWindowDimensions();

    const handleCloseStatistics = () => {
        closeSubmenuFullScreen();
    };
    const { language } = props;
    const statisticsType = 'StatisticsForAssignment'
    const label = statisticsForTeamAssignment[language]

    const [currentFilters, setCurrentFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [statistics, setStatistics] = useState([]);
    const [totalTreasures, setTotalTreasures] = useState(0);
    const [total, setTotal] = useState({});
    const [superAdmin, setSuperAdmin] = useState("active")
    const [admin, SetAdmin] = useState("")
    const [SuperAdmincl, setSuperAdminCl] = useState(true)
    const [Admincl, setAdminCl] = useState(false)
   



    const [clickedUsername, setClickedUsername] = useState('');
    const clickedUsernameHandler = (rowId, username) => {
        setClickedUsername(username);
    };

    useEffect(() => {
        fetchStatisticsTeamAssignment()
    }, []);

    const [userData, setUserData] = useState([]);
    const fetchStatisticsTeamAssignment = async () => {
        const response = await getStatisticsForSTeamAssignment();
        setUserData(response);
        return response;
    };


    const statisticsQuery = useQuery(
        ['StatisticsForAssignment', { ...currentFilters, page: currentPage }],
        fetchStatisticsTeamAssignment,
        {
            staleTime: 1800000,
            cacheTime: 3600000,
        }
    );

    const SuperAdmin = () => {
        setSuperAdmin("active")
        setSuperAdminCl(true)
        SetAdmin("")
        setAdminCl(false)

    }
    const Admin = () => {
        SetAdmin("active")
        setSuperAdmin("")
        setSuperAdminCl(false)
         setAdminCl(true)

    }

    useEffect(() => {
        if (statisticsQuery.data) {
            const parsedStatistics = [data]

            setTotal({
                totalBalance: statisticsQuery.data.totalCoins,
                purchasedCoins: statisticsQuery.data.purchasedCoins,
                convertedCoins: statisticsQuery.data.coinsFromConversion,
                usedCoins: statisticsQuery.data.usedCoins,
            });
            setStatistics(parsedStatistics);
            setTotalTreasures(statisticsQuery.data.totalTransactions);
        }
    }, [statisticsQuery.data]);

    const onFiltersChangeHandler = async (filters) => {
        setCurrentPage(0);
        setCurrentFilters(filters);
        setClickedUsername('');
    };

    const onPageChangeHandler = async (page) => {
        setCurrentPage(page);
    };
    return (
        <>
            <Row
                className="menu-wrapper full-screen-submenu"
                >
                {/* Back button and title */}
                <Row className="contest-top-row" type="flex" justify="start" align="middle">
                    <Button onClick={handleCloseStatistics} className="details-arrow">
                        <img src={arrow} alt="close" />
                    </Button>
                    <Typography.Text className="contest-title bold">{label}</Typography.Text>
                </Row>
                <div style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                    {/* Filter and page numbers */}
                    <Row type="flex" justify="space-between">
                        <Col>
                            <Filter
                                language={language}
                                statisticsType={statisticsType}
                                clickedUsername={clickedUsername}
                                kitList={kitList}
                                onChange={onFiltersChangeHandler}
                                role={role}
                            />
                        </Col>
                        <Col> <Row className="text-center">
                            <button
                                onClick={SuperAdmin}
                                className={
                                    'ant-btn primary-ghost-btn xs ml-5 mr-5 ' + superAdmin}>
                                Super Admin
                            </button>

                            <button
                                onClick={Admin}
                                className={'ant-btn primary-ghost-btn xs ' + admin
                                } style={{ marginLeft: "15px",  }}>
                                Admin
                            </button>
                        </Row></Col>
                        <Col>
                            <Paginate
                                onPageChange={(page) => onPageChangeHandler(page.selected)}
                                totalItems={totalTreasures}
                                pageSize={10}
                                forcePage={currentPage}
                            />
                        </Col>
                    </Row>
                    {SuperAdmincl
                        &&
                        <Table
                            width={width - 70}
                            height={height - 220}
                            language={language}
                            loading={statisticsQuery.isLoading}
                            dataSource={statistics}
                            {...otherProps}
                        />
                    }

                    {
                    Admincl && 
                   <ForAdmin/>
                    }

                    {statistics.length !== 0 && !statisticsQuery.isLoading && (
                        <div className="statistics-below-table">
                            {statisticsType === 'StatisticsForAssignment' && (
                                <BellowTable
                                    label={statisticsStrings.TotalnoofTeam[language]}
                                    value={parseInt(userData['Count']) - 1}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Row>
        </>

    );
}

Statistics2.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    statisticsType: PropTypes.string,
    dataSource: PropTypes.array,
    onPageChange: PropTypes.func,
    totalItems: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    total: PropTypes.object,
};

Statistics2.defaultProps = {
    language: 'EN',
    onPageChange: () => { },
    pageSize: 10,
};

const mapStateToProps = (state) => ({
    language: state.authentication.language,
    kitList: state.kitList,
    role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
    closeSubmenuFullScreen: () => dispatch(menuActions.closeSubmenuFullScreen()),
    openStats: () => dispatch(menuActions.openSubmenuFullScreen),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics2);

export function BellowTable(props) {
    const { label, value } = props;
    return (
        <div className="column">
            <div className="row">{label}</div>
            <div className="value">{value}</div>
        </div>
    );
}

BellowTable.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
