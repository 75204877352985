
import React, { useContext, useEffect, useState } from 'react';
import { Row, Button, Typography, Col, InputNumber, Empty } from 'antd';
import {   notification, } from 'antd';
import equalIcon from '../../../../assets/equal.svg';
import { persistor } from '../../../../helpers/store';
import closeIcon from '../../../../assets/closeXsmall.svg';
import { languages } from '../../../../lib/interfaces/language';
import AppContext from '../../../../AppContext';
import {cointoPlum,coinsReceivedApi } from '../../../../services/domeService';
 import {Plumtocash } from '../../../../services/domeService';

interface Props {
  updateLCashus:(amounts:number)=>void;
  us:number;
  language?: languages;
  
}
const Exchange = (props: Props) => {
  const [single_plum_value,setSingle_plum_value]=useState("")
  const [single_cash_value,setSingle_cash_value]=useState("")
  const [data,setData]=useState({key1:null,key2:null})
  const profile = useContext(AppContext);
  useEffect(() => {
    persistor.persist();
    persistor.flush();
  }, []);

  // var plums=(props.us ? props.us / 8.33:'')
  // console.log("mmmm",data)




  const ExchangePlums = (e:any) => {
    setSingle_plum_value(e)
     
     
  };
const  rate= async()=>{
  let data= await coinsReceivedApi()
  // console.log("5555",data)
  // console.log("vvvv",data)
  setData({...data,key1:data.data[0].coinValueInPoints,key2:data.data[1].coinValueInPoints})
  // console.log("7777",{key1:data.data[0].coinValueInPoints,key2:data.data[1].coinValueInPoints}) 
}
  useEffect(() => {
    rate()
  }, []);

  const ExchangeUS=(e:any)=>{
    setSingle_cash_value(e);
   
  }


  const StripePayment = async() => {
    let res= await Plumtocash (single_cash_value)
     rate()
   };



  const onStripePayment = async() => {
   let res= await cointoPlum(single_plum_value)
   rate()
  };

  const closeexchange = () => {
    profile.profile['exchange'] = null;
    profile.setContextData(profile.profile);
  };

  return (
    <Row className="kit-list-wrapper">
      <div className="scrollable">
        <Row
          className="contest-top-row"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <div>
            <h4 className="text-primary mb-0 font-bold">&#62; Exchange rate</h4>
          </div>
          <div>
            <i onClick={closeexchange} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>
        </Row>
        <Row className="recharge-container">
         <h4 className="mb-0 font-bold">Exchange coins for Plum</h4>
          <Row className="recharge-form">
            <Row type="flex" justify="center">
              <Col className='col-3'>
                <Row className='text-center'>
                <Typography.Text>Coins</Typography.Text>
                </Row>
                <Row className='inpCenter'>
                  {console.log("4444",data.key1)}
                  <InputNumber
                    className="input-treasure" 
                    min={0}
                     name="lcash"
                     value={data.key1}
                    onChange={(e) =>{ ExchangePlums(e)
                    }}
                  />
                </Row>
              </Col>
              <Col style={{ margin: '1em' }}>
                <img src={equalIcon} style={{ width: '55px' }} />
              </Col>
              <Col className='col-3'>
                <Row className='text-center'>
                  <Typography.Text className='text-center'>Plums</Typography.Text>
                </Row>
                <Row className='bot-line-ui text-center'>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {1}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="center" className="button-payment">
              <Button
                className="buttons yellow-gradient"
                  onClick={onStripePayment}
              >
                Enter
              </Button>
            </Row>
          </Row>
        </Row>



        <Row className="recharge-container">
         <h4 className="mb-0 font-bold">Plums vs U$ for the cashing request</h4>
          <Row className="recharge-form">
            <Row type="flex" justify="center">
              <Col className='col-3'>
                <Row className='text-center'>
                <Typography.Text>Plums</Typography.Text>
                </Row>
                <Row className='bot-line-ui text-center'>
                  <Typography.Text style={{ fontSize: '16px' }}>
                    {/* { typeof plums == 'number' && !isNaN(plums)? plums.toFixed(2):''} */}
                    {1}
                  </Typography.Text>
                </Row>
                
              </Col>
              <Col style={{ margin: '1em' }}>
                <img src={equalIcon} style={{ width: '55px' }} />
              </Col>
              <Col className='col-3'>
                <Row className='text-center'>
                  <Typography.Text>U$</Typography.Text>
                </Row>
                <Row className='inpCenter'>
                  <InputNumber
                    className="input-treasure"
                    min={0}
                    name="lcash"
                    value={data.key2}
                    onChange={(e) => ExchangeUS(e)}

                  />
                </Row>
               
              </Col>
            </Row>
            <Row type="flex" justify="center" className="button-payment">
              <Button
                className="buttons yellow-gradient"
                  onClick={StripePayment}
              >
                Enter
              </Button>
            </Row>
          </Row>
        </Row>
      </div>
    </Row>
  );
};export default (Exchange)
