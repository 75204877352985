import React, { useState } from 'react';
import { Row, Spin } from 'antd';
import AppContext from '../../../AppContext';
import { listMyDomes } from '../../../services/domeService';
import { notification } from 'antd';
import { connect } from 'react-redux';
// import { Translation } from 'react-i18next';




class MyDomButton extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      mydome_active: '',
    };
    this.myDomeHandler = this.myDomeHandler.bind(this);
  }

   showErrorMessage = (title) => {
    notification.error({
      message: `${title}`,
      duration: 10,
    });
  };
  myDomeHandler = async (e: any) => {
    const context = this.context;
    let profile = context.profile;
    profile.loader = true;
    context.setProfile(profile);
    let domeList = await listMyDomes();
    if(domeList.length==0){
     this. showErrorMessage(" No Dome Address is registered");
    }

    profile.loader = false;
    profile.domeList = domeList;
    profile.domeSettingList = [];
    profile.current_dome = false;
    profile.register = false;
    context.setProfile(profile);
    this.setState({
      mydome_active: 'active', 
    });
  };

  render() {
    const { translation } = this.props
    return (
      <>
        <Row
          style={{ justifyContent: 'space-around' }}
          className="fx-jcc flex-btn mb-15"
        >
          <button
            style={{ width: '120px' }}
            onClick={this.myDomeHandler}
            
            className={
              'ant-btn primary-ghost-btn xs ' + this.state.mydome_active
            }
          >
           { translation("My Dome Address")} 
          </button>
          <button
            style={{ width: '120px' }}
            disabled
            // onClick={this.myDomeHandler}
            className={'ant-btn primary-ghost-btn xs '}
          >
            {translation("Buy & Sell")}
          </button>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state:any) => (
  {
  translation: state.userData.translation,
}
);

export default connect(mapStateToProps, null)(MyDomButton);
