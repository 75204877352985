import React, { useEffect } from 'react';
import PhoneNumberForm from './PhoneNumberForm';
import VerificationForm from './VerificationForm';
import { Typography, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as signin from '../../lib/strings/signin';
import { locationConstants } from '../../constants/locationConstants';
import { LOCAL_STORAGE_USER_LOCATION, LOCAL_STORAGE_LANGUAGE } from '../../lib/config';
import { userConstants } from '../../constants/signinConstants';
import logo from '../../assets/legalscreen_logo.svg';
import logoWeb from '../../assets/logoWeb.svg';
import I18next from '../dashboard/menu/i18next';
// import {b} from '../dashboard/menu/i18next';
import { useTranslation} from "react-i18next";

import googleBtn from '../../assets/google-play-store.png';
import appleBtn from '../../assets/apple-store.png';
import appStoreBtn from '../../assets/appStoreBtn.svg';
import playStoreBtn from '../../assets/playStoreBtn.svg';

const { Title, Text, Paragraph } = Typography;

const mapStateToProps = (state: {
  authentication: { waitingVerification: boolean; user: any; language: any };
}) => {
  const { authentication } = state;
  const { waitingVerification, user, language } = authentication;
  return { waitingVerification, user, language };
};

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    address?: { lat: number; lng: number };
    language?: string;
  }) => any
) => {
  return {
    storeUserLocation: (address: { lat: number; lng: number }) =>
      dispatch({ type: locationConstants.UPDATE_MAP_ADDRESS, address }),
    saveLangPreferences: (language: string) => dispatch({ type: userConstants.LANGUAGE, language }),
    resetReduxToInitial: () => dispatch({ type: userConstants.RESET }),
  };
};

interface SignInProps {
  waitingVerification: boolean;
  user: any;
  storeUserLocation: (address: { lat: number; lng: number }) => void;
  language: languages;
  saveLangPreferences: (code: string) => void;
  resetReduxToInitial: () => void;
}

type languages = 'EN' | 'KO';

const SignIn = (props: SignInProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    // reset redux state to initial
    if (!props.user) {
      props.resetReduxToInitial();
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getLocation);
    }
    if (navigator.language === 'ko') {
      props.saveLangPreferences('KO');
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, 'KO');
    } else {
      props.saveLangPreferences('EN');
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, 'EN');
    }
  }, []);

  const getLocation = (position: any) => {
    props.storeUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
    localStorage.setItem(
      LOCAL_STORAGE_USER_LOCATION,
      JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude })
    );
  };

  if (props.user) {
    return <Redirect to="/dashboard" />;
  }

  const { language } = props;

  return (
    <>
      <Row className='headerMain'>
        <Col className='headerMain__wrapper'>
          <a href="http://front.loamoa.cladev.com/" className="logo-icon">
            <div id="logo">
              <img src={logoWeb} alt="loamoa" width="52" height="34" loading="lazy" />
              <div className="fredoka-text logo-text notranslate" translate="no">LoaMoa</div>
            </div>
          </a>
          <nav className='right-block'>
            <a href="https://apps.apple.com/tc/app/loamoa/id1500906138" className='appBtn appBtn--apple'>
              <img src={appStoreBtn} alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.loamoa&hl=es_BO" className='appBtn appBtn--google'>
              <img src={playStoreBtn} alt="Play Store" />
            </a>
            <I18next />
          </nav>
        </Col>
      </Row>

      <Row
        className={props.language === 'KO' ? 'signin gmarket-medium pb-10 pt-15' : 'signin pb-10 pt-15'}
        type="flex"
        justify="center"
        align="middle"
      >
        <Col
          className="signin-wrapper"
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 16 }}
          lg={{ span: 10 }}
          xl={{ span: 8 }}
        >
          <Row type="flex" justify="center" className="signin-title">
            <Col>
              <Title className="signin-title" level={3}>
                {t("Welcome to LoaMoa")}
                 {/* {signin.Welcome[language]}  */}
                <Text className="master-account-title">{t("Master Account")}</Text>
              </Title>
            </Col>
          </Row>
          <Row type="flex" justify="center" className="logo-img">
            <img src={logo} alt="LoaMoa" />
          </Row>
          {props.waitingVerification ? <VerificationForm /> : <PhoneNumberForm />}
          <Row className="legal link" type="flex" justify="center">
            <Paragraph>
              {' '}
              {t("To learn more,read")}{' '}
              <Link to="terms-of-use">{t('Terms of Use')}</Link>{' '}
              {t('and')}{' '}
              <Link to="privacy-policy">{t('Privacy Policy')}</Link>
            </Paragraph>
          </Row>
        </Col>
      </Row>
      <Row 
        className='signin pt-0'
        type="flex"
        justify="center"
        align="middle"
      >
      <Col
          className="signin-wrapper"
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 16 }}
          lg={{ span: 10 }}
          xl={{ span: 8 }}
        >
          <h3 className='newtothis'>{t('New to this…')}</h3>
          <p className='text-dark'>{t('To access our web')}</p>
          <div className='app-button'>
            <a href="https://play.google.com/store/apps/details?id=com.loamoa&hl=es_BO" className='app-button__google'>
              <img src={googleBtn} alt="" />
            </a>
            <a href="https://apps.apple.com/tc/app/loamoa/id1500906138" className='app-button__apple'>
              <img src={appleBtn} alt="" />
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
