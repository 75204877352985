import { baseURL, localization, localizationUploadFiles } from '../lib/config';
import { store } from '../helpers/store';
import { notification } from 'antd';
import { filesUploadErr } from '../lib/strings/error';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';
import { parseLocalizationData } from '../components/localization/localizationHelperFunctions';

export const postModelLocalization = async (model) => {
  try {
    const fetchResponse = await fetch(baseURL + '/api/armax/localization', {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });
    const response = await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data.stories;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
    throw `${response.error} ${response.code}`;
  } catch (error) {
    notification.error({
      message: filesUploadErr,
    });
    return error;
  }
};

export const getModelLocalization = async (ID: number, model: string) => {
  try {
    const fetchResponse = await fetch(`${baseURL}/api/armax/localization/byModel/${model}/${ID}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
    throw `${response.error} ${response.code}`;
  } catch (error) {
    notification.error({
      message: filesUploadErr,
    });
    return error;
  }
};

export const getModelLocalizationQuery = async ({ queryKey }) => {
  const { ID, model } = queryKey[1];
  const fetchResponse = await fetch(`${baseURL}${localization}/${model}/${ID}`, {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const response = await fetchResponse.json();

  if (response.code === responseCodes.successful) {
    return parseLocalizationData(response.data);
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  }
  throw `${response.error} ${response.code}`;
};

export const postModelLocalizationQuery = async (model) => {
  // const { model } = queryKey[1];
  const fetchResponse = await fetch(baseURL + '/api/armax/localization', {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });
  const response = await fetchResponse.json();

  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  }
  throw `${response.error} ${response.code}`;
};

export const uploadLocalizationFilesQuery = async (formData: any, listener: any) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.open('PUT', `${baseURL}${localizationUploadFiles}`, true);
    xhr.setRequestHeader('access-token', store.getState().authentication.user.newToken);
    xhr.responseType = 'json';

    xhr.upload.onprogress = (e: ProgressEvent) => {
      const perc = Math.floor((e.loaded / e.total) * 1000) / 10;
      if (listener && listener.onProgress) listener.onProgress(perc);
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (xhr.response.code === responseCodes.successful) {
            resolve(xhr.response.data);
          } else {
            reject(`${xhr.response.error} ${xhr.response.code}`);
          }
        } else {
          notification.error({
            message: filesUploadErr,
          });
          reject(status);
        }
      }
    };
    xhr.send(formData);
  });
};
