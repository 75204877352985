import { notification, Row } from 'antd';
import React, { Component } from 'react';

import closeIcon from '../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../src/assets/location-on-map.svg';
import iconDelete from '../../../../src/assets/icon_delete_o.svg';
import AppContext from '../../../AppContext';
import { deleteItem, getCart } from '../../../services/addToCartService';
import { store } from '../../../helpers/store';
import { getMarkerLocation } from '../../../helpers/GetLocation';
import { isPaymentInitiated } from '../../../reducers/treasureReducer';
import { connect } from 'react-redux';
import { getCardList } from './domeAccount/account';
import { cartPayment } from '../../../services/domePaymentService';
import { languages } from '../../../lib/interfaces/language';
import {b} from '../../dashboard/menu/i18next'



interface  Props{
  language?: languages;
}

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

class CartItems extends Component<any, any > {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      carts: false,
      isActive: false,
      profile: {},
      price: 18.0,
      discount_price: 18.0,
      total: 18.0,
      year: 1,
      check: false,
      marker: '',
      circle: '',
      
    };
    this.getLocation = this.getLocation.bind(this);
  }

  handleClick = async (index: any, check: any, e: any ) => {
    const context = this.context;
    let profile = context.profile;
    let items = profile.items;
    items[index].check = !check;

    profile.items = items;
    context.setProfile(profile);
    await this.getTotalCostOfCheckedItem(e);
  };

  getTotalCostOfCheckedItem = async (e: any) => {
    const context = this.context;
    let profile = context.profile;
    let items = profile.items;
    let total = 0;
    for (var item in items) {
      if (items[item].check === true  ) {
        total = total + items[item]['total'];
      }

    }
    if (items && items.length) {
      profile.dome_name = items[items.length - 1]['name'];
      profile.dome_lng = items[items.length - 1]['longitude'];
      profile.dome_lat = items[items.length - 1]['latitude'];
    } else {
      profile.dome_name = null;
      profile.dome_lng = null;
      profile.dome_lat = null;
    }

    items['total'] = total;
    profile.items = items;
    context.setProfile(profile);
  };
  
  paymentHandlerCoupon = async (price: any) => {
    let profile = this.context.profile;
    const data = profile.total
    if (!data) {
      this.setState({ total: 0 })
      }
    else {
      let a = data - price
      this.setState({ total: a })
      this.paymentHandler(0)
    }
    this.setState({
      isActive: false,

    });
  }

  paymentHandler = async (e: any) => 
  {
    const context = this.context;
    let profile = context.profile;
    this.setState({
      isActive: false,
    });
    const cardList = await getCardList();
    profile['cardList'] = cardList;
    let items = profile.items;
    let isAnyChecked = false;
    let cartPayedItems = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].check) {
        isAnyChecked = true;
        cartPayedItems.push({
          dome_name: items[i]['name'],
          dome_lng: items[i]['longitude'],
          dome_lat: items[i]['latitude'],
        });
        // profile.dome_name = items[i]['name'];
        // profile.dome_lng = items[i]['longitude'];
        // profile.dome_lat = items[i]['latitude'];
      }
    }
    if (!isAnyChecked) {
      return;
    }
    profile.total = this.state.total;
    profile.year = this.state.year;
    profile.is_payment = true;
    profile['enter_for_location'] = true;
    profile['payedFromCart'] = true;
    profile['cartPayedItems'] = cartPayedItems;
    context.setProfile(profile); 
  };
  closeRegisterPopUp = (e: any) => {
    const context = this.context;
    let profile = this.context.profile;
    profile.register = false;
    profile.iscart = null;
    profile.fetch_in_boundry = false;
    profile.enter_for_location = false;
    context.setProfile(profile);
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }
  };

  getItems = async () => {
    const context = this.context;
    let profile = context.profile;
    const cartItems = await getCart();
    let itemsMap = [];
    let total = 0;
    for (var item in cartItems) {
      var itemMap = {};
      itemMap['id'] = cartItems[item].ID;
      itemMap['year'] = 1;
      itemMap['check'] = true;
      itemMap['base_price'] = 18;
      itemMap['amount'] = 18;
      itemMap['total'] = itemMap['year'] * itemMap['amount'];
      itemMap['name'] = cartItems[item].name;
      itemMap['is_register'] = cartItems[item].is_register;
      itemMap['latitude'] = cartItems[item].location.x
        ? cartItems[item].location.x
        : 0.0;
      itemMap['longitude'] = cartItems[item].location.y
        ? cartItems[item].location.y
        : 0.0;
      //   total = total + itemMap['total'];
      itemsMap.push(itemMap);
    }

    if (itemsMap.length === 1) {
      if (!itemsMap[0]['is_register']) {
        itemsMap[0]['check'] = true;
        total = itemsMap[0]['total'];
      } else {
        itemsMap[0]['check'] = false;
        total = 0;
      }
    } else {
      for (let i = 0; i < itemsMap.length; i++) {
        itemsMap[i]['check'] = false;
        total = 0;
      }
    }

    itemsMap['total'] = total;
    profile.items = itemsMap;
    profile.name = 'cart';
    profile.item_count = itemsMap.length;
    profile.iscart = true;
    profile.register = false;
    context.setProfile(profile);
  };

  deleteFromCart = async (e: any) => {
    e.stopPropagation();
    const context = this.context;
    let profile = context.profile;
    let id = parseInt(e.target.getAttribute('data-id'));
    await deleteItem(id);
    await this.getItems();

    if (profile.items && !profile.items.length) {
      profile.iscart = false;
      profile['enter_for_location'] = false;
      context.setProfile(profile);
    }
  };

  onChangeYear = (e: any) => {
    // this.context.profile.show=false
    let value = e.target.value;
    value = value.split('_');
    let discount = parseInt(value[0]);
    let year = parseInt(value[1]);
    let key = parseInt(e.target.getAttribute('data-id'));
    let discount_price = this.state.price - (this.state.price * discount) / 100;
    const context = this.context;
    let profile = context.profile;
    let cartItems = profile.items;
    let total = 0;

    let newItem = cartItems.map((item) => {
      if (item.check || !item.check) {
        if (item.id == key ) {
        if(item.check){
          total +=
            (item.base_price - (item.base_price * discount) / 100) * year;
        }
          return {
            ...item,
            year: year,
            amount: item.base_price - (item.base_price * discount) / 100,
            total:
              (item.base_price - (item.base_price * discount) / 100) * year,
        };

        } else {
          
          if(item.check){  
          total += item.total;
          }
          return item;
        }
      } else {
        
        return item;
      }


    });

    newItem['total'] = total;
    profile.items = newItem;
    context.setProfile(profile);
  };
  
  getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
    const contextData = this.context;
    let profile = contextData.profile;
   
    const mapInstance = store.getState().address.mapInstance;
    
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }
    

    let res = getMarkerLocation(lat, lng, dome_addr);
    console.log("resquest",res)
    mapInstance.setCenter(res.center);
    this.setState({
      marker: res.marker,
    });
 
  };

  OnCartItemClick = (item) => {
    showErrorMessage(`${item.name} ${b?.t('is not available')}`);
  };

  superAdminCheckout = async () => {
    const context = this.context;
    const profile = context.profile;
    let data = {
      profile: profile,
      email: this.state.email,
    };

    await cartPayment({}, data);

    let items = profile.items.filter(function (item) {
      return item.check === false;
    });

    if (items && items.length) {
      profile.dome_name = items[items.length - 1]['name'];
      profile.dome_lng = items[items.length - 1]['longitude'];
      profile.dome_lat = items[items.length - 1]['latitude'];    
    } else {
      profile.dome_name = null;
      profile.dome_lng = null;
      profile.dome_lat = null;
    }

    profile.iscart = null;
    profile.items = null;
    profile.item_count = items.length;
    profile.is_payment = false;
    profile.year = null;

    profile['fetch_in_boundry'] = true;
    // remove all current dome
    let markers = profile['markers'] || [];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    profile['markers'] = [];
    context.setProfile(profile);
    profile['is_dome_registered_successfully'] = true;
    showSuccessMessage(b?.t('Dome created successfully'));
    // showSuccessMessage('Coming Soon...');
  };

  toggleClass = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const { translation } = this.props
     const isActive = this.state.isActive;
    var register = false;  
    var pageName = 'register';
    var is_payment = false;
    var items = [];
    const profile = this.context.profile;

    if (profile) {
      register = profile.register;
      pageName = translation("Cart");
      is_payment = true;
      items = profile.items;
    }

    return (
      <>
        {items.length ? (
          <Row className="menu-wrapper treasure-menu-wrapper second-dialog bigger">
            <div className="second-card register-card">
              <div className="fx-jcsb-aic mb-20">
                <h4 className="text-primary mb-0 font-bold">
                  {' '}
                  &#62; {pageName}
                </h4>
                <i onClick={this.closeRegisterPopUp} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
              <div className='cartLoopContainer scrollSection'>
                {items.map((item, index) => (
                  <div
                    key={item.id}
                    style={
                      item.is_register
                        ? {
                          background: '#d3d3d354',
                          marginBottom: '15px',
                          cursor: 'pointer',
                        }
                        : { marginBottom: '15px' }
                    }
                    className="let-long-loop"
                    onClick={
                      item.is_register
                        ? () => this.OnCartItemClick(item)
                        : () => { }
                    }
                  >
                    <div className="let-long-loop__left">
                      <div className="let-long-loop__left__row">
                        <i className="let-long-loop__left__row__info__row__check mr-5">
                          <input
                            onClick={
                              item.is_register
                                ? () => { }
                                : (e) => this.handleClick(index, item.check, e )
                            }
                            checked={item.check}
                            type="checkbox"
                            style={
                              item.is_register
                                ? {
                                  cursor: 'not-allowed',
                                }
                                : {}
                            }
                          // disabled={item.is_register}
                          />
                        </i>
                        <div className="let-long-loop__left__row__info">
                          <h3 className="font-bold size14 mb-0">{item.name}</h3>
                          <div className="let-long-loop__left__row__info__row">
                            <i
                            style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.getLocation(
                                  item.longitude,
                                  item.latitude,
                                  item.name,
                                  e
                                )
                              }
                              className="let-long-loop__left__row__icon"
                            >
                              <img src={locationIcon} alt="Location" />
                            </i>
                            <div className="let-long-loop__left__row__info__row__info size10">
                              <div className="mr-10">
                                <p className="mb-0">{translation('Latitude')}</p>
                                <p className="mb-0">
                                  {item.latitude.toFixed(11)}
                                </p>
                              </div>
                              <div className="mb-0">
                                <p className="mb-0">{translation('Longitude')}</p>
                                <p className="mb-0">
                                  {item.longitude.toFixed(11)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="let-long-loop__right">
                      <div className="fx-aic size10 mb-10">
                        <span translate="no">
                        {/* ${(profile.show)?18:this.state.total.toFixed(2)}  */}
                      ${item.amount.toFixed(2)}
                        </span>
                        <i className="p-5">x</i>
                        <select
                          data-id={item.id}
                          onChange={this.onChangeYear}
                          className="size10 p-5"
                        >
                          <option value="0_1">1 {translation('Year')}</option>
                          <option value="5_2">2 {translation('Year')} (5% off)</option>
                          <option value="10_3">3 {translation('Year')} (10% off)</option>
                          <option value="15_5">5 {translation('Year')} (15% off)</option>
                        </select>
                        <i className="p-5">=</i>
                        <span className="p-5" translate="no">$
                        {/* ${(profile.show)?18:this.state.total.toFixed(2)}  */}
                        {item.total.toFixed(2)}
                        </span>
                        <i>    
                          <img
                          style={{ cursor: "pointer" }}
                            data-id={item.id}
                            onClick={this.deleteFromCart}
                            src={iconDelete}
                            alt="Delete"
                            height="16px"
                          />
                        </i>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="grid-row mt-20">
                  <div className="col col-7"></div>
                  <div className="col col-5">
                    <div className="totalAmt">
                      <span className="totalAmt__title">{translation('Total amount')}:</span>
                      <span className="totalAmt__value" translate="no">
                        ${items['total'].toFixed(2)}
                        {/* ${(profile.show)?18:this.state.total.toFixed(2)} */}
                      </span>
                    </div>
                    <div className="fx" translate="no">
                      {this.props.role === 1100 ? (
                        <button
                          onClick={this.superAdminCheckout}
                          className="ant-btn buttons yellow-gradient xs rd-5"
                        >
                          Checkout
                        </button>
                      ) : (
                        <button
                           onClick={this.toggleClass}
                          className="ant-btn buttons yellow-gradient xs rd-5"
                        >
                          {translation('Pay')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        ) : (
          <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
            <div className="second-card register-card">
              <div className="fx-jcsb-aic mb-20">
                <h4 className="text-primary mb-0 font-bold">
                  {' '}
                  &#62; {pageName}
                </h4>
                <i onClick={this.closeRegisterPopUp} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
              <h2 style={{ fontWeight: 900, textAlign: 'center' }}>
               {translation('There are no Dome Addresses in the Cart')} 
              </h2>
            </div>
          </Row>


        )}

        <div className={!isActive ? "overlay" : "overlay opened"}></div> 
       <div
         className={!isActive ? "custom-modal xs" : "custom-modal xs opened"}
       >
          <div className="custom-modal__close" 
          // onClick={this.toggleClass}
          ></div>
          <div className="custom-modal__body">
            <p><span className='couponBadge'>COUPON</span> Free coupons available:{this.state.coupons}</p>
            <p>{translation("Would you like to pay with free coupon?")}</p>
            <div className="fx-jcsb">
              <button
                onClick={this.paymentHandler}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
                No thanks
                {/* <div className={!isActive ? "custom-modal xs" : "custom-modal xs opened"}></div> */}
              </button>
              <button
                // onClick={this.yesToDelete}
                className="ant-btn yellow-gradient xs rd-5"
                 onClick={() => this.paymentHandlerCoupon(this.state.price)}
              // onClick={() => handleCouponCartCheckout()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.userData.role,
  translation: state.userData.translation,
});

export default connect(mapStateToProps, null)(CartItems);