import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import {
  FileUpload,
  PublishImageWithUrl,
  loadDome,
  importDomeEditing,
} from '../../../../services/domeService';
import { baseURL } from '../../../../lib/config';
import { notification } from 'antd';

import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import {b} from '../../../dashboard/menu/i18next'

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};
const errorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

function ImageSection(props) {
  return (
    <div className="imgUrlLoop">
      <div onClick={props.removeUrlHandler} className="close">
        {' '}
        close{' '}
      </div>
      <ol className="editingList noStyle mb-30">
        <li className="editingList__item">
          <div className="editingList__item__row">
            <div className="editingList__item__row__left fullSide">
              <div className="grid-row xs">
                <div className="col col-2 fx-aic">
                  <label className="fileLabel">{b?.t('Image')}</label>
                </div>
                <div className="col col-4">
                  <input
                    type="text"
                    value={props.item.fileName}
                    className="form-control xs rd-0"
                    readOnly
                  />
                </div>
                <div className="col col-3">
                  <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn">
                    <input
                      onChange={props.handleFile}
                      name="file"
                      type="file"
                    />
                    {b?.t('Upload')}
                  </label>
                </div>
                <div className="col col-3">
                  <button className="ant-btn yellow-gradient xs">
                    {props.item.preview ? (
                      <img
                        className="ant-btn yellow-gradient xs"
                        width="50"
                        height="50"
                        src={props.item.preview}
                      />
                    ) : (
                      ''
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="editingList__item__row__action">
              <button className="iconx xs">
                <img src={editIcon} alt="Edit" height="14" />
              </button>
              <button className="iconx xs">
                <img src={deleteIcon} alt="Delete" height="14" />
              </button>
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
}

class DomeImage extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      inputList: [{ preview: '', image: '', fileName: '' }],
      addr_name: '',
    };
  }

  getimages = async () => {
    let previewUrl = baseURL + '/uploads/';
    const profile = await this.getContext();
    let id = profile.edit_current_dome.ID;

    let result = await loadDome(id);

    let inputList = [];
    for (let i = 0; i < result.file.length; i++) {
      var _inputList = {
        preview: previewUrl + result.file[i].file.id,
        fileName: result.file[i].file.name,
        file: result.file[i].file,
      };
      inputList.push(_inputList);
    }

    this.setState({ inputList: inputList });
  };

  componentDidMount = async () => {
    await this.getimages();
  };

  addMore = async (e: any) => {
    this.setState({
      inputList: [
        ...this.state.inputList,
        { preview: '', image: '', fileName: '' },
      ],
    });
  };

  removeUrlHandler = async (e, index) => {
    let list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({
      inputList: list,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  handleFile = async (e: any, index) => {
    if (e.target.files.length > 0) {
      let list = [...this.state.inputList];

      list[index]['image'] = e.target.files[0];
      list[index]['fileName'] = e.target.files[0].name;
      list[index]['preview'] = URL.createObjectURL(e.target.files[0]);
      const file = await FileUpload(e.target.files[0]);
      list[index]['file'] = file.file;

      this.setState({
        inputList: list,
      });
    }
  };
  publish = async () => {
    const profile = await this.getContext();
    let id = profile.edit_current_dome.ID;

    const inputList = this.state.inputList;
    let data = [];
    let errors = [];
    for (let i = 0; i < inputList.length; i++) {
      var file_exits = inputList[i].file.id || false;
      if (!file_exits) {
        errors.push({
          file: b?.t('Image should not be an empty.'),
        });
        continue;
      }
      var _data = {
        file: inputList[i].file,
      };
      data.push(_data);
    }
    if (data.length === 0) {
      return false;
    }
    // API Call
    const response = await PublishImageWithUrl(id, 2, data);
    showSuccessMessage(b?.t('Success', 'Image uploded successfully'));
  };

  importImage = async (e: any) => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    const addr_name = profile.edit_current_dome.addr_name.trim();
    const import_addr_name = this.state.addr_name.trim();
    if (addr_name == import_addr_name) {
      errorMessage(
        'Error',
        'Import dome address name should not be same as current dome address name.'
      );
      return false;
    }
    const type = 4;
    let importImageUrlData = await importDomeEditing( id, this.state.addr_name, type );
    await this.getimages();
  };

  inputHandler = (e: any) => {
    this.setState({
      addr_name: e.target.value,
    });
  };

  render() {
    const context = this.context;
    const inputList = this.state.inputList;
    return (
      <div id="tab-4" className="tab-pane ">
        <div className="mb-10">
          <i className="blueBox mr-5"></i>
          <span className="text-primary mr-5">{b?.t('Image editing')}</span>
          <i>
            <img src={infoIcon} alt="Info" height="14" />
          </i>
        </div>

        <div className="grid-row sm mb-10">
          <div className="col col-1">&nbsp;</div>
          <div className="col col-11 border-bottom pb-10">
            <div className="fx-aic mb-10">
              <span className="size12 mr-5">{b?.t('Import')}</span>
              <input
                type="text"
                value="Dome Address to import"
                className="form-control xs"
              />
              <input
                onChange={this.inputHandler}
                type="text"
                value={this.state.addr_name}
                className="form-control xs ml-5"
              />
              <button
                onClick={this.importImage}
                className="ant-btn primary-ghost-btn xs ml-5"
              >
                {b?.t('Verify')}
              </button>
            </div>
            <div className="fx-aic">
              <span className="size12 mr-5"> {b?.t('Not at this time')}</span>
              <input type="checkbox" />
            </div>
          </div>
        </div>

        <div className="addMoreImg">
          {inputList.map((item, index) => (
            <ImageSection
              key={index}
              handleFile={(e) => this.handleFile(e, index)}
              item={item}
              removeUrlHandler={(e) => this.removeUrlHandler(e, index)}
            />
          ))}
        </div>
        <div className="fx-jcfe mt-10 mb-10">
          <button onClick={this.addMore} className="ant-btn addMore">
            +
          </button>
        </div>

        <div className="grid-row fx-jcc">
          <div className="col col-6 fx-jcsb">
            <button
              onClick={(e) =>
                this.props.closeContent(e, { activeImages: false })
              }
              className="ant-btn primary-ghost-btn xs"
            >
             {b?.t('Cancel')} 
            </button>
            <button
              onClick={this.publish}
              className="ant-btn yellow-gradient xs"
            >
             {b?.t('Publish')}  
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DomeImage;
