import React, { Component } from 'react';
import {
  Row,
  Button,
  Typography,
  Form,
  Select,
  Input,
  Radio,
  DatePicker,
  Col,
  Checkbox,
  Tag,
} from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import RadioGroup from 'antd/lib/radio/group';
import calendar from '../../../../assets/icon_date.svg';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import { menuConstants } from '../../../../constants/menuConstants';
import refreshIcon from '../../../../assets/location_refresh_icon.svg';
import loamoaIcon from '../../../../assets/logo_push.svg';
import { FormComponentProps } from 'antd/lib/form';
import {
  createNotification,
  getAllUsersList,
  getIdolUsersList,
  updateNotification,
} from '../../../../services/notificationsService';
import { searchUsers, searchContesants } from '../../../../lib/SubMenuConstants';
import { getContest } from '../../../../services/contestService';
import { contestConstants } from '../../../../constants/contestConstants';
import moment from 'moment';
import {
  all,
  loaMoaIdol,
  typeContestants,
  typeVoters,
  audience,
  selected,
  browseButton,
  selectAll,
  saveButton,
  cancelButton,
  previewLabel,
  optionSchedule,
  optionImmediately,
  schedule,
  url,
  generalLabel,
  android,
  ios,
  titleEdit,
  createNotif,
  urlValidation,
  msgValidation,
  nameValidation,
  idolValidation,
  typeValidation,
  noContestants,
  votedForLabel,
  now,
  groupValidation,
  titleText,
} from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';
import * as notificationActions from '../../../../actions/notificationsActions';

import { LanguageSelector } from '../../../../components';

interface Props extends FormComponentProps {
  isEditing?: boolean;
  handleClose?: () => Action;
  title?: string;
  category?: string;
  userIDs?: Array<any>;
  description?: string;
  url?: string;
  timestamp?: number;
  whenToSend?: number;
  handleSelect: (category: number) => Action;
  handleInput: (param: string, value: string) => Action;
  handleDates: (timestamp: number) => Action;
  notification: any;
  audienceType: number;
  changeWhenToSend: (value: number) => Action;
  openUserList: (menu: string) => Action;
  storeUsers: (users: Array<any>) => Action;
  storeContests: (response: any) => Action;
  contestList: Array<any>;
  storeSelectedContestID: (ID: number) => Action;
  contestID: number;
  areAllUsersSelected: boolean;
  handleAllUsers: (areAllUsersSelected: boolean) => Action;
  storeContestUserType: (contestUserType: string) => Action;
  contestUserType: string;
  contestantIDs: Array<any>;
  getContestants: (list: any) => Action;
  contestants: Array<any>;
  clearUsers: () => Action;
  language: languages;
  type: string;
  setType: (type: string) => Action;
  clear: () => Action;
  updateNotifications: (value: boolean) => void;
  updateNotificationLocalization: any;
  notificationLocalization: any;
}

interface State {
  preview: string;
}

const mapState = (state: {
  notification: any;
  contestList: Array<any>;
  contestantsList: any;
  authentication: any;
  notificationLocalization: any;
}) => {
  //renameati sutra propse!!
  return {
    url: state.notification.url,
    title: state.notification.title,
    notification: state.notification,
    category: state.notification.category,
    whenToSend: state.notification.whenToSend,
    audienceType: state.notification.audienceType,
    timestamp: state.notification.timestamp,
    contestList: state.contestList,
    contestID: state.notification.contestID,
    areAllUsersSelected: state.notification.areAllUsersSelected,
    userIDs: state.notification.userIDs,
    contestUserType: state.notification.contestUserType,
    contestantIDs: state.notification.contestantIDs,
    contestants: state.contestantsList,
    isEditing: state.notification.isEditing,
    description: state.notification.description,
    language: state.authentication.language,
    type: state.notification.type,
    notificationLocalization: state.notificationLocalization,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    handleInput: (param: string, value: string) =>
      dispatch({ type: notificationsConstants.NOTIFICATION_INPUT, param, value }),
    handleSelect: (category: number) =>
      dispatch({ type: notificationsConstants.NOTIFICATION_TYPE, category }),
    handleDates: (timestamp: number) =>
      dispatch({ type: notificationsConstants.NOTIFICATION_DATE, timestamp }),
    changeWhenToSend: (value: number) =>
      dispatch({ type: notificationsConstants.WHEN_TO_SEND, value }),
    openUserList: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_2, menu }),
    storeUsers: (users: Array<any>) =>
      dispatch({ type: notificationsConstants.STORE_USERS, users }),
    storeContests: (response: any) => dispatch({ type: contestConstants.STORE_CONTESTS, response }),
    storeSelectedContestID: (ID: number) =>
      dispatch({ type: notificationsConstants.STORE_CONTEST_ID, ID }),
    handleAllUsers: (areAllUsersSelected: boolean) =>
      dispatch({ type: notificationsConstants.ALL_SELECTED, areAllUsersSelected }),
    storeContestUserType: (contestUserType: string) =>
      dispatch({ type: notificationsConstants.CONTEST_USER_TYPE, contestUserType }),
    getContestants: (list: any) => dispatch({ type: contestConstants.SHOW_CONTESTANTS_LIST, list }),
    clearUsers: () => dispatch({ type: notificationsConstants.CLEAR_USERS }),
    setType: (notificationType: string) =>
      dispatch({ type: notificationsConstants.TYPE_BACKEND, notificationType }),
    clear: () => dispatch({ type: notificationsConstants.NOTIFICATIONS_INITIAL }),
    updateNotifications: (value: boolean) =>
      dispatch(notificationActions.updateNotifications(value)),
    updateNotificationLocalization: (language: string, value: any) =>
      dispatch(notificationActions.updateNotificationLocalization(language, value)),
  };
};

class NotificationForm extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      preview: 'android',
    };
  }

  componentDidMount = async () => {
    if (!this.props.timestamp) this.props.handleDates(Date.now());
    if (this.props.isEditing) {
      if (this.props.category === 'idol') {
        const contestList = await getContest();
        this.props.storeContests(contestList);
      }
      this.props.form.setFieldsValue({ title: this.props.title });
      this.props.form.setFieldsValue({ message: this.props.description });
      this.props.form.setFieldsValue({ deeplink: this.props.url });
      this.props.form.setFieldsValue({ category: this.props.category });
      this.props.form.setFieldsValue({ idol: this.props.contestID });
      this.props.form.setFieldsValue({ time: moment(this.props.timestamp) });
      this.props.form.setFieldsValue({
        group: this.props.notification.type === 'contest-voters-all' ? 'voters' : 'contestants',
      });
    }
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.isEditing !== this.props.isEditing) {
      if (this.props.isEditing === false) {
        this.props.form.setFieldsValue({ title: '' });
        this.props.form.setFieldsValue({ message: '' });
        this.props.form.setFieldsValue({ deeplink: '' });
      }
    }
  };

  handleDates = (e: any, id = 'string') => {
    const unixTimestamp = new Date(e._d).getTime();
    this.props.handleDates(unixTimestamp);
  };

  handleSelect = async (e: any) => {
    this.props.handleSelect(e);
    if (e === 'idol') {
      const contestList = await getContest();
      this.props.storeContests(contestList);
    }
  };

  handleUsers = () => {
    if (!this.props.areAllUsersSelected) {
      if (this.props.contestUserType === 'voters' && this.props.contestantIDs.length > 0) {
        this.props.storeContestUserType('votersOfContestants');
      }
      this.props.openUserList(searchUsers);
    } else {
      this.props.clearUsers();
    }
  };

  openContestantsList = () => {
    this.props.openUserList(searchContesants);
  };

  handleSelectContest = (e: any) => {
    this.props.storeSelectedContestID(e);
  };

  handleButtons = async (e: any) => {
    this.props.storeContestUserType(e.target.value);
    if (e.target.value === 'contestants') {
      const contestants = await getIdolUsersList(1, this.props.contestID, e.target.value);
      this.props.getContestants(contestants);
      this.props.setType('contest-contestants-all');
    } else {
      const res = await getIdolUsersList(1, this.props.contestID, e.target.value);
      this.props.storeUsers(res);
      this.props.setType('contest-voters-all');
    }
  };

  handleInput = (e: any) => {
    const { handleInput, updateNotificationLocalization } = this.props;
    if (e.target.name === 'url') {
      let link = e.target.value;
      link = link.replace(/^/, 'https://');
      handleInput(e.target.name, link);
    } else {
      handleInput(e.target.name, e.target.value);
      updateNotificationLocalization(this.props.notification.language, {
        [e.target.name]: e.target.value,
      });
    }
  };

  appLocalizationToApiLocalization = () => {
    const { notificationLocalization } = this.props;
    const languages = Object.keys(notificationLocalization);
    const vaulesForLocalization = ['title', 'description'];
    let apiLocalization = {};
    vaulesForLocalization.map((section) => {
      let sectionDetails = {};
      languages.map((language) => {
        sectionDetails = {
          ...sectionDetails,
          [language]: notificationLocalization[language][section],
        };
      });
      apiLocalization = { ...apiLocalization, [section]: { ...sectionDetails } };
    });
    return apiLocalization;
  };

  handleSubmit = async () => {
    const { isEditing, notificationLocalization, notification } = this.props;
    this.appLocalizationToApiLocalization();
    this.props.form.validateFields();
    let validationErrors = Object.values(
      this.props.form.getFieldsValue(['title', 'message', 'category'])
    );
    if (this.props.category === 'idol') {
      validationErrors = Object.values(
        this.props.form.getFieldsValue(['title', 'message', 'category', 'idol'])
      );
    }
    if (!validationErrors.some((e) => e === undefined)) {
      let time;
      if (this.props.whenToSend === 1) {
        time = Date.now();
      } else {
        time = this.props.timestamp;
      }

      let localizedNotification = { ...notification };
      if (notificationLocalization.en) {
        localizedNotification = {
          ...notification,
          title: notificationLocalization.en.title,
          description: notificationLocalization.en.description,
        };
      }

      const localization = this.appLocalizationToApiLocalization();
      if (isEditing) {
        await updateNotification(localizedNotification, time, localization);
      } else {
        await createNotification(localizedNotification, time, localization);
      }
      this.props.clear();
      this.props.updateNotifications(true);
      this.props.handleClose();
    }
  };

  chooseWhenToSend = (e: any) => {
    this.props.changeWhenToSend(e.target.value);
  };

  handleRadio = (e: any) => {
    this.setState({
      preview: e.target.value,
    });
  };

  handleLanguageSelect = (value: string) => {
    const { handleInput, isEditing, form, notificationLocalization } = this.props;
    handleInput('language', value);

    if (!notificationLocalization[value.toLocaleLowerCase()]) {
      form.setFieldsValue({ title: '' });
      form.setFieldsValue({ message: '' });
    } else {
      form.setFieldsValue({ title: notificationLocalization[value.toLocaleLowerCase()].title });
      form.setFieldsValue({
        message: notificationLocalization[value.toLocaleLowerCase()].description,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { notificationLocalization, notification } = this.props;
    const selectedLanguage = notification.language || 'EN';

    // not working propperly when creating new notifications
    const contestUserType = notification.type === 'contest-voters-all' ? 'voters' : 'contestants';

    return (
      <Row className="kit-list-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="start" align="middle">
            <Button onClick={this.props.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {this.props.isEditing
                ? titleEdit[this.props.language]
                : createNotif[this.props.language]}
            </Typography.Text>
          </Row>
          <Row type="flex" justify="center">
            <Form>
              <Form.Item label="Category">
                <Row>
                  {getFieldDecorator('category', {
                    rules: [{ required: true, message: typeValidation[this.props.language] }],
                  })(
                    <Select
                      placeholder="Select"
                      onChange={this.handleSelect}
                      className="select-treasure-type treasure-type-picker"
                    >
                      <Select.Option value="all" key="all">
                        {all[this.props.language]}
                      </Select.Option>
                      <Select.Option value="idol" key="idol">
                        {loaMoaIdol[this.props.language]}
                      </Select.Option>
                    </Select>
                  )}
                </Row>
              </Form.Item>

              {this.props.category === 'idol' ? (
                <>
                  <Form.Item label="Item">
                    <Row type="flex" justify="center">
                      {getFieldDecorator('idol', {
                        rules: [{ required: true, message: idolValidation[this.props.language] }],
                      })(
                        <Select
                          placeholder="Select idol"
                          onChange={this.handleSelectContest}
                          className="select-treasure-type treasure-type-picker"
                        >
                          {this.props.contestList.map((contest) => {
                            return (
                              <Select.Option key={contest.ID} value={contest.ID}>
                                {contest.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('group', {
                      rules: [{ required: false, message: groupValidation[this.props.language] }],
                    })(
                      <Row type="flex" justify="space-around" className="notif-radio">
                        {this.props.contestID ? (
                          <Radio.Group value={contestUserType} onChange={this.handleButtons}>
                            <Radio.Button value="voters">
                              {typeVoters[this.props.language]}
                            </Radio.Button>
                            <Radio.Button value="contestants">
                              {typeContestants[this.props.language]}
                            </Radio.Button>
                          </Radio.Group>
                        ) : null}
                      </Row>
                    )}
                  </Form.Item>
                </>
              ) : null}

              {/* {this.props.contestUserType === 'voters' ?
                                <>
                                    <Row type='flex' justify='space-between' style={{ marginBottom: '1em' }}>
                                        <Typography.Text className='contest-title bold'>
                                            {votedForLabel[this.props.language]}
                                    </Typography.Text>


                                        <div className={'all-prices'} onClick={this.openContestantsList}>
                                            {typeContestants[this.props.language]}
                                </div>
                                    </Row>
                                    {this.props.contestantIDs.length > 0 ? <Row>
                                        {this.props.contestants.map((contestant) => {
                                            if (this.props.contestantIDs.includes(contestant.mysqlID)) {
                                                return <Tag key={contestant.mysqlID}>{contestant.username}</Tag>
                                            }
                                        })}
                                    </Row> : <Typography.Text>{noContestants[this.props.language]}</Typography.Text>}

                                </> : null} */}

              <Row className="margin-form">
                <Row type="flex" justify="space-between" style={{ marginBottom: '1em' }}>
                  <Typography.Text className="contest-title bold">
                    {audience[this.props.language]}
                  </Typography.Text>

                  <div
                    className={this.props.areAllUsersSelected ? 'disabled-text' : 'all-prices'}
                    onClick={this.handleUsers}
                  >
                    {browseButton[this.props.language]}
                  </div>
                </Row>
                <Row>
                  {this.props.userIDs.length} {selected[this.props.language]}
                </Row>
                <Row>
                  <Checkbox
                    checked={this.props.areAllUsersSelected}
                    onChange={() => this.props.handleAllUsers(!this.props.areAllUsersSelected)}
                  />{' '}
                  {selectAll[this.props.language]}
                </Row>
              </Row>

              <Row type="flex" justify="start">
                <Typography.Text className="contest-title bold">
                  {generalLabel[this.props.language]}
                </Typography.Text>
              </Row>

              <LanguageSelector
                containerStyle={{ paddingTop: '22px' }}
                onChange={this.handleLanguageSelect}
                defaultValue={this.props.language || 'EN'}
              />

              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [{ required: true, message: nameValidation[this.props.language] }],
                })(
                  <Input
                    required={true}
                    onBlur={this.handleInput}
                    name="title"
                    placeholder="Add title"
                    className="input-treasure full-width"
                  />
                )}
              </Form.Item>

              <Form.Item label="Message">
                {getFieldDecorator('message', {
                  rules: [{ required: true, message: msgValidation[this.props.language] }],
                })(
                  <Input.TextArea
                    required
                    maxLength={200}
                    onBlur={this.handleInput}
                    name="description"
                    placeholder="Add message"
                  />
                )}
              </Form.Item>

              <Row type="flex" justify="start" className="margin-form">
                <Row style={{ marginBottom: '1em' }}>
                  <Typography.Text className="contest-title bold">
                    {url[this.props.language]}
                  </Typography.Text>
                </Row>
                <Row style={{ width: '100%' }}>
                  <Form.Item>
                    {getFieldDecorator('deeplink', {
                      rules: [{ required: false, message: urlValidation[this.props.language] }],
                    })(
                      <Input
                        name="url"
                        addonBefore="https://"
                        onBlur={this.handleInput}
                        className="full-width link-input"
                      />
                    )}
                  </Form.Item>
                </Row>
              </Row>

              <Row type="flex" justify="start" className="margin-form radio-btn">
                <Row className="full-width">
                  <Typography.Text className="contest-title bold">
                    {schedule[this.props.language]}
                  </Typography.Text>
                </Row>
                <RadioGroup
                  name="radiogroup"
                  value={this.props.whenToSend}
                  onChange={this.chooseWhenToSend}
                  style={{ marginBottom: '1em' }}
                >
                  <Radio className="full-width" value={1}>
                    {optionImmediately[this.props.language]}
                  </Radio>

                  {/* <Radio className='full-width' value={2}>{optionSchedule[this.props.language]}</Radio> */}
                </RadioGroup>

                {/* <Row>
                                    <Form.Item>
                                        {getFieldDecorator('time')(
                                            <DatePicker
                                                disabled={this.props.whenToSend === 1}
                                                showTime={true}
                                                format={"DD/MM/YY hh:mm A"}
                                                className='date'
                                                name='schedule'
                                                suffixIcon={<img src={calendar} />}
                                                onChange={(e) => this.handleDates(e, 'schedule')} />)}
                                    </Form.Item>
                                </Row> */}
              </Row>

              <Row type="flex" justify="start">
                <Typography.Text className="contest-title bold">
                  {previewLabel[this.props.language]}
                </Typography.Text>
              </Row>
              <Row type="flex" justify="space-between" className="notif-radio">
                <Col>
                  <Radio.Group onChange={this.handleRadio} value={this.state.preview}>
                    <Radio.Button value="android">{android[this.props.language]}</Radio.Button>
                    <Radio.Button value="ios">{ios[this.props.language]}</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col>
                  <Button className="location-button">
                    <img src={refreshIcon} />
                  </Button>
                </Col>
              </Row>
              <Row type="flex" justify="center" className="full-width">
                {this.state.preview === 'android' ? (
                  <Row className="notification-preview android">
                    <Row>
                      <img src={loamoaIcon} />
                      <Typography.Text className="notif-head-android">LoaMoa </Typography.Text>
                      <Typography.Text>
                        {moment(this.props.timestamp, 'x').format('h:mm A')}
                      </Typography.Text>
                    </Row>
                    <Row className="notification-title">
                      {notificationLocalization[selectedLanguage.toLowerCase()]?.title ||
                        titleText[this.props.language]}
                    </Row>
                    <Row>
                      {notificationLocalization[selectedLanguage.toLowerCase()]?.description}
                    </Row>
                  </Row>
                ) : (
                  <Row className="notification-preview ios">
                    <Row type="flex" justify="space-between">
                      <Col>
                        <img src={loamoaIcon} />
                        <Typography.Text className="notif-head-ios">LOAMOA</Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text className="notif-head-ios">
                          {this.props.timestamp !== Date.now()
                            ? now[this.props.language]
                            : moment(this.props.timestamp, 'x').format('h:mm A')}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row className="notification-title">
                      {notificationLocalization[selectedLanguage.toLowerCase()]?.title ||
                        titleText[this.props.language]}
                    </Row>
                    <Row>
                      {notificationLocalization[selectedLanguage.toLowerCase()]?.description}
                    </Row>
                  </Row>
                )}
              </Row>
            </Form>
          </Row>
          <Row type="flex" justify="space-around" style={{ padding: '25px' }}>
            <Button onClick={this.props.handleClose} className="transparent-btn-black">
              {cancelButton[this.props.language]}
            </Button>
            <Button onClick={this.handleSubmit} className="buttons purple-gradient-right">
              {saveButton[this.props.language]}
            </Button>
          </Row>
        </div>
      </Row>
    );
  }
}

const wrappedNotificationCreationForm = Form.create({ name: 'notification' })(NotificationForm);
export default connect(mapState, mapDispatch)(wrappedNotificationCreationForm);
