import React from 'react';
import Dropzone from 'react-dropzone';

import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { baseURL } from '../../../../lib/config';

import burgerKing from '../../../../../src/assets/burgerKing.png';
import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import logoPic from '../../../../../src/assets/logoPic.png';
import backLogoForHall from '../../../../../src/assets/dome-full-preview-latest.png';
import backLogoForArena from '../../../../../src/assets/dome-full-preview-arena.png';
import {b} from '../../../dashboard/menu/i18next'
import {
  FileUpload,
  getDomeTypes,
  UpdateSetting,
} from '../../../../services/domeService';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { blobToFile, getCroppedImg } from '../../../../helpers/ImageCropping';
import { connect } from 'react-redux';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

class LobbyLogo extends React.Component<any, any> {
  private imageElement: React.RefObject<HTMLImageElement>;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      src: '',
      type: '',
      crop: {
        unit: 'px', // default, can be 'px' or '%'
        x: 10,
        y: 50,
        width: 100,
        height: 100,
        img:false
      },
    };
    this.imageElement = React.createRef();
  }

  onChange = (crop) => {
    this.setState({ crop });
  };

  onImageLoaded = (image) => {
    this.imageElement = image;
  };

  onComplete = async (crop) => {
    const croppedFile = await getCroppedImg(
      this.imageElement,
      crop,
      this.state.fileName,
      this.state.type
    );
    const file = blobToFile(croppedFile, this.state.fileName);
    const result = await FileUpload(file);
    let resultfile = result.file;

    preview: baseURL + '/uploads/' + resultfile.id;
    let preview = URL.createObjectURL(croppedFile);
    this.setState({
      preview: preview,
      file: resultfile,
    });
  };
  componentDidMount = async () => {
    const profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};

    this.setState({
       image: current_edit_dome.lobbylogo,
       fileName: current_edit_dome.lobbylogo.name,  
      preview: current_edit_dome.lobbylogo.id
        ? baseURL + '/uploads/' + current_edit_dome.lobbylogo.id
        : null,
    });
    
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  // oNDropFileHandler = async(files) =>{
  // 	let file = files[0];
  // 	let image = file;
  // 	let fileName = file.name;
  // 	let preview = URL.createObjectURL(file);
  // 	const result = await FileUpload(file);
  // 	let resultfile = result.file;

  // 	this.setState({
  // 		image: image,
  // 		fileName:fileName,
  // 		preview: preview,
  // 		file: resultfile
  // 	});

  // 	// console.log(this.state.preview);
  // }

  publish = async () => {
    if (!this.state.file) {
      showErrorMessage(b?.t('File not uploaded.'));
      return false;
    }
    let profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;

    let obj = {
      loby_logo: this.state.file,
    };
   
    const res = await UpdateSetting(id, obj);
    const domeSetting = await getDomeTypes(id);
   

    profile.domeTypeSetting = domeSetting.dome_type_setting;
    current_edit_dome.lobbylogo = this.state.file;
    profile.current_edit_dome = current_edit_dome;
    await this.update(profile);
    showSuccessMessage(b?.t('Dome updated successfully'));
    this.props.closeLobbyLogo();
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
    await this.updateContext(profile);
  };

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result = await FileUpload(e.target.files[0]);
      let file = result.file;
      

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
        src: preview,
        type: image.type,
      });
    }
  };

  renderBackgroundUrl = (template_type) => {
    if (template_type === 'A') {
      return `${backLogoForHall}`;
    }
    return `${backLogoForArena}`;
  };

  render() {
    const { translation } = this.props
    const context = this.context;
    let profile = context.profile || {};
    let template_type = '';
    let domeTypeSetting =
      profile && profile.domeTypeSetting && profile.domeTypeSetting.length > 0
        ? profile.domeTypeSetting
        : [];
    if (domeTypeSetting && domeTypeSetting.length !== 0) {
      for (let i = 0; i < domeTypeSetting.length; i++) {
        if (domeTypeSetting[i]['name'] === 'Hall') {
          template_type = 'A';
        }
        if (domeTypeSetting[i]['name'] === 'Arena') {
          template_type = 'B';
        }
      }
    }
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        {console.log("props123",this.props)}
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62;{ translation('Lobby Logo')}</h4>
            <i onClick={this.props.closeLobbyLogo} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">{ translation('Logo file upload')}</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value={this.state.fileName}
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input
                        onChange={this.fileHandler}
                        type="file"
                        name="file"
                        className="d-none"
                      />
                     { translation('Upload')} 
                    </label>
                  </div>
                </div>
              </div>
              {/* <button onClick= {()=>{this.props.img? this.setState({preview:''}):"" }} >deltr</button> */}

              <div className="col col-4 text-center">
                <figure className="photoPrev">
                  <ReactCrop
                    src={this.state.src}
                    onComplete={this.onComplete}
                    onImageLoaded={this.onImageLoaded}
                    crop={this.state.crop}
                    onChange={(newCrop) => this.onChange(newCrop)}
                  />
                </figure>
                <h5>{translation('Drag and fit')}</h5>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary weight-700">{translation('Preview')}</h3>
            </div>

            <div
              style={{
                backgroundImage: `url(${this.renderBackgroundUrl(
                  template_type
                )})`,
              }}
              className="dome-full-preview-new"
            >
              <div
                style={{
                  transform:
                    template_type === 'A'
                      ? 'translateY(-82px) translateX(-6px)'
                      : 'translateY(-82px) translateX(-3px)',
                  height: '45px',
                  width: '45px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #0000004a',
                  // boxShadow: '0px 2px 8px 0px',
                  // background: '#fff',
                }}
              >
                {console.log(this.state.preview)}
                <img
                  // style={{
                  //   transform:
                  //     template_type === 'A'
                  //       ? 'translateY(-82px) translateX(-6px)'
                  //       : 'translateY(-82px) translateX(-3px)',
                  // }}
                  src={this.state.preview ? this.state.preview : logoPic}
                  alt="Logo"
                  height="40"
                />
              </div>
            </div>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button
                  onClick={(e) => this.props.closeLobbyLogo(e)}
                  className="ant-btn primary-ghost-btn xs"
                >
                 {translation('Cancel')} 
                </button>
                <button
                  onClick={this.publish}
                  className="ant-btn yellow-gradient xs"
                >
                 {translation('Edit')}  
                </button>
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (LobbyLogo);
