import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { alert } from './alertReducer';
import { authentication, initialStateAuth } from './authenticationReducer';
import {
  menuUIChange,
  openLocationUIs,
  isSubMenu1Open,
  subMenu1,
  isSubMenu2Open,
  subMenu2,
  initialStateMenu,
  isSubMenuFullScreenOpen,
  subMenuFullScreen,
  menu,
} from './menuReducer';
import {
  treasure,
  address,
  locations,
  initialLocations,
  messageLocations,
  selectedLocation,
  treasureUnitsRandomNumbers,
  treasureUnitsLocation,
  treasureUnitsResponse,
  treasureUnitsSent,
  characters,
  isPaymentInitiated,
  treasureUnitCandy,
  initialStateTreasure,
  treasureErrors,
  initialTreasureErrors,
} from './treasureReducer';
import { userData } from './userReducer';
import * as constant from '../lib/const';
import {
  treasureDetails,
  clients,
  treasureList,
  treasureListInitialState,
} from './treasureDetailsReducer';
import {
  contest,
  contestList,
  contestDetails,
  contestantsList,
  contestant,
  initialStateContest,
  contestants,
  initialContestants,
} from './contestReducer';
import { kit, kitList, kitDetails, exchangeRate, initialStateKit } from './kitReducer';
import { recharge,plumsCoins } from './rechargeReducer';
import {
  notificationsList,
  notification,
  userList,
  notificationDetails,
  initialStateNotification,
  notificationsLogic,
  notificationsLogicInitialState,
  notificationLocalization,
} from './notificationsReducer';
import { userConstants } from '../constants/signinConstants';
import {
  character,
  characterList,
  characterDetails,
  initialStateCharacter,
  characterStoryLocalization,
} from './characterReducer';

import { candyEvent, initialCandyEvent } from './candyEventReducer';
import { cashExchange, initialCashExchange } from './cashExchangeReducer';
import { partnerRequests, initialPartnerRequests } from './partnerRequestsReducer';

const createAppReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    alert,
    authentication,
    menuUIChange,
    treasure,
    locations,
    address,
    messageLocations,
    selectedLocation,
    treasureUnitsRandomNumbers,
    treasureUnitsLocation,
    treasureUnitsResponse,
    treasureUnitsSent,
    userData,
    characters,
    treasureDetails,
    clients,
    treasureList,
    isPaymentInitiated,
    openLocationUIs,
    treasureUnitCandy,
    character,
    characterList,
    characterDetails,

    contest,
    contestList,
    contestDetails,
    contestantsList,
    contestant,
    contestants,

    isSubMenu1Open,
    subMenu1,
    kit,
    isSubMenu2Open,
    subMenu2,
    isSubMenuFullScreenOpen,
    subMenuFullScreen,
    kitList,
    kitDetails,
    exchangeRate,
    recharge,
    plumsCoins,
    notificationsList,
    notification,
    userList,
    notificationDetails,
    notificationsLogic,
    characterStoryLocalization,
    notificationLocalization,
    menu,
    treasureErrors,
    candyEvent,
    cashExchange,
    partnerRequests,
  });

let emptyArr = new Array();

export const initialState: any = {
  alert: {},
  authentication: {},
  menuUIChange: initialStateMenu,
  treasure: initialStateTreasure,
  address: {
    lat: constant.DefaultLat,
    lng: constant.DefaultLng,
    radius: constant.DefaultRadius,
    zoom: constant.DefaultZoom,
  },
  locations: initialLocations,
  messageLocations: emptyArr,
  selectedLocation: 0,
  treasureUnitsRandomNumbers: {},
  treasureUnitsLocation: {},
  treasureUnitsResponse: {},
  treasureUnitsSent: emptyArr,
  userData: {
    role: constant.roleMaster,
    books:{},
    coupons:{}
  },
  characters: emptyArr,
  treasureDetails: {},
  clients: emptyArr,
  isPaymentInitiated: false,
  openLocationUIs: emptyArr,
  treasureUnitCandy: emptyArr,
  characterList: emptyArr,
  characterDetails: {},

  contestList: emptyArr,
  contestDetails: {},
  contestantsList: emptyArr,
  contestant: {},
  contest: initialStateContest,
  contestants: initialContestants,

  character: initialStateCharacter,
  isSubMenu1Open: false,
  subMenu1: '',
  kit: initialStateKit,
  isSubMenu2Open: false,
  subMenu2: '',
  kitList: emptyArr,
  kitDetails: {},
  exchangeRate: {},
  recharge: {},
  plumsCoins:{},
  notificationsList: emptyArr,
  notification: initialStateNotification,
  userList: emptyArr,
  notificationDetails: {},
  notificationsLogic: notificationsLogicInitialState,
  treasureList: treasureListInitialState,
  candyEvent: initialCandyEvent,
  treasureErrors: initialTreasureErrors,
  cashExchange: initialCashExchange,
  partnerRequests: initialPartnerRequests,
};

const createRootReducer = (history: any) => (state: any, action: any) => {
  if (action.type === userConstants.RESET) {
    return createAppReducer(history)(initialState, action);
  } else {
    return createAppReducer(history)(state, action);
  }
};

export default createRootReducer;
