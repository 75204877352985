import { DatePicker, Form, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import closeIcon from '../../../../../../src/assets/closeXsmall.svg';
import calendar from '../../../../../assets/icon_date.svg';
// import "../node_modules/video-react/dist/video-react.css";
import '../../../../../../node_modules/video-react/dist/video-react.css';

import {
  Player,
  ControlBar,
  BigPlayButton,
  VolumeMenuButton,
  ReplayControl,
  ForwardControl,
  PlayToggle,
} from 'video-react';
import moment from 'moment';
import { FileUpload } from '../../../../../services/domeService';
import { baseURL } from '../../../../../lib/config';
import { useTranslation} from "react-i18next";

type ImagePreviewModalProps = {
  closeModal: () => void;
  arEditImagePreview: string;
  setValuesForarshowDataOnSave: (
    ind: number,
    values: {
      startDate: any;
      endDate: any;
      videoName: any;
    },
    videores: any,
    updateId: any
  ) => void;
  index: number;
  loadAgain: boolean;
  selectedEditDataForArShow: {
    start_date: string;
    end_date: string;
    name: string;
    videoUrlBac: string;
    file: any;
    updateId: any;
  };
};

const ArEditModal = (props: ImagePreviewModalProps) => {
  const playerRef = useRef<any>();
  const imageReplayRef = useRef<any>();
  const [videoUrl, setVideoUrl] = useState<any>('');
  const [videoResFile, setVideoResFile] = useState({});
  const [animateClass, setAnimateClass] = useState('image-animate-arshow');
   const { t } = useTranslation();
  const [state, setState] = useState<{
    startDate: any;
    endDate: any;
    videoName: any;
  }>({
    startDate: '',
    endDate: '',
    videoName: '',
  });

  useEffect(() => {
    if (
      props.selectedEditDataForArShow &&
      Object.keys(props.selectedEditDataForArShow).length !== 0
    ) {
      const { start_date, end_date, name, videoUrlBac, file } =
        props.selectedEditDataForArShow;
      setState({
        startDate: moment(start_date),
        endDate: moment(end_date),
        videoName: name,
      });
      setVideoUrl(videoUrlBac);
      setVideoResFile(file);
    } else {
      setState({
        startDate: '',
        endDate: '',
        videoName: '',
      });
      setVideoUrl('');
      setVideoResFile(null);
    }
    setAnimateClass('');
    setTimeout(() => {
      setAnimateClass('image-animate-arshow');
    }, 0);
  }, [props.index]);

  const onVideoFileUpload = async (e) => {
    e.persist();
    const res = await FileUpload(e.target.files[0]);
    if (res && res.file) {
      setVideoResFile(res.file);
    }
    var reader = new FileReader();

    console.log(e.target.files[0]);

    setState({
      ...state,
      videoName: e.target.files[0].name,
    });
    var url = window.URL.createObjectURL(
      new Blob(e.target.files, { type: 'application/zip' })
    );
    setVideoUrl(url);
  };

  const onReplayClick = () => {
    playerRef?.current?.actions.play();
    // playerRef?.current?.actions.replay(1000);
    let src = imageReplayRef?.current?.src;
    if (imageReplayRef && imageReplayRef.current) {
      imageReplayRef.current.src = '';
    }

    setAnimateClass('');
    setTimeout(() => {
      setAnimateClass('image-animate-arshow');
      if (imageReplayRef && imageReplayRef.current) {
        imageReplayRef.current.src = src;
      }
    }, 0);
    // image-animate-arshow
  };

  const onDateChange = (e: moment.Moment, name: string) => {
    setState({
      ...state,
      [name]: e,
    });
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current <= moment(state.startDate).add(1, 'days');
  }
  function disableStartDate(current) {
    // Can not select days before today and today
    return current > moment(state.endDate).subtract(1, 'days');
  }

  const onSaveClick = async () => {
    const { updateId } = props.selectedEditDataForArShow;
    await props.setValuesForarshowDataOnSave(
      props.index,
      state,
      videoResFile,
      updateId
    );
    props.closeModal();
  };

  const renderVideoOrWebp = () => {
    if (videoResFile && videoResFile.mimeType) {
      return (
        <>
          {videoResFile &&
          (videoResFile.mimeType === 'image/webp' ||
            videoResFile.mimeType === 'image/gif') ? (
            <picture style={{ border: '1px solid silver' }}>
              <source
                srcSet={`${baseURL}/uploads/${videoResFile.id}`}
                type="image/webp"
              />
              <source
                srcSet={`${baseURL}/uploads/${videoResFile.id}`}
                type="image/jpeg"
              />
              <source
                srcSet={`${baseURL}/uploads/${videoResFile.id}`}
                type="image/gif"
              />
              <img
                className={animateClass}
                ref={imageReplayRef}
                // className="image-animate-arshow"
                width={160}
                height={300}
                style={{ objectFit: 'contain' }}
                src={`${baseURL}/uploads/${videoResFile.id}`}
                alt="Alt Text!"
              />
            </picture>
          ) : (
            // ) : videoUrl ? (
            //   <Player
            //     playsInline
            //     src={videoUrl}
            //     fluid={false}
            //     width={160}
            //     height={300}
            //     ref={playerRef}
            //   >
            //     <BigPlayButton position="center" />
            //     <ControlBar autoHide={true} disableDefaultControls={true}>
            //       <PlayToggle />
            //       <ReplayControl seconds={5} order={2.1} />
            //       <ForwardControl seconds={5} order={3.1} />
            //       <VolumeMenuButton vertical />
            //     </ControlBar>
            //   </Player>
            // )
            <div
              style={{
                height: '300px',
                width: '160px',
                background: 'silver',
                margin: 'auto',
              }}
            ></div>
          )}
        </>
      );
    } else if (
      props.selectedEditDataForArShow &&
      props.selectedEditDataForArShow.updateId
    ) {
      const { file } = props.selectedEditDataForArShow;
      return (
        <>
          {file &&
          (file.mimeType === 'image/webp' || file.mimeType === 'image/gif') ? (
            <picture>
              <source
                srcSet={`${baseURL}/uploads/${file.id}`}
                type="image/webp"
              />
              <source
                srcSet={`${baseURL}/uploads/${file.id}`}
                type="image/jpeg"
              />
              <source
                srcSet={`${baseURL}/uploads/${file.id}`}
                type="image/gif"
              />
              <img
                ref={imageReplayRef}
                width={160}
                height={300}
                src={`${baseURL}/uploads/${file.id}`}
                alt="Alt Text!"
              />
            </picture>
          ) : (
            // ) : videoUrl ? (
            //   <Player
            //     playsInline
            //     src={videoUrl}
            //     fluid={false}
            //     width={160}
            //     height={300}
            //     ref={playerRef}
            //   >
            //     <BigPlayButton position="center" />
            //     <ControlBar autoHide={true} disableDefaultControls={true}>
            //       <PlayToggle />
            //       <ReplayControl seconds={5} order={2.1} />
            //       <ForwardControl seconds={5} order={3.1} />
            //       <VolumeMenuButton vertical />
            //     </ControlBar>
            //   </Player>
            // )
            <div
              style={{
                height: '300px',
                width: '160px',
                background: 'silver',
                margin: 'auto',
              }}
            ></div>
          )}
        </>
      );
    }

    return (
      <div
        style={{
          height: '300px',
          width: '160px',
          background: 'silver',
          margin: 'auto',
        }}
      ></div>
    );
  };

  return (
    <>
      <Row
        style={{ overflowY: 'auto' }}
        className="menu-wrapper treasure-menu-wrapper third-dialog"
      >
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; {t("ARShow Editing")}
            </h4>
            <i className="iconx cursor">
              <img
                onClick={(e) => props.closeModal()}
                src={closeIcon}
                alt="Info"
              />
            </i>
          </div>
          <div
            style={{
              display: 'flex',

              justifyContent: 'spaceEvenly',
            }}
          >
            <div>
              <div>
                <label>{t("Start Date")}:</label>
              </div>

              <DatePicker
                style={{
                  marginRight: '1rem',
                  width: '200px',
                }}
                format={'MM/DD/YY'}
                name="registrationStartDate"
                suffixIcon={<img src={calendar} />}
                onChange={(e) => onDateChange(e, 'startDate')}
                disabledDate={state.endDate && disableStartDate}
                dropdownClassName="arShow__datepicker"
                defaultValue={state.startDate}
                value={state.startDate}
                // disabled={!startDate || !endDate}
                // disabledDate={(current) => {
                //   // const startDateList = [startDate];
                //   // const minDate = findMinOrMaxDate("max", startDateList);

                //   const endDateList = [
                //     endDate,
                //     registrationEndDate,
                //     qualificationsStartDate,
                //     qualificationsEndDate,
                //     finalistsAnnouncementDate,
                //     finalsEndDate,
                //     finalsStartDate,
                //   ];
                //   const maxDate = findMinOrMaxDate("min", endDateList);
                //   return (
                //     setStartDate(current, 100) <= minDate ||
                //     setStartDate(current) >= maxDate
                //   );
                // }}
              />
            </div>
            <div>
              <div>
                <label>{t("End Date")}:</label>
              </div>
              <DatePicker
                format={'MM/DD/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => onDateChange(e, 'endDate')}
                disabledDate={disabledDate}
                dropdownClassName="arShow__datepicker"
                defaultValue={state.endDate}
                value={state.endDate}
                // minDate={state.startDate}
                //   disabled={!startDate || !endDate}
                //   disabledDate={(current) => {
                //     const startDateList = [startDate, registrationStartDate];
                //     const minDate = findMinOrMaxDate("max", startDateList);

                //     const endDateList = [
                //       endDate,
                //       qualificationsStartDate,
                //       qualificationsEndDate,
                //       finalistsAnnouncementDate,
                //       finalsEndDate,
                //       finalsStartDate,
                //     ];
                //     const maxDate = findMinOrMaxDate("min", endDateList);
                //     return (
                //       setEndDate(current) <= minDate ||
                //       setEndDate(current, 100) >= maxDate
                //     );
                //   }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                marginTop: '0.7rem',
                marginRight: '1rem',
                borderRadius: '10px',
              }}
              className="col col-8"
            >
              <label>{t("Content Upload")}</label>
              <input
                type="text"
                value={state.videoName}
                className="form-control xs rd-0"
                readOnly
              />
            </div>
            <label
              style={{
                marginTop: '2rem',
                marginRight: '1rem',
              }}
              className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3"
            >
              <input
                onChange={onVideoFileUpload}
                type="file"
                accept="image/webp,image/gif"
              />
             {t("Upload")} 
            </label>
          </div>
          <div
            style={{
              marginLeft: '11px',
              fontSize: '13px',
              fontWeight: 900,
            }}
          >
          {t("Gif, Webp file only")}  
          </div>
          <div
            style={{
              marginTop: '1.5rem',
            }}
            className="text-center mb-14"
          >
            <h3 className="text-primary weight-700">{t("Preview")} </h3>
          </div>

          <div
            style={{
              margin: 'auto',
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            // className="dome-full-preview"
          >
            {renderVideoOrWebp()}
            {/* {videoResFile && videoResFile.mimeType === 'image/webp' ? (
              <picture>
                <source
                  srcSet={`${baseURL}/uploads/${videoResFile.id}`}
                  type="image/webp"
                />
                <source
                  srcSet={`${baseURL}/uploads/${videoResFile.id}`}
                  type="image/jpeg"
                />
                <img
                  width={160}
                  height={300}
                  src={`${baseURL}/uploads/${videoResFile.id}`}
                  alt="Alt Text!"
                />
              </picture>
            ) : videoUrl ? (
              <Player
                playsInline
                src={videoUrl}
                fluid={false}
                width={160}
                height={300}
                ref={playerRef}
              >
                <BigPlayButton position="center" />
                <ControlBar autoHide={true} disableDefaultControls={true}>
                  <PlayToggle />
                  <ReplayControl seconds={5} order={2.1} />
                  <ForwardControl seconds={5} order={3.1} />
                  <VolumeMenuButton vertical />
                </ControlBar>
              </Player>
            ) : (
              <div
                style={{
                  height: '300px',
                  width: '160px',
                  background: 'silver',
                  margin: 'auto',
                }}
              ></div>
            )} */}
          </div>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <span
              onClick={onReplayClick}
              style={{
                marginRight: '1rem',
                display: 'justify-center',
              }}
              className="ant-btn primary-ghost-btn xs fileUploadBtn mb-3"
            >
              {t("Replay")}
            </span>
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            <label
              style={{
                marginTop: '1rem',
                marginRight: '1rem',
              }}
              onClick={props.closeModal}
              className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3"
            >
             {t("Cancel")} 
            </label>
            <label
              style={{
                marginTop: '1rem',
                marginRight: '1rem',
              }}
              onClick={onSaveClick}
              className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3"
            >
             {t("Save")}  
            </label>
          </div>
        </div>
      </Row>
    </>
  );
};

export default ArEditModal;
