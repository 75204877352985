import React, { useState, useEffect } from 'react';
import { Button, Form, Typography, Row, Col, notification } from 'antd';
import * as userActions from '../../actions/signinActions';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form/Form';
import * as signin from '../../lib/strings/signin';
import { languages } from '../../lib/interfaces/language';
import PINinput from './PINinput';
import * as signinService from '../../services/signinService';
// import {b} from '../dashboard/menu/i18next';
import { useTranslation} from "react-i18next";

const { Text } = Typography;

const mapStateToProps = (state: {
  authentication: { phoneNumber: string; language: languages };
}) => {
  const { authentication } = state;
  const { phoneNumber, language } = authentication;
  return { phoneNumber, language };
};

const actionCreators = {
  onClickSignin: userActions.signin,
  onClickCancel: userActions.goBackToPhoneNumberForm,
};

interface VerificationProps extends FormComponentProps {
  onClickCancel: () => void;
  onClickSignin: (verificationCode: string, phoneNumber: string) => void;
  phoneNumber: string;
  language: languages;
}

const VerificationForm = (props: VerificationProps) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [counter, setCounter] = useState(60);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleChange = (event: string) => {
    setVerificationCode(event);

    if (event.length === 6 && event !== verificationCode) {
      props.onClickSignin(event, props.phoneNumber);
      return;
    }
  };

  const handleSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (verificationCode.length !== 6) {
      notification.error({
        message: signin.validationErorMessageVerification[props.language],
      });
    } else {
      props.onClickSignin(verificationCode, props.phoneNumber);
    }
  };

  const handleCancel = () => {
    props.onClickCancel();
  };

  const resendCodeHandler = () => {
    signinService.sendPhoneNumber(props.phoneNumber);
    setCounter(60);
  };

  const { language } = props;
  return (
    <Row>
      <Col>
        <Text className="center">{t('Code sent to')}</Text>
        <Text strong={true} className="center phone-number">
          {props.phoneNumber}
        </Text>
      </Col>
      <Row type="flex" justify="center" className="signin-row signin-normal-text">
        <Col>
          <Text>{t("Input 6 digit code")}</Text>
        </Col>
      </Row>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <PINinput onUpdate={handleChange} />
        <Row type="flex" justify="center" className="signin-row signin-normal-text">
          {counter > 0 && (
            <Col>
              <Text className="signin-purple-text">{counter}</Text>
            </Col>
          )}
          {counter === 0 && (
            <>
              <Col>
                <Text> {t("Didn't get the code")}&nbsp;</Text>
              </Col>
              <Col onClick={resendCodeHandler}>
                <Text className="signin-purple-text signin-pointer">{t('RESEND')}</Text>
              </Col>
            </>
          )}
        </Row>
        <Row type="flex" justify="center">
          <Col>
            <Button htmlType="submit" className="signin-form-button purple-gradient-right buttons">
              {t('Verify')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col>
            <Button
              className="signin-form-button buttons transparent-btn purple-txt bold"
              onClick={handleCancel}
            >
              {t('Back')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

const wrappedVerificationForm = Form.create({ name: 'login' })(VerificationForm);
export default connect(mapStateToProps, actionCreators)(wrappedVerificationForm);
