import React, { useState } from 'react';
import Treasure from './Treasure';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import NoTreasure from './NoTreasure';
import TreasureReview from '../../treasureCreation/TreasureReview';

interface TreasureListProps {
  treasureList?: any;
  role: number;
}

const mapState = (state: { userData: { role: number } }) => {
  const { userData } = state;
  const { role } = userData;
  return { role };
};

const TreasureList = (props: TreasureListProps) => {
  const { role } = props;

  const [treasureID, setTreasureID] = useState(null);
 
  const handleSelectedTreasure = (id: number) => {
    setTreasureID(id);
  };

  return (
    <Row type="flex" justify="center" className="scrollable-parent">
      <Col className="scrollable-child">
        <Row>
          {props.treasureList && props.treasureList.length > 0 ? (
            props.treasureList.map((treasure: any) => {
              return (
                <Treasure
                  ID={treasure.ID}
                  key={treasure.ID}
                  name={treasure.treasureName}
                  totalNumberOfUnits={treasure.totalNumberOfUnits}
                  exposureDuration={
                       treasure.rec_duration
                      // treasure.status === 3
                      //   ? (treasure.endDate - treasure.startDate) / 1000 / 3600
                      //   : 0
                  }
                  startDate={treasure.startDate}
                  endDate={treasure.endDate}
                  treasureType={treasure.treasureType}
                  unitImage={treasure.unitImage}
                  locations={treasure.locations}
                  status={treasure.status}
                  pointImage={treasure.mediaPath}
                  selected={treasure.ID === treasureID}
                  handleSelectedTreasure={handleSelectedTreasure}
                  createdAt={treasure.created ? treasure.created : '-'}
                  startsAt={treasure.lastStatusUpdate ? treasure.lastStatusUpdate : '-'}
                  username={treasure.ownerData ? treasure.ownerData.username : ''}
                />
              );
            })
          ) : (
            <NoTreasure />
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default connect(mapState)(TreasureList);
