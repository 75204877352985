import React, { Component, RefObject } from 'react';
import { Row, Form, Col, Typography, notification, Select, Input, Radio, Button } from 'antd';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { connect } from 'react-redux';
import { languages } from '../../../lib/interfaces/language';
import * as treasureActions from '../../../actions/treasureActions';
import { isDev } from '../../../lib/config';
import { Action, bindActionCreators } from 'redux';
import { adMaker } from '../../../lib/SubMenuConstants';
import {
  UploadFile,
  ModalLoadingProgressText,
  MakeNewUploadReuse,
  CouponLocation,
} from '../../../components';
import { uploadFiles } from '../../../services/sharedServices';
import { baseURL, treasureAPI } from '../../../lib/config';
import * as treasureService from '../../../services/treasureService';
import { menuConstants } from '../../../constants/menuConstants';
import { roleAdmin, rolePartner } from '../../../lib/const';

import StartEndDatePicker from '../../../components/treasure/StartEndDatePicker';
import LocationForm from './LocationForm';
import location_icon from '../../../assets/location_add_new_location_icon.svg';
import ReviewButtons from '../../../components/treasure/ReviewButtons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import * as partnerRequestsServices from '../../../services/partnerRequestsServices';

import { fillImage, fillVideo } from '../../../qucikFill/pointFill';
import {b} from '../../dashboard/menu/i18next'

const { Option } = Select;

interface Props {
  language: languages;
  imgUrl: string;
  form: any;
  pointsPerUnit: number;
  storeStickerImage?: (
    img: File,
    imagePreviewUrl: any,
    pointsPerUnit: number,
    fileType: string,
    kitType: string
  ) => Action;
  kitList: Array<any>;
  country: string;
  isVideo: boolean;
  updateKitOptions?: (points: number, price: number, kitTypeID: number, kitType: string) => Action;
  price: number;
  kitTypeID: number;
  kitType: string;
  makeAd: (useAdMaker: boolean) => Action;
  useAdMaker: boolean;
  handleInput: (name: string, value: any) => Action;
  openSubMenu1: (menu: string) => any;
  addNewHashtag: (hashtag: string[]) => void;
  enterTreasureName?: (name: string) => void;
  hashtags: Array<any>;
  treasureName?: string;
  ID: number;
  chatReply: string | boolean;
  updateMediaPath: (payload) => void;
  mediaPath: any;
  addPointAdvertisement: (image: File, imgUrl: string, isVideo: boolean) => void;
  treasure: any;
  removeHashtags: (hashtags: any) => void;
  dispatch: any;
  locations: any;
}

const mapState = (state) => {
  const { authentication, treasure, kitList, userData, locations } = state;
  const { language } = authentication;
  const {
    imgUrl,
    pointsPerUnit,
    isVideo,
    price,
    kitTypeID,
    kitType,
    useAdMaker,
    treasureName,
    hashtags,
    ID,
    chatReply,
    mediaPath,
  } = treasure;
  const { country, role, showPartnerInput } = userData;
  return {
    language,
    imgUrl,
    pointsPerUnit,
    kitList,
    country,
    isVideo,
    price,
    kitTypeID,
    kitType,
    useAdMaker,
    hashtags,
    treasureName,
    ID,
    chatReply,
    mediaPath,
    treasure,
    role,
    locations,
    showPartnerInput,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeStickerImage: treasureActions.storePointAdvertisement,
      updateKitOptions: treasureActions.updateKitOptions,
      makeAd: treasureActions.makeAd,
      handleInput: treasureActions.handleInput,
      openSubMenu1: treasureActions.openSubMenu1,
      updateMediaPath: treasureActions.updateMediaPath,
      addPointAdvertisement: treasureActions.addPointAdvertisement,
      handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
      resetTreasureErrors: () => dispatch(treasureActions.resetTreasureErrors()),
    },
    dispatch
  );

class PointCreation extends Component<any, any> {
  uploadImageRef: RefObject<any>;
  uploadLogoRef: RefObject<any>;
  uploadAdditionalFiles: RefObject<any>;
  windowDimensions: any;
  partnerTimer: any;
  partnerCheckSuccessful: boolean;

  constructor(props: Readonly<Props>) {
    super(props);

    this.uploadImageRef = React.createRef();
    this.uploadLogoRef = React.createRef();
    this.uploadAdditionalFiles = React.createRef();
    this.windowDimensions = React.createRef();

    this.state = {
      pointCreationOptionalLogo: '',
      pointCreationAdditionalFiles: '',
      uploadReuseMakeNew: 'upload',
      loading: false,
      done: false,
      percentage: 0,
      windowsWidth: 0,
      partnerCheckSuccessful: false,
    };
    this.partnerTimer = null;
  }

  async componentDidMount() {
    const { treasure, handleInput, role, locations, kitList, kitTypeID } = this.props;
    const { inputParameters } = treasureActions;
    this.props.form.setFieldsValue({
      [inputParameters.kitTypeID]: this.props.kitTypeID,
    });

    handleInput('uploadFile', this.uploadFile);
    handleInput('onAdvertisementChange', this.onAdvertisementChange);
    handleInput('onLogoChange', this.onLogoChange);

    if (!this.props.chatReply) {
      treasureService.treasureUpdate(this.props.ID, 'chatReply', '0');
    }

    if (treasure.isEditing) {
      if (kitTypeID) {
        const selectedKit = kitList.find((kit) => kit.ID === kitTypeID);
        handleInput('kitType', selectedKit.type);
      }

      if (treasure.mediaPath?.logo?.path) {
        handleInput('logoUrl', `${baseURL}${treasure.mediaPath?.logo?.path}`);
      }
    }

    if (treasure.onBack) {
      if (treasure.mediaPath?.video?.path || treasure.mediaPath?.pic?.path || treasure.imgUrl) {
        this.props.form.setFieldsValue({
          treasureAdvertisement:
            treasure.mediaPath?.video?.path || treasure.mediaPath?.pic?.path || treasure.imgUrl,
        });
      }
    }

    // getUsersLDiscounts
    if (role < roleAdmin) {
      const usersLDiscounts = await treasureService.getUserLDiscount();
      handleInput('availableDiscounts', usersLDiscounts.availableDiscounts);

      // calculate totalPrice, numberOfUnits and price per one unit
      let count = 0;
      locations.forEach((location: { numberOfUnits }) => {
        count = count + location.numberOfUnits;
      });
      if (treasure.kitTypeID) {
        const i = this.props.kitList.findIndex((kit) => kit.ID === treasure.kitTypeID);
        let price = 0;
        if (i !== -1) {
          const pricesPerCountry = this.props.kitList[i].pricesPerCountry;
          const dataByCountry = pricesPerCountry.includes({ country: this.props.country });
          if (dataByCountry) {
            const index = pricesPerCountry.findIndex(
              (country: { country: string }) => country.country === this.props.country
            );
            price = this.props.kitList[i].pricesPerUnit[index].price;
          } else {
            const index = pricesPerCountry.findIndex(
              (country: { country: string }) => country.country === 'default'
            );
            price = this.props.kitList[i].pricesPerCountry[index].price;
          }
        }
        handleInput('price', price);
        handleInput('totalPrice', treasureActions.calculateTotalPrice(price, count));
      }
    }

    if (treasure.partnerUsername) {
      handleInput(inputParameters.useReferral, 'yes');
      this.props.form.validateFields([inputParameters.partnerUsername]);
    }
  }

  componentDidUpdate() {
    const { form, treasureErrors, resetTreasureErrors } = this.props;
    if (!isEmpty(treasureErrors)) {
      const errorKeys = Object.keys(treasureErrors);
      let setValues = {};
      errorKeys.forEach((errorKey) => {
        setValues[errorKey] = null;
      });
      form.setFieldsValue(setValues);

      resetTreasureErrors();
    }
  }

  handleKitData = (e: string) => {
    const { inputParameters } = treasureActions;
    treasureService.treasureUpdate(this.props.treasure.ID, inputParameters.kitTypeID, e);

    const i = this.props.kitList.findIndex((kit) => kit.ID === e);
    if (i !== -1) {
      const pricesPerCountry = this.props.kitList[i].pricesPerCountry;
      const dataByCountry = pricesPerCountry.includes({ country: this.props.country });
      if (dataByCountry) {
        const index = pricesPerCountry.findIndex(
          (country: { country: string }) => country.country === this.props.country
        );
        this.props.updateKitOptions(
          this.props.kitList[i].pricesPerCountry[index].points,
          this.props.kitList[i].pricesPerUnit[index].price,
          this.props.kitList[i].ID,
          this.props.kitList[i].type
        );
      } else {
        const index = pricesPerCountry.findIndex(
          (country: { country: string }) => country.country === 'default'
        );
        this.props.updateKitOptions(
          this.props.kitList[i].pricesPerCountry[index].points,
          this.props.kitList[i].pricesPerCountry[index].price,
          this.props.kitList[i].ID,
          this.props.kitList[i].type
        );
      }
    }

    if (this.props.kitList[i].type !== this.props.kitType) {
      this.props.updateMediaPath({ pic: {}, video: {} });
      this.props.handleClose();
    }
  };

  onLogoChange = async ({ file, fileUrl }) => {
    const { handleInput, treasure } = this.props;

    if (fileUrl !== treasure.logoUrl) {
      handleInput('logoUrl', fileUrl);

      const formData = new FormData();

      //will change in future
      formData.append('logo', 'true');
      formData.append('file', file);

      const res = await this.uploadFile(formData);
      if (res.code !== 1) {
        handleInput('logoUrl', '');
      }
    }
  };

  onCuponChange = async ({ file, fileUrl }) => {
    const { handleInput, treasure } = this.props;

    if (fileUrl !== treasure.cuponUrl) {
      handleInput('cuponUrl', fileUrl);

      const formData = new FormData();

      //will change in future
      formData.append('coupon', 'true');
      formData.append('file', file);

      const res = await this.uploadFile(formData);
      if (res.code !== 1) {
        handleInput('cuponUrl', '');
      }
    }
  };

  onAdvertisementChange = async ({ file, fileUrl, fileType }) => {
    const {
      addPointAdvertisement,
      pointsPerUnit,
      kitType,
      ID,
      kitTypeID,
      treasure,
      handleInput,
      form,
    } = this.props;

    if (fileUrl !== treasure.imgUrl) {
      addPointAdvertisement(file, fileUrl, kitType === 'video');

      this.setState({ loading: true, done: false });
      treasureService.updatePointProps(ID, pointsPerUnit, kitTypeID);
      const treasureData = new FormData();

      // should change on API in future
      treasureData.append('file', file);
      const res = await this.uploadFile(treasureData);
      if (res.code !== 1) {
        handleInput('imgUrl', '');
        form.setFieldsValue({ treasureAdvertisement: undefined });
      } else {
        form.setFieldsValue({ treasureAdvertisement: 'full' });
      }
    }
  };

  uploadFile = async (formDataFile: FormData) => {
    this.setState({ loading: true, done: false });
    const url = `${baseURL}${treasureAPI}/${this.props.ID}/uploadFile`;

    const res: any = await uploadFiles(url, formDataFile, {
      onProgress: (perc: number) => {
        if (perc >= 100) {
          this.setState({ done: true, loading: false });
        }
        this.setState({ percentage: perc });
      },
    });
    return res;
  };

  changeChatReplay = (event) => {
    const { chatReply, handleInput, ID } = this.props;
    if (chatReply !== event.target.value) {
      handleInput('chatReply', event.target.value);
      treasureService.treasureUpdate(ID, 'chatReply', event.target.value);
    }
  };

  updateMediaPath = async (payload) => {
    const { ID, form, treasure } = this.props;
    this.props.updateMediaPath(payload);

    //preparing data for API
    // not great way for getting current mediaPath, but would need mayor refactor when creating new treasure
    const currentTreasure = await treasureService.getTreasureByID(ID);

    let mediaPath = { coupon: {}, logo: {}, pic: {}, video: {} };
    mediaPath = { ...mediaPath, ...currentTreasure.treasure?.mediaPath };
    const newMediaPath = { ...mediaPath, ...payload };

    // API call
    const res = await treasureService.updateTreasureMediaPath(ID, newMediaPath);
    form.setFieldsValue({ treasureAdvertisement: 'treasure.mediaPath' });
    if (res.error) {
      this.props.updateMediaPath({ pic: {}, video: {}, thumbnail: {} });
      form.setFieldsValue({ treasureAdvertisement: undefined });
    }
  };

  makeNewUploadReuseHandler = (value) => {
    const { treasure, handleInput, form } = this.props;
    if (treasure.uploadReuseMakeNew !== value && value === 'makeNew') {
      this.props.openSubMenu1(adMaker);
    }
    if (treasure.onBack === true && treasure.uploadReuseMakeNew === 'reuse') {
      handleInput('onBack', false);
    } else {
      handleInput('uploadReuseMakeNew', value);
    }
  };

  onCouponAddressChange = (address, latitude, longitude) => {
    const { treasure, handleInput, ID } = this.props;
    const { inputParameters } = treasureActions;
    if (address !== treasure.couponAddress) {
      handleInput(inputParameters.couponAddress, address);
      treasureService.treasureUpdate(ID, inputParameters.couponAddress, address);
    }
    if (latitude !== treasure.couponLatitude) {
      handleInput(inputParameters.couponLatitude, latitude);
      treasureService.treasureUpdate(ID, inputParameters.couponLatitude, latitude);
    }
    if (longitude !== treasure.couponLongitude) {
      handleInput(inputParameters.couponLongitude, longitude);
      treasureService.treasureUpdate(ID, inputParameters.couponLongitude, longitude);
    }
  };

  onReviewHandler = () => {
    const { onReview, language } = this.props;
    const { validateFields } = this.props.form;
    validateFields((errors, values) => {
      if (!errors) {
        onReview();
      } else {
        notification.error({
          message: b?.t('Please enter all required fields'),
        });
      }
    });
  };

  handlePointName = async (event) => {
    const { handleInput, treasure } = this.props;
    handleInput(treasureActions.inputParameters.treasureName, event.target.value);
    treasureService.treasureUpdate(
      treasure.ID,
      treasureActions.inputParameters.treasureName,
      event.target.value
    );
  };

  useReferralHandler = async (value) => {
    const { handleInput, treasure } = this.props;
    const { inputParameters } = treasureActions;
    handleInput(treasureActions.inputParameters.useReferral, value);
    // missing API call
    if (value === 'no') {
      handleInput(inputParameters.partnerUsername, '');
      treasureService.treasureUpdate(treasure.ID, inputParameters.partnerUsername, '');
    }
  };

  handlePartnerUsername = async (partnerUsername) => {
    const { handleInput, treasure } = this.props;
    const { inputParameters } = treasureActions;

    handleInput(inputParameters.partnerUsername, partnerUsername);

    treasureService.treasureUpdate(treasure.ID, inputParameters.partnerUsername, partnerUsername);
  };

  render() {
    const {
      language,
      treasure,
      onHashtagChange,
      onStartDateChange,
      onEndDateChange,
      locations,
      onAnotherLocationClick,
      role,
      onCancel,
      form,
      showPartnerInput,
    } = this.props;
    const { getFieldDecorator } = form;
    const { inputParameters } = treasureActions;

    const options: JSX.Element[] = this.props.kitList.map((kit: any, index: number) => {
      //issues
      const i = this.props.kitList[index]?.pricesPerCountry?.findIndex(
        (country: { country: string }) => country.country === this.props.country
      );
      if (i > -1) {
        return (
          <Option key={index} value={kit.ID}>
            {kit.name}{' '}
            {`(${kit.pricesPerCountry[i]?.price} ${b?.t('Lcash/unit')})`}
          </Option>
        );
      } else if (i === -1) {
        const j = this.props.kitList[index]?.pricesPerCountry?.findIndex(
          (country: { country: string }) => country.country === 'default'
        );
        if (j > -1)
          return (
            <Option key={index} value={kit.ID}>
              {kit.name}{' '}
              {`(${kit.pricesPerCountry[j]?.price} ${b?.t('Lcash/unit')})`}
            </Option>
          );
      }
    });

    return (
      <>
        <div className="create-treasure-padding" style={{ width: '100%' }}>
          <div ref={(ref) => (this.windowDimensions = ref)}>
            <Form>
              {/* Partner username referral */}
              {!!showPartnerInput && role < rolePartner && (
                <>
                  <Form.Item label={b?.t("Partner username")}>
                    {getFieldDecorator(inputParameters.useReferral, {
                      rules: [{ required: false }],
                      initialValue: treasure.useReferral || 'no',
                    })(
                      <Select
                        className="select-treasure-type treasure-type-picker"
                        onChange={this.useReferralHandler}
                      >
                        <Select.Option value="no">{b?.t('No')}</Select.Option>
                        <Select.Option value="yes">{b?.t('Yes')}</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                  {treasure.useReferral === 'yes' && (
                    <Form.Item
                      className="roboto-regular"
                      label={treasureCreation.partnerUsername[language]}
                      hasFeedback
                      extra={
                        this.state.partnerCheckSuccessful ? (
                          <Typography.Text style={{ color: '#3CDB52' }}>
                            {b?.t('Correct username')}
                          </Typography.Text>
                        ) : null
                      }
                    >
                      {getFieldDecorator(inputParameters.partnerUsername, {
                        validateTrigger: 'onChange',
                        rules: [
                          {
                            required: true,
                            message: b?.t('Please input partner referral username'),
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (!this.state.partnerCheckSuccessful) {
                                clearTimeout(this.partnerTimer);

                                this.partnerTimer = setTimeout(() => {
                                  checkerFunction(value);
                                }, 1000);

                                const checkerFunction = async (val) => {
                                  const response = await partnerRequestsServices.checkUsername(val);

                                  if (response === rolePartner) {
                                    this.setState({ partnerCheckSuccessful: true });
                                    this.handlePartnerUsername(val);
                                    callback();
                                  } else {
                                    this.setState({ partnerCheckSuccessful: false });
                                    this.handlePartnerUsername('');
                                    callback('Partner does not exists');
                                  }
                                };
                              } else {
                                callback();
                              }
                            },
                            message: b?.t('Incorrect username'),
                          },
                        ],
                        initialValue: treasure.partnerUsername || '',
                      })(
                        <Input
                          className="input-treasure full-width"
                          placeholder={b?.t("Name...")}
                          name="partnerUsername"
                        />
                      )}
                    </Form.Item>
                  )}
                </>
              )}

              {/* Kit type selector */}
              <Form.Item label={b?.t('Kit type')}>
                {getFieldDecorator(inputParameters.kitTypeID, {
                  rules: [
                    { required: true, message: b?.t('Kit type must be selected!') },
                  ],
                })(
                  <Select
                    className="select-treasure-type treasure-type-picker"
                    onChange={this.handleKitData}
                  >
                    {options}
                  </Select>
                )}
              </Form.Item>

              {(this.props.kitType === 'image' || this.props.kitType === 'video') && (
                <>
                  {/* Treasure name input field */}
                  <Form.Item
                    className="roboto-regular"
                    label={b?.t('Treasure Name')}
                  >
                    {getFieldDecorator(inputParameters.treasureName, {
                      rules: [
                        {
                          required: true,
                          message: b?.t('Please input sticker name!')
                        },
                      ],
                      initialValue: treasure.treasureName || '',
                    })(
                      <Input
                        className="input-treasure full-width"
                        onBlur={this.handlePointName}
                        placeholder={b?.t('Name...')}
                        name="treasurePointName"
                      />
                    )}
                  </Form.Item>

                  {/* Upload logo */}
                  <UploadFile
                    language={language}
                    label={b?.t('Upload logo (Optional)')}
                    id="logo"
                    size="small"
                    onChange={this.onLogoChange}
                    initialFileUrl={treasure.mediaPath?.logo?.path}
                    valueUrl={treasure.logoUrl ? treasure.logoUrl : ''}
                  />

                  {/* Hasthtags input field */}
                  <Form.Item
                    className="roboto-regular"
                    label={b?.t('Hashtag')}
                  >
                    <Row>
                      <Col>
                        {getFieldDecorator(inputParameters.hashtag, {
                          rules: [
                            {
                              required: true,
                              message: b?.t('Add at least one hashtag!'),
                            },
                          ],
                          initialValue:
                            treasure.hashtags.map((hashtagObject) => hashtagObject.name) || [],
                        })(
                          <Select
                            placeholder="#"
                            className="hashtag"
                            mode="tags"
                            tokenSeparators={[',']}
                            onBlur={onHashtagChange}
                            maxTagCount={10}
                          ></Select>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>

                  <MakeNewUploadReuse
                    language={language}
                    kitType={this.props.kitType}
                    mediaPath={this.props.mediaPath}
                    updateMediaPath={this.updateMediaPath}
                    onChange={this.makeNewUploadReuseHandler}
                    value={treasure.uploadReuseMakeNew}
                    form={this.props.form}
                  />

                  {/* Upload Advertisment Image and Video*/}
                  {treasure.uploadReuseMakeNew === 'upload' && (
                    <Form.Item
                      className="roboto-regular image-upload-req"
                      label={b?.t('Upload advertisement')}
                    >
                      {getFieldDecorator('treasureAdvertisement', {
                        rules: [
                          {
                            required: true,
                            message: b?.t("Please upload advertisement file"),
                          },
                        ],
                        initialValue:
                          this.props.kitType === 'video'
                            ? treasure.mediaPath?.video?.path
                            : treasure.mediaPath?.pic?.path,
                      })(
                        <div>
                          <UploadFile
                            language={language}
                            id="advertisement"
                            isVideo={this.props.kitType === 'video'}
                            onChange={this.onAdvertisementChange}
                            initialFileUrl={
                              this.props.kitType === 'video'
                                ? treasure.mediaPath?.video?.path
                                : treasure.mediaPath?.pic?.path
                            }
                            valueUrl={treasure.imgUrl ? treasure.imgUrl : ''}
                          />
                        </div>
                      )}
                    </Form.Item>
                  )}

                  {treasure.uploadReuseMakeNew === 'makeNew' && (
                    <Form.Item className="roboto-regular image-upload-req">
                      {getFieldDecorator('treasureAdvertisement', {
                        rules: [
                          {
                            required:
                              treasure.mediaPath?.video?.path || treasure.mediaPath?.pic?.path
                                ? false
                                : true,
                            message: b?.t('Please upload advertisement file'),
                          },
                        ],
                        initialValue:
                          treasure.mediaPath?.video?.path || treasure.mediaPath?.pic?.path,
                      })(
                        <div>
                          <UploadFile
                            language={language}
                            id="advertisement"
                            isVideo={this.props.kitType === 'video'}
                            initialFileUrl={
                              this.props.kitType === 'video'
                                ? treasure.mediaPath?.video?.path
                                : treasure.mediaPath?.pic?.path
                            }
                            valueUrl={treasure.imgUrl ? treasure.imgUrl : ''}
                            disableFileSelect={false}
                          />
                        </div>
                      )}
                    </Form.Item>
                  )}

                  {/* Upload additional file (eg. Coupon, Additional information) that you send to users via chat (Optional): */}
                  <div className="location-wrapper">
                    <UploadFile
                      language={language}
                      label={b?.t('Upload additional file (eg. Coupon, Additional information) that you send to users via chat (Optional)')}
                      id="coupon"
                      size="small"
                      formClassName="two-rows-label roboto-regular image-upload-req"
                      formOther={{ colon: false }}
                      onChange={this.onCuponChange}
                      initialFileUrl={treasure.mediaPath?.coupon?.path}
                      valueUrl={treasure.cuponUrl ? treasure.cuponUrl : ''}
                    />
                  </div>

                  <div className="location-wrapper">
                    <CouponLocation
                      language={language}
                      onChange={this.onCouponAddressChange}
                      form={form}
                      initialAddress={treasure.couponAddress}
                      initialLat={treasure.couponLatitude}
                      initialLng={treasure.couponLongitude}
                      showLatLng={role >= roleAdmin}
                    />
                  </div>

                  <div className="location-wrapper">
                    <Radio.Group
                      className="treasure-radio-buttons"
                      onChange={this.changeChatReplay}
                      defaultValue={this.props.chatReply?.toString() || '0'}
                    >
                      <Row>
                        <Radio value={'0'}>
                          {b?.t('No reply from users via chat')}
                        </Radio>
                      </Row>
                      <Row>
                        <Radio value={'1'}>
                          {b?.t('Reply from users via chat')}
                        </Radio>
                      </Row>
                    </Radio.Group>
                  </div>

                  <StartEndDatePicker
                    language={language}
                    initialStartDateValue={
                      treasure.isEditing || treasure.onBack
                        ? treasure.startDate
                        : parseInt(moment().format('x'))
                    }
                    initialEndDateValue={treasure.endDate}
                    onChangeStartDate={onStartDateChange}
                    onChangeEndDate={onEndDateChange}
                    form={form}
                    formStartDateId={inputParameters.startDate}
                    formEndDateId={inputParameters.endDate}
                  />

                  {locations.map((location: any, index: number) => {
                    return (
                      <LocationForm
                        key={index}
                        locationID_UI={index}
                        form={form}
                        location={(treasure.isEditing || treasure.onBack) && location}
                      />
                    );
                  })}

                  <Row
                    type="flex"
                    justify="center"
                    style={{ paddingTop: '25px', paddingBottom: '25px' }}
                  >
                    <Button
                      style={{ width: '100%' }}
                      className="buttons purple-gradient-right menu-button-width"
                      onClick={onAnotherLocationClick}
                    >
                      <img src={location_icon} alt="location" />
                      {b?.t('Add another location')}
                    </Button>
                  </Row>

                  <ModalLoadingProgressText
                    loading={this.state.loading}
                    done={this.state.done}
                    percentage={this.state.percentage}
                    text={'Files are uploading'}
                  />
                </>
              )}
            </Form>
          </div>
        </div>
        <ReviewButtons
          treasure={treasure}
          role={role}
          onCancel={onCancel}
          onReview={this.onReviewHandler}
        />

        {/* quickFill for testing */}
        {isDev && !treasure.isEditing && (
          <div className="quick-fill-container">
            <Button
              onClick={() => {
                fillImage(
                  this.handleKitData,
                  this.handlePointName,
                  this.props.onHashtagChange,
                  this.props.form.setFieldsValue,
                  onEndDateChange,
                  this.onAdvertisementChange
                );
              }}
            >
             {b?.t('Point Image')} 
            </Button>

            <Button
              onClick={() => {
                fillVideo(
                  this.handleKitData,
                  this.handlePointName,
                  this.props.onHashtagChange,
                  this.props.form.setFieldsValue,
                  onEndDateChange,
                  this.onAdvertisementChange
                );
              }}
            >
             {b?.t('Point Video')} 
            </Button>
          </div>
        )}
      </>
    );
  }
}

const wrappedTreasureCreationForm = Form.create<any>({ name: 'pointTreasure' })(PointCreation);
export default connect(mapState, mapDispatchToProps)(wrappedTreasureCreationForm);
