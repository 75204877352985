import React from 'react';
import Dropzone from 'react-dropzone'
import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { baseURL } from '../../../../lib/config';
import burgerKing from '../../../../../src/assets/burgerKing.png';
import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import { connect } from 'react-redux';


import { FileUpload, UpdateSetting } from '../../../../services/domeService';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { blobToFile, getCroppedImg } from '../../../../helpers/ImageCropping';

class DomeThreedIcon extends React.Component<any, any> {
    private imageElement: React.RefObject<HTMLImageElement>;
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            success: false,
            image: '',
            fileName: '',
            preview: '',
            file: '',
            src: '',
            type: '',
            crop: {
                unit: 'px', // default, can be 'px' or '%'
                x: 10,
                y: 50,
                width: 100,
                height: 100
            }
        }
        this.imageElement = React.createRef();
    }

    onChange = crop => {
        this.setState({ crop });
    };

    onImageLoaded = image => {
        this.imageElement = image;
    }

    onComplete = async (crop) => {
        const croppedFile = await getCroppedImg(this.imageElement, crop, this.state.fileName, this.state.type);
        const file = blobToFile(croppedFile, this.state.fileName);
        const result = await FileUpload(file);
        let resultfile = result.file;
        preview: baseURL + '/uploads/' + resultfile.id
        let preview = URL.createObjectURL(croppedFile);
        this.setState({
            preview: preview,
            file: resultfile,
        })

    }

    componentDidMount = async () => {
        const profile = await this.getContext();
        const current_edit_dome = profile['edit_current_dome'] || {};
        this.setState({
            image: current_edit_dome.icon_3d,
            fileName: current_edit_dome.icon_3d.name,
            preview: baseURL + '/uploads/' + current_edit_dome.icon_3d.id
        })
    }

    getContext = async () => {
        const context = this.context;
        let profile = context.profile || {};
        return profile;
    }

    updateContext = async (data: any) => {
        const context = this.context;
        context.setProfile(data);
    }

    oNDropFileHandler = async (files) => {
        let file = files[0];
        let image = file;
        let fileName = file.name;
        let preview = URL.createObjectURL(file);
        const result = await FileUpload(file);
        let resultfile = result.file;
        this.setState({
            image: image,
            fileName: fileName,
            preview: preview,
            file: resultfile
        });
    }

    fileHandler = async (e) => {
        if (e.target.files.length > 0) {
            let image = e.target.files[0];
            let fileName = e.target.files[0].name;
            let preview = URL.createObjectURL(e.target.files[0]);
            const result = await FileUpload(e.target.files[0]);
            let file = result.file;
            this.setState({
                image: image,
                fileName: fileName,
                preview: preview,
                file: file,
                src: preview,
                type: image.type,
            });
        }
    }
    update = async (profile: any) => {
        let domeSettingList = profile.domeSettingList;
        let current_edit_dome = profile.current_edit_dome;
        domeSettingList[current_edit_dome.current_index] = current_edit_dome;
        profile.domeSettingList = domeSettingList;
        await this.updateContext(profile);
    }
    render() {
        const {translation}=this.props
        const { icons } = this.props;
        return (
            <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
                <div className="third-card relative">
                    <div className="fx-jcsb-aic mb-20">
                        <h4 className="text-primary mb-0 font-bold">&#62; {translation('3D Icon')}</h4>
                        <i onClick={this.props.closeThreedIcon} className="iconx cursor">
                            <img src={closeIcon} alt="Info" />
                        </i>
                    </div>
                    <div className="domeAddContainer scrollSection">
                        <div className='text-left mb-20'>
                            <em className='text-primary'>{translation('Choose one')}</em>
                        </div>
                        <div className='threeDiconBlock'>
                            <ul>
                                {icons.map((res, i) => (
                                    <li key={i}>
                                        <figure>
                                            <img src={'https://app.loamoa.cladev.com/uploads/' + res.id} alt="Icon" height="40" width="60" />
                                        </figure>
                                        <input type="radio" name="icon" checked={res.id === this.props.isChecked} onChange={(e) => this.props.handleChangeIcon(e, res)} value={res.id} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="grid-row fx-jcc">
                            <div className="col col-6 fx-jcsb">
                                <button onClick={e => this.props.closeThreedIcon(e)} className="ant-btn primary-ghost-btn xs">{translation('Cancel')}</button>
                                <button onClick={this.props.publish} className="ant-btn yellow-gradient xs">{this.props.loader ? translation('Loading...') :translation ('Edit')}</button>
                               {console.log(this.props.publish,"pppppppppp")}
                            </div>
                        </div>
                    </div>
                </div>
            </Row >
        )

    }

}

const mapStateToProps = (state:any) => ({
    translation: state.userData.translation,
  });
  export default connect(mapStateToProps, null)(DomeThreedIcon);