import React from 'react';
import { Row, Spin, Table } from 'antd';
import AppContext from '../../../../AppContext';
import DomeNotification from './domeNotification';
import MyPicks from './domeMyPicks';
import ChatNow from './domeNow';
import ChatEdit from './domeChatEdit';
import DomeNotice from './domeNotice';
import DomePerformance from './domePerformance';
import DomeArshow from './domeArshow';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import logoPic from '../../../../../src/assets/logoPic.png';
import picksIcon from '../../../../../src/assets/picksIcon.svg';
import joinedEllipse from '../../../../../src/assets/joinedEllipse.svg';
import burgerKing from '../../../../../src/assets/burgerKing.png';

import { Chart } from "react-google-charts";

export const data = [
  ["Month", "Visitors"],
  ["1", 950],
  ["2", 830],
  ["3", 660],
  ["4", 500],
  ["5", 100],
  ["6", 250],
  ["7", 152],
  ["8", 600],
  ["9", 700],
  ["10", 350],
  ["11", 450],
  ["12", 850],
];

export const options = {
  chart: {
    title: "Visitors of this year.",
    subtitle: "Visitors: 2022-2023",
  },
};

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import DomeTemplate from './domeTemplate';
import ReactTooltip from 'react-tooltip';
import ArEditModal from './components/ArEditModal';
import {
  getArShowData,
  getChatUsersList,
  getDeleteArShow,
  postArShow,
} from '../../../../services/domeService';
import moment from 'moment';
import { baseURL } from '../../../../lib/config';
import { store } from '../../../../helpers/store';
import { getMarkerLocation } from '../../../../helpers/GetLocation';
import { connect } from 'react-redux';
import QandA1 from './QandA';
//import burgerKing from '../../../../src/assets/burgerKing.png';
//import burgerKingTrans from '../../../../src/assets/burgerKingTrans.png';

// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

class DomeAdmin extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      notification_tab: false,
      arshow_tab: false,
      chat_tab: false,
      Monthly_tab:false,
      notice_tab: false,
      stats_tab: false,
      picks_tab:false,
      joined_tab:false,


      notification: {
        notification_tab_active: '',
        notification_tab: false,
        create_notification: false,
        create_template: false,
        create_QandA:false,
      },

      arshow: {
        notice_tab: false,
        notice_tab_active: '',
      },

      chat: {},
      joined:{},
      Monthly :{},
      notice: {},
      performance: {},
      stats: {},
      QandA:{},
      picks:{},
      notification_tab_active: '',
      performance_tab_active: '',
      arshow_tab_active: '',
      chat_tab_active: '',
      joined_tab_active:'',
      Monthly_tab_active:"",
      notice_tab_active: '',
      stats_tab_active: '',
      picks_tab_active: '',
      create_notification: false,
      my_picks: false,
      now: false,
      now_chat_active: '',
      arEditModalShow: false,
      arshowData: [
        { start_date: '', end_date: '', name: '', videoUrlBac: '', file: null },
      ],
      selectedArEditIndex: null,
      loadAgain: false,
      selectedEditDataForArShow: null,
      isActive: false,
      chatJoinedUsers: [],
      chat_joined_total: 0,
      chat_picked_total: 0,
      loadArShowAgain: false,
    };
    this.closeAdmin = this.closeAdmin.bind(this);
  }

  /*#################################################################### */

  toggleClass = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  getApiCallForArData = async () => {
    const profile = await this.getContext();

    let id = profile.admin_dome.ID;
    const res = await getArShowData(id);
    // console.log(res);
    let temp_data = [];
    if (res && res.length > 0) {
      temp_data = res.map((item) => {
        return {
          start_date: item.start_date,
          end_date: item.end_date,
          name: item.file.name,
          videoUrlBac: `${baseURL}/uploads/${item.file.id}`,
          file: item.file,
          updateId: item.id,
        };
      });
    } else {
      temp_data = [{ start_date: '', end_date: '', name: '' }];
    }
    this.setState({
      arshowData: temp_data,
      loadArShowAgain: false,
    });
  };

  getApiCallForChatUsersList = async () => {
    const profile = await this.getContext();

    let id = profile.admin_dome.ID;

    const res = await getChatUsersList(id);
    console.log('chat   ', res);
    this.setState({
      chatJoinedUsers: res?.data?.length ? res.data : [],
      chat_joined_total: res?.joined_total ? res.joined_total : 0,
      chat_picked_total: res?.picked_total ? res.picked_total : 0,
    });
  };

  componentDidMount(): void {
    this.getApiCallForArData();
    this.getApiCallForChatUsersList();
  }

  componentDidUpdate = async () => {
    // isDomeSubAdminActive
    let profile = await this.getContext();
    if (profile.isDomeSubAdminActive) {
      if (profile.domeAdminTypeName) {
        if (profile.domeAdminTypeName === 'Noticeboard') {
          this.noticeTab('');
        } else if (profile.domeAdminTypeName === 'Performance Schedule') {
          this.performanceTab('');
        }

        profile.isDomeSubAdminActive = false;
        await this.updateContext(profile);
      }
    }
    if (this.state.loadArShowAgain) {
      await this.getApiCallForArData();
      // this.setState({
      //   loadArShowAgain: false,
      // });
    }
  };

  addMore = async (e: any) => {
    let arshow = [
      ...this.state.arshowData,
      { start_date: '', end_date: '', url: '' },
    ];
    this.setState({ arshowData: arshow });
  };

  handleFile = async (e: any, index) => {
    if (e.target.files.length > 0) {
      let arshowData = [...this.state.arshowData];
      arshowData[index]['name'] = e.target.files[0].name;
      this.setState({
        arshowData: arshowData,
      });
    }
  };

  removeUrlHandler = async (e, index) => {
    let arshowData = [...this.state.arshowData];
    const profile = await this.getContext();
    let id = profile.admin_dome.ID;
    let ar_id = arshowData[index].updateId;
    if (ar_id) {
      await getDeleteArShow(id, ar_id);
    }
    arshowData.splice(index, 1);
    this.setState({
      arshowData: arshowData,
      arEditModalShow: false,
    });
  };

  setValuesForarshowDataOnSave = async (ind, values, videoRes, updateId) => {
    const profile = await this.getContext();
    let id = profile.admin_dome.ID;
    let formValues = {
      start_date: moment(values.startDate).format('YYYY-MM-DD'),
      end_date: moment(values.endDate).format('YYYY-MM-DD'),
      file: videoRes,
    };
    if (updateId) {
      formValues.id = updateId;
    }
    await postArShow(id, formValues);

    let arshowData = [...this.state.arshowData];
    arshowData[ind] = {
      start_date: values.startDate,
      end_date: values.endDate,
      name: values.videoName,
    };
    this.setState({
      arshowData,
      loadArShowAgain: true,
    });
  };

  /*#################################################################### */

  onArEditClick = (ind, item) => {
    console.log(item);
    this.setState({
      arEditModalShow: true,
      selectedArEditIndex: ind,
      loadAgain: !this.state.loadAgain,
      selectedEditDataForArShow: item,
    });
  };

  onArEditClickClose = () => {
    this.setState({
      arEditModalShow: false,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  closeAdmin = async (e: any) => {
    let profile = await this.getContext();
    profile['admin_dome'] = null;
    await this.updateContext(profile);
  };

  notificationTab = async (e: any) => {
    let notification = {
      ...this.state.notification,
      notification_tab: true,
      notification_tab_active: 'active',
    };
    this.setState({
      notification: notification,
      arshow: {},
      chat: {},
      notice: {},
      stats: {},

      performance: {},
      Monthly:{},
      arEditModalShow: false,
      isActive: false,
    });
  };

  createNotification = async (e: any) => {
    let notification = {
      ...this.state.notification,
      create_notification: true,
      create_template: false,
    };
    this.setState({
      notification: notification,
    });
  };

  createTemplate = async (e: any) => {
    let notification = {
      ...this.state.notification,
      create_template: true,
      create_notification: false,
    };
    this.setState({
      notification: notification,
    });
  };

  createQandA = async (e: any) => {
    let QandA = {
      ...this.state.QandA,
      create_QandA: true,
      create_notification: false,
    };
    this.setState({
      QandA: QandA,
      Monthly:{},
      pinks:{},
      joined:{},
    });
  };

  closeCreateNotification = async (e: any) => {
    let notification = {
      ...this.state.notification,
      create_notification: false,
    };
    this.setState({
      notification: notification,
    });
  };

  closeCreateTemplate = async (e: any) => {
    let notification = { ...this.state.notification, create_template: false };
    this.setState({
      notification: notification,
    });
  };

  closeQandA = async (e: any) => {
    let QandA = { ...this.state.QandA, create_QandA: false };
    this.setState({
      QandA: QandA,
      
      
    });
  };

  arshowTab = async (e: any) => {
    let arshow = {
      ...this.state.arshow,
      arshow_tab: true,
      arshow_tab_active: 'active',
    };
    this.setState({
      arshow: arshow,
      notification: {},
      chat: {},
      notice: {},
      stats: {},
      Monthly:{},
      pinks:{},
      joined:{},
      performance: {},
      isActive: false,
    });
  };

  Monthlyvisitors = async (e: any) => {
    let Monthly = {
      ...this.state.Monthly,
      Monthly_tab: true,
      Monthly_tab_active: 'active',
      chat_tab_active: 'active',
    };
    this.setState({
      Monthly: Monthly,
      notification: {},
      chat: {},
      notice: {},
      performance: {},
      picks:{},
      joined:{},
      isActive: false,
    });
  };

  picksTab = async (e: any) => {
    let picks = {
      ...this.state.picks,
      picks_tab: true,
      picks_tab_active: 'active',
      
    };
    this.setState({
      picks: picks,
      notification: {},
      Monthly:{},
      chat: {},
      notice: {},
      joined:{},
      performance: {},
      isActive: false,
    });
  };

  joinedTab= async (e: any) => {
    let joined = {
      ...this.state.joined,
      joined_tab: true,
      // picks_tab_active: 'active',
      // chat_tab_active: 'active',
      joined_tab_active:"active"
    };
    this.setState({
      joined: joined,
      notification: {},
      Monthly:{},
      chat: {},
      notice: {},
      picks:{},
      performance: {},
      isActive: false,
    });
  };

  closeArShow = async (e: any) => {
    let arshow = {
      ...this.state.arshow,
      arshow_tab: false,
      arshow_tab_active: '',
    };
    this.setState({
      arshow: arshow,
    });
  };

  chatTab = async (e: any) => {
    let chat = {
      ...this.state.chat,
      chat_tab: true,
      chat_tab_active: 'active',
    };
    this.setState({
      chat: chat,
      notification: {},
      arshow: {},
      notice: {},
      stats: {},
      Monthly:{},
      pinks:{},
      joined:{},
      performance: {},
      arEditModalShow: false,
    });
  };
  noticeTab = async (e: any) => {
    let notice = {
      ...this.state.notice,
      notice_tab: true,
      notice_tab_active: 'active',
    };
    this.setState({
      notice: notice,
      notification: {},
      arshow: {},
      chat: {},
      stats: {},
      Monthly:{},
      pinks:{},
      joined:{},
      performance: {},
      arEditModalShow: false,
      isActive: false,
    });
  };

  statsTab = async (e: any) => {
    let stats = {
      ...this.state.stats,
      stats_tab: true,
      stats_tab_active: 'active',
    };
    this.setState({
      stats: stats,
      notice: {},
      notification: {},
      arshow: {},
      chat: {},
      performance: {},
      Monthly:{},
      picks:{},
      joined:{},
      arEditModalShow: false,
      isActive: false,
    });

  }
  performanceTab = async (e: any) => {
    let performance = {
      ...this.state.performance,
      performance_tab: true,
      performance_tab_active: 'active',
    };
    this.setState({
      performance: performance,
      notification: {},
      arshow: {},
      chat: {},
      notice: {},
      stats: {},
      Monthly:{},
      pinks:{},
      joined:{},
      arEditModalShow: false,
      isActive: false,
    });
  };
  myPicks = async (e: any) => {
    let chat = {
      ...this.state.chat,
      my_picks: true,
      now: false,
      now_chat_active: '',
      edit: false,
    };
    this.setState({
      chat: chat,
      arEditModalShow: false,
    });
  };
  closeMyPicks = async (e: any) => {
    let chat = { ...this.state.chat, my_picks: false };
    this.setState({
      chat: chat,
    });
  };

  nowChat = async (e: any) => {
    let chat = {
      ...this.state.chat,
      my_picks: false,
      now: true,
      now_chat_active: 'active',
      edit: false,
    };
    this.setState({
      chat: chat,
      arEditModalShow: false,
    });
  };
  closeNowChat = async (e: any) => {
    let chat = { ...this.state.chat, now: false, now_chat_active: '' };
    this.setState({
      chat: chat,
    });
  };
  editChat = async (e: any) => {
    let chat = {
      ...this.state.chat,
      my_picks: false,
      now: false,
      now_chat_active: '',
      edit: true,
    };
    this.setState({
      chat: chat,
    });
  };

  onCloseEdit = async (e: any) => {
    let chat = { ...this.state.chat, edit: false };
    this.setState({
      chat: chat,
    });
  };
  closeNotice = async (e: any) => {
    let notice = {
      ...this.state.notice,
      notice_tab: false,
      notice_tab_active: '',
    };
    this.setState({
      notice: notice,
    });
  };
  closePerformance = async (e: any) => {
    let performance = {
      ...this.state.performance,
      performance_tab: false,
      performance_tab_active: '',
    };
    this.setState({
      performance: performance,
    });
  };

  getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
    const contextData = this.context;
    let profile = contextData.profile;
    const mapInstance = store.getState().address.mapInstance;
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }

    let res = getMarkerLocation(lat, lng, dome_addr);
    mapInstance.setCenter(res.center);
    this.setState({
      marker: res.marker,
    });
  };

  chatWithUserClick = (user) => {
    console.log(user);
    this.setState({
      chat: {
        now: true,
      },
      selectedUserForChat: user.spikaID,
    });
  };

  // state.chat.now

  renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return '';
  };


  

  render() {
    const { translation } = this.props
    const context = this.context;
    const profile = context.profile || {};
    const admin_dome = profile.admin_dome || {};
    const dome_type = admin_dome.dome_type || '0';
    const isActive = this.state.isActive;


    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">&#62;{translation('Dome Admin')}</h4>
              <div className="fx-aic">
                <div className="size12 text-primary">
                  <p className="mb-3 text-primary">
                  {translation('Dome Address')}:{' '}
                    <em>
                      {admin_dome.addr_status ? (
                        <span className="published">{translation('Published')}</span>
                      ) : (
                        <span className="unpublished">{translation('Unpublished')}</span>
                      )}
                    </em>
                  </p>
                </div>
                <i onClick={this.closeAdmin} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="domeAddContainer">
              <div className="let-long-loop domeAddLoop">
                <div className="let-long-loop__left">
                  <div className="let-long-loop__left__row mb-10">
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>
                        this.getLocation(
                          admin_dome.location.y,
                          admin_dome.location.x,
                          admin_dome.addr_name,
                          e
                        )
                      }
                      className="let-long-loop__left__row__icon"
                    >
                      <img src={locationIcon} alt="Location" />
                    </i>
                    <div className="let-long-loop__left__row__info" translate='no'>
                      <h4 className="font-bold mb-0">
                        <span className="text-muted">@</span>
                        {/* {admin_dome.dome_status ? admin_dome.dome_name : ''} */}
                        {this.renderDomeName(
                          admin_dome.dome_status,
                          admin_dome.dome_name,
                          admin_dome.dome_name_suffix,
                          admin_dome.file
                        )}
                      </h4>
                      <p className="text-dark size13 mb-0">
                        {admin_dome.addr_name}
                      </p>
                      <div className="let-long-loop__left__row__info__row">
                        <div className="let-long-loop__left__row__info__row__info size10">
                          <div className="mr-10">
                            <p className="mb-0">{translation('Latitude')}</p>
                            <p className="mb-0">{admin_dome.location.x}</p>
                          </div>
                          <div className="mb-0">
                            <p className="mb-0">{translation('Longitude')}</p>
                            <p className="mb-0">{admin_dome.location.y}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="domeAdminScroll">
                <div className="fx-jcc fx-fw mt-15">
                  <button
                    onClick={this.notificationTab}
                    className={
                      'ant-btn primary-ghost-btn mb-5 xs mr-3 ' +
                      this.state.notification.notification_tab_active
                    }
                  >
                    {' '}
                    {translation('Notifications')} 
                  </button>
                  <button
                    onClick={this.arshowTab}
                    className={
                      'ant-btn primary-ghost-btn mb-5 xs mr-3 ' +
                      this.state.arshow.arshow_tab_active
                    }
                  >
                    {' '}
                    {translation('ARshow')} 
                  </button>
                  <button
                    // onClick={this.chatTab}
                    // className={
                    //   'ant-btn primary-ghost-btn mb-5 xs mr-10 ' +
                    //   this.state.chat.chat_tab_active
                    // }
                    disabled={true} className={'ant-btn primary-ghost-btn mr-3 xs '}
                  >
                    {' '}
                    Q & A
                  </button>
                  
                  <button
                    onClick={this.noticeTab}
                    className={
                      'ant-btn primary-ghost-btn mb-5 xs mr-3 ' +
                      this.state.notice.notice_tab_active
                    }
                  >
                    {' '}
                    {translation('Noticeboard')}  
                  </button>

                  <button
                    // onClick={this.statsTab}
                    // className={
                    //   'ant-btn primary-ghost-btn mb-5 xs '
                    //   + this.state.stats.stats_tab_active
                    // }
                    disabled={true} className={'ant-btn primary-ghost-btn mr-3 xs '}
                  >
                    {' '}
                    {translation('Stats')}  
                  </button>


                  {dome_type == 1 ||dome_type ==5 ? null : (
                    <button
                      onClick={this.performanceTab}
                      className={
                        'ant-btn primary-ghost-btn xs mb-5 mr-3 ' +
                        this.state.performance.performance_tab_active
                      }
                    >
                      {' '}
                      {translation('Performance Schedule')} 
                    </button>
                  )}
                </div>

                <div className="fx-jcfe mt-15">
                  {/* <button onClick={this.noticeTab} className={ "ant-btn primary-ghost-btn xs " + this.state.notice_tab_active }> Notice</button> */}
                </div>

                <div className="tab-content-box">
                  {/* notifications-tab */}
                  {this.state.notification.notification_tab && (
                    <div id="notifications-tab" className="tab-pane">
                      <div className="mb-10">
                        <i className="blueBox mr-5"></i>
                        <span className="text-primary mr-5">{translation('Notifications')}</span>
                        <div className="infoTip">
                          <span
                            className="infoTip__icon"
                            data-for="soclose"
                            data-tip="8"
                          >
                            &nbsp;
                          </span>
                          <ReactTooltip
                            id="soclose"
                            getContent={(dataTip) => (
                              <div>
                                <p>
                                {translation('Make the notification')}
                                  {/* Make the notification to the users who are
                                  interested in your service. */}
                                </p>
                              </div>
                            )}
                            effect="solid"
                            delayHide={500}
                            delayShow={500}
                            delayUpdate={500}
                            place={'right'}
                            border={true}
                            type={'light'}
                          />
                        </div>
                      </div>
                      <div className="fx-jcc">
                        <button
                          onClick={this.createNotification}
                          className={
                            this.state.notification.create_notification
                              ? 'ant-btn primary-ghost-btn xs mr-10 active'
                              : 'ant-btn primary-ghost-btn xs mr-10'
                          }
                        >
                         {translation('Create new notifications')} 
                        </button>
                        <button
                          onClick={this.createTemplate}
                          className={
                            this.state.notification.create_template
                              ? 'ant-btn primary-ghost-btn xs active'
                              : 'ant-btn primary-ghost-btn xs'
                          }
                        >
                          {translation('Use a template')}
                        </button>
                      </div>
                    </div>
                  )}
                  {/*stats-tab*/}
                  {this.state.stats.stats_tab && (
                    <div className="fx-jcc fx-fw mt-15">
                      <button
                         onClick={this.Monthlyvisitors}
                        className={
                          'ant-btn primary-ghost-btn mb-5 xs ' +
                          this.state.Monthly.Monthly_tab_active
                        }
                      >
                        {' '}
                        {translation('Monthly visitors')} 
                      </button>

                      <button
                         onClick={this.picksTab}
                        className={
                          'ant-btn primary-ghost-btn mb-5 xs ml-10 mr-10 ' 
                           + this.state.picks .picks_tab_active
                        }
                      >
                        {' '}
                        {translation('Picks')} 
                      </button>
                      <button
                         onClick={this.joinedTab}
                        className={
                          'ant-btn primary-ghost-btn mb-5 xs mr-10 '
                            + this.state.joined.joined_tab_active
                        }
                      >
                        {' '}
                       {translation("Joined")} 
                      </button>
                    </div>

                  )}


                  {/* arshow-tab */}
                  {this.state.arshow.arshow_tab && (
                    <DomeArshow
                      onArEditClick={this.onArEditClick}
                      closeArShow={this.closeArShow}
                      arshowData={this.state.arshowData}
                      handleFile={this.handleFile}
                      removeUrlHandler={this.removeUrlHandler}
                      addMore={this.addMore}
                      setValuesForarshowDataOnSave={
                        this.setValuesForarshowDataOnSave
                      }
                    />
                  )}
                  {/* Dome Performance */}
                  {/* {this.state.performance.performance_tab_active && (
                    <DomePerformance closePerformance={this.closePerformance} />
                  )} */}
                  {/* chat-tab */}
                  {this.state.chat.chat_tab && (
                    <div>
                      <div className="fx-jcsb-aic mb-20">
                        <h4 className="text-primary mb-0 font-bold">
                          {' '}
                          {/* &#62; Chat */}
                        </h4>
                        {/* <i
                          onClick={this.props.closeCreateTemplate}
                          className="iconx cursor"
                        >
                          <img src={closeIcon} alt="Info" />
                        </i> */}
                      </div>
                      <div
                        style={{ height: '300px' }}
                        className="domeAddContainer scrollSection fx-jcc-aic"
                      >
                        {/* <div className="text-center">
                          <figure className="mb-3">
                            <img src={logoPic} alt="logo" />
                          </figure>
                          <h2 className="text-muted mb-0">Coming soon</h2>
                        </div> */}

                        <Row>
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: '100px' }} >Date</th>
                                <th style={{ width: '160px' }} className='text-left'>Title</th>
                                <th style={{ width: '110px' }} className="text-left">
                                  Username
                                </th>
                                <th style={{ width: '72px' }} className="text-left">&nbsp;</th>
                              </tr>
                            </thead>
                          </table>
                          <div className='domeAddContainer scrollSection' style={{ maxHeight: '36vh' }}>
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td style={{ width: '100px'}} className='text-left'>01/24/2023</td>
                                  <td style={{ width: '160px'}} className='text-left'>When do you visit?</td>
                                  <td style={{ width: '110px'}} className='text-left'>same1</td>
                                  <td style={{ width: '72px' }} className="text-left">
                                    <button className='iconx xs'><img src={editIcon}
                                    onClick={this.createQandA} alt="Edit" height="14" /></button>
                                    <button className='iconx xs'><img src={deleteIcon} alt="Delete" height="14" /></button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Row>
                        
                      </div>
                    </div>

                    // <div id="chat-tab" className="tab-pane ">
                    //   <div className="fx-jcsb-aic mb-10">
                    //     <h4 className="text-primary mb-0 font-bold">
                    //       &#62; Chat
                    //     </h4>
                    //     {/* <button className="iconx">
                    // 			<img onClick={this.editChat} src={editIcon} alt="icon" height="14" />
                    // 		</button> */}
                    //   </div>
                    //   <div className="fx-jcc">
                    //     <button
                    //       onClick={this.myPicks}
                    //       className={
                    //         this.state.chat.my_picks
                    //           ? 'ant-btn primary-ghost-btn xs mr-10 active'
                    //           : 'ant-btn primary-ghost-btn xs mr-10'
                    //       }
                    //     >
                    //       My Picks
                    //     </button>
                    //     <button
                    //       // onClick={this.nowChat}
                    //       onClick={this.toggleClass}
                    //       className={
                    //         this.state.chat.now
                    //           ? 'ant-btn primary-ghost-btn xs active'
                    //           : 'ant-btn primary-ghost-btn xs'
                    //       }
                    //     >
                    //       1:1 Chat List
                    //     </button>
                    //   </div>
                    // </div>
                  )}

                    {this.state.Monthly.Monthly_tab && (
                    <div className='pt-15'>
                        {/* <div className="text-center">
                          <figure className="mb-3">
                            <img src={logoPic} alt="logo" />
                          </figure>
                          <h2 className="text-muted mb-0">Coming soon</h2>
                        </div> */}

                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="250px"
                          data={data}
                          options={options}
                        />
                        
                      
                    </div> 
                  )}

                  {this.state.picks.picks_tab && (
                    <div className='pt-15'>
                      <Row>
                        <div className="text-center text-primary mb-5">Picks(328)</div>
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: '116px' }} >Date</th>
                              <th style={{ width: '127px' }} className='text-left'>Username</th>
                              <th style={{ width: '185px' }} className="text-left">Number of visit (Latest Month)</th>
                            </tr>
                          </thead>
                        </table>
                        <div className='domeAddContainer scrollSection' style={{ maxHeight: '36vh' }}>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: '116px' }} className='text-left'>01/24/2023</td>
                                <td style={{ width: '127px' }} className='text-left'>Zest</td>
                                <td style={{ width: '185px' }} className='text-center'>1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Row>
                        
                      
                    </div> 
                  )}

{this.state.joined.joined_tab && (
                    <div className='pt-15'>
                      <Row>
                        <div className="text-right text-primary mb-5 pr-50">Joined(122)</div>
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: '116px' }} >Date</th>
                              <th style={{ width: '127px' }} className='text-left'>Username</th>
                              <th style={{ width: '185px' }} className="text-left">Number of visit (Latest Month)</th>
                            </tr>
                          </thead>
                        </table>
                        <div className='domeAddContainer scrollSection' style={{ maxHeight: '36vh' }}>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: '116px' }} className='text-left'>01/24/2023</td>
                                <td style={{ width: '127px' }} className='text-left'>Zest</td>
                                <td style={{ width: '185px' }} className='text-center'>1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Row>
                        
                      
                    </div> 
                  )}



                  <div
                    className={
                      isActive ? 'chat-list-block' : 'chat-list-block d-none'
                    }
                  >
                    <div className="chat-list-block__form">
                      <form>
                        <input
                          type="text"
                          placeholder="Nickname"
                          className="form-control"
                        />
                        <input type="submit" value="" className="searchBtn" />
                      </form>
                    </div>
                    <div className="picks-joined-main">
                      <div className="picks-joined-main__header">
                        <div className="text-primary">
                          <img src={picksIcon} alt="icon" /> Picks(
                          {this.state.chat_picked_total})
                        </div>
                        <div className="text-primary">
                          <img src={joinedEllipse} alt="icon" /> Joined(
                          {this.state.chat_joined_total})
                        </div>
                      </div>
                      <div className="picks-joined-main__body">
                        {this.state.chatJoinedUsers?.length
                          ? this.state.chatJoinedUsers.map((user) => {
                            return (
                              <div
                                style={{ padding: '8px 0px' }}
                                onClick={() => this.chatWithUserClick(user)}
                                key={user.id}
                                className="picks-joined-loop"
                              >
                                <div
                                  style={{
                                    width: '75%',
                                    fontSize: '12px',
                                  }}
                                  className="picks-joined-loop__left"
                                >
                                  <figure className="userIcon">
                                    {user?.file?._id ? (
                                      <img
                                        style={{
                                          width: '35px',
                                          height: '35px',
                                          objectFit: 'cover',
                                        }}
                                        src={`https://app.loamoa.cladev.com/uploads/${user.file._id}`}
                                        alt=""
                                      />
                                    ) : (
                                      <img src={burgerKing} alt="" />
                                    )}
                                    {/* <img src={burgerKing} alt="" /> */}
                                  </figure>
                                  <span className="picks-joined-loop__left__username">
                                    {user.nickname}
                                  </span>
                                </div>
                                <div
                                  style={{ width: '24%' }}
                                  className="picks-joined-loop__right"
                                >
                                  <img
                                    style={{ height: '15px', width: '15px' }}
                                    src={picksIcon}
                                    alt="icon"
                                    className="mr-5"
                                  />
                                  <img
                                    style={{ height: '15px', width: '15px' }}
                                    src={joinedEllipse}
                                    alt="icon"
                                    className="mr-5"
                                  />
                                  {/* <span>Jan.21.2022</span> */}
                                  <i
                                    style={{ height: '18px', width: '18px' }}
                                    className="picks-joined-loop__right__count"
                                  >
                                    2
                                  </i>
                                </div>
                              </div>
                            );
                          })
                          : null}

                        {/* <div className="picks-joined-loop">
                          <div className="picks-joined-loop__left">
                            <figure className="userIcon">
                              <img src={burgerKing} alt="" />
                            </figure>
                            <span className="picks-joined-loop__left__username">
                              Zest
                            </span>
                          </div>
                          <div className="picks-joined-loop__right">
                            <img src={picksIcon} alt="icon" className="mr-5" />
                            <img
                              src={joinedEllipse}
                              alt="icon"
                              className="mr-5"
                            />
                            <span>Jan.21.2022</span>
                            <i className="picks-joined-loop__right__count">2</i>
                          </div>
                        </div>
                        <div className="picks-joined-loop">
                          <div className="picks-joined-loop__left">
                            <figure className="userIcon">
                              <img src={burgerKing} alt="" />
                            </figure>
                            <span className="picks-joined-loop__left__username">
                              Zest
                            </span>
                          </div>
                          <div className="picks-joined-loop__right">
                            <img src={picksIcon} alt="icon" className="mr-5" />
                            <img
                              src={joinedEllipse}
                              alt="icon"
                              className="mr-5"
                            />
                            <span>Jan.21.2022</span>
                            <i className="picks-joined-loop__right__count">2</i>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* notice-tab */}
                  {/* {this.state.notice_tab &&
									<div id="notice-tab" className="tab-pane"></div>
								} */}
                </div>
              </div>
            </div>
          </div>
        </Row>

        {this.state.performance.performance_tab_active && (
          <DomePerformance closePerformance={this.closePerformance} />
        )}

        {this.state.notification.create_notification && (
          <DomeNotification
            closeCreateNotification={this.closeCreateNotification}
          />
        )}

        {this.state.notification.create_template && (
          <DomeTemplate closeCreateTemplate={this.closeCreateTemplate} />
        )}
        {this.state.QandA.create_QandA && (
          <QandA1 closeQandA={this.closeQandA} />
        )}

        {/* My Picks */}
        {this.state.chat.my_picks && (
          <MyPicks closeMyPicks={this.closeMyPicks} />
        )}
        {/* Now */}
        {this.state.chat.now && (
          <ChatNow
            selectedUserForChat={this.state.selectedUserForChat}
            closeNowChat={this.closeNowChat}
          />
        )}
        {/* Chat Edit */}
        {this.state.chat.edit && <ChatEdit onCloseEdit={this.onCloseEdit} />}
        {/* Notice */}
        {this.state.notice.notice_tab && (
          <DomeNotice closeNotice={this.closeNotice} />
        )}
        {this.state.arEditModalShow && (
          <ArEditModal
            index={this.state.selectedArEditIndex}
            closeModal={this.onArEditClickClose}
            arEditImagePreview={this.state.preview}
            setValuesForarshowDataOnSave={this.setValuesForarshowDataOnSave}
            loadAgain={this.state.loadAgain}
            selectedEditDataForArShow={this.state.selectedEditDataForArShow}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});

export default connect(mapStateToProps, null)(DomeAdmin);
