import React from 'react';
import LocationPricingData from './LocationPricingData';
import { connect } from 'react-redux';

interface Props {
  locations: any;
  messageLocations?: any;
  treasure: any;
}

const mapState = (state) => {
  const { locations, messageLocations, treasure } = state;
  return { locations, messageLocations, treasure };
};

const LocationPricingDataList = (props: Props) => {
  return (
    <>
      {props.locations.map((location: any, i: number) => {
        let count: any = [];

        props.messageLocations.forEach((messageLocation: any) => {
          if (messageLocation.locationID === location.ID) {
            count.push(location.ID);
          }
        });
        return (
          <LocationPricingData
            lat={location.latitude}
            lng={location.longitude}
            numberOfUnits={location.numberOfUnits}
            messagingLocations={count.length}
            key={i}
            numberOfLocation={i}
            locationPrice={props.treasure.price * location.numberOfUnits}
          />
        );
      })}
    </>
  );
};

export default connect(mapState)(LocationPricingDataList);
