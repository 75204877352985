import { userConstants } from '../constants/signinConstants';
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_LANGUAGE, LOCAL_STORAGE_COUNTRY } from '../lib/config';

let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
let language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
let defaultLanguage = 'EN';
const prefferredLanguage = language ? language : defaultLanguage;

export const initialStateAuth = user
  ? {
      loggedIn: true,
      user,
      language: prefferredLanguage,
    }
  : {
      language: defaultLanguage,
    };

export function authentication(state = initialStateAuth, action: any) {
  switch (action.type) {
    case userConstants.SEND_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    case userConstants.SEND_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        waitingVerification: true,
        phoneNumber: action.phoneNumber,
      };
    case userConstants.SEND_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        showMasterInfoButton: true,
        phoneNumber: action.phoneNumber,
      };
    case userConstants.CANCEL:
      return {
        ...state,
        waitingVerification: false,
        phoneNumber: '',
      };
    case userConstants.SIGNIN_REQUEST:
      return {
        ...state,
        waitingVerification: true,
        loggingIn: true,
      };
    case userConstants.SIGNIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.response,
      };
    case userConstants.SIGNIN_FAILURE:
      return {
        ...state,
        waitingVerification: true,
      };
    case userConstants.LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case userConstants.COUNTRY_CODE:
      return {
        ...state,
        country: action.country,
      };
    default:
      return state;
  }
}
