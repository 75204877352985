
import React, { useState, useEffect } from 'react';
import { Table,Paginate } from '../../../../components';
import ReactPaginate from 'react-paginate';
import ChevronLeft from '../../../../assets/chevronLeft.svg';
import ChevronRight from '../../../../assets/chevronRight.svg';
import { Row, Spin, Col, message } from 'antd';
import {getReceivedPlumaApiSuperAdmin}  from '../../../../services/domeService';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import moment from 'moment';
import DomePlums from '../statistics/DomePlums';
import Search from 'antd/lib/input/Search';
function getWindowDimensions() {
 
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
  );
  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const  PlumReceived = (props:any) => {
  const [data, setData] = useState([])
  const [isActive, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [length1, setLength1] = useState(0);
  const [total_plum_received,setTotal_plum_received]=useState(0)
  const [pagination,setPagination]=useState(0)
  const [pagcurrent, setPagCurrent]=useState(1)
  // const [usersdonated,setUsersdonated ]=useState("")
 
 

  useEffect(() => {
    
    (async () => {
      setLoading(true);
            let data = await getReceivedPlumaApiSuperAdmin(1,props.datafun.ReceivedSearch
               ,props.datafun.Rec1,props.datafun.Rec2); 
      setData(data.data);
      setPagination(data.pagination.total_pages)
      setPagCurrent(data.pagination.current)
      setLength1(data.data.length)
      setTotal_plum_received(data.total_plum_received)
      setLoading(false);
      propFun(data.data.length,data.total_plum_received,
        data.tab_data[0].donated,
        data.tab_data[0].received,data.tab_data[0].sumplum );
        
    })();
  }, [props.datafun.ReceivedSearch,props.datafun.Rec1,props.datafun.Rec2])
  
const propFun=(le:any,data:any,user:any,rec:any,sum:any)=>{
  props.props(le,data,user,rec,sum)
  console.log("123456",le,data,user,rec,sum)
}

const onPageChange=async (e:any)=>{
  let page_no=e.selected
  let data = await getReceivedPlumaApiSuperAdmin(page_no+1,props.datafun.ReceivedSearch,props.datafun.Rec1,props.datafun.Rec2)
  setData(data.data);
  setLength1(data.data.length)
  setTotal_plum_received(data.total_plum_received)
  setPagCurrent(data.pagination.current)
  setLoading(false);

}



  let columns = [
    {
      title: 'Number',
      key: 'Number',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, record) => <span>{indx + (pagcurrent-1)*500+1}</span>,
    },
    

    {
      title: 'Username who received',
      key:  ' Username who received',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, item) => <span>
      
         {[item.donated_to_user]} 
      </span>,
    },

    {
      title: ' Dome Name',
      key:  'Dome Name',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, item) => <span>
       
        {[item.dome_name]}
      </span>,
    },

    {
      title: 'Username who donated',
      key: 'Username who donated',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, item) => <span>
      
         {[item.nickname]}
      </span>,
    },
    
    {
      title: 'Plum received',
      key: 'Plum received ',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, items) => <span className={items.is_expired==1?'text-error':''}>
        <span>
        {[items.plum]}
          {'   '}
        </span>
      </span>,
    },


    {
      title: 'Date',
      key: 'Date',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, index, item) => {
        return <span>
          {moment(item.date).format('MM/DD/YY')}
        </span>;
      },
    },

    {
      title: 'Expired',
      key: 'Expired',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, item) => <span className={item.is_expired==1?'text-error':''}>
         {moment(item.expired_at).format('MM/DD/YY')}
      </span>,
    },


    {
      title: 'Returned due to Expired',
      key: 'Returned due to Expired',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, item) => <span   className='text-error'> 
       { [item.is_expired==1?item.plum :""]} 
      </span>,
    },

  ];
  
  const { width, height } = useWindowDimensions();
  return (    
     <>
     <div className='plum-received'>
        <Table
            width={width - 70}
            height={height - 220}
            // language={language}
            loading={loading}
            dataSource={data}
            columns={columns}
             

            // {...otherProps} 
            />
            <Row style={{ marginTop: '30px' }} type="flex" justify="center">
                      <Col>                
        <ReactPaginate
        containerClassName="statistic-paging-container"
        pageClassName="statistic-paging-page"
        pageLinkClassName="statistic-paging-page-link"
        activeLinkClassName="statistic-paging-page-link-active"
        pageCount={pagination}
        previousLabel={<img src={ChevronLeft} />}
        nextLabel={<img src={ChevronRight} />}
        marginPagesDisplayed={1}
        onPageChange={onPageChange}
        
      />
      </Col>
    </Row>
    </div>
  </>

    



  )
};
export default PlumReceived;

PlumReceived.defaultProps = {
  language: 'EN',
  onPageChange: () => { },
  pageSize: 2,
};

