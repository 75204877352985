import React, { Component } from 'react';
import { Row, Col } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
//import CaseDetails from './caseDetails';
import CaseDetails from '../support/caseDetails';
//import { PointResult } from '../../../../lib/strings/treasureCreation';
import { getSupportCases, getSupportMessage } from '../support/supportService';
import moment from 'moment';

class HelpSupport extends Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      support_cases: [
        {
          id: 0,
          date_time: '',
          subject: '',
          status: '',
          user_id: 0,
          rate: null,
        },
      ],
      users_list: {},
      case_details: false,
      case_id: 0,
      status: 1,
      checked: false,
      details: [],
      value: 0,
      selectedUserId: null,
    };
  }

  caseDetails = async (id, subject, date_time, status, value, e, userId) => {
    // this.closeCaseDetails();
    let profile = await this.getContext();
    const red_case_id = localStorage.getItem('red_case_id');
    if (red_case_id == id) {
      localStorage.setItem('red_case_id_dot', 0);
    }
    const message = await getSupportMessage(id);
    // let profile = this.getContext();
    // profile['support__mesaages'] = message;

    this.setState({
      case_details: true,
      case_id: id,
      subject: subject,
      date_time: date_time,
      status: status,
      details: message,
      checked: status == 0 ? true : false,
      value: value,
      selectedUserId: userId,
    });
    profile['support__mesaages'] = message;

    await this.updateContext(profile);
  };

  close = () => {
    this.closeCaseDetails();
    let profile = this.getContext();
    profile['openMasterSupport'] = false;
    profile['support'] = false;
    this.updateContext(profile);
  };

  closeCaseDetails = () => {
    this.setState({
      case_details: false,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;

    context.setProfile(data);
  };
  componentDidMount = async () => {
    // call API inside this
    const cases = await getSupportCases();
    if (cases?.data?.length) {
      const red_case_id = localStorage.getItem('red_case_id');
      if (red_case_id != cases.data[0].id) {
        localStorage.setItem('red_case_id', cases.data[0].id);
        localStorage.setItem('red_case_id_dot', 1);
      }
    }

    this.setState({
      support_cases: cases?.data,
      users_list: cases?.users,
    });
  };

  updateStatus = (id) => {
    // console.log(this.state.support_cases);
    let support = this.state.support_cases;
    let update_cases = support.map((s) => {
      if (s.id == id) {
        return { ...s, status: s.status == 1 ? 0 : 1 };
      }
      return s;
    });

    this.setState({
      support_cases: update_cases,
      checked: !this.state.checked,
    });
  };

  render() {
    let red_case_id_dot = localStorage.getItem('red_case_id_dot') || 0;
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62; Support cases
              </h4>
              <div className="fx-aic">
                <i onClick={this.close} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="domeAddContainer scrollSection ">
              <table className="table">
                <thead>
                  <tr className="text-primary">
                    <th>Created</th>
                    <th>Subject</th>
                    <th>Username</th>
                    <th>Rate</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.support_cases.map((s, indx) => (
                    <tr key={s.id}>
                      <td>
                        {s.date_time
                          ? moment(s.date_time).format('MMMM DD YYYY')
                          : ''}
                      </td>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={(e) =>
                          this.caseDetails(
                            s.id,
                            s.subject,
                            s.date_time,
                            s.status,
                            s.rate,
                            e,
                            s.user_id
                          )
                        }
                      >
                        <u>
                          {s.subject.substring(0, 20)}
                          {s.subject.length > 20 ? '----' : ''}{' '}
                        </u>
                        {indx === 0 && red_case_id_dot != 0 && (
                          <span
                            style={{
                              display: 'inline-block',
                              background: 'red',
                              width: '5px',
                              height: '5px',
                              borderRadius: '50%',
                              marginLeft: '3px',
                              marginBottom: '3px',
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {this.state.users_list &&
                        this.state.users_list[s?.spikaID]
                          ? this.state.users_list[s?.spikaID]
                          : ''}
                      </td>
                      <td>{s.rate ? s.rate : ''}</td>
                      <td>{s.status == 0 ? 'Resolved**' : 'Not resolved'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Row>

        {this.state.case_details && (
          <CaseDetails
            close={this.closeCaseDetails}
            issue_status={this.state.status}
            subject={this.state.subject}
            date_time={this.state.date_time}
            case_id={this.state.case_id}
            details={this.state.details}
            checked={this.state.checked}
            value={this.state.value}
            updateStatus={this.updateStatus}
            selectedUserId={this.state.selectedUserId}
          />
        )}
      </>
    );
  }
}
export default HelpSupport;
