import React, { ChangeEvent, useState, useEffect } from 'react';
import { Row, Button, Typography, Col, Input, Checkbox } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import searchIcon from '../../../../assets/search.svg';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import User from './User';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { getAllUsersList, getIdolUsersList } from '../../../../services/notificationsService';
import { audience, selectAll } from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';

interface Props {
  handleClose: () => Action;
  category: number;
  users?: Array<any>;
  storeUsers: (users: Array<any>) => Action;
  selectedUsers: (user: any) => Action;
  userIDs: any;
  subMenu1: string;
  contestID: number;
  contestUserType: string;
  contestantIDs: Array<any>;
  language: languages;
}

const mapState = (state: {
  notification: any;
  userList: any;
  subMenu1: string;
  authentication: any;
}) => {
  return {
    category: state.notification.category,
    users: state.userList,
    userIDs: state.notification.userIDs,
    subMenu1: state.subMenu1,
    contestID: state.notification.contestID,
    contestUserType: state.notification.contestUserType,
    contestantIDs: state.notification.contestantIDs,
    language: state.authentication.languages,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
    storeUsers: (users: Array<any>) =>
      dispatch({ type: notificationsConstants.STORE_USERS, users }),
    selectedUsers: (user: any) => dispatch({ type: notificationsConstants.SELECTED_USERS, user }),
  };
};

const SearchUsers = (props: Props) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const indeterminateCheckbox = (e: any) => {
    setIndeterminate(false);
    e.target.checked
      ? props.selectedUsers(props.users.map((users) => users.mysqlID))
      : props.selectedUsers([]);
  };

  const hanldeCheckboxGroup = (checked: CheckboxValueType[]) => {
    setIndeterminate(true);
    props.selectedUsers(checked);
  };

  const getNewPage = async (which: string) => {
    let pageNo = pageNumber;
    which === 'prev' ? pageNo-- : pageNo++;
    const res = await getAllUsersList(pageNo);
    props.storeUsers(res);
    setPageNumber(pageNo);
  };

  const fetchUsersByType = async () => {
    if (!props.contestID) {
      const res = await getAllUsersList(1);
      props.storeUsers(res);
    } else {
      switch (props.contestUserType) {
        case 'voters':
          let x = await getIdolUsersList(1, props.contestID, props.contestUserType);
          props.storeUsers(x);
          break;
        case 'contestants':
          let y = await getIdolUsersList(1, props.contestID, props.contestUserType);
          props.storeUsers(y);
        case 'votersOfContestants':
          props.contestantIDs.forEach(async (id) => {
            const res = await getIdolUsersList(1, props.contestID, props.contestUserType, null, id);
            props.storeUsers(res);
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    fetchUsersByType();
  }, []);

  useEffect(() => {
    fetchUsersByType();
  }, [props.contestUserType]);

  const searchUsers = async (e: ChangeEvent<HTMLInputElement>) => {
    const res = await getAllUsersList(1, e.target.value);
    props.storeUsers(res);
  };

  return (
    <Row className="kit-list-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="space-between" align="middle">
          <Col>
            <Button onClick={props.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {audience[props.language]}
            </Typography.Text>
          </Col>
          <Col style={{ marginRight: '1em' }}>
            <Typography.Text className="bold">{props.userIDs.length} </Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="search-container">
          <Row className="search-input-container">
            <Input.Search
              onChange={searchUsers}
              className="search-input"
              placeholder="Search"
              prefix={<img src={searchIcon} />}
            />
          </Row>
          {props.users.length > 0 ? (
            <Row>
              {/* <Row type='flex' justify='space-between' className='full-width'>
                            <Typography.Text>{selectAll[props.language]}</Typography.Text>
                            <Checkbox onChange={(checked) => indeterminateCheckbox(checked)} indeterminate={indeterminate} />
                        </Row> */}
              <Checkbox.Group
                value={props.userIDs}
                className="full-width"
                onChange={(checked) => hanldeCheckboxGroup(checked)}
              >
                {props.users.map((user: any, index) => {
                  return (
                    <User
                      ID={user.mysqlID}
                      key={user.mysqlID}
                      username={user.username}
                      avatar={user.avatar}
                    />
                  );
                })}
              </Checkbox.Group>
            </Row>
          ) : null}
        </Row>
        <Row>
          <Button onClick={() => getNewPage('prev')}>{'<'}</Button>
          <Button onClick={() => getNewPage('next')}>{'>'}</Button>
        </Row>
      </div>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(SearchUsers);
