import React, { Component } from 'react';

import cartIcon from '../../../../src/assets/icon_cart.svg';
import infoIcon from '../../../../src/assets/icon_info.svg';
// import { connect } from 'react-redux';
import AppContext from '../../../AppContext';

import { store } from '../../../helpers/store';
import Infobox from './infobox';
import { createDome, addressName, baseURL } from '../../../lib/config';
import { addToCart, getCart } from '../../../services/addToCartService';
import { registerDome } from '../../../services/domeService';
// import Dashboard  from '../../../assets/icon_dashboard.svg';
// import Dashboard  from '../../../assets/icon_dashboard.svg';

import changeIcon1 from '../../../assets/changeIcon1.png';

import Geocode from 'react-geocode';
import Profile from '../menu/Profile';
export const gmk = 'AIzaSyAC7FC5wCXyfRRFiA5b3zQ1hF85iz_8EM4';

// import {Debounce} from './autoComplete';
import Autocomplete from 'react-google-autocomplete';
import { notification } from 'antd';
import { connect } from 'react-redux';
import {b} from '../../dashboard/menu/i18next'
import { Translation } from 'react-i18next';

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

interface DomCreationState {
  dom_address: string;
  enter_for_location: boolean;
  profile: any;
  dome_lat: any;
  dome_lng: any;
  error: any;
  marker: any;
  circle: any;
  dom_name: any;
  // dome_name: any;
  inserted_dome_id: number;
  dome_error: any;
  lat: any;
  lng: any;
  placeHolder: boolean;
}

interface DomCreationProps {
  domClickHandler?: () => void;
  registerDomHandler?: () => void;
  getLatLngFromAddress?: () => void;
  addToCart?: () => void;
  getCartItems?: () => void;
}

class DomCreation extends Component<any, DomCreationState> {
  static contextType = AppContext;
  autoRef: React.RefObject<unknown>;
  constructor(props: any) {
    super(props);
    this.autoRef = React.createRef();
    this.state = {
      dom_address: '',
      // dom_address: '',
      enter_for_location: false,
      profile: null,
      dome_lat: 0,
      dome_lng: 0,
      error: '',
      marker: null,
      circle: null,
      dom_name: '',
      // dome_name:
      inserted_dome_id: null,
      dome_error: false,
      lat: 0,
      lng: 0,
      placeHolder: true,
    };

    this.registerDomHandler = this.registerDomHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.getCartItems = this.getCartItems.bind(this);
  }

  _initProfile(status, name = '') {
    const context = this.context;
    let profileData = context.profile;
    profileData.register = status;
    profileData.name = name;
    profileData.dom_map = true;
    profileData.dome_lat = this.state.dome_lat;
    profileData.dome_lng = this.state.dome_lng;
    profileData.dome_name = this.state.dom_name ? this.state.dom_name : '';
    profileData.zoom = 20;

    context.setProfile(profileData);
  }

  async registerDomHandler(e: any) {
    const PageName = e.target.getAttribute('data-name');
    const context = this.context;
    let profile = context.profile;
    let profile1 = { ...profile };

    /** NEED TO REMOVE THIS IF  */
    if (this.props.role === 1100) {
      const requestOption = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addr_name: profile.dome_name,
          longitude: profile.dome_lat,
          latitude: profile.dome_lng,
        }),
      };

      const res = await fetch(baseURL + createDome, requestOption).then((r) =>
        r.json()
      );

      if (res?.data?.paid) {
        profile.iscart = null;
        profile.items = null;
        profile.is_payment = false;
        profile.year = null;
        profile.register = false;

        profile['fetch_in_boundry'] = true;
        // remove all current dome
        let markers = profile['markers'] || [];
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
        profile['markers'] = [];
        profile['is_dome_registered_successfully'] = true;
        context.setProfile(profile);
        showSuccessMessage('Dome created successfully');
      } else {
        /** NEED TO UNCOMMENT THESE */
        // if (res.code !== 1) {
        //   profile.dome_error = true;
        //   profile.register = false;
        //   // profile.dome_name = profile1.dome_name;
        //   context.setProfile(profile);
        //   this.setState({
        //     dome_error: true,
        //   });
        //   return false;
        // } else {
        //   this._initProfile(true, PageName);
        //   profile.dome_error = false;
        //   profile.register = true;
        //   profile.showItems = true;
        //   profile.iscart = false;
        //   // profile.enter_for_location = false;
        //   context.setProfile(profile);
        //   this.setState({
        //     dome_error: false,
        //   });
        // }
        /** NEED TO UNCOMMENT THESE */
      }
    } else {
      this._initProfile(true, PageName);
      profile.dome_error = false;
      profile.register = true;
      profile.showItems = true;
      profile.iscart = false;
      // profile.enter_for_location = false;
      context.setProfile(profile);
      this.setState({
        dome_error: false,
      });
    }

    /** NEED TO UNCOMMENT THESE */
    // const inserted_dome_id = res.data.inserted_dome_id;
    // profile.inserted_dome_id = inserted_dome_id;
    /** NEED TO UNCOMMENT THESE */
    // profile.inserted_dome_id = profile.dome_id;
    // profile.dome_name = this.state.dom_name;
    profile.dome_name = profile1.dome_name;
    profile.dome_lat = profile1.dome_lat;
    profile.dome_lng = profile1.dome_lng;
    context.setProfile(profile);
  }

  async getDomeName() {
    const requestOption = {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const res = await fetch(baseURL + addressName, requestOption).then((r) =>
      r.json()
    );

    const context = this.context;
    let profile = context.profile;
    profile.dome_name = res.data.name;
    context.setProfile(profile);
    return res.data;
  }

  handleChange = (e: any) => {
    this.setState({
      dom_address: e.target.value,
    });
  };

  async onClickHandler() {
    const mapInstance = store.getState().address.mapInstance;
    const lat = parseFloat(this.state.lat);
    const lng = parseFloat(this.state.lng);
    // let newCenter = new google.maps.LatLng(lat, lng);
    // mapInstance.setCenter(newCenter);
    //   let newCenter = new google.maps.LatLng(lat, lng);
    //   mapInstance.setCenter(newCenter);
    //   mapInstance.setZoom(20);

    //alert('ffgd');
    // await this.getLatLngFromAddress();
    const context = this.context;
    context.profile.iscart = false;
    context.profile.search_lat = lat;
    context.profile.search_lng = lng;
    context.profile.fetch_in_boundry = true;
    context.profile.isDraggingMap = false;
    let markers = context.profile['markers'];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    context.profile['markers'] = [];
    // context.profile.myDome = [];
    context.setProfile(context.profile);
  }

  cancelHandler = () => {
    this.setState({
      enter_for_location: false,
    });

    const context = this.context;
    context.profile.register = false;
    context.profile.is_payment = false;

    context.profile.enter_for_location = false;
    context.setProfile(context.profile);

    this._initProfile(false);
    // const mapInstance = store.getState().address.mapInstance;
    // let marker = this.state.marker;
    // let circle = this.state.circle;
    // marker.setMap(null);
    // circle.setMap(null);
  };

  goToHome = () => {
    this.setState({
      enter_for_location: false,
    });
    this._initProfile(false);
    const mapInstance = store.getState().address.mapInstance;
    let marker = this.state.marker;
    let circle = this.state.circle;
    marker.setMap(null);
    circle.setMap(null);
  };

  onCancel = () => {
    this.setState({
      lat: 0,
      lng: 0,
      placeHolder: true,
    });

    if (this.autoRef.current) {
      this.autoRef.current.value = '';
      this.autoRef.current.focus();
    }
  };

  checkMarkersWithinCircle = async (profileData) => {
    const context = this.context;
    let profile = context.profile;

    let lat = profileData.dome_lat;
    let lng = profileData.dome_lng;

    let circle = this.state.circle;
    let marker = this.state.marker;
    // let latLngCenter = new google.maps.LatLng(lat, lng);
    let bounds = circle.getBounds();

    let markers = profile.dome;

    let markerInsideCircle = [];
    profile.dome_error = false;

    for (let marker in markers) {
      var marker_lat = markers[marker].location.x;
      var marker_lng = markers[marker].location.y;
      let latLngMarker = new google.maps.LatLng(marker_lat, marker_lng);
      let iswithinRange = bounds.contains(latLngMarker);

      if (iswithinRange) {
        markerInsideCircle.push(markers[marker]);
      }

      if (iswithinRange) {
        break;
      }
    }

    if (markerInsideCircle.length > 0) {
      profile.dome_error = true;
    }
    context.setProfile(profile);
  };

  onDragEnd = async (e: any) => {
    const conext = this.context;
    let profileData = conext.profile;
    profileData.dome_lat = e.latLng.lat();
    profileData.dome_lng = e.latLng.lng();
    await this.checkMarkersWithinCircle(profileData);
    conext.setProfile(profileData);
  };

  // Get Latitude and Longitude from address to create DOME
  async getLatLngFromAddress() {
    const { lat, lng } = this.state;
    if (lat === 0) {
      this.setState({
        error: 'Address should not be an empty.',
      });
      return false;
    }
    this._initProfile(false);
  }

  addToCart = async () => {
    const context = this.context;
    let profile = context.profile;
    let data = {
      dome_name: context.profile.dome_name,
      dome_lng: context.profile.dome_lat,
      dome_lat: context.profile.dome_lng,
    };
    try {
      const cart = await addToCart(data);
      if (profile.item_count === null) {
        profile.item_count = 1;
      } else if (isNaN(profile.item_count)) {
        profile.item_count = 1;
      } else {
        profile.item_count = parseInt(profile.item_count) + 1;
      }
    } catch (error) {
      profile.dome_point_unavailable = true;
    }

    context.setProfile(profile);
    // let marker = this.state.marker;
    // let circle = this.state.circle;
    // marker.setMap(null);
    // circle.setMap(null);
  };

  componentDidMount = async () => {
    const context = this.context;
    let profile = context.profile;
    const cartItems = await getCart();
    profile.item_count = cartItems.length;
    context.setProfile(profile);
    const mapInstance = store.getState().address.mapInstance;
    //  Bind Click event with Map
    mapInstance.addListener('dblclick', this.createMarker);
  };

  // Create Marker after click on map
  createMarker = async (event) => {
    const mapInstance = store.getState().address.mapInstance;
    let center = mapInstance.getCenter();
    let lat = event.latLng.lat();
    let lng = event.latLng.lng();
    let latLng = {
      latitude: lat,
      longitude: lng,
    };
    //call API to create dome with blue icon
    const result = await registerDome(latLng);
    let profile = this.context.profile;

    profile.fetch_in_boundry = true;
    profile.dome_loader = true;
    // remove all current dome
    let markers = profile['markers'];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    profile['markers'] = [];
    this.context.setProfile(profile);

    // await this.createMarkerOnMap(lat, lng, result.dome[0] );
    mapInstance.setCenter(center);
  };

  // createMarkerOnMap = async(dome: any, latLng: any) =>{
  createMarkerOnMap = async (lat, lng, dome) => {
    // const context = this.context;
    const mapInstance = store.getState().address.mapInstance;
    let latlngset = new google.maps.LatLng(lat, lng);

    var markerIcon = {
      url: 'https://app.loamoa.cladev.com/uploads/brown.png',
      // scaledSize: new google.maps.Size(20, 20),
      labelOrigin: new google.maps.Point(10, -6),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(10, 11),
    };

    const marker = new google.maps.Marker({
      position: latlngset,
      draggable: false,
      optimized: false,
      icon: markerIcon,
      // label: {
      //   text: dome.addr_name,
      //   color: '#0275d8',
      //   className: 'marker-label',
      // },
      map: mapInstance,
    });

    marker.addListener(
      'click',
      (function (lat, lng, dome, context) {
        return function () {
          // let profile = context.profile;
          let profileData = context.profile;
          profileData.dome_lat = lat;
          profileData.dome_lng = lng;
          profileData.dome_name = dome.addr_name;
          // profileData.zoom = 20;

          context.setProfile(profileData);
          // this.setState({
          //   enter_for_location: true,
          //   dome_lat: lat,
          //   dome_lng: lng,
          //   dom_name: dome.addr_name,
          // });
          // this._initProfile(false);
        };
      })(lat, lng, dome, this.context)
    );

    marker.addListener('mouseover', function (e) {
      this.setLabel({
        text: dome.addr_name,
        color: '#0275d8',
        className: 'marker-label',
      });
    });
    marker.addListener('mouseout', function (e) {
      this.setLabel(null);
    });
  };

  openRegister = (lat, lng, dome) => {
    this.setState({
      enter_for_location: true,
      dome_lat: lat,
      dome_lng: lng,
      dom_name: dome.addr_name,
    });
    this._initProfile(false);
  };

  removePlaceHolder = () => {
    this.setState({
      placeHolder: false,
    });
  };

  getCartItems = async () => {
    const context = this.context;
    let profile = context.profile;
    const cartItems = await getCart();
    let itemsMap = [];
    let total = 0;
    for (var item in cartItems) {
      var itemMap = {};
      itemMap['id'] = cartItems[item].ID;
      itemMap['check'] = true;
      itemMap['year'] = 1;
      itemMap['base_price'] = 18;
      itemMap['amount'] = 18;
      itemMap['total'] = itemMap['year'] * itemMap['amount'];
      itemMap['name'] = cartItems[item].name;
      itemMap['is_register'] = cartItems[item].is_register;
      itemMap['latitude'] = cartItems[item].location.x
        ? cartItems[item].location.x
        : 0.0;
      itemMap['longitude'] = cartItems[item].location.y
        ? cartItems[item].location.y
        : 0.0;
      // total = total + itemMap['total'];
      itemsMap.push(itemMap);
    }

    if (itemsMap.length === 1) {
      if (!itemsMap[0]['is_register']) {
        itemsMap[0]['check'] = true;
        total = itemsMap[0]['total'];
      } else {
        itemsMap[0]['check'] = false;
        total = 0;
      }
      // itemsMap[0]['check'] = true;
      // total = itemsMap[0]['total'];
    } else {
      for (let i = 0; i < itemsMap.length; i++) {
        itemsMap[i]['check'] = false;
        total = 0;
      }
    }

    itemsMap['total'] = total;
    profile.items = itemsMap;
    // profile.checkedItems = itemsMap;
    // profile.item_count = total;
    profile.name = 'cart';
    profile.iscart = true;
    profile.fetch_in_boundry = false;
    profile.register = false;
    profile.showItems = true;
    // profile.register = true;
    context.setProfile(profile);
  };

  renderDomeStatusAfterPayment = (
    is_dome_registered_successfully,
    profile,
    dome_point_unavailable,
    isPaid
  ) => {
    if (this.context.profile.payedFromCart) {
      return (
        <>
          {this.context.profile.cartPayedItems &&
            this.context.profile.cartPayedItems.length &&
            this.context.profile.cartPayedItems.map((item) => (
              <div style={{ marginTop: '30px' }} className="addressLocation">
                {!this.context.profile.iscart ? (
                  <div className="grid-row">
                    <div className="col col-6 form-group">
                      <label>{b?.t('Latitude')}:</label>
                      <input
                        type="text"
                        value={item.dome_lng}
                        // value={parseFloat(this.context.profile.dome_lng).toFixed(
                        //   11
                        // )}
                        className="inp"
                      />
                    </div>
                    <div className="col col-6 form-group">
                      <label>{b?.t('Longitude')}:</label>
                      <input
                        type="text"
                        value={item.dome_lat}
                        // value={parseFloat(this.context.profile.dome_lat).toFixed(
                        //   11
                        // )}
                        className="inp"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="grid-row"></div>
                )}
                {!this.context.profile.iscart &&
                  is_dome_registered_successfully && (
                    <div className="cardboard-white mb-10">
                      <p className="text-primary mb-15">
                       {b?.t('It has been sucessfully registered')} 
                      </p>
                      <h4 className="font-bold">
                        {item.dome_name}
                        <img src={changeIcon1} alt="check" />
                      </h4>
                    </div>
                  )}
              </div>
            ))}
        </>
      );
    } else {
      return (
        <div className="addressLocation">
          {!this.context.profile.iscart ? (
            <div className="grid-row">
              <div className="col col-6 form-group">
                <label>{b?.t('Latitude')}:</label>
                <input
                  type="text"
                  value={this.context.profile.dome_lng}
                  // value={parseFloat(this.context.profile.dome_lng).toFixed(
                  //   11
                  // )}
                  className="inp"
                />
              </div>
              <div className="col col-6 form-group">
                <label>{b?.t('Longitude')}:</label>
                <input
                  type="text"
                  value={this.context.profile.dome_lat}
                  // value={parseFloat(this.context.profile.dome_lat).toFixed(
                  //   11
                  // )}
                  className="inp"
                />
              </div>
            </div>
          ) : (
            <div className="grid-row"></div>
          )}
          {!this.context.profile.iscart ? (
            is_dome_registered_successfully ? (
              <div className="cardboard-white mb-10">
                <p className="text-primary mb-15">
                {b?.t('It has been sucessfully registered')} 
                </p>
                <h4 className="font-bold">
                  {this.state.dom_name
                    ? this.state.dom_name
                    : profile.dome_name}
                  <img src={changeIcon1} alt="check" />
                </h4>
              </div>
            ) : (
              <>
                {!this.context.profile.dome_error && !dome_point_unavailable ? (
                  <div className="cardboard-white mb-10">
                    <p className="text-primary mb-15">
                    {b?.t('You may register a new Dome Address in this location')} 
                      
                    </p>
                    <h4 className="font-bold">
                      {this.state.dom_name
                        ? this.state.dom_name
                        : profile.dome_name}
                      {isPaid && <img src={changeIcon1} alt="check" />}
                    </h4>
                    <h3 className="mb-0">
                      $18.00 <small>{b?.t('for the first year')}</small>
                    </h3>
                  </div>
                ) : (
                  <div className="cardboard-white mb-10">
                    {!this.context.profile.dome_error &&
                    dome_point_unavailable ? (
                      <>
                        <p>
                          {b?.t('It has just been unavailable. Choose other purple spot.')}
                          
                          <h4 className="font-bold">
                            {this.state.dom_name
                              ? this.state.dom_name
                              : profile.dome_name}
                          </h4>
                        </p>
                      </>
                    ) : (
                      <p>
                        This location is not available. Move your cursor until
                        it changes from red to purple. Your cursor changes to
                        purple when it is more than 20 meters away from another
                        registered Dome nearby, and you may register a new Dome
                        Address from that pint on.{' '}
                      </p>
                    )}
                  </div>
                )}
              </>
            )
          ) : (
            <div className="cardboard-white mb-10"></div>
          )}
          {!isPaid && !is_dome_registered_successfully && (
            <div className="fx-jcsb flex-btn">
              {/* <button onClick={this.goToHome} className="ant-btn buttons rd-5 white-btn xs"> */}
              {/* <a href="#"> <img  onClick={this.goToHome}  src={Dashboard} className="dashboard-icon" /></a> */}
              <button
                onClick={this.cancelHandler}
                className="ant-btn buttons rd-5 white-btn xs"
              >
               {b?.t('Cancel')} 
              </button>
              {dome_point_unavailable ||
              this.context.profile.dome_error ? null : (
                <>
                  <button
                    onClick={this.addToCart}
                    className="ant-btn buttons rd-5 gray-btn xs"
                  >
                   {b?.t('Add to Cart')} 
                  </button>
                  <button
                    data-name="Register"
                    onClick={this.registerDomHandler}
                    className="ant-btn buttons rd-5 yellow-gradient xs"
                  >
                   {b?.t('Register')} 
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    const {translation}=this.props
    let isPaid =
      this.context.profile.is_paid !== undefined
        ? this.context.profile.is_paid
        : false;
    const { placeHolder } = this.state;
    const contextProfile = this.context;
    let profile = contextProfile.profile;
    const is_dome_registered_successfully =
      profile['is_dome_registered_successfully'];
    const dome_point_unavailable = profile['dome_point_unavailable'];
    let enter_for_location = this.state.enter_for_location
      ? this.state.enter_for_location
      : profile.enter_for_location || false;

    return (
      <>
        <div
          style={this.context.profile.payedFromCart ? { height: '300px' } : {}}
          className={`notranslate dome-address ${
            this.context.profile.payedFromCart ? 'scrollSection' : ''
          }`}
        >
          <div className="fx-jcsb-aic mb-15">
            <h4 className="mb-0">{translation("Dome Address location")}:</h4>
            {/* <img className="iconx cursor" data-name='Cart' onClick={this.registerDomHandler} src={cartIcon} alt="cart" /> */}
            <div className="cart-ic">
              <img
                className="iconx cursor"
                data-name="Cart"
                onClick={this.getCartItems}
                src={cartIcon}
                alt="cart"
              />
              <span className="cart-ic__count">
                {this.context.profile.item_count}
              </span>
            </div>
          </div>
          {!enter_for_location ? (
            <div className="addressDetail">
              <div className="fx-jcsb">
                <div className="dome-address__content">
                  <p>
                  {translation("Enter the address nearby your wanted location in order to pinpoint the location you’d like to find.")}
                    
                  </p>
                </div>
                {/* <i className="iconx cursor">
                  <img src={infoIcon} alt="Info" />
                </i> */}
              </div>
              <div className="form-group">
                <label> {translation('Address')}:</label>
                <Autocomplete
                   ref={this.autoRef}
                  placeholder={
                    placeHolder ? translation( 'Enter an address or location name') : ''
                  }
                   apiKey={gmk}
                  style={{ width: '100%' }}
                  onPlaceSelected={(place, inputRef, autocomplete) => {
                    this.setState({
                      // dom_address: place.formatted_address,
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                    });
                  }}
                  onClick={this.removePlaceHolder}
                  options={{
                    fields: [
                      'address_components',
                      'geometry',
                      'name',
                      'formatted_address',
                    ],
                    strictBounds: false,
                    types: [],
                  }}
                />
              </div>
              <div className="fx-jcfe">
                <span>{this.state.error}</span>
                {this.state.lat ? (
                  <button
                    onClick={this.onCancel}
                    className="ant-btn buttons rd-5 white-btn xs mr-5"
                  >
                   {translation('Cancel')} 
                  </button>
                ) : (
                  ''
                )}
                <input
                  type="submit"
                   value={translation("Enter")}
                  onClick={this.onClickHandler}
                  className="ant-btn buttons yellow-gradient xs"
                />
              </div>
            </div>
          ) : (
            this.renderDomeStatusAfterPayment(
              is_dome_registered_successfully,
              profile,
              dome_point_unavailable,
              isPaid
            )
            // <div className="addressLocation">
            //   {!this.context.profile.iscart ? (
            //     <div className="grid-row">
            //       <div className="col col-6 form-group">
            //         <label>Latitude:</label>
            //         <input
            //           type="text"
            //           value={this.context.profile.dome_lng}
            //           // value={parseFloat(this.context.profile.dome_lng).toFixed(
            //           //   11
            //           // )}
            //           className="inp"
            //         />
            //       </div>
            //       <div className="col col-6 form-group">
            //         <label>Longitude:</label>
            //         <input
            //           type="text"
            //           value={this.context.profile.dome_lat}
            //           // value={parseFloat(this.context.profile.dome_lat).toFixed(
            //           //   11
            //           // )}
            //           className="inp"
            //         />
            //       </div>
            //     </div>
            //   ) : (
            //     <div className="grid-row"></div>
            //   )}
            //   {!this.context.profile.iscart ? (
            //     is_dome_registered_successfully ? (
            //       <div className="cardboard-white mb-10">
            //         <p className="text-primary mb-15">
            //           It has been sucessfully registered
            //         </p>
            //         <h4 className="font-bold">
            //           {this.state.dom_name
            //             ? this.state.dom_name
            //             : profile.dome_name}
            //           <img src={changeIcon1} alt="check" />
            //         </h4>
            //       </div>
            //     ) : (
            //       <>
            //         {!this.context.profile.dome_error &&
            //         !dome_point_unavailable ? (
            //           <div className="cardboard-white mb-10">
            //             <p className="text-primary mb-15">
            //               You may register a new Dome Address in this location
            //             </p>
            //             <h4 className="font-bold">
            //               {this.state.dom_name
            //                 ? this.state.dom_name
            //                 : profile.dome_name}
            //               {isPaid && <img src={changeIcon1} alt="check" />}
            //             </h4>
            //             <h3 className="mb-0">
            //               $18.00 <small>for the first year</small>
            //             </h3>
            //           </div>
            //         ) : (
            //           <div className="cardboard-white mb-10">
            //             {!this.context.profile.dome_error &&
            //             dome_point_unavailable ? (
            //               <>
            //                 <p>
            //                   It has just been unavailable. Choose other purple
            //                   spot.
            //                   <h4 className="font-bold">
            //                     {this.state.dom_name
            //                       ? this.state.dom_name
            //                       : profile.dome_name}
            //                   </h4>
            //                 </p>
            //               </>
            //             ) : (
            //               <p>
            //                 This location is not available. Move your cursor
            //                 until it changes from red to purple. Your cursor
            //                 changes to purple when it is more than 20 meters
            //                 away from another registered Dome nearby, and you
            //                 may register a new Dome Address from that pint on.{' '}
            //               </p>
            //             )}
            //           </div>
            //         )}
            //       </>
            //     )
            //   ) : (
            //     <div className="cardboard-white mb-10"></div>
            //   )}
            //   {!isPaid && !is_dome_registered_successfully && (
            //     <div className="fx-jcsb flex-btn">
            //       {/* <button onClick={this.goToHome} className="ant-btn buttons rd-5 white-btn xs"> */}
            //       {/* <a href="#"> <img  onClick={this.goToHome}  src={Dashboard} className="dashboard-icon" /></a> */}
            //       <button
            //         onClick={this.cancelHandler}
            //         className="ant-btn buttons rd-5 white-btn xs"
            //       >
            //         Cancel
            //       </button>
            //       {dome_point_unavailable ||
            //       this.context.profile.dome_error ? null : (
            //         <>
            //           <button
            //             onClick={this.addToCart}
            //             className="ant-btn buttons rd-5 gray-btn xs"
            //           >
            //             Add to Cart
            //           </button>
            //           <button
            //             data-name="Register"
            //             onClick={this.registerDomHandler}
            //             className="ant-btn buttons rd-5 yellow-gradient xs"
            //           >
            //             Register
            //           </button>
            //         </>
            //       )}
            //     </div>
            //   )}
            // </div>
          )}
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  role: state.userData.role,
  translation: state.userData.translation,

});

export default connect(mapStateToProps, null)(DomCreation);
// export default DomCreation;
